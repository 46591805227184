const jsonResponse = {
  en: {
    "app.title": "Athena Board Portal",
    "username":"Username",
    "password":"Password",
    "error.usererror":"Username is required",
    "error.passerror":"Password is required",
    "error.userandpass":"Username is required",
  },
  de: {
    "app.title": "Athena Board Portal",
    "username":"Username",
    "password":"Password",
    "error.usererror":"Username is required",
    "error.passerror":"Password is required",
    "error.userandpass":"Username is required",
  },
};

export default jsonResponse;
