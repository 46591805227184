import React, { useState } from 'react';
import { MuiButton, LoadingOverlay } from '@common/MUI';
import { boardService } from '@services/admin';
import { popoverAction, userActions } from '@actions/admin';
import { Stack } from '@mui/material';
import { store } from '../../../apps/admin/store';
import { RoutesConstants } from '@constants';

export const ResetSampleDataButton = (props) => {
    const [loading, setLoading] = useState(false);
    if (!props.customerIds) { return null; }

    function resetSampleData() {
        Promise.all(props.customerIds.map((customerId => {
            return new Promise((resolve, reject) => {
                boardService.resetSampleData(customerId)
                    .then((response) => { resolve(); })
                    .catch(() => { })
                    .finally(() => {
                    })
            })
        })))
            .then(() => {
            })
            .finally(() => {
                store.dispatch(popoverAction.remove('sample_demo_is_loading_popover'));
                setLoading(false);
            })
            setTimeout(() => {
                store.dispatch(popoverAction.remove('sample_demo_is_loading_popover'));
                localStorage.removeItem('AppWeb');
                localStorage.removeItem('AthenaWeb');
                props.history.push(RoutesConstants.logout);
            }, 1000);
    }

    return (
        <React.Fragment>
            <MuiButton
                variant='contained'
                loading={loading}
                style={{ marginLeft: '25px' }}
                onClick={() => {
                    store.dispatch(popoverAction.showDialog({
                        dialogId: 'reset_sample_data_dialog',
                        title: 'Are you sure you want to reset the demonstration data?',
                        content: <div style={{ paddingBottom: '20px' }}>
                            <div>You will be logged out.</div>
                            <div>This demo account will be locked during the reset.</div>
                            <div>The process may take up to 15 minutes.</div>
                            <div>You will receive an email when the process has completed and can then log in.</div>
                        </div>,
                        dialogActions: <div><Stack direction='row' spacing={2}>
                            <MuiButton variant='contained' type='red' onClick={() => { store.dispatch(popoverAction.remove('reset_sample_data_dialog')); }}>No</MuiButton>
                            <MuiButton variant='outlined' onClick={() => {
                                setLoading(true);
                                store.dispatch(popoverAction.remove('reset_sample_data_dialog'));
                                store.dispatch(popoverAction.showDemoDataPopulateDialog(-1, true));
                                resetSampleData();
                            }}>Yes</MuiButton></Stack>
                        </div>
                    }))
                }}
            >Reset demo data {props.customerIds.length > 1 ? 'for all your companies' : ''}</MuiButton>
        </React.Fragment>
    )
} 