import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { board } from './board.reducer';
import { file } from './file.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { binder } from './binder.reducer';
import { binderUsage } from './usage.reducer';
import { binderItems } from './bitems.reducer';
import { company } from './company.reducer';
import { uploadQueue } from './uploadqueue.reducer';
import { device } from './device.reducer';
import { minutes } from './minutes.reducer';
import { popovers } from '../popover.reducer';
import { marketingMessages } from '../marketing.reducer';
import { kvp } from './kvp.reducer';
import { adminPermissions } from './adminPermissions.reducer';
import { intlReducer } from 'react-intl-redux'

const rootReducer = combineReducers({
  authentication,
  registration,
  board,
  binder,
  binderUsage,
  binderItems,
  company,
  file,
  uploadQueue,
  users,
  alert,
  device,
  minutes,
  popovers,
  marketingMessages,
  keyValuePair: kvp,
  adminPermissions,
  intl: intlReducer,
});

export default rootReducer;
