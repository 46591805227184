import React from 'react';
import { FaSearch as Search, FaEye as EyeIcon, FaRegEye as SeeIcon } from 'react-icons/fa';
import autosize from "autosize";
import { Icon, TextInput as AceTextInput} from '@ansarada/ace-react';

class TextExpand extends React.Component {
  constructor(props) {
    super(props);
    this.refNode = null
    this.onResize = this.onResize.bind(this)
    this.state = {
      text: this.props.value,
      onResize: this.onResize,
    }

  }

  componentDidMount() {
    autosize(this.refNode);
  }

  onResize(){
    autosize.update(this.refNode);
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(state.text !== nextProps.value){
      setTimeout(state.onResize, 10)
    }
    state.text = nextProps.value

    return state
  }

  render() {
    const style = {
      minHeight: "46px",
      resize: "none",
      padding: "9px",
      boxSizing: "border-box",
    };
    const { name, className, placeholder, value, type, autoComplete, maxlength, onChange, onFocus, onKeyPress, onBlur } = this.props
    return (
      <textarea
        className={className}
        name={name}
        type={type}
        style={style}
        ref={c => { this.refNode = c }}
        placeholder={placeholder!==undefined?placeholder:""}
        rows={1}
        defaultValue=""
        value={value}
        wrap="hard"
        autoComplete={autoComplete}
        maxLength={maxlength}

        onChange={(e) => { if(onChange !== undefined) onChange(e); }}
        onFocus={(e) => { if(onFocus !== undefined) onFocus(e); }}
        onKeyPress={(e) => { if(onKeyPress !== undefined) onKeyPress(e); }}
        onBlur={(e) => {
          if(onBlur !== undefined) onBlur(e);
        }}
      />
    );
  }
}

export class TextInput extends React.Component {
  constructor(props) {
    super(props);

    var max = 120, type = ""
    if(this.props.maxLength !== undefined && this.props.maxLength > 0)
      max = this.props.maxLength
    if(this.props.type !== undefined){
      type = this.props.type
    }
    this.state = {
      focus: false,
      firstChange: false,
      maxLength: max,
      type: type,
      showPassword: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onFocusout = this.onFocusout.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.refrence = null;
  }

  componentDidMount() {
    if(this.refrence !== null){
      if(this.props.hasOwnProperty('refpoint')){
        this.props.refpoint(this.refrence);
      }
    }
  }

  onFocus(e){
    this.setState({focus: true});
    if(this.props.hasOwnProperty('onFocusIn'))
      this.props.onFocusIn();
  }

  onFocusout(e){
    this.setState({focus: false, firstChange: false});
    if(this.props.hasOwnProperty('onFocusOut'))
      this.props.onFocusOut();
  }

  onChangeValue(value){
    const newValue = value.replace(/[&\\#+()$~%^":;?<>=\[\]_{}]/g,'')
    this.props.onChange({
      target: {
        name: this.props.name,
        value: newValue
      }
    });
  }

  onChange(event){
    if(!this.state.firstChange && this.state.focus){
      this.setState({firstChange: true});
      if(this.props.hasOwnProperty('onFirstChange'))
        this.props.onFirstChange();
    }

    let { name, value } = event.target;
    if(value === undefined){
      value = event.target.innerHTML
      name = this.props.name
    }
    var newValue = value.replace(/[\/\\#+$^@":;<>=\[\]_{}]/g,'')
    if(this.state.type === 'password'){
      newValue = value.trim()
    }else if(this.state.type === 'email'){
      newValue = value.split('').filter(char => /[a-zA-Z0-9@+'._~-]/.test(char)).join('')
//      newValue = value.replace(/\s/g, '') //.replace(/[&\/\\#,+()$~%!^'":;*?<>=\[\]_{}]/g,'')
    }else if(this.state.type === 'login'){
      newValue = value.split('').filter(char => /[a-zA-Z0-9@+'._~-]/.test(char)).join('')
//      newValue = value.replace(/[&\\#()$~%^";?<>=\[\]_{}]/g,'').toLowerCase().trim()
    }else if(this.state.type === 'search'){
      newValue = value.replace(/[&\\#+()$~%^":;?<>=\[\]_{}]/g,'')
    }else if(this.state.type === 'location'){
      newValue = value//.replace(/[&\\#+()$~%^":;?<>=\[\]_{}]/g,'')
    }

    this.props.onChange({
      target: {
        name,
        value: newValue
      }
    });
  }

  onKeyPress(e){
    if(e.key == 'Enter'){
      if(this.props.multiline === undefined)
        this.refrence.blur();
      if(this.props.hasOwnProperty('onReturn'))
        this.props.onReturn();
    }
  }

  onShowPassword(state, event){
    this.setState({showPassword: state})
    if(this.props.showPassword)
      this.props.showPassword(state)
  }

  render() {
    var value = "", initial = "", autofocus = false, hide = false, search = false;
    if(this.props.hasOwnProperty('value'))
      value = this.props.value;
    var multi = false;
    if(this.props.hasOwnProperty('multiline'))
      multi = this.props.multiline;
    if(this.props.hasOwnProperty('initial'))
      initial = this.props.initial;
    if((this.props.type !== undefined && this.props.type === 'search') || initial === 'Search')
      search = true;
    if(this.props.hasOwnProperty('autoFocus'))
      autofocus = this.props.autoFocus;
    if(this.props.excludeAnalytics === true || this.state.type === 'password'){
      hide = true
    }

    if(search){
      return (
        <AceTextInput
          className={`${this.props.stylenormal} ${value === "" && this.props.styleempty} ${hide?"fs-exclude":""}`}
          name={this.props.name}
          onChangeValue={this.onChangeValue}
          onKeyPress={this.onKeyPress}
          onFocus={ this.onFocus }
          onBlur={ this.onFocusout }
          maxLength={this.state.maxlength}
          autoFocus={autofocus}
          autoComplete={this.props.autoComplete!==undefined?this.props.autoComplete:""}
          value={value === "" && this.state.focus === false ? initial : value}
          //ref={(node) => { this.refrence = node;}}

          clearable={true}
          //defaultValue=""
          icon={<Icon glyph="Search" />}
          placeholder={this.props.placeholder}
          />
      )
    }
    if(!multi){
      var c = "ainput maxWidth"
      if(this.props.showHint === true) c = "toolp ainput maxWidth"
      if(this.state.type === 'password') c = "ainput lpassword"
      return (
        <div className={c}>
          <input type={this.state.type === 'password' && !this.state.showPassword? "password" : "text"}
            className={`maxWidth ${this.props.stylenormal} ${value === "" && this.props.styleempty} ${hide?"fs-exclude":""}`}
            name={this.props.name}
            onChange={this.onChange}
            onKeyPress={this.onKeyPress}
            onFocus={ this.onFocus }
            onBlur={ this.onFocusout }
            maxLength={this.state.maxlength}
            autoFocus={autofocus}
            autoComplete={this.props.autoComplete!==undefined?this.props.autoComplete:""}
            value={value === "" && this.state.focus === false ? initial : value}
            ref={(node) => { this.refrence = node;}}
            placeholder={this.props.placeholder}
            data-lpignore="true"
          />
          {this.props.showHint === true &&
            <span className="toolptext toolp-delay" style={{left: 200, bottom: -30}}>{value}</span>
          }
          {this.state.type === 'password' && !this.state.showPassword &&
            <EyeIcon onClick={this.onShowPassword.bind(this, true)}/>
          }
          {this.state.type === 'password' && this.state.showPassword &&
            <SeeIcon onClick={this.onShowPassword.bind(this, false)}/>
          }
        </div>
      );
    }else{
      return (
        <TextExpand
          type={this.state.type === 'password' && value !== ""? "password" : "text"}
          className={`ainput ${this.props.stylenormal} ${value === "" && this.props.styleempty} ${hide?"fs-exclude":""}`}
          name={this.props.name}
          onChange={this.onChange}
          onFocus={ this.onFocus }
          onKeyPress={this.onKeyPress}
          onBlur={ this.onFocusout }
          wrap="hard"
          maxLength={this.state.maxlength}
          autoComplete={this.props.autoComplete!==undefined?this.props.autoComplete:""}
          value={value === "" && this.state.focus === false ? initial : value}
          ref={(node) => { this.refrence = node;}}
        />
      );
/*      return (
        <textarea type={this.state.type === 'password' && value !== ""? "password" : "text"}
          className={`${this.props.stylenormal} ${value === "" && this.props.styleempty}`}
          name={this.props.name}
          onChange={this.onChange}
          onFocus={ this.onFocus }
          onKeyPress={this.onKeyPress}
          onBlur={ this.onFocusout }
          wrap="hard"
          maxLength={this.state.maxlength}
          autoComplete={this.props.autoComplete!==undefined?this.props.autoComplete:""}
          value={value === "" && this.state.focus === false ? initial : value}
          ref={(node) => { this.refrence = node;}}
        />
      );*/
    }
  }
}
