/* global appConfig */
import { createStore, applyMiddleware, compose  } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';

const middlewares = [thunkMiddleware];
if (process.env.NODE_ENV === 'development' || appConfig.env === 'dev') {
  const loggerMiddleware = createLogger();

  middlewares.push(loggerMiddleware);
}

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

export const enhancer = composeEnhancers(
  applyMiddleware(...middlewares),
);
