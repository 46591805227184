import { companyConstants, customerConstants, userConstants, boardConstants } from '@constants/admin/';
import { UserTypeEnum } from '@constants/common.constants';
import { copySettings } from '@lib/simpletools';

export var CUSTOMER_INITIAL_STATE = {
  id:"",
  name:"",
  companyName:"",
  mainUserId:"",
  customerOwner: "",
  billingResponsibility: false,
  billingCustomerId: "",
  parentCompanyId: "",
  customerContactId: "",
  customerContact: null,
  acn: "",
  abn: "",
  gststatus: "",
  currency: "AUD",
  postalAddressId: "",
  postalAddress: null,
  billingAddressId: "",
  billingAddress: null,
  recoveryCardDeliveryId: "",
  recoveryCardDelivery: null,
  keepRecoveryCardsForCustomer: true,
  userLimit: 12,
  billingCycle: "",
  contractPeriod: "",
  commencementDate: null,
  accountActive: false,
  accountSuspended: true,
  accountType: "",
  accountPricingTierId: "",
  accountPricingTier: null,
  passwordPolicy: '',
  passwordPolicyRegex: '',
  passwordPolicyDescription: '',
  requiresRecoveryCard: false,
  daysBeforeReset: 365,
  daysBeforePasswordEntry: 365,
  deleteDataRequestUserId : "",
  deleteDataRequestDate : "",
  error: "",
  loading: false,
  userIds: null,
  adminUserIds: null,
  customerSettings: null,
  customerProperties: null,
  boardIds: null,
  boards: null,
  isDeleted: false,
};

function populateUsageLogs(state, logsData){
  logsData.customers.forEach((c)=>{
    state = populateCustomer(state, c)
  })
  for(var key in logsData.company){
    var c = logsData.company[key]
    state[c.id].usages = c.usages
  }

  return state
}

function populateCustomerList(state, list){
  state.list = []
  if(list === undefined) return state
  list.forEach(function(item){
    state.list.push(item.id)
    populateCustomer(state, item)
  });

  return state
}

function listCustomer(state, list){
  if(list === undefined) return state
  list.forEach((cust)=>{
    state = setCustomer(state, cust)
  })

  return state
}

function setCustomer(state, item){
  if(item === undefined) return state
  var id = item.id
  if(item.customerId !== undefined) id = item.customerId

  if(state[id] === undefined)
    state[id] = Object.assign({}, CUSTOMER_INITIAL_STATE);

  Object.keys(item).map(e => {
    state[id][e] = item[e]
  });

  return state
}

function updateCompanyName(state, payload) {
  if (state && state[payload.customerId]) {
    state[payload.customerId].companyName = payload.companyName;
    if (state[payload.customerId].customerSettings) {
      state[payload.customerId].customerSettings.companyName = payload.companyName;
    }
    if (state[payload.customerId].boards) {
      for (let i in state[payload.customerId].boards) {
        if (state[payload.customerId].boards[i]) {
          state[payload.customerId].boards[i].customerName = payload.companyName;
        }
      }
    }
  }
  return state;
}

function populateCustomer(state, item){
  if(item === undefined || item.id === undefined) return state
  if(state[item.id] === undefined)
    state[item.id] = Object.assign({}, CUSTOMER_INITIAL_STATE);
  state[item.id].loading = false;
  state[item.id].error = "";

  var skip = ['adminUsers','mainUser','users']
  Object.keys(item).map(e => {
    if(skip.includes(e)) return;
    state[item.id][e] = item[e]
  });

  if(item.hasOwnProperty("users")){
    state[item.id].userIds = [];
    item.users.forEach(function(o){
      state[item.id].userIds.push(o.id);
    })
  }
  if(item.hasOwnProperty("adminUsers")){
    state[item.id].adminUserIds = [];
    item.adminUsers.forEach(function(o){
      if(o.type === UserTypeEnum.Master) return
      state[item.id].adminUserIds.push(o.id);
    })
  }
  if(item.hasOwnProperty("boards")){
    state[item.id].boardIds = [];
    item.boards.forEach(function(o){
      state[item.id].boardIds.push(o.id);
    })
  }
  if(item.hasOwnProperty("mainUser")){
    state[item.id].mainUserId = item.mainUser.id;
  }

  return state;
}

function getCompanySettingReq(state, id){
  if(state[id] === undefined) return state
  state[id].customerSettings = true
  return state
}

function getCompanySetting(state, item){
  if(item == undefined) return state
  var id = "";

  if(item.customerId !== undefined)
    id = item.customerId

  if(id === "") return state;

  if(state[id] === undefined){
    state[id] = Object.assign({}, CUSTOMER_INITIAL_STATE)
    state[id].loading = false
  }

  state[id].customerSettings = item
  if(item.countryBlacklist !== undefined)
    state[id].customerSettings.defaultCountryBlackList = item.countryBlacklist
  if(item.selectedPreviousMoveMechanism !== undefined)
    state[id].customerSettings.defaultSelectedPreviousMoveMechanism = item.selectedPreviousMoveMechanism

  if(item.settings !== null && item.settings !== undefined){
    var newSettings = copySettings(item.settings);
    state[id].customerSettings.defaultSettings = newSettings;
  }

  if(item.numberOfDaysPrevious !== undefined)
    state[id].customerSettings.defaultNumberOfDaysPrevious = item.numberOfDaysPrevious
  if(item.endOfBoardYear !== undefined)
    state[id].customerSettings.defaultEndOfBoardYear = item.endOfBoardYear
  if(item.endOfFinancialYear !== undefined)
    state[id].customerSettings.defaultEndOfFinancialYear = item.endOfFinancialYear

  return state
}

function getCompanySettingFail(state, id){
  if(state[id] === undefined) return state
  state[id].customerSettings = null
  return state
}

function setCompanySetting(state, items){
  items.forEach((item)=>{
    if(item === undefined) return
    if(state[item.customerId] !== undefined){
      state[item.customerId].customerSettings = item
    }
  })
  return state
}

function requestDetails(state, id){
  if(state[id] === undefined) return state
  state[id].postalAddress = true
  state[id].customerContact = true
  return state
}

function failedDetails(state, id){
  if(state[id] === undefined) return state
  state[id].postalAddress = null
  state[id].customerContact = null
  return state
}

function companyActiveState(state, id, active){
  if(id === undefined) return state
  if(state[id] === undefined)
    state[id] = Object.assign({}, CUSTOMER_INITIAL_STATE)
  state[id].loading = false

  state[id].accountActive = active

  return state
}

function companyToDelete(state, id, done, authCode){
  if(id === undefined) return state
  if(state[id] === undefined)
    state[id] = Object.assign({}, CUSTOMER_INITIAL_STATE)
  state[id].loading = false
  if(authCode !== ""){
    state[id].toDelete = false
    state[id].deleteDataRequestUserId = done
  }else{
    state[id].toDelete = true
    state[id].deleteDataRequestUserId = done
  }
  return state
}

function companyToDeleteClear(state, id){
  if(id === undefined) return state
  if(state[id] === undefined)
    state[id] = Object.assign({}, CUSTOMER_INITIAL_STATE);
  state[id].loading = false;
  state[id].toDelete = false;
  return state;
}

function companyDeleteRequest(state, id, user){
  if(id === undefined) return state
  if(state[id] === undefined)
    state[id] = Object.assign({}, CUSTOMER_INITIAL_STATE);
  state[id].loading = true;
  if(user !== "") state[id].toDelete = false;
  state[id].error = "";
  return state;
}

function companyError(state, id, error){
  if(id === undefined) return state
  if(state[id] === undefined)
    state[id] = Object.assign({}, CUSTOMER_INITIAL_STATE);
  state[id].loading = false;
  state[id].error = error;
  return state;
}

function requestCustomer(state, id){
  if(id === undefined) return state
  if(state[id] === undefined)
    state[id] = Object.assign({}, CUSTOMER_INITIAL_STATE);
  state[id].loading = true;
  return state;
}

function requestProperties(state, id){
  if(id === undefined) return state
  if(state[id] === undefined)
    state[id] = Object.assign({}, CUSTOMER_INITIAL_STATE);
  state[id].customerProperties = true;
  return state;
}

function setProperties(state, payload){
  if(payload.customerId === undefined) return state
  if(state[payload.customerId] === undefined)
    state[payload.customerId] = Object.assign({}, CUSTOMER_INITIAL_STATE);
  state[payload.customerId].customerProperties = payload;
  return state;
}

function failedProperties(state, id){
  if(id === undefined) return state
  if(state[id] === undefined)
    state[id] = Object.assign({}, CUSTOMER_INITIAL_STATE);
  state[id].customerProperties = false;
  return state;
}

function setUser(state, item){
  if(item === undefined) return state
  if(item.customerId === undefined) return state

  if(state[item.customerId] === undefined)
    state[item.customerId] = Object.assign({}, CUSTOMER_INITIAL_STATE);

  let list = null
  if(item.type === "User"){
    list = state[item.customerId].userIds
  }else if(item.type === UserTypeEnum.Publish || item.type === UserTypeEnum.Create){
    list = state[item.customerId].adminUserIds
  }

  if(list !== null && list.find(o => o === item.id) === undefined)
    list.push(item.id)

  return state
}

function DeleteUser(state, id, customerId){
  if(state[customerId] === undefined) return state

  if(state[customerId].adminUserIds !== undefined && state[customerId].adminUserIds !== null){
    var index = state[customerId].adminUserIds.indexOf(id)
    if (index > -1) {
      state[customerId].adminUserIds.splice(index, 1)
    }
  }

  if(state[customerId].userIds !== undefined && state[customerId].userIds !== null){
    var index = state[customerId].userIds.indexOf(id)
    if (index > -1) {
      state[customerId].userIds.splice(index, 1)
    }
  }

  //adminCount
  //totalUserCount
  return state
}

export function company(state = {}, action) {
  switch (action.type) {
    case companyConstants.GET_COMPANY_REQUEST:
      return Object.assign({}, state, {loading: true});
    case companyConstants.GET_COMPANY_SUCCESS:
      return Object.assign({}, populateCustomer(state, action.payload), {loading: false});
    case companyConstants.GET_COMPANY_FAILURE:
      return Object.assign({}, state, {loading: false});
    case companyConstants.POPULATE_COMPANY_REQUEST:
      return Object.assign({}, state, {loading: true});
    case companyConstants.POPULATE_COMPANY_SUCCESS:
      return Object.assign({}, populateCustomer(state, action.payload), {loading: false});
    case companyConstants.POPULATE_COMPANY_FAILURE:
      return Object.assign({}, state, {loading: false});

    case companyConstants.GET_COMPANYDETAILS_REQUEST:
      return Object.assign({}, requestDetails(state, action.customerId));
    case companyConstants.GET_COMPANYDETAILS_SUCCESS:
      return Object.assign({}, setCustomer(state, action.payload));
    case companyConstants.GET_COMPANYDETAILS_FAILURE:
      return Object.assign({}, failedDetails(state, action.customerId));

    case userConstants.DEMO_ADDUSER:
    case userConstants.GETUSER_SUCCESS:
      return Object.assign({}, setUser(state, action.item));
    case userConstants.DELETE_SUCCESS:
      return Object.assign({}, DeleteUser(state, action.id, action.customerId))

    case companyConstants.GET_COMPANY_LIMITS_SUCCESS:
      return Object.assign({}, listCustomer(state, action.list));

    case userConstants.GETALL_REQUEST:
      return Object.assign({}, requestCustomer(state, action.customerId));
    case userConstants.GETALL_SUCCESS:
      return Object.assign({}, populateCustomer(state, action.items));

    case userConstants.POPULATEALL_USERS_REQUEST:
      return Object.assign({}, requestCustomer(state, action.customerId));
    case userConstants.POPULATEALL_USERS_SUCCESS:
      return Object.assign({}, populateCustomer(state, action.items));
    case userConstants.USERS_GETALL_SUCCESS:
      return Object.assign({}, populateCustomer(state, action.items));

    case userConstants.ADD_NEW_COMPANY_SUCCESS:
      return Object.assign({}, populateCustomer(state, action.items), {loading: false});

    case customerConstants.UPDATE_CUST_COMPANY_NAME_SUCCESS:
      return Object.assign({}, updateCompanyName(state, action), { latestAction: new Date() });

        
    case companyConstants.GET_ALL_COMPANY_REQUEST:
      return Object.assign({}, state, {loading: true});
    case companyConstants.GET_ALL_COMPANY_SUCCESS:
      return Object.assign({}, populateCustomerList(state, action.list), {loading: false});
    case companyConstants.GET_ALL_COMPANY_FAILURE:
      return Object.assign({}, state, {loading: false});

    case customerConstants.GET_CUST_SETTINGS_REQUEST:
      return Object.assign({}, getCompanySettingReq(state, action.id));
    case customerConstants.GET_CUST_SETTINGS_SUCCESS:
      return Object.assign({}, getCompanySetting(state, action.payload));
    case customerConstants.GET_CUST_SETTINGS_FAILURE:
      return Object.assign({}, getCompanySettingFail(state, action.id));

    case companyConstants.GET_COMPANYPROPERTIES_REQUEST:
      return Object.assign({}, requestProperties(state, action.customerId));
    case companyConstants.GET_COMPANYPROPERTIES_SUCCESS:
      return Object.assign({}, setProperties(state, action.payload));
    case companyConstants.GET_COMPANYPROPERTIES_FAILURE:
      return Object.assign({}, failedProperties(state, action.customerId));

    case companyConstants.UPDATE_COMPANY_SUCCESS:
      return Object.assign({}, setCustomer(state, action.payload));

    case customerConstants.SET_CUST_SETTINGS_SUCCESS:
      return Object.assign({}, getCompanySetting(state, action.payload));
    case customerConstants.UPDATE_CUST_SETTINGS_SUCCESS:
      return Object.assign({}, setCompanySetting(state, action.customers));

    case companyConstants.DELETE_COMPANY_REQUEST:
      return Object.assign({}, companyDeleteRequest(state, action.id, action.user));
    case companyConstants.DELETE_COMPANY_SUCCESS:
      return Object.assign({}, companyToDelete(state, action.id, action.done, action.authCode));
    case companyConstants.DELETE_COMPANY_FAILURE:
      return Object.assign({}, companyError(state, action.id, action.error));
    case companyConstants.CLEARDELETE_COMPANY_SUCCESS:
      return Object.assign({}, companyToDeleteClear(state, action.id));

    case companyConstants.CHECK_COMPANY_NAME_REQUEST:
      return Object.assign({}, state, {checkName: action.name});
    case companyConstants.CHECK_COMPANY_NAME_SUCCESS:
      return Object.assign({}, state, {checkName: action.result});
    case companyConstants.CHECK_COMPANY_NAME_FAILURE:
      return Object.assign({}, state, {checkName: false});
    case companyConstants.CHECK_COMPANY_NAME_CLEAR:
      return Object.assign({}, state, {checkName: undefined});

    case companyConstants.COMPANY_SUSPEND_SUCCESS:
      return Object.assign({}, companyActiveState(state, action.id, false));
    case companyConstants.COMPANY_ACTIVE_SUCCESS:
      return Object.assign({}, companyActiveState(state, action.id, true));

    case companyConstants.COMPANY_ACTIVE_REQUEST:
      return Object.assign({}, setCustomer(state, {id: action.id, loading: true}));
    case companyConstants.COMPANY_ACTIVE_SUCCESS:
      return Object.assign({}, setCustomer(state, {id: action.id, loading: false}));
    case companyConstants.COMPANY_ACTIVE_FAILURE:
      return Object.assign({}, setCustomer(state, {id: action.id, loading: false}));

    case customerConstants.GET_NOTFIC_SETTINGS_REQUEST:
      return Object.assign({}, setCustomer(state, {id: action.customerId, notification: null}));
    case customerConstants.GET_NOTFIC_SETTINGS_SUCCESS:
      return Object.assign({}, setCustomer(state, {id: action.customerId, notification: action.payload}));
    case customerConstants.GET_NOTFIC_SETTINGS_FAILURE:
      return Object.assign({}, setCustomer(state, {id: action.customerId, notification: false}));

    case companyConstants.AUDIT_POPULATEFILELIST_SUCCESS:
      return Object.assign({}, populateUsageLogs(state, action.allItem))

    case customerConstants.SWITCH_CUSTOMER:
      return state

    default:
      return state
  }
}
