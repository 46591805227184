import React from 'react';
import { MuiButton } from '@common/MUI';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export class ConfirmAlertModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render(){
    return (
      <Dialog
        fullWidth
        maxWidth='sm'
        open={this.props.open}
      >
        <DialogTitle>
          <div className="boardpanel spacebetween binder-modal-align">
            <div>
              <strong className='bold'>{this.props.title}</strong>
            </div>
            {this.props.showClose === true && (
              <IconButton onClick={this.props.onClose}>
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </DialogTitle>
        {this.props.content && (
          <DialogContent>
            <p>{this.props.content}</p>
          </DialogContent>
        )}
        <DialogActions>
          {this.props.actions.map((item,index)=>{
            return <MuiButton key={index} variant={item.variant} onClick={item.click}>{item.name}</MuiButton>
          })}
        </DialogActions>
      </Dialog>
  )}
}