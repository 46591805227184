import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { RoutesConstants } from '@constants/common.constants';
import { userActions } from '@actions/admin';
import { Button, Alerts } from '@common/admin';
import {
  FaTimes as ExitIcon,
  FaInfoCircle as InfroIcon,
  FaCircle as DotFill,
  FaRegCircle as DotEmpty,
} from 'react-icons/fa';
//import Tour from 'reactour'
import Tour from '@common/tour'
import { getHelp, SettingStorage, getVersion, getVer } from '@lib';
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import {
  CARDLOGINCHECK,
} from '@lib/limits';

import AthenaHelp from '@image/logo/athena-logo-blue.svg';

class WhatsNew extends React.Component {
  constructor(props) {
    super(props);

    const {username, name} = this.props

    this.state = {
      key: username +"-new"+name,
      rkey: username +"-recovery",
      steps: this.props.steps,
      currentStep: 0,
      showFlag: false,
      showMsg: false,
      showNeedsRecovery: false,
      showSixTeen: false,
    }

    this.conditions = this.conditions.bind(this)
    this.check = this.check.bind(this)
    this.recovery = this.recovery.bind(this)
    this.timerDelay = null
  }

  componentDidMount() {
    this.check()
    this.recovery()

    this.props.dispatch(userActions.getUserSerialCards(this.props.myId));

    this.timerDelay = setTimeout(this.recovery, 15000)
  }

  componentWillUnmount() {
    clearTimeout(this.timerDelay)
  }

  static getDerivedStateFromProps(nextProps, state){
    const { myId, userItems, companies, customerId } = nextProps
    if(myId === "") return
    if(userItems === undefined) return null
    if(userItems[myId] === undefined) return null

    let doRecoverCards = false
    if(companies[customerId] !== undefined && companies[customerId].requiresRecoveryCard === true)
      doRecoverCards = true

    var showNeedsRecovery = false
    if(userItems[myId].recoveryCards !== null && doRecoverCards){
      var f = userItems[myId].recoveryCards.find(o => o.keptOnBehalfOfCustomer !== true)
      if(f === undefined)
        showNeedsRecovery = true
    }

    return {
      showNeedsRecovery,
    }
  }

  check(){
    if(this.state.steps){
      SettingStorage.Get(this.state.key)
      .then((data)=>{
        this.conditions(data.key)
      }).catch((e)=>{
        if(e === "pending"){
          setTimeout(this.check, 1000)
          return
        }
        //we dont have key set
        this.conditions(0.0)
      })
    }
  }

  recovery(){
    SettingStorage.Get(this.state.rkey)
    .then((data)=>{
      if(data.key <= 0){
        this.setState({showSixTeen: true})
      }
    }).catch((e)=>{
      if(e === "pending"){
        setTimeout(this.recovery, 1000)
        return
      }

      this.setState({showSixTeen: true})
    })
  }

  conditions(current){
    if(this.state.steps){
      if(this.state.steps.some(item => item.version > current)){
        var steps = this.state.steps.filter(o => o.version > current)
        if(steps.length)
          this.setState({showFlag: true, steps})
      }
    }
  }

  onShow(){
    this.setState({
      showFlag: false,
      showMsg: true,
    })
  }

  onClose(){
    this.setState({showFlag: false, showMsg: false})
    SettingStorage.Put({id: this.state.key, key: getVer()}).then(()=>{}).catch((e)=>{});
  }

  selectorContent(){
    if(this.state.steps.length  === 0) return;
    if(this.state.currentStep >= this.state.steps.length) return;
    var step = this.state.steps[this.state.currentStep];
    if(typeof step.content === 'function') return step.content();
    return step.content;
  }

  selectorDots(){
    var array = [];

    for(var x=0; x<this.state.steps.length; x++){
      if(this.state.currentStep === x){
        array.push(
          <DotFill key={x} className="tourdot" color="#4680ff"/>
        )
      }else{
        array.push(
          <span key={x} className="tourdotEmpty" onClick={this.onMove.bind(this, x)}></span>
        )
      }
    }
    return array
  }

  onMove(pos){
    this.setState({currentStep: pos});
  }

  onPrevious(){
    if(this.state.currentStep==0) return;

    var pos = this.state.currentStep-1;
    this.setState({currentStep: pos});
  }

  onNext(){
    if(this.state.currentStep==this.state.steps.length-1) return;

    var pos = this.state.currentStep+1;
    this.setState({currentStep: pos});
  }

  onCardShow(){
    this.props.history.push({
      pathname: RoutesConstants.myaccount,
      query: { recovery: true }
    });
  }

  onCardClose(){
    this.setState({showNeedsRecovery: false, showSixTeen: false})
    SettingStorage.Put({id: this.state.rkey, key: CARDLOGINCHECK}).then(()=>{}).catch((e)=>{
    });
  }

  render(){
    return (
      <div>
        {this.state.showNeedsRecovery && this.state.showSixTeen &&
          <div className="centerFlex">
            <Alerts
              icon="info"
              type="white"
              title={"Have you received a Recovery Card?"}
              text={"Add your Recovery Card now to help reset your password in future"}
              options={[
                {
                  title: "Dismiss",
                  onClick: this.onCardClose.bind(this),
                  type: "clear"
                },
                {
                  title: "Add Card",
                  onClick: this.onCardShow.bind(this),
                  type: ""
                }
              ]}
              />
          </div>
        }
        {/*this.state.showFlag &&
          <div className="centerFlex">
            <Alerts
              icon="info"
              type="blue"
              title={"See what's new in version "+getVersion()+"?"}
              options={[
                {
                  title: "Show Me",
                  onClick: this.onShow.bind(this),
                  type: "high"
                },
                {
                  title: "No Thanks",
                  onClick: this.onClose.bind(this),
                  type: "high"
                }
              ]}
              />
          </div>
        */}
        {this.state.showMsg &&
          <div className="aBoxTop-overlay">
            <div className="aPopup-box">
            <ExitIcon size={24} className="exitbut" color='#999999' onClick={this.onClose.bind(this)}/>
            <div className="aPopup-HeaderNB">
              <div>
                <h1 style={{marginBottom:11}}>Whats New</h1>
              </div>
            </div>
            <div className="aPopup-content normalText">
              <div>
                {this.selectorContent()}
              </div>
              {this.state.steps.length > 1 &&
                <div className="center" style={{marginTop: 20}}>
                  {this.selectorDots()}
                </div>
              }
            </div>
            <div className="boardpanel spacebetween" style={{margin: '20px 40px'}}>
              {this.state.currentStep !== 0 ?
                <button className="btn-clear" onClick={this.onPrevious.bind(this)}>Previous</button>
                :
                <div/>
              }
              {this.state.currentStep === this.state.steps.length-1 ?
                <button className="btn-bg" onClick={this.onClose.bind(this)}>Got It</button>
                :
                <button className="btn-bg" onClick={this.onNext.bind(this)}>Next</button>
              }
            </div>
            </div>
          </div>
        }
      </div>
    )
  }
}


function mapStateToProps(state) {
  const { data } = state.users;
  const { username, userId, customerId } = state.authentication;
  return {
    myId: userId,
    userItems:data,
    username,
    companies: state.company,
  };
}

const connectedWhatsNew = connect(mapStateToProps)(WhatsNew);
export { connectedWhatsNew as WhatsNew };
