
//item : {id: '', el: <Popper>/<Popover>/<Dialog>}

import { popoverConstants } from "../constants/admin";

export function popovers(state = [], action) {
    switch (action.type) {
        case popoverConstants.ADD_POPOVER:
            return [...(state.filter((p) => p.id !== action.payload.id)), action.payload];
        case popoverConstants.REMOVE_POPOVER:
            return state.filter((p) => p.id !== action.payload);
        case popoverConstants.CLEAR_ALL:
            return [];
        default:
            return state;
    }
}