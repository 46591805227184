import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '@actions/admin';
import { webConstants } from '@constants/admin';
import { Button, TextInput } from '@common';
import { MuiButton, LoadingOverlay } from '@common/MUI';
import {
  FaTimes as ExitIcon,
  FaSyncAlt as RefreshIcon,
} from 'react-icons/fa';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingIndicator } from '@ansarada/ace-react';
import ProgressBar from './progressBar';

import * as CrytpoLib from '@lib/cryptojs';
import { ReplaceValue, DeviceFingerprint } from '@lib/simpletools';
import { keysStorage, SettingStorage } from '@lib/indexeddb';
import { RoutesConstants } from '@constants/common.constants';

import '@pages/loginpage/loginpage.css';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';

const LOGOUT_TIMER = 15 * 60 * 1000;

export class LockScreenBase extends React.Component {
  constructor(props) {
    super(props);

    var initial = {
      current: 0,
      total: 0,
      type: '',
      currentfile: "",
      overallPercent: 0,
      sectionPercent: 0,
    }
    if (this.props.Queue !== undefined) {
      if (this.props.Queue['master'] !== undefined) {
        initial.current = this.props.Queue['master'].current;
        initial.total = this.props.Queue['master'].total;
        initial.type = this.props.Queue['master'].type;
        initial.currentfile = this.props.Queue['master'].currentfile;
        initial.overallPercent = this.props.Queue['master'].overallPercent;
        initial.sectionPercent = this.props.Queue['master'].sectionPercent;
      }
    }
    if (this.props.taskList !== undefined) {
      for (var key in this.props.taskList) {
        if (this.props.taskList[key].dateCompleted !== "") continue;
        if (!this.props.taskList[key].start) continue;
        if (initial.total === 0) {
          initial.overallPercent = Math.floor((this.props.taskList[key].jobStep / 4) * 100);
          initial.sectionPercent = Math.floor((this.props.taskList[key].jobTodo / this.props.taskList[key].jobCompleted) * 100);
        }
        initial.total++;
      }
    }

    this.onLogOut = this.onLogOut.bind(this);

    this.state = {
      current: initial.current,
      total: initial.total,
      type: initial.type,
      currentfile: initial.currentfile,
      overallPercent: initial.overallPercent,
      sectionPercent: initial.sectionPercent,

      deviceHash: this.props.deviceHash,
      deviceId: this.props.deviceId,
      phash: '',
      block: false,
      logoutTimer: null,

      iframeLoaded: false,

      onLogOut: this.onLogOut,
    };

    var iFrameComms = {
      completeAuth0: function (auth) {
        if (auth.user !== undefined && auth.user['https://platform.ansarada.com/claims/identity/profile/id'] !== undefined) {
          this.props.dispatch(userActions.Auth0Complete(auth))
          //this.setState({showAuth0: false})
        }
        this.setState({ showFrameExit: false })
      },
      getUsername: function () {
        console.log(this.props)
        return this.props.alias
      }
    };

    window.iFrameComms = Object.create(iFrameComms);
    iFrameComms.completeAuth0 = iFrameComms.completeAuth0.bind(this);
    iFrameComms.getUsername = iFrameComms.getUsername.bind(this);


    this.timerId = null;
    this.Submit = this.Submit.bind(this);
    this.handleChangep = this.handleChangep.bind(this);
    this.tryAgain = this.tryAgain.bind(this);
  }

  componentDidMount() {
    var username = this.props.username;
    var _this = this;
    keysStorage.Get(username + 'deviceId')
      .then((data) => {
        if (data.key !== null) {
          if (!_this.state.block) {
            _this.setState({ deviceId: data.key });
          }
        }
      }).catch((e) => {
        //try again in few milliseconds as database could be still trying to open
        this.timerId = setTimeout(this.tryAgain, 200);
      })
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
    this.setState({ block: true });
  }

  static getDerivedStateFromProps(nextProps, state) {
    var addtoState = {
    }

    if (nextProps.deviceId !== "" && nextProps.deviceId !== undefined) {
      addtoState.deviceId = nextProps.deviceId;
    }
    if (nextProps.deviceHash !== "" && nextProps.deviceHash !== undefined) {
      addtoState.deviceHash = nextProps.deviceHash;
    }

    if (nextProps.lockScreen) {
      if (nextProps.Queue !== undefined) {
        if (nextProps.Queue['master'] !== undefined) {
          addtoState.current = nextProps.Queue['master'].current;
          addtoState.total = nextProps.Queue['master'].total;
          addtoState.type = nextProps.Queue['master'].type;
          addtoState.currentfile = nextProps.Queue['master'].currentfile;
          addtoState.overallPercent = nextProps.Queue['master'].overallPercent;
          addtoState.sectionPercent = nextProps.Queue['master'].sectionPercent;

        }
      }
      if (nextProps.taskList !== undefined) {
        for (var key in nextProps.taskList) {
          if (nextProps.taskList[key].dateCompleted !== "") continue;
          if (!nextProps.taskList[key].start) continue;
          if (addtoState.total === undefined)
            addtoState.total = 1;
          else
            addtoState.total++;
          if (addtoState.total === 1) {
            addtoState.overallPercent = Math.floor((nextProps.taskList[key].jobStep / 4) * 100);
            addtoState.sectionPercent = Math.floor((nextProps.taskList[key].jobStepCurrent / nextProps.taskList[key].jobStepTotal) * 100);
          }
        }
      }
    }

    if (Object.keys(addtoState).length > 0) {
      return addtoState
    } else if (state.total > 0) {
      addtoState.total = 0;
      addtoState.overallPercent = 0;
      addtoState.sectionPercent = 0;
    }

    if (!nextProps.lockScreen && state.logoutTimer !== null) {
      clearTimeout(state.logoutTimer);
      addtoState.logoutTimer = null;
    } else if (nextProps.lockScreen && state.logoutTimer === null) {
      var settime = setTimeout(state.onLogOut, LOGOUT_TIMER);
      addtoState.logoutTimer = settime;
    }

    return addtoState
  }

  /*
  componentWillReceiveProps(nextProps){
    var addtoState = {
    }

    if(nextProps.deviceId !== "" && nextProps.deviceId !== undefined){
      addtoState.deviceId = nextProps.deviceId;
    }

    var addtoState = {};
    if(nextProps.lockScreen){
      if(nextProps.Queue !== undefined){
        if(nextProps.Queue['master'] !== undefined){
          addtoState.current = nextProps.Queue['master'].current;
          addtoState.total = nextProps.Queue['master'].total;
          addtoState.type = nextProps.Queue['master'].type;
          addtoState.currentfile = nextProps.Queue['master'].currentfile;
          addtoState.overallPercent = nextProps.Queue['master'].overallPercent;
          addtoState.sectionPercent = nextProps.Queue['master'].sectionPercent;

        }
      }
      if(nextProps.taskList !== undefined){
        for(var key in nextProps.taskList) {
          if(nextProps.taskList[key].dateCompleted !== "")continue;
          if(!nextProps.taskList[key].start) continue;
          if(addtoState.total === undefined)
            addtoState.total = 1;
          else
            addtoState.total++;
          if(addtoState.total === 1){
            addtoState.overallPercent = Math.floor((nextProps.taskList[key].jobStep/4)*100);
            addtoState.sectionPercent = Math.floor((nextProps.taskList[key].jobStepCurrent/nextProps.taskList[key].jobStepTotal)*100);
          }
        }
      }
    }

    if(Object.keys(addtoState).length > 0){
      this.setState(addtoState);
    }else if(this.state.total > 0){
      this.setState({
        total: 0,
        overallPercent: 0,
        sectionPercent: 0,
      });
    }

    if(!nextProps.lockScreen && this.state.logoutTimer !== null){
      clearTimeout(this.state.logoutTimer);
      this.setState({logoutTimer: null});
    }else if(nextProps.lockScreen && this.state.logoutTimer === null){
      var settime = setTimeout(this.onLogOut,LOGOUT_TIMER);
      this.setState({logoutTimer: settime});
    }
  }*/

  onExit = () => {
    clearTimeout(this.state.logoutTimer);
    this.setState({ logoutTimer: null });
    // this.props.dispatch(userActions.logout(this.state.deviceId));
    window.location.href = RoutesConstants.logout;
    //    if(this.props.history !== undefined) this.props.history.push("/login");
  }

  onLogOut() {
    clearTimeout(this.state.logoutTimer);
    this.setState({ logoutTimer: null });
    // this.props.dispatch(userActions.logout(this.state.deviceId));
    window.location.href = RoutesConstants.logout;
  }

  tryAgain() {
    var username = this.props.username;
    var _this = this;
    keysStorage.Get(username + 'deviceId')
      .then((data) => {
        if (data.key !== null || data.key !== "") {
          //    if(!_this.state.block)
          //      _this.setState({deviceId: data.key});
        }
      }).catch((e) => { })
  }

  handleChangep(e) {
    const { name, value } = e.target;

    //var newvalue = value.replace(/[&\/\\#,+()$~%.!^@'":*?<>=\[\]_{}]/g,'').trim();

    this.setState({ [name]: value });
  }

  Submit() {
    const { phash, deviceId, deviceHash } = this.state;
    var username = this.props.username;
    if (!username) {
      let user = JSON.parse(localStorage.getItem(window.athenaAppID));
      if (user) {
        username = user.username;
      }
    }

    if (deviceId === "" || deviceId === undefined || deviceHash === "" || deviceHash === undefined) {
      var _this = this;
      keysStorage.Get(username + 'deviceId')
        .then((data) => {
          if (data.key !== null || data.key !== "") {
            _this.setState({ deviceId: data.key });
            this.SendPost(username, data.key, "", phash)
          }
        }).catch((e) => { })

    } else
      this.SendPost(username, deviceId, deviceHash, phash);
  }

  SendPost(username, deviceId, deviceHash, phash) {
    const send = (id, hash) => {
      var loginReqest = {
        alias: this.props.alias,
        username: username,
        deviceId: id,
        deviceHash: hash,
        password: phash,
        keys: true,
        mode: this.props.mode,
      }
      this.props.dispatch(userActions.loginLockScreen(loginReqest));
    }
    if (deviceHash === "") {
      DeviceFingerprint('lock')
        .then((ihash) => {
          if (!this.state.block)
            this.setState({ deviceHash: ihash });

          send(deviceId, ihash)
          this.setState({ phash: '' });
        })
        .catch(function (error) {
        });
    } else {
      send(deviceId, deviceHash)
      this.setState({ phash: '' });
    }
  }

  Auth0Refresh() {
    this.props.dispatch(userActions.Auth0Refresh(true))
  }

  iFrameLoaded() {
    this.setState({ iframeLoaded: true })
  }

  render() {
    if (this.props.lockScreen === undefined) {
      return (
        <LoadingOverlay />
      );
    }
    if (this.props.loggingIn === true && this.props.lockScreen) {
      return (
        <div className="aBoxTop-cover" style={{ zIndex: 99 }}>
          <LoadingIndicator size={48} dark={true} />
        </div>
      );
    }
    var failed = this.props.failed;
    if (failed === undefined) failed = 0;
    if (!this.props.lockScreen) return (<div></div>);

    if (this.props.sessionReplaced === true) {
      return (
        <Dialog open={true} maxWidth='sm' fullWidth={true}>
          <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px', paddingBottom: '20px' }}>
            <h1 className="colorStand">Multiple Active Sessions Detected</h1>
            <IconButton hidden={this.state.total} aria-label="close" onClick={() => { this.onExit() }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <label>Your login credentials have been used to login from another device.</label>
            <label>Please contact support if this was not you.</label>
          </DialogContent>
          <DialogActions>
            <MuiButton variant='contained' onClick={this.onExit}>OK</MuiButton>
          </DialogActions>
        </Dialog>
      )
    }
    // if (this.props.universalLogin === true && this.props.universalRedirect === false) {
    //   return (
    //     <div className="aBoxTop-cover" style={{ zIndex: 99 }}>
    //       <div>
    //         <div className="Auth0OverLay" style={{ left: this.state.iframeLoaded ? 0 : window.innerWidth }}>
    //           <div className="Auth0Centre">
    //             <ExitIcon className="login-iframe-exit" onClick={this.onExit} />
    //             <iframe
    //               id="frameParent"
    //               className="auth0Frame"
    //               src="/auth0Sign.html" //"/loginSignIn"
    //               title="Single Sign Login"
    //               onLoad={this.iFrameLoaded.bind(this)}
    //             >
    //             </iframe>
    //           </div>
    //         </div>
    //         <div className="ansarada-loading">
    //           <span className="css-1cnp6ed-LoadingIndicator"></span>
    //         </div>
    //       </div>
    //     </div>
    //   )
    // }
    return (
      <Dialog open={true} maxWidth='sm' fullWidth={true}>
        <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px', paddingBottom: '20px' }}>
          Screen Locked
          <IconButton hidden={this.state.total} aria-label="close" onClick={() => { this.onExit() }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div>
            {this.state.total == 0 && failed < 2 &&
              <div>
                <label>The screen has been locked due to inactivity.</label>
              </div>
            }
            {this.state.total > 0 && failed < 2 &&
              <div>
                <label>The screen has been locked due to inactivity.</label>
                <label>Upload in progress - please do not close or refresh your browser</label>
                <label>Do NOT refresh the page.</label>
              </div>
            }
            {this.state.total == 0 && failed > 1 &&
              <div>
                <label>The screen has been locked due to inactivity.</label>
                <label>Press OK button to redirected back to login screen.</label>
              </div>
            }
            {this.state.total > 0 && failed > 1 &&
              <div>
                <label>The screen has been locked due to inactivity.</label>
                <label>Background task are happening, please wait until all task are completed.</label>
                <label>Do NOT refresh the page.</label>
              </div>
            }
            {this.state.total > 0 &&
              <div>
                <div className="boardpanel uploadText">
                  <div className="text14bold">Processing Queue Left {this.state.total}</div>
                </div>
                {this.state.sectionPercent !== 0 && this.state.sectionPercent !== undefined ?
                  <ProgressBar
                    section={this.state.sectionPercent}
                    success={this.state.overallPercent}
                    height={2}
                    className="progressBar"
                  />
                  :
                  <ProgressBar
                    success={this.state.overallPercent}
                    height={2}
                    className="progressBar"
                  />
                }
              </div>
            }
            {failed < 2 && (this.props.universalLogin !== true || this.props.universalRedirect !== true) &&
              <div >
                <label>Please enter your password</label>
                <div className="page authcode">
                  <input type="hidden" name="password" />
                  <TextInput
                    name="phash"
                    type='password'
                    stylenormal=""
                    styleempty=""
                    onChange={this.handleChangep}
                    onReturn={this.Submit}
                    value={this.state.phash}
                    initial=""
                    autoComplete="off"
                  />
                  {this.props.error !== "" &&
                    <div className="error" style={{ textAlign: 'left' }}>{this.props.error}</div>
                  }
                </div>
              </div>
            }
          </div>
        </DialogContent>
        <DialogActions>
          {(this.state.total == 0 && failed > 1) ?
            <MuiButton variant='contained' onClick={this.onExit}>OK</MuiButton> : null
          }
          {(this.props.universalLogin === true && this.props.universalRedirect === true) ?
            <div style={{ display: 'flex', flex: 1, alignContent: 'center' }} className={this.props.updateWebPage ? 'spacebetween' : 'flexend'}>
              {this.props.updateWebPage === true &&
                <div><RefreshIcon style={{ marginRight: '5px' }} size={16} color="#f12314" />Update Available</div>
              }
              <MuiButton variant='contained' onClick={this.Auth0Refresh.bind(this)}>Ok</MuiButton>
            </div> : null
          }
          {Boolean(failed < 2 && (this.props.universalLogin !== true || this.props.universalRedirect !== true)) ?
            <div style={{display: 'flex', flex: 1, alignContent: 'center'}} className={this.props.updateWebPage ? 'spacebetween' : 'flexend'}>
              {this.props.updateWebPage === true &&
                <div><RefreshIcon style={{ marginRight: '5px' }} size={16} color="#f12314" />Update Available</div>
              }
              <MuiButton variant='contained' onClick={this.Submit}>Submit</MuiButton>
            </div> : null
          }
        </DialogActions>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  const uploadQueue = state.uploadQueue;
  const authentication = state.authentication;
  const { taskList, latestAction } = state.users;
  return {
    Queue: state.uploadQueue,
    alias: authentication.alias,
    username: authentication.username,
    deviceId: authentication.deviceId,
    deviceHash: authentication.deviceHash,
    keys: authentication.keys,
    error: authentication.error,
    failed: authentication.failed,
    loggingIn: authentication.loggingIn,
    lockScreen: authentication.lockScreen,
    loggedIn: authentication.loggedIn,
    taskList,
    latestAction,
    updateWebPage: authentication.updateWebPage,
    mode: authentication.mode,
    universalLogin: authentication.universalLogin,
    universalRedirect: authentication.universalRedirect,
    sessionReplaced: authentication.sessionReplaced,
  };
}

const connectedLockScreenDialog = connect(mapStateToProps)(LockScreenBase);
export { connectedLockScreenDialog as LockScreenDialog };
