import React from 'react';
import { connect } from 'react-redux';

import { userActions } from '@actions/appuser';
import { webConstants } from '@constants/appuser';

import { LoadingIndicator } from '@ansarada/ace-react';
import { FaTimes as ExitIcon } from 'react-icons/fa';
import { Button } from '@common/admin';
import logo from '@image/logo/ansarada-board-logo-black.svg';
import { RoutesConstants } from '@constants/common.constants';
import LoadingOverlay from './MUI/LoadingOverlay';

const DEFAULT_TIME = 30

class LoginBoxApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timer: null,
      countDown: 0,
      lastState: false,
      updateTimer: this.updateTimer.bind(this),
      playAudio: this.playAudio.bind(this)
    }

    this.onLogOut = this.onLogOut.bind(this)
  }

  componentWillUnmount() {
    clearInterval(this.state.timer)
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.lockScreen === true && state.lastState === false){
      clearInterval(state.timer)
      state.countDown = DEFAULT_TIME
      state.timer = setInterval(state.updateTimer, 1000)
      setTimeout(state.playAudio, 10)
    }
    state.lastState = nextProps.lockScreen

    return state
    //If refresh browser while lockscreen just logout and redirect to main screen
  }

  updateTimer(){
    let t = this.state.countDown - 1
    if(t <= 0){
      this.onLogOut()
      return
    }
    this.setState({countDown: t})
  }

  onContinue(){
    clearInterval(this.state.timer)
    this.setState({ timer: null})
    this.props.dispatch(userActions.activeScreen());
  }

  onExit(){
    clearTimeout(this.state.timer);
    this.setState({timer: null});
    this.props.dispatch(userActions.logout(this.state.deviceId));
    window.location.href=RoutesConstants.login;
//    if(this.props.history !== undefined) this.props.history.push(RoutesConstants.login);
  }

  onLogOut(){
    clearTimeout(this.state.timer);
    this.setState({timer: null});
    this.props.dispatch(userActions.logout(this.state.deviceId));
    // window.location.href=RoutesConstants.login;
  }

  playAudio(){
    const audioEl = document.getElementsByClassName("audio-element")[0]
    audioEl.play()
  }

  render(){
    const { lastState } = this.state
    if(lastState === undefined){
      return (
        <LoadingOverlay />
      );
    }

    if(!lastState) return (<div></div>);

    if(this.props.sessionReplaced === true){
      return (
        <div className="aBoxTop-cover" style={{zIndex: 99}}>
          <div className="aPopup-box page">
            <div>
              <div className="auth-panel">
                <div className="boardpanel spacebetween">
                  <h1 className="colorStand">Multiple Active Sessions Detected</h1>
                  <ExitIcon onClick={this.onExit} size={24} className="bpanel-Exit"/>
                </div>
              </div>
              <div className='screenmarg'>
                <div className='page' style={{marginBottom:10}}>
                  <label>Your login credentials have been used to login from another device.</label>
                  <label>Please contact support if this was not you.</label>
                </div>
                <div className="boardpanel" style={{justifyContent: 'flex-end', marginBottom: 30}}>
                  <Button onClick={this.onExit}>OK</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="aBoxTop-cover applockscreen" style={{zIndex: 99}} onClick={this.onContinue.bind(this)}>
        <div className="applock">
            <div>
              <img src={logo} />
            </div>
            <label>Logging out in {this.state.countDown} seconds</label>
            <label>click to cancel</label>
        </div>
        <audio className="audio-element">
          <source src="lib/DownloadComplete.mp3"></source>
        </audio>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const  authentication = state.authentication;
  return {
    keys: authentication.keys,
    loggingIn: authentication.loggingIn,
    lockScreen: authentication.lockScreen,
    loggedIn: authentication.loggedIn,
    sessionReplaced: authentication.sessionReplaced,
  };
}

const connectedLockScreenDialogApp = connect(mapStateToProps)(LoginBoxApp);
export { connectedLockScreenDialogApp as LockScreenDialog };
