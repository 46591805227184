import { alertConstants } from '@constants';
import { alertService } from '@services/alert.base.service';
import moment from 'moment';
import {deepClone} from '@lib//simpletools';
import { v4 as uuidv4 } from 'uuid';

export const alertActions = {
  success,
  notificationSuccess,
  notificationClearById,
  error,
  clear,
  sendAanalytics,
  sendDiagnosticData,
  errorDiagnosticData,
  recordDiagnosticData,
};

function success(message, item = null) {
  return { type: alertConstants.SUCCESS, message, item };
}

function notificationSuccess(message) {
  return { type: alertConstants.SUCCESS_NOTIFICATION, message };
}

function notificationClearById(id) {
  return { type: alertConstants.CLEAR_BYID_NOTIFICATION, id };
}

function stripBinder(data){
  var d = deepClone(data);

  if(data.binder.items !== undefined){
    for(var x=0; x<data.binder.items.length; x++){
      var i = data.binder.items[x]
      if(i.image !== undefined && i.image !== null)
        d.binder.items[x].image = {arrayBufferLen: i.image.byteLength }
      if(i.itemdata !== undefined && i.itemdata !== null){
        d.binder.items[x].itemdata = {
          size: i.itemdata.size,
          type: i.itemdata.type,
          name: i.itemdata.name,
          path: i.itemdata.path,
          lastModified: i.itemdata.lastModified,
          lastModifiedDate: i.itemdata.lastModifiedDate,
        }
      }
    }
  }

  if(data.items !== undefined){
    if(data.items.aesKey !== undefined){
      data.items.aesKey = "strip"
    }
  }

  return JSON.stringify(d)
}

function error(message) {
  return { type: alertConstants.ERROR, message };
}

function clear() {
  return { type: alertConstants.CLEAR };
}

function errorDiagnosticData(error){
  return (dispatch, getState) => {
    if(window.demo) return
    let authentication = getState().authentication

    const userId =authentication.userId
    const customerId = authentication.customerId

    var items = (window.diagLayer = window.diagLayer || []).slice(0)
    window.diagLayer = []

    const id = uuidv4();
    var j = {
      id: id,
      searchName: error.code,
      customerId,
      date: moment(error.date).utc().format(),
      sendEmail: true,
      summary: error.message
    }

    try {
      j.byteData = error.data !== undefined ? JSON.stringify(error.data) : null;
    } catch (e) {
      j.byteData = 'Error with JSON.stringify: ' + JSON.stringify(e);
    }

    if (userId) {
      j.userId = userId;
    }

    const data = error.data
    if(data !== undefined){
      if(data.binderId !== undefined && data.binderId !== "")
        j.binderId = data.binderId
      if(data.objectId !== undefined && data.objectId !== "")
        j.objectId = data.objectId
    }

    var type = error.className

    if(error.stack !== undefined){
      j.stringData = {'stack':error.stack.toString()}
    }

    alertService.diagnosticData([...items, j])
      .then(
        item => {
        },
        error => {

        }
      );
  };
}

function recordDiagnosticData(type, data, options = null, sendEmail = false){
  return (dispatch, getState) => {
    log("recordDiagData" + JSON.stringify({ type, data, options, sendEmail }));
    if(window.demo) return
    let authentication = getState().authentication

    const userId =authentication.userId
    const customerId = authentication.customerId

    var obj = {
      searchName: type,
      customerId,
      date: moment().utc().format(),
      byteData: JSON.stringify(data),
      expiryDate: moment().add(30, 'days').utc().format(),
      sendEmail: sendEmail,
    };

    if (userId) {
      obj.userId = userId;
    }

    if(data.boardId !== undefined && data.boardId !=="")
      obj.boardId = data.boardId;
    if(type === 'binderUpload' || type === 'binderWorker' || type === 'templateNew' || type === 'templateUpdate'){
      if(data.id !== undefined && data.id !=="" && (type === 'binderUpload' || type === 'binderWorker'))
        obj.binderId = data.id;

      obj.byteData = stripBinder(data);
    }

    if(data.binderId !== undefined && data.binderId !=="")
      obj.binderId = data.binderId;
    if(data.userId !== undefined && data.userId !=="")
      obj.userId = data.userId;
    if(data.objectId !== undefined && data.objectId !=="")
      obj.objectId = data.objectId;

    (window.diagLayer = window.diagLayer || []).push(obj);
  }
}

function sendAanalytics(){
  return (dispatch, getState) => {
    if(window.demo) return
    let authentication = getState().authentication

    const userId =authentication.userId
    const customerId = authentication.customerId

    var items = (window.dataAnsaradaLayer = window.dataAnsaradaLayer || []).slice(0)
    window.dataAnsaradaLayer = []
    items.forEach((d) => {
      d.userId = userId;
      if (!d.customerId && customerId) {
        d.customerId = customerId
      }
    })
    if(items.length < 1) return
    alertService.analytics(items)
      .then(
        item => {

        },
        error => {

        }
      );
  };
}

function sendDiagnosticData(headers, customerIdParam, userIdParam){
  return (dispatch, getState) => {
    if(window.demo) return
    const state = getState()
    let authentication = getState().authentication

    var userId = authentication.userId
    if (!userId && userIdParam) {
      userId = userIdParam;
    }
    var customerId = authentication.customerId
    if (!customerId && customerIdParam) {
      customerId = customerIdParam
    }

    var items = (window.diagLayer = window.diagLayer || []).slice(0)
    window.diagLayer = []

    if(items.length < 1) return

    items.forEach((d) => {
      if (!d.userId && userId) {
        d.userId = userId;
      }
      if (!d.customerId && customerId) {
        d.customerId = customerId
      }
    })

    alertService.diagnosticData(items, headers)
      .then(
        item => {

        },
        error => {

        }
      );
  };
}
