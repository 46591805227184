import { customerConstants } from '@constants/admin';
import { customerService, boardService } from '@services/admin';
import { userActions, alertActions, adminPermissionsActions } from './';
import { history } from '@lib';
import {SettingStorage} from '@lib/indexeddb';

export const customerActions = {
  getCustomerSettings,
  setCustomerSettings,
  updateCustomerSettings,
  switchCustomer,

  getNotificationSettings,

  getAllLocks,
  hasLock,
  setLock,
  deleteLock,
  clearLock,

  sendUpgrade,
  setStatus,
  getStatus,
};

function getCustomerSettings(id) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(request(id));
      customerService.getCustomerSettings(id)
        .then(
          payload => {
            dispatch(success(payload));
            resolve(payload);
          },
          error => {
            dispatch(failure(error));
            reject();
          }
        );
    })
  };

  function request(id) { return { type: customerConstants.GET_CUST_SETTINGS_REQUEST, id } }
  function success(payload) { return { type: customerConstants.GET_CUST_SETTINGS_SUCCESS, payload } }
  function failure(id, error) { return { type: customerConstants.GET_CUST_SETTINGS_FAILURE, id, error } }
}

function setCustomerSettings(items, redirect = null, skipSettings) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(request(items.customerId));
      if (window.demo) {
        dispatch(success(items))
        if (redirect !== null)
          history.push(redirect)
        resolve(items);
        return
      }
      customerService.setCustomerSettings(items)
        .then(
          payload => {
            if (payload && payload.customerId && payload.companyName) {
              dispatch(updateCompanyName(payload.customerId, payload.companyName));
            }
            if (items.customerIds.length > 0 || items.boards.length > 0) {
              customerService.applySetting(items)
                .then(
                  d => {
                    dispatch(update(d.customers, d.boards))
                    dispatch(success(payload))
                    resolve(payload);
                    if (redirect !== null)
                      history.push(redirect)
                  },
                  error => {
                    dispatch(failure(error))
                  }
                )
            } else {
              dispatch(success(payload))
              resolve(payload);
              if (redirect !== null)
                history.push(redirect)
            }
          },
          error => {
            dispatch(failure(error))
          }
        );
    })
  };

  function request(id) { return { type: customerConstants.SET_CUST_SETTINGS_REQUEST, id } }
  function update(customers, boards) { return { type: customerConstants.UPDATE_CUST_SETTINGS_SUCCESS, customers, boards } }
  function updateCompanyName(customerId, companyName) { return { type: customerConstants.UPDATE_CUST_COMPANY_NAME_SUCCESS, customerId, companyName } }
  function success(payload) { return { type: customerConstants.SET_CUST_SETTINGS_SUCCESS, payload } }
  function failure(id, error) { return { type: customerConstants.SET_CUST_SETTINGS_FAILURE, id, error } }
}

function updateCustomerSettings(items, redirect = null) {
  return dispatch => {
    dispatch(request(items.customerId));
    if(window.demo){
      dispatch(success(items))
      if(redirect !== null)
        history.push(redirect)
      return
    }
    customerService.updateCustomerSettings(items)
      .then(
          payload => {
            if (payload && payload.customerId && payload.companyName) {
              dispatch(updateCompanyName(payload.customerId, payload.companyName));
            }
            if(items.customerIds.length > 0 || items.boards.length > 0){
              customerService.applySetting(items)
              .then(
                  d => {
                    dispatch(update(d.customers, d.boards))
                    dispatch(success(payload))
                    if(redirect !== null)
                      history.push(redirect)
                  },
                  error => {
                    dispatch(failure(error))
                  }
              )
            }else{
              dispatch(success(payload))
              if(redirect !== null)
                history.push(redirect)
            }
          },
          error => {
            dispatch(failure(error))
          }
      );
  };

  function request(id) { return { type: customerConstants.SET_CUST_SETTINGS_REQUEST, id } }
  function update(customers, boards) { return { type: customerConstants.UPDATE_CUST_SETTINGS_SUCCESS, customers, boards } }
  function updateCompanyName(customerId, companyName) { return { type: customerConstants.UPDATE_CUST_COMPANY_NAME_SUCCESS, customerId, companyName } }
  function success(payload) { return { type: customerConstants.SET_CUST_SETTINGS_SUCCESS, payload } }
  function failure(id, error) { return { type: customerConstants.SET_CUST_SETTINGS_FAILURE, id, error } }
}

function switchCustomer(customerId, switchBoard = false){
  return (dispatch, getState) => {
    const username = getState().authentication.username;
    const customerIds = getState().authentication.customerIds;

    // dispatch(alertActions.sendAanalytics());
    dispatch(alertActions.sendDiagnosticData());
    dispatch(request(customerId));
    dispatch(userActions.loadCustomer(customerId, switchBoard))
    dispatch(userActions.populateListofUsers(customerId));
    var cust = {
      last: customerId,
      customer: [],
    }
    SettingStorage.Get(username+'customers')
    .then((data)=>{
      cust = data.key
      cust.customer = cust.customer.filter(o => customerIds.includes(o.customerId));
      var missing = customerIds.filter(o => {
        if(cust.customer.find(i => i.customerId === o))
          return false;
        return true;
      });
      missing.forEach(o => {
        cust.customer.push({
          customerId: o,
          rank: 0,
        })
      })
      //find one we switching too
      var c = cust.customer.find(o => o.customerId === customerId)
      if(c)
        if(c.rank < 255)
          c.rank++;
      //find lowest rank
      var rank = 255;
      cust.customer.forEach(o => {
        if(o.rank < rank){
          rank = o.rank;
        }
      })

      if(rank > 0){
        cust.customer.forEach(o => {
          o.rank -= rank;
        })
      }

      SettingStorage.Put({id: username+'customers', key: cust})
      .then(()=>{})
      .catch((e)=>{log(e);});
    }).catch((e)=>{
      customerIds.forEach(o => {
        cust.customer.push({
          customerId: o,
          rank: customerId===o?1:0,
        })
      })

      SettingStorage.Put({id: username+'customers', key: cust})
      .then(()=>{})
      .catch((e)=>{log(e);});
    })

  };

  function request(customerId) { return { type: customerConstants.SWITCH_CUSTOMER, customerId } }
}

function getNotificationSettings(customerId){
  return dispatch => {
    dispatch(request(customerId));
    customerService.getNotificationSettings(customerId)
      .then(
          payload => dispatch(success(customerId, payload)),
          error => {
            dispatch(failure(error))
          }
      );
  };

  function request(customerId) { return { type: customerConstants.GET_NOTFIC_SETTINGS_REQUEST, customerId } }
  function success(customerId, payload) { return { type: customerConstants.GET_NOTFIC_SETTINGS_SUCCESS, customerId, customerId, payload } }
  function failure(customerId, error) { return { type: customerConstants.GET_NOTFIC_SETTINGS_FAILURE, customerId, error } }
}

function getAllLocks(customerId){
  return dispatch => {
    if(window.demo) return
    dispatch(request(customerId));
    customerService.getAllLocks(customerId)
      .then(
          payload => {
            dispatch(success(customerId, payload))
          },
          error => {
            dispatch(failure(error))
          }
      );
  };

  function request(customerId) { return { type: customerConstants.GET_ALL_LOCKS_REQUEST, customerId } }
  function success(customerId, payload) { return { type: customerConstants.GET_ALL_LOCKS_SUCCESS, customerId, payload } }
  function failure(customerId, error) { return { type: customerConstants.GET_ALL_LOCKS_FAILURE, customerId, error } }
}

function hasLock(objectType, id){
  return dispatch => {
    if(window.demo) return
    dispatch(request(id));
    customerService.hasLock(objectType, id)
      .then(
          payload => dispatch(success(objectType, id)),
          error => {
            dispatch(failure(id, error))
          }
      );
  };

  function request(id) { return { type: customerConstants.GET_VIEW_LOCKS_REQUEST, id } }
  function success(objectType, id) { return { type: customerConstants.GET_VIEW_LOCKS_SUCCESS, objectType, id } }
  function failure(id, error) { return { type: customerConstants.GET_VIEW_LOCKS_FAILURE, id, error } }
}

function setLock(item){
  return (dispatch, getState) => {
    if(window.demo) return
    const userId = getState().authentication.userId
    dispatch(request(item.objectId));
    if(item.expirySeconds === undefined)
      item.expirySeconds = 5 * 60
    if(item.detail === undefined)
      item.detail = 'Edit'
    customerService.setLock(item)
      .then(
          payload => {
            item.userId = userId
            dispatch(success(item))
          },
          error => {
            dispatch(failure(item.objectId, error))
          }
      );
  };

  function request(id) { return { type: customerConstants.SET_LOCKS_REQUEST, id } }
  function success(payload) { return { type: customerConstants.SET_LOCKS_SUCCESS, payload } }
  function failure(id, error) { return { type: customerConstants.SET_LOCKS_FAILURE, id, error } }
}

function deleteLock(objectType, id){
  return dispatch => {
    if(window.demo) return
    dispatch(request(id));
    customerService.deleteLock(objectType, id)
      .then(
          payload => dispatch(success({objectType, objectId: id, expiryTime: "", detail: "", userId: ""})),
          error => {
            dispatch(failure(id, error))
          }
      );
  };

  function request(id) { return { type: customerConstants.DELETE_LOCKS_REQUEST, id } }
  function success(payload) { return { type: customerConstants.DELETE_LOCKS_SUCCESS, payload } }
  function failure(id, error) { return { type: customerConstants.DELETE_LOCKS_FAILURE, id, error } }
}

function clearLock(objectType, id){
  return dispatch => {
    if(window.demo) return
    dispatch(success({
      objectType,
      objectId: id,
      expiryTime: "",
      expirySeconds: "",
      detail: ""
    }))
  }

  function success(payload) { return { type: customerConstants.SET_LOCKS_SUCCESS, payload } }
}

function sendUpgrade(){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId

    dispatch(request(customerId))
    customerService.sendUpgrade(customerId)
      .then(
          payload => {
            dispatch(success(customerId))
          },
          error => {
            dispatch(failure(customerId, error))
          }
      );
  };

  function request(customerId) { return { type: customerConstants.SEND_UPGRADE_REQUEST, customerId } }
  function success(customerId) { return { type: customerConstants.SEND_UPGRADE_SUCCESS, customerId } }
  function failure(customerId, error) { return { type: customerConstants.SEND_UPGRADE_FAILURE, customerId, error } }
}

function setStatus(items) {
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId

    dispatch(request(customerId));
    const data = {
      customSettings: items
    }
    boardService.setStatus(data)
      .then(
          payload => dispatch(success(items, customerId)),
          error => {
            dispatch(failure(error))
          }
      );
  };

  function request(customerId) { return { type: customerConstants.SET_SETTINGS_STATUS_REQUEST, customerId } }
  function success(payload, customerId) { return { type: customerConstants.SET_SETTINGS_STATUS_SUCCESS, payload, customerId } }
  function failure(error) { return { type: customerConstants.SET_SETTINGS_STATUS_FAILURE, error } }
}

function getStatus() {
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId

    dispatch(request(customerId));
    boardService.getStatus()
      .then(
          payload => {
            if(payload.hasOwnProperty("customSettings")){
              dispatch(success(payload.customSettings, customerId))
            }
          },
          error => {
            dispatch(failure(error))
          }
      );
  };

  function request(customerId) { return { type: customerConstants.GET_SETTINGS_STATUS_REQUEST, customerId } }
  function success(payload, customerId) { return { type: customerConstants.GET_SETTINGS_STATUS_SUCCESS, payload, customerId } }
  function failure(error) { return { type: customerConstants.GET_SETTINGS_STATUS_FAILURE, error } }
}
