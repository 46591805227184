import { authHeader, GetURL, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch } from '@lib';
import { copySettings, BLANK_GUID } from '@lib/simpletools';
import fetch from '@lib/fetch-retry';
import * as CrytpoLib from '@lib/cryptojs';
import {
  ADMINTIMEOUT,
} from '@lib/limits';
import { v4 as uuidv4 } from 'uuid';

export const customerService = {
  getCustomerSettings,
  setCustomerSettings,
  
  updateCustomerSettings,
  applySetting,
  getNotificationSettings,

  getAllLocks,
  hasLock,
  setLock,
  deleteLock,

  sendUpgrade,
};

var INITIAL_CUSTOMER = {
  title: '',
  abn: '',
  acn: '',
  logoImageId: '',
  defaultSettings: null,
  countrylist: [],
  selectedPreviousMoveMechanism: 3,
  endOfBoardYear: null,
  endOfFinancialYear: null,
  numberOfDaysPrevious: 60,
  secondsForAdminTimeout: ADMINTIMEOUT,
}

function getCustomerSettings(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch(GetURL() + 'CustomerSettings/Customer/' + id, requestOptions)
    .then(response => {
      if (!response.ok) {
        if(response.status === 404){
          return Object.assign({}, INITIAL_CUSTOMER, {
            customerId: id,
            secondsForAdminTimeout: 600,
          });
        }
        return response.text().then(data => {
          try{
            var j = JSON.parse(data.toLowerCase());
            if(j.hasOwnProperty('code')) return Promise.reject(j);
          }catch(err){

          }

          return Promise.reject(response.statusText);
        });

      }else if(response.status === 204){
        return {};
      }else return response.json();
    })
    .then((data)=>{
      if(data.id === '00000000-0000-0000-0000-000000000000'){
        return Object.assign({}, INITIAL_CUSTOMER, {
          customerId: id,
          secondsForAdminTimeout: ADMINTIMEOUT,
        })
      }
      return data
    })
    .catch((e)=>{
      if(e === "404"){
        return Promise.resolve(
          Object.assign({}, INITIAL_CUSTOMER, {
            customerId: id,
            secondsForAdminTimeout: ADMINTIMEOUT,
          })
        )
      }
    });
}

function setCustomerSettings(item) {
  console.log("setCustomerSettings",item)
  return new Promise(function(resolve, reject) {
    //Do we need to update image?
    if(!item.hasOwnProperty('image')){
      resolve({imageId: item.imageId});
      return;
    }
    var imageId = uuidv4();

    var hash = CrytpoLib.MD5(item.image.data);
    var addheader = {
      DocumentChunkSize: item.image.data.byteLength,
      DocumentHash: hash,
      ChunkHash: hash,
      customerId: item.customerId,
      'Content-Type': 'application/json',
    }

    var Data = {
      data: CrytpoLib.arrayBufferToBase64String(item.image.data)
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader(addheader),
        body: JSON.stringify(Data)
    };
    fetch(GetURL() + 'Document/'+imageId, requestOptions)
    .then((response) => {
      if (!response.ok) {
          if(response.status === 401){
            LogoutAndRedirect();
            reject();
          }
      }
      if(response.status === 201 || response.status === 200){
        resolve({imageId: imageId});
      }else if(response.status === 200){
        //TODO more chunks
        reject();
      }else reject();
    })
    .catch(function(error) {
      reject(error);
    });
  })
  .then(data => {
    if(item.notification === undefined || item.notification === null || item.notification === false){
      return data
    }

    return new Promise(function(resolve, reject) {
      var newNote = false
      if(item.notification !== undefined && (item.notification.id === BLANK_GUID || item.notification.id === "" || item.notification.id === undefined)){
        item.notification.id = item.id
        newNote = true
      }
      item.notification.binderStatusFutureUpdateEmailEnabled = true

      const requestOptions = {
          method: !newNote?'PATCH':'POST',
          headers: authHeader(),
          body: JSON.stringify(item.notification)
      };

      fetch(GetURL() + 'NotificationSettings/Customer/'+item.customerId, requestOptions)
      .then((response) => {
        if (!response.ok) {
            if(response.status === 401){
              LogoutAndRedirect();
              reject();
            }
        }

        resolve(data);
      })
      .catch(function(error) {
        reject(error);
      });
    });
  })
  .then(data => {
    return new Promise(function(resolve, reject) {
      var updateitem = {
        customerId: item.customerId,
        defaultCountryBlackList : item.countryBlacklist || [],
        secondsForAdminTimeout: item.secondsForAdminTimeout,
        defaultSelectedPreviousMoveMechanism: item.selectedPreviousMoveMechanism,
      };

      if (item.companyName) {
        updateitem.companyName = item.companyName;
      }

      if(data.imageId !== undefined){
        if(data.imageId !== ''){
          updateitem.logoImageId = data.imageId;
        }
      }
      if(item.settings !== null && item.settings !== undefined){
        var newSettings = copySettings(item.settings);
        updateitem.defaultSettings = newSettings;
      }

      if(item.numberOfDaysPrevious !== undefined)
        updateitem.defaultNumberOfDaysPrevious = item.numberOfDaysPrevious;
      if(item.endOfBoardYear !== undefined)
        updateitem.defaultEndOfBoardYear = item.endOfBoardYear;
      if(item.endOfFinancialYear !== undefined)
        updateitem.defaultEndOfFinancialYear = item.endOfFinancialYear;

      if(item.abn !== undefined)
        updateitem.abn = item.abn
      if(item.acn !== undefined)
        updateitem.acn = item.acn
      if(item.defaultMeetingLocation !== undefined)
        updateitem.defaultMeetingLocation = item.defaultMeetingLocation
      if(item.defaultPdfFont !== undefined)
        updateitem.defaultPdfFont = item.defaultPdfFont
      if(item.defaultPdfImageId !== undefined)
        updateitem.defaultPdfImageId = item.defaultPdfImageId

      const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(updateitem)
      };

      fetch(GetURL() + 'CustomerSettings', requestOptions)
      .then((response) => {
        if (!response.ok) {
            if(response.status === 401){
              LogoutAndRedirect();
              reject();
            }
        }

        return response.json()
      })
      .then((j)=>{
        if(j !== undefined && j.id !== undefined)
          updateitem.id = j.id
        if(item.hasOwnProperty('image')){
          updateitem.image = item.image
        }else if(item.hasOwnProperty('logoImageId')){
          updateitem.logoImageId = item.logoImageId
        }

        resolve(updateitem);
      })
      .catch(function(error) {
        reject(error);
      });
    });
  })
  .catch(handleCatch);
}

function updateCustomerSettings(item) {
  return new Promise(function(resolve, reject) {
    //Do we need to update image?
    if(!item.hasOwnProperty('image')){
      resolve({imageId: item.imageId});
      return;
    }
    var imageId = uuidv4();

    var hash = CrytpoLib.MD5(item.image.data);
    var addheader = {
      DocumentChunkSize: item.image.data.byteLength,
      DocumentHash: hash,
      ChunkHash: hash,
      customerId: item.customerId,
      'Content-Type': 'application/json',
    }

    var Data = {
      data: CrytpoLib.arrayBufferToBase64String(item.image.data)
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader(addheader),
        body: JSON.stringify(Data)
    };
    fetch(GetURL() + 'Document/'+imageId, requestOptions)
    .then((response) => {
      if (!response.ok) {
          if(response.status === 401){
            LogoutAndRedirect();
            reject();
          }
      }
      if(response.status === 201 || response.status === 200){
        resolve({imageId: imageId});
      }else if(response.status === 200){
        //TODO more chunks
        reject();
      }else reject();
    })
    .catch(function(error) {
      reject(error);
    });
  })
  .then(data => {
    if(item.notification === undefined || item.notification === null || item.notification === false){
      return data
    }

    return new Promise(function(resolve, reject) {
      var newNote = false
      if(item.notification.id === BLANK_GUID){
        //item.notification.id = item.customerId
        newNote = true
      }
      item.notification.binderStatusFutureUpdateEmailEnabled = true

      const requestOptions = {
          method: !newNote?'PATCH':'POST',
          headers: authHeader(),
          body: JSON.stringify(item.notification)
      };

      fetch(GetURL() + 'NotificationSettings/Customer/'+item.customerId, requestOptions)
      .then((response) => {
        if (!response.ok) {
            if(response.status === 401){
              LogoutAndRedirect();
              reject();
            }
        }

        resolve(data);
      })
      .catch(function(error) {
        reject(error);
      });
    });
  })
  .then(data => {
    return new Promise(function(resolve, reject) {
      var updateitem = {
        id: item.id,
        customerId: item.customerId,
      };

      if(item.companyName !== undefined)
        updateitem.companyName = item.companyName
      if(item.countryBlacklist !== undefined)
        updateitem.defaultCountryBlackList = item.countryBlacklist
      if(item.secondsForAdminTimeout !== undefined)
        updateitem.secondsForAdminTimeout = item.secondsForAdminTimeout
      if(item.selectedPreviousMoveMechanism !== undefined)
        updateitem.defaultSelectedPreviousMoveMechanism = item.selectedPreviousMoveMechanism

      if(data.imageId !== undefined){
        if(data.imageId !== ''){
          updateitem.logoImageId = data.imageId;
        }
      }else if(item.logoImageId !== undefined && item.logoImageId === BLANK_GUID){
        updateitem.logoImageId = item.logoImageId;
      }

      if(item.settings !== null && item.settings !== undefined){
        var newSettings = copySettings(item.settings);
        updateitem.defaultSettings = newSettings;
      }

      if(item.numberOfDaysPrevious !== undefined)
        updateitem.defaultNumberOfDaysPrevious = item.numberOfDaysPrevious
      if(item.endOfBoardYear !== undefined)
        updateitem.defaultEndOfBoardYear = item.endOfBoardYear
      if(item.endOfFinancialYear !== undefined)
        updateitem.defaultEndOfFinancialYear = item.endOfFinancialYear

      if(item.abn !== undefined)
        updateitem.abn = item.abn
      if(item.acn !== undefined)
        updateitem.acn = item.acn
      if(item.defaultMeetingLocation !== undefined)
        updateitem.defaultMeetingLocation = item.defaultMeetingLocation
      if(item.defaultPdfFont !== undefined)
        updateitem.defaultPdfFont = item.defaultPdfFont
      if(item.defaultPdfImageId !== undefined)
        updateitem.defaultPdfImageId = item.defaultPdfImageId

      const requestOptions = {
          method: 'PATCH',
          headers: authHeader(),
          body: JSON.stringify(updateitem)
      };

      fetch(GetURL() + 'CustomerSettings/'+item.id, requestOptions)
      .then((response) => {
        if (!response.ok) {
            if(response.status === 401){
              LogoutAndRedirect();
              reject();
            }
        }

        if(item.hasOwnProperty('image')){
          updateitem.image = item.image
        }else if(item.hasOwnProperty('logoImageId')){
          updateitem.logoImageId = item.logoImageId
        }

        resolve(updateitem);
      })
      .catch(function(error) {
        reject(error);
      });
    });
  })
  .catch(handleCatch)
}

function applySetting(item){
  return new Promise(function(resolve, reject) {
    if(item.customerIds.length === 0){
      return resolve([])
    }
    //get custer setting id for each customer
    var promiseArray = []
    item.customerIds.forEach((customerId)=>{
      promiseArray.push(
        new Promise((resolveCust, rejectCust) => {
          var f = item.customerSettingsIds.find(o => o.customerId === customerId)
          if(f){
            return resolveCust(f)
          }

          const requestOptions = {
            method: 'GET',
            headers: authHeader()
          };
          fetch(GetURL() + 'CustomerSettings/Customer/' + customerId, requestOptions)
            .then(response => {
              if (!response.ok) {
                if(response.status === 404 || response.status === 403){
                  return {id: uuidv4(), new: true}
                }
                return response.text().then(data => {
                  try{
                    var j = JSON.parse(data.toLowerCase());
                    if(j.hasOwnProperty('code')) return Promise.reject(j);
                  }catch(err){

                  }

                  return Promise.reject(response.statusText);
                });

              }else if(response.status === 204){
                return {id: uuidv4(), new: true};
              }else return response.json();
            })
            .then((data)=>{
              data.customerId = customerId
              if(data.id === '00000000-0000-0000-0000-000000000000'){
                data.id = uuidv4()
                data.new = true
              }
              resolveCust({id: data.id, customerId: customerId})
            })
            .catch((e)=>{
              if(e === "404"){
                resolveCust({id: uuidv4(), customerId: customerId, new: true})
              }
            });
        })
      )
    })

    Promise.all(promiseArray)
    .then(data => {
      resolve(data)
    })
    .catch((error) => {
      reject(error)
    })
  })
  .then(customerSettingsIds => {
    if(item.customerIds.length === 0){
      return []
    }
    //post customerSetting

    var promiseArray = []
    item.customerIds.forEach((customerId)=>{
      promiseArray.push(
        new Promise((resolve, reject) => {
          var f = item.customerSettingsIds.find(o => o.customerId === customerId)
          if(!f){
            return resolve(f)
          }

          var updateitem = {
            customerId: customerId,
            defaultCountryBlackList : item.countryBlacklist,
            secondsForAdminTimeout: item.secondsForAdminTimeout,
            defaultSelectedPreviousMoveMechanism: item.selectedPreviousMoveMechanism,
          }
          if(f){
            updateitem.id = f.id
          }

          if(item.settings !== null && item.settings !== undefined){
            var newSettings = copySettings(item.settings);
            updateitem.defaultSettings = newSettings;
          }

          if(item.numberOfDaysPrevious !== undefined)
            updateitem.defaultNumberOfDaysPrevious = item.numberOfDaysPrevious;
          if(item.endOfBoardYear !== undefined)
            updateitem.defaultEndOfBoardYear = item.endOfBoardYear;
          if(item.endOfFinancialYear !== undefined)
            updateitem.defaultEndOfFinancialYear = item.endOfFinancialYear;

          const requestOptions = {
              method: 'POST',
              headers: authHeader(),
              body: JSON.stringify(updateitem)
          }
          var url = GetURL() + 'CustomerSettings'

          if(f && !f.new && f.id !== undefined && f.id !== ""){
            requestOptions.method = 'PATCH'
            url += "/"+f.id
          }

          fetch(url, requestOptions)
          .then((response) => {
            if (!response.ok) {
              reject()
              return
            }

            resolve(updateitem);
          })
          .catch((error) => {
            reject(error)
          });
        })
      )
    })

    return Promise.all(promiseArray)
  })
  .then(data => {
    if(item.boards.length === 0){
      return {customers: data, boards: []}
    }

    //post boardSettings
    return new Promise((resolve, reject) => {
      var promiseArray = []
      item.boards.forEach((board)=>{
        board.boardIds.forEach((boardId)=>{
          promiseArray.push(
            new Promise((resolveBoard, rejectBoard) => {
              var updateitem = {
                boardId: boardId,
                customerId: board.customerId,
                countryBlacklist: item.countryBlacklist,
              }

              if(item.settings !== null && item.settings !== undefined){
                var newSettings = copySettings(item.settings);
                updateitem.boardSettings = newSettings;
              }

              if(item.selectedPreviousMoveMechanism !== undefined)
                updateitem.selectedPreviousMoveMechanism = item.selectedPreviousMoveMechanism;
              if(item.numberOfDaysPrevious !== undefined)
                updateitem.numberOfDaysPrevious = item.numberOfDaysPrevious;
              if(item.endOfBoardYear !== undefined)
                updateitem.endOfBoardYear = item.endOfBoardYear;
              if(item.endOfFinancialYear !== undefined)
                updateitem.endOfFinancialYear = item.endOfFinancialYear;

              const requestOptions = {
                  method: 'PATCH',
                  headers: authHeader(),
                  body: JSON.stringify(updateitem)
              };

              fetch(GetURL() + 'Boards/'+boardId, requestOptions)
              .then((response) => {
                if (!response.ok) {
                    rejectBoard();
                    return
                }
                resolveBoard(updateitem);
              })
              .catch((error) => {
                rejectBoard(error)
              });
            })
          )
        })
      })

      Promise.all(promiseArray)
      .then((boards)=>{
        resolve({customers: data, boards: boards})
      })
      .catch((error) => {
        reject(error)
      })
    })
  })
  .catch(handleCatch)
}

function getNotificationSettings(customerId){
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(GetURL() + 'NotificationSettings/Customer/'+customerId, requestOptions)
    .then((response)=>{
      if (!response.ok) {
        if(response.status === 404){
          return fetch(GetURL() + 'NotificationSettings/Default', requestOptions)
          .then(handleJsonResponse)
          .catch(handleCatch)
        }
        if(response.status === 401){
          LogoutAndRedirect();
        }
        return response.text().then(data => {
          try{
            var j = JSON.parse(data.toLowerCase());
            if(j.hasOwnProperty('code')) return Promise.reject(j);
          }catch(err){

          }

          return Promise.reject(response.statusText);
        });

      }else if(response.status === 204){
        return {};
      }else return response.json();
    })
    .catch((e)=>{
      if(e === "404"){
        return fetch(GetURL() + 'NotificationSettings/Default', requestOptions)
        .then(handleJsonResponse)
        .catch(handleCatch)
      }
      return Promise.reject(ErrorType(error));
    });
}

function getAllLocks(customerId){
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch(GetURL() + 'Locks?customerId='+customerId, requestOptions).then(handleJsonResponse).catch(handleCatch);
}

function getAllLocksByType(customerId, objectType){
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch(GetURL() + 'Locks/'+objectType+'?customerId='+customerId, requestOptions).then(handleJsonResponse).catch(handleCatch);
}

function hasLock(objectType, id){
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch(GetURL() + 'Locks/'+objectType+'/'+id, requestOptions).then(handleJsonResponse).catch(handleCatch);
}

function setLock(item){
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(item)
  };
  return fetch(GetURL() + 'Locks', requestOptions).then(handleStandResponse).catch(handleCatch);
}

function deleteLock(objectType, id){
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };
  return fetch(GetURL() + 'Locks/'+objectType+'/'+id, requestOptions).then(handleStandResponse).catch(handleCatch);
}

function sendUpgrade(customerId, comments) {
  var body = {
    customerId: customerId,
  };
  if (comments) {
    body.comments = comments
  }
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body)
  };
  return fetch(GetURL() + 'SignUp/Upgrade', requestOptions).then(handleStandResponse).catch(handleCatch);
}