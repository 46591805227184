/* global appConfig */
import React from 'react';
import { connect } from 'react-redux';

import { userActions } from '../../actions/login';

import { AnsaradaLoading, Auth0CallBack } from '@lib/simpletools';
import {keysStorage} from '@lib/indexeddb';

export const CompletePageHook = (props) => {
console.log("CompletePageHook", props.alias, props.authentication)
  if(props.alias === undefined){
    Auth0CallBack()
    .then((auth0) => {
      props.Auth0Complete(auth0)
    })
    .catch((error) => {
  console.log(error)
    })
  }

  var s = document.getElementById('loading')
  if(s !== null) s.style.display = 'none';

  return (
    <div>
      <AnsaradaLoading />
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    alias,
  } = state.authentication;

  return {
    alias,
    authentication: state.authentication,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    Auth0Complete: (auth0) => dispatch(userActions.Auth0CompleteDevice(auth0)),
  }
}

export const CompletePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompletePageHook);
