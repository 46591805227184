import { adminPermissionsConstants } from "../../constants/admin";
import { v4 as uuidv4 } from 'uuid';

var INITIAL_STATE = {}
// [boardId: string] : { id: string, boardId: string, userId: string, isSaved: boolean, shouldDelete: boolean, shouldUpdate: boolean }[] (AdminMembershipDto)
export const AdminMembershipType = {
    Standard: 1,
    Owner: 2,
}

function setPermissions(state, permissions, boardId) {
    var newState = { ...state };
    newState[boardId] = [];

    if (permissions && permissions.length) {
        permissions.forEach(permission => {
            newState[boardId].push({ ...permission, isSaved: true, shouldDelete: false, shouldUpdate: false });
        });
    }

    return newState;
}

function setNewBoardPermissions(state, boardId) {
    var newState = { ...state };
    newState[boardId] = [];

    return newState;
}

function copyBoardPermissions(state, payload) {
    var { oldBoardId, newBoardId, deleteOld } = payload;
    var newState = { ...state };

    if (newState[oldBoardId]) {
        newState[newBoardId] = [...newState[oldBoardId]];
        newState[newBoardId].forEach(ap => {
            ap.boardId = newBoardId;
        })
        if (deleteOld) {
            delete newState[oldBoardId];
        }
    }

    return newState;
}

function addPermission(state, userId, boardId, properties = {}) {
    var newState = { ...state };

    var newPermission = {
        id: uuidv4(),
        userId,
        boardId,
        type: AdminMembershipType.Standard,
        shouldUpdate: false,
        shouldDelete: false,
        isSaved: false,
    };

    Object.keys(properties).forEach(k => {
        newPermission[k] = properties[k];
    })

    if (newState[boardId]) {
        if (!newState[boardId].find(p => p.userId == userId)) {
            newState[boardId] = [...newState[boardId]];
            newState[boardId].push(newPermission);
        }
    } else {
        newState[boardId] = [newPermission];
    }

    return newState;
}

function removePermission(state, permission) {
    if (!permission) { return; }
    var newState = { ...state };
    if (newState[permission.boardId]) {
        var index = newState[permission.boardId].findIndex(p => p.id == permission.id);
        if (index > -1) {
            newState[permission.boardId] = [...newState[permission.boardId]];
            newState[permission.boardId].splice(index, 1);
        }
    }
    return newState;
}

function updatePermissionByUserId(state, boardId, userId, newProperties = {}) {
    if (!boardId || !userId || !state[boardId]) { return state; }

    var newState = { ...state };
    var perm = newState[boardId].find(p => p.userId == userId);

    if (!perm) {
        newState = addPermission(newState, userId, boardId);
        perm = newState[boardId].find(p => p.userId == userId);
    }

    Object.keys(newProperties).forEach(prop => {
        perm[prop] = newProperties[prop];
    });

    if (!newProperties.hasOwnProperty('shouldUpdate')) {
        perm.shouldUpdate = perm.isSaved ? true : false;
    }

    if (!newProperties.hasOwnProperty('shouldDelete')) {
        perm.shouldDelete = false;
    }

    newState[boardId] = [...newState[boardId]];

    return newState;
}

function shouldDeletePermission(state, permission, shouldDelete = true) {
    if (!permission) { return; }
    var newState = { ...state };
    if (newState[permission.boardId]) {
        var perm = newState[permission.boardId].find(p => p.id == permission.id);
        if (perm) {
            perm.shouldUpdate = false;
            perm.shouldDelete = shouldDelete;
            newState[permission.boardId] = [...newState[permission.boardId]];
        }
    }
    return newState;
}

export function adminPermissions(state = INITIAL_STATE, action) {
    switch (action.type) {
        case adminPermissionsConstants.COPY_BOARD_PERMISSIONS:
            return copyBoardPermissions(state, action.payload);
        case adminPermissionsConstants.SET_NEW_BOARD_PERMISSIONS:
            return setNewBoardPermissions(state, action.payload.boardId);
        case adminPermissionsConstants.SET_PERMISSIONS:
            return setPermissions(state, action.payload.permissions, action.payload.boardId);
        case adminPermissionsConstants.ADD_PERMISSION:
            return addPermission(state, action.payload.userId, action.payload.boardId, action.payload.properties);
        case adminPermissionsConstants.UPDATE_PERMISSION_BY_USERID:
            return updatePermissionByUserId(state, action.payload.boardId, action.payload.userId, action.payload.newProperties);
        case adminPermissionsConstants.SHOULD_DELETE_PERMISSION:
            return shouldDeletePermission(state, action.payload.permission, action.payload.shouldDelete);
        case adminPermissionsConstants.REMOVE_PERMISSION:
            return removePermission(state, action.payload.permission);
        default:
            return state;
    }
}