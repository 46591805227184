/* global appConfig */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import { userActions } from '@actions/admin';

import { DeviceFingerprint, AnsaradaCredentials, convertCountryToCode, isValidMobile, TrackEvent  } from '@lib/simpletools';
import { Button, TextInput } from '@common/admin';
import { AuthCode } from '@common';
import { LoadingIndicator, TextInput as AnsaradaTextInput, Autocomplete, ComboBox } from '@ansarada/ace-react';
import PhoneInput from '@common/phoneinput';
import { RoutesConstants } from '@constants/common.constants';
import { isValidNumber } from 'libphonenumber-js';
import { FaPencilAlt as PenIcon } from 'react-icons/fa';
import logo from '../../assets/images/logo/Ansarada Board Product Logo Black RGB.svg';
import ansaradaLogo from '../../assets/images/logo/Ansarada Logo Inline Black RGB.svg';
import '../loginpage/loginapp.css';
import { Checkbox, FormControlLabel } from '@mui/material';
import { MuiCheckbox } from '../common/MUI/MuiCheckbox';

class SignUpClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      company: '',
      city: '',
      country: '',
      mobile: '',
      token: '',
      username: '',
      deviceHash: '',
      hasError: false,
      hasAccepted: false,

      loading: false,
      completed: false,

      searchCity: '',
      citiesList: [],
      loadingSearch: false,
      page: 0,
      password: "",
      isFileCompleted: false,
      error: "",
      tcChecked: false,
    }

    this.list = []
    this.searchTimer = null

    this.filterList = this.filterList.bind(this)
    this.authSubmitSend = this.authSubmitSend.bind(this);
    this.clearAuth = this.clearAuth.bind(this);
  }

  componentDidMount() {
    if (this.props.existingUser === false || this.props.clientId === undefined || this.props.username === undefined
      || this.props.clientId === "" || this.props.username === "") {
      window.open(RoutesConstants.trial,"_self")
    }
    if (localStorage.getItem('acceptCookies'))
      this.setState({ hasAccepted: true })

    TrackEvent("f_board_verify-email-button.clicked",{
      signup_email: this.props.username,
      plan: "board.freemium",
      is_existing: true,
    })

    DeviceFingerprint('login')
      .then((hash) => {
        this.setState({ deviceHash: hash });
      })
      .catch(function (error) {
      });

    this.loadNames();
  }

  static getDerivedStateFromProps(nextProps, state) {
    state.completed = false
    if (state.company !== "" && state.city !== "") {
      if(state.mobile !== "" && isValidNumber(state.mobile) && isValidMobile(state.mobile))
        state.completed = true
      else if(state.mobile === "" || state.mobile.length < 4)
        state.completed = false
    }

    if(nextProps.auth0Result !== false && nextProps.auth0Result !== undefined && state.page === 0){
      state.page = 1
    }

    return state
  }

  async loadNames() {
    const response = await fetch('/lib/countries.min.json');
    const names = await response.json();

    var l = []
    for (const country in names) {
      names[country].forEach((city) => {
        if (l.indexOf(city + ", " + country) === -1)
          l.push({
            label: city + ", " + country,
            value: city + ", " + country,
            country: country,
            city: city,
          })
      });

    }

    this.list = l
  }

  handleChange(name, value) {
    if(/^\s/.test(value)) return;//no space in the start of input
    this.setState({ [name]: value })
    this.handleValidation();
  }

  acceptCookies() {
    localStorage.setItem('acceptCookies', "true")
    this.setState({ hasAccepted: true })
  }

  filterList() {
    const citiesList = this.list.filter(o => o.city.toLowerCase().startsWith(this.state.searchCity.toLowerCase()))
    this.setState({ citiesList, loadingSearch: false });
  }

  doAskforCode(){
    if(!this.props.hasOwnProperty('askforCode')) return false;
    return this.props.askforCode;
  }

  clearAuth(){
    //this.props.dispatch(alertActions.clear());
    window.open(RoutesConstants.trial,"_self")
  }

  onTcChange = () => {
    this.setState({
      tcChecked: !this.state.tcChecked
    });
  }

  handleValidation(){
    setTimeout(()=>{
      if(this.state.searchCity !== "" && this.state.city === ""){
        this.setState({error: "Please select your city from the list"})
      }else if(this.state.mobile.length >= 4 && (!isValidNumber(this.state.mobile) || !isValidMobile(this.state.mobile))){
        this.setState({error: "Mobile number is invalid"})
      }else {
        this.setState({error: ""})
      }
    }, 4000)
  }

  async authSubmitSend(authCode){
    const { dispatch, username, registrationCode, deviceId, auth0Result, athenaUsername } = this.props;

    if(auth0Result.profileId === undefined || auth0Result.profileId === "" || athenaUsername === undefined){
      this.clearAuth()
    }
    var KBDF2 = await AnsaradaCredentials(athenaUsername, auth0Result.profileId)
    var regReqest = {
      alias: username,
      username: athenaUsername,
      //customerName: "", //TODO REMOVE
      password: registrationCode,
      newpassword: KBDF2,
      // cardSerial: [],
      deviceId: deviceId,
      deviceHash: this.state.deviceHash,
      authCode: authCode,
      checkTermsAndConditions: null,
      id: auth0Result.profileId,
      mode: 2,
    }
    dispatch(userActions.registerExistingUser(regReqest));
  }

  requiredFieldIcon(state){
    if((state === 'mobile' && this.state[state].length < 4) || this.state[state] === "")
      return <span style={{color: 'red'}}> *</span>
  }

  render() {
    const { loggingIn } = this.props;
    if (this.state.page === 0) {
      return (
        <div className="page spacebetween content logContent shadeWhite">
        <div className="logboxContent page">
          <div className="loginbox">
            <div className="login-lock-header">
              <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img style={{ width: '50%', marginTop: '20px' }} src={ansaradaLogo} />
                <img style={{ width: '25%', marginTop: '30px', marginBottom: '50px' }} src={logo} />
              </span>
              <h1>Board management software designed to give boards total confidence</h1>
              <div className="signInput">
                  {this.props.error !== undefined && this.props.error !== "" && (
                    <div className="login-error-msgapp-freemium">
                      <span className="animated fadeInUp">
                        <span>{this.props.error}</span>
                      </span>
                    </div>
                  )}
                  <div className="loginUserName" onClick={() => window.open(RoutesConstants.trial, "_self")}>
                    <label>{this.props.username}</label>
                    <PenIcon style={{ marginLeft: 20 }} size={20} />
                  </div>
                  <div>
                    <TextInput
                      type='password'
                      placeholder="Password"
                      value={this.state.password}
                      onChange={(e) => { this.handleChange('password', e.target.value) }}
                    />
                  </div>
              </div>
              <div>
                <Button
                  isLoading={loggingIn === true}
                  isDisabled={this.state.password === ""}
                  onClick={() => {
                    this.props.dispatch(userActions.Auth0Login({
                      alias: this.props.username,
                      username: this.props.username,
                      password: this.state.password
                    }))
                  }}
                >Log in</Button>
              </div>
              <label className="signUpFooter">No credit card required</label>
            </div>
          </div>
          <div className="signUpAlready">
            <label>By signing up, you agree to our <a href="https://www.ansarada.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>, <a href="https://www.ansarada.com/terms-conditions" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and <a href="https://www.ansarada.com/gdpr" target="_blank" rel="noopener noreferrer">GDPR information</a>.</label>
          </div>
        </div>
        </div>
      )
    } else {
      return (
        <div className="page spacebetween maxHeight logContent shadeWhite" style={{ minHeight: '100vh', width: '100%' }}>
          <div>
            <div className="signs1logo">
              <a href="https://www.ansarada.com">
                <img style={{width: '150px', marginLeft: '150px', marginBottom: '50px', marginTop: '40px'}}  src={logo} />
              </a>
            </div>
            <div className="signs1start">
              <h4>Complete your profile</h4>
              <p>You're signing up with {this.props.username}</p>
              <div className="signUpPanel">
                <div className="signq">
                  <label>Company{this.requiredFieldIcon('company')}</label>
                  <AnsaradaTextInput
                    value={this.state.company}
                    onChangeValue={(e) => { this.handleChange('company', e) }}
                  />
                </div>
                <div className="signq">
                  <label>City{this.requiredFieldIcon('city')}</label>
                  <Autocomplete
                    name="city"
                    selected={this.state.city}
                    onSelected={(e) => {
                      this.handleChange('city', e.city)
                      this.handleChange('country', e.country)
                      this.setState({ loadingSearch: false, searchCity: e.value })
                    }}
                    onChangeValue={(e) => {
                      if (e === "") return
                      this.setState({ loadingSearch: true, searchCity: e })
                      clearTimeout(this.searchTimer)
                      this.searchTimer = setTimeout(this.filterList, 1000)
                    }}
                    onToggle={open=>{
                      if(!open) this.setState({ loadingSearch: false, searchCity: "" })
                    }}
                    placeholder="Enter and select your location or closest city"
                    items={this.state.citiesList}
                    defaultSelected={null}
                    filter="none"
                    insertMode={false}
                    loading={this.state.loadingSearch}
                    value={this.state.searchCity}
                    multiple={false}
                    size="Medium"
                    status="Good"
                  />
                </div>
                <div className="signq">
                  <label>Mobile{this.requiredFieldIcon('mobile')}</label>
                  <PhoneInput
                    value={this.state.mobile}
                    onChange={(e) => { this.handleChange('mobile', e) }}
                  />
                </div>
                {((this.props.error !== undefined && this.props.error !== "" && this.props.error !== 'H215') || this.state.error !== "") && (
                  <div className="login-error-msgapp-freemium">
                    <span className="animated fadeInUp">
                      <span>{this.state.error !== "" ? this.state.error : this.props.error}</span>
                    </span>
                  </div>
                )}
                {this.props.error === 'H215' && (
                  <div className="login-message-msgapp">
                    <span className="animated fadeInUp">
                      <span>Please <a href={RoutesConstants.login} className='login-message-link'>log in</a> here</span>
                    </span>
                  </div>
                )}
              </div>
              <FormControlLabel control={<MuiCheckbox background='white' checked={this.state.tcChecked} onChange={this.onTcChange} />} label={<div>
                <span>I have read and I agree to the&nbsp;
                  <a href="https://www.ansarada.com/terms-conditions" target="_blank">Terms and Conditions</a>,&nbsp;
                  <a href="https://www.ansarada.com/cookie-policy" target="_blank">Cookie Policy</a>&nbsp;
                  and <a href="https://www.ansarada.com/privacy" target="_blank">Privacy Policy</a>.
                </span>
              </div>}
              />
              <div className="signs1but">
                <Button
                  isLoading={this.props.loading}
                  isDisabled={!this.state.completed || !this.state.tcChecked}
                  onClick={() => {
                    this.setState({ isFileCompleted: true });
                    const countryCode = convertCountryToCode(this.state.country)
                    this.props.dispatch(userActions.completeExistingUserSignUp({
                      company: this.state.company.trim(),
                      city: this.state.city,
                      country: countryCode,
                      mobile: this.state.mobile,
                      token: this.props.auth0Result.token,
                      deviceHash: this.state.deviceHash,
                      username: this.props.username
                    }))
                  }}
                >Complete profile</Button>
              </div>
            </div>
          </div>
          {loggingIn && this.state.isFileCompleted &&
            <div className="spin-overlay">
              <LoadingIndicator size={48} dark={true}/>
            </div>
          }
          {this.state.hasAccepted &&
            <div className="cookieAccept">
              <div className="cookieText">
                <label>Use of this site accepts the <a href="https://www.ansarada.com/cookie-policy" className="cookieLink">use of cookies</a>.</label>
                <div className="cookieBut">
                  <Button type="clear" onClick={this.acceptCookies.bind(this)}>Ok</Button>
                </div>
              </div>
            </div>
          }
          {this.doAskforCode() && !this.props.loading &&
            <AuthCode
              onSubmit={this.authSubmitSend}
              onExit={this.clearAuth}
              isFreemium={true}
              {...this.props}
            />
          }
        </div>
      )
    }

  }
}


function mapStateToProps(state) {
  return {
    username: state.authentication.username,
    existingUser: state.authentication.existingUser,
    clientId: state.authentication.clientId,
    loggingIn: state.authentication.loggingIn,
    auth0Result: state.authentication.auth0Result,
    error: state.authentication.error,
    loading: state.authentication.loading,
    askforCode: state.authentication.askforCode,
    mfaId: state.authentication.mfaId,
    mfaType: state.authentication.mfaType,
    deviceId: state.authentication.deviceId,
    registrationCode: state.authentication.registrationCode,
    athenaUsername: state.authentication.athenaUsername,
    alert: state.alert,
  };
}

const connectedSignUpPage = connect(mapStateToProps)(SignUpClass);
export { connectedSignUpPage as SignUpExistingUser };
