import { deviceConstants } from '@constants/admin';
import { deviceService } from '@services/admin';
import { alertActions } from './';
import { history } from '@lib';
import { CheckInternet } from '@lib/simpletools';

export const deviceActions = {
    getDeviceDetails,
    setEnableDisableDevice,
    giveNewDeviceApproval,
};

function getDeviceDetails(id) {
  return dispatch => {
      dispatch(request(id));
      deviceService.getDeviceDetails(id)
          .then(
              payload => dispatch(success(payload)),
              error => {
                error = CheckInternet(error, dispatch)
                dispatch(failure(error))
              }
          );
  };

  function request(id) { return { type: deviceConstants.GET_DEVICE_REQUEST, id } }
  function success(payload) { return { type: deviceConstants.GET_DEVICE_SUCCESS, payload } }
  function failure(id, error) { return { type: deviceConstants.GET_DEVICE_FAILURE, id, error } }
}

function setEnableDisableDevice(item) {
  return dispatch => {
      dispatch(request());
      deviceService.setEnableDisableDevice(item)
          .then(
              payload => dispatch(success(item)),
              error => dispatch(failure(error))
          );
  };

  function request() { return { type: deviceConstants.SET_DEVICE_STATUS_REQUEST } }
  function success(item) { return { type: deviceConstants.SET_DEVICE_STATUS_SUCCESS, item } }
  function failure(error) { return { type: deviceConstants.SET_DEVICE_STATUS_FAILURE, error } }
}

function giveNewDeviceApproval(item) {
  return dispatch => {
      dispatch(request(item));
      deviceService.giveNewDeviceApproval(item)
          .then(
              payload => {
                dispatch(success(item, payload))
              },
              error => dispatch(failure(item, {error}))
          );
  };

  function request(item) { return { type: deviceConstants.SET_NEW_DEVICE_REQUEST, item } }
  function success(item, payload) { return { type: deviceConstants.SET_NEW_DEVICE_SUCCESS, item, payload } }
  function failure(item, error) { return { type: deviceConstants.SET_NEW_DEVICE_FAILURE, item, error } }
}
