import { userConstants as baseConstants } from '../user.constants'

const constants = {
  CLEAR_AUTH_CODE_REQUEST: 'CLEAR_AUTH_CODE_REQUEST',

  LOGIN_CLEAR: 'LOGIN_CLEAR',
  LOGIN_CLEAR_ERROR: 'LOGIN_CLEAR_ERROR',
  LOGIN_CHECK: 'USERS_LOGIN_CHECK',

  REGISTER_USERANDDEVICE_REQUEST: 'REGISTER_USERANDDEVICE_REQUEST',
  REGISTER_USERANDDEVICE_SUCCESS: 'REGISTER_USERANDDEVICE_SUCCESS',
  REGISTER_USERANDDEVICE_FAILURE: 'REGISTER_USERANDDEVICE_FAILURE',

  REGISTER_USERDEVICEKEY_REQUEST: 'USERDEVICEKEY_REGISTER_REQUEST',
  REGISTER_USERDEVICEKEY_SUCCESS: 'USERDEVICEKEY_REGISTER_SUCCESS',
  REGISTER_USERDEVICEKEY_FAILURE: 'USERDEVICEKEY_REGISTER_FAILURE',

  REGISTER_NEW_USER_REQUEST: 'REGISTER_NEW_USER_REQUEST',
  REGISTER_NEW_USER_SUCCESS: 'REGISTER_NEW_USER_SUCCESS',
  REGISTER_NEW_USER_FAILURE: 'REGISTER_NEW_USER_FAILURE',
  REGISTER_NEW_USER_CLEAR: 'REGISTER_NEW_USER_CLEAR',
  REGISTER_NEW_USER_CCOMPLETED: 'REGISTER_NEW_USER_CCOMPLETED',

  FORGOT_NOCODE_LOGIN_REQUEST: 'FORGOT_NOCODE_LOGIN_REQUEST',

  FORGOT_NOCODE_NOTFY_REQUEST: 'FORGOT_NOCODE_NOTFY_REQUEST',
  FORGOT_NOCODE_NOTFY_SUCCESS: 'FORGOT_NOCODE_NOTFY_SUCCESS',
  FORGOT_NOCODE_NOTFY_FAILURE: 'FORGOT_NOCODE_NOTFY_FAILURE',

  FORGOT_NOCODE_CODE_REQUEST: 'FORGOT_NOCODE_CODE_REQUEST',
  FORGOT_NOCODE_CODE_SUCCESS: 'FORGOT_NOCODE_CODE_SUCCESS',
  FORGOT_NOCODE_CODE_FAILURE: 'FORGOT_NOCODE_CODE_FAILURE',

  FORGOT_NOCODE_NEWPASS_REQUEST: 'FORGOT_NOCODE_NEWPASS_REQUEST',
  FORGOT_NOCODE_NEWPASS_SUCCESS: 'FORGOT_NOCODE_NEWPASS_SUCCESS',
  FORGOT_NOCODE_NEWPASS_FAILURE: 'FORGOT_NOCODE_NEWPASS_FAILURE',

  FORGOT_CARD_NOTFY_REQUEST: 'FORGOT_CARD_NOTFY_REQUEST',
  FORGOT_CARD_NOTFY_SUCCESS: 'FORGOT_CARD_NOTFY_SUCCESS',
  FORGOT_CARD_NOTFY_FAILURE: 'FORGOT_CARD_NOTFY_FAILURE',

  FORGOT_CARD_NEWPASS_REQUEST: 'FORGOT_CARD_NEWPASS_REQUEST',
  FORGOT_CARD_NEWPASS_SUCCESS: 'FORGOT_CARD_NEWPASS_SUCCESS',
  FORGOT_CARD_NEWPASS_FAILURE: 'FORGOT_CARD_NEWPASS_FAILURE',

  AUTHZERO_RESET_REQUEST: 'AUTHZERO_RESET_REQUEST',
  AUTHZERO_RESET_SUCCESS: 'AUTHZERO_RESET_SUCCESS',
  AUTHZERO_RESET_FAILURE: 'AUTHZERO_RESET_FAILURE',

  LOGIN_WEBSOCKET_SUCCESS: 'LOGIN_WEBSOCKET_SUCCESS',
  LOGIN_WEBSOCKET_FAILURE: 'LOGIN_WEBSOCKET_FAILURE',

  LOGIN_LOCK_REQUEST: 'USERS_LOCK_LOGIN_REQUEST',
  LOGIN_LOCK_FAILURE: 'USERS_LOCK_LOGIN_FAILURE',

  KEEP_SESSION_ALIVE: 'KEEP_SESSION_ALIVE',
  KILL_SESSION_ALIVE: 'KILL_SESSION_ALIVE',
  ACTIVE_SESSION_ALIVE: 'ACTIVE_SESSION_ALIVE',
  WEBSITE_UPDATE_AVAILABLE: 'WEBSITE_UPDATE_AVAILABLE',

  GET_USERS_REQUEST: 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_FAILURE: 'GET_USERS_FAILURE',

  GET_PASSWORDPOLICY_REQUEST: 'GET_PASSWORDPOLICY_REQUEST',
  GET_PASSWORDPOLICY_SUCCESS: 'GET_PASSWORDPOLICY_SUCCESS',
  GET_PASSWORDPOLICY_FAILURE: 'GET_PASSWORDPOLICY_FAILURE',

  GET_IMPERSONATE_REQUEST: 'GET_IMPERSONATE_REQUEST',
  GET_IMPERSONATE_SUCCESS: 'GET_IMPERSONATE_SUCCESS',
  GET_IMPERSONATE_FAILURE: 'GET_IMPERSONATE_FAILURE',

  SWITCH_CUSTOMER: 'SWITCH_CUSTOMER',
  SET_TIMEOUT: 'SET_TIMEOUT',

  TRAIL_COMPLETE_REQUEST: 'TRAIL_COMPLETE_REQUEST',
  TRAIL_COMPLETE_SUCCESS: 'TRAIL_COMPLETE_SUCCESS',
  TRAIL_COMPLETE_FAILURE: 'TRAIL_COMPLETE_FAILURE',

  TRAIL_EXISTINGCOMPLETE_REQUEST: 'TRAIL_EXISTINGCOMPLETE_REQUEST',
  TRAIL_EXISTINGCOMPLETE_SUCCESS: 'TRAIL_EXISTINGCOMPLETE_SUCCESS',
  TRAIL_EXISTINGCOMPLETE_FAILURE: 'TRAIL_EXISTINGCOMPLETE_FAILURE',

  TRAIL_EXISTINGREGISTER_REQUEST: 'TRAIL_EXISTINGREGISTER_REQUEST',
  TRAIL_EXISTINGREGISTER_SUCCESS: 'TRAIL_EXISTINGREGISTER_SUCCESS',
  TRAIL_EXISTINGREGISTER_FAILURE: 'TRAIL_EXISTINGREGISTER_FAILURE',

  TRAIL_CHECKEMAIL_REQUEST: 'TRAIL_CHECKEMAIL_REQUEST',
  TRAIL_CHECKEMAIL_SUCCESS: 'TRAIL_CHECKEMAIL_SUCCESS',
  TRAIL_CHECKEMAIL_FAILURE: 'TRAIL_CHECKEMAIL_FAILURE',
  TRAIL_CHECKEMAIL_DELETE: 'TRAIL_CHECKEMAIL_DELETE',

  TRAIL_COSECSAVE_REQUEST: 'TRAIL_COSECSAVE_REQUEST',
  TRAIL_COSECSAVE_SUCCESS: 'TRAIL_COSECSAVE_SUCCESS',
  TRAIL_COSECSAVE_FAILURE: 'TRAIL_COSECSAVE_FAILURE',

  TRAIL_COMPLETE_REQUEST: 'TRAIL_COMPLETE_REQUEST',
  TRAIL_COMPLETE_SUCCESS: 'TRAIL_COMPLETE_SUCCESS',
  TRAIL_COMPLETE_FAILURE: 'TRAIL_COMPLETE_FAILURE',

  LOGIN_AUTHZERO_REQUEST: 'LOGIN_AUTHZERO_REQUEST',
  LOGIN_AUTHZERO_SUCCESS: 'LOGIN_AUTHZERO_SUCCESS',
  LOGIN_AUTHZERO_FAILURE: 'LOGIN_AUTHZERO_FAILURE',
};

export const userConstants = Object.assign(baseConstants, constants)
