import React from 'react';

import '../../styles/progressbar.css';

export default class ProgressBar extends React.Component {
  constructor(props) {
    super(props);
  }

  getValue(value){
    if(value < 0) return 0;
    if(value > 100) return 100;
    return value;
  }

  render() {
    var defaultBar = "successBar";
    if(this.props.classNameBar !== undefined)
      defaultBar = this.props.classNameBar;
    var sucess = '0%'
    if(this.props.success !== undefined && this.props.success !== null)
      sucess = this.getValue(this.props.success).toString()+'%';
    if(this.props.percent === undefined && this.props.section === undefined)
      return (
        <div className={`${this.props.className} myBarProgress`}>
          <span className={defaultBar} style={{ display:'flex',width : sucess}}></span>
        </div>
      )
    else if(this.props.percent !== undefined){
      const load = this.getValue(this.props.percent).toString()+'%';
      return (
        <div className={`${this.props.className} myBarProgress`}>
          <span className="loadBar" style={{ display:'flex',width : load}}>
            <span className={defaultBar} style={{ display:'flex',width : sucess}}></span>
          </span>
        </div>
      );
    }else{
      const load = this.getValue(this.props.section).toString()+'%';
      return (
        <div>
          <div className={`${this.props.className} myBarProgress`}>
            <span className="successBar" style={{ display:'flex',width : sucess}}></span>
          </div>
          <div className='myBarProgress'>
          <span className="loadBar" style={{ display:'flex',width : load}}></span>
          </div>
        </div>
      );
    }
  }
}
