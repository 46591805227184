import { authHeader, GetURL, LogoutAndRedirect, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch } from '@lib';
import fetch from '@lib/fetch-retry';

export const alertService = {
  sendPushNotifications,
  analytics,
  diagnosticData,
};

function sendPushNotifications(items){
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(items)
  };
  return fetch(GetURL() + 'PNS/Messages', requestOptions).then(handleStandResponse).catch(handleCatch);
}

function analytics(items){
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(items)
  };
  return fetch(GetURL() + 'Analytics/Populated', requestOptions).then(handleStandResponse).catch(handleCatch);
}

function diagnosticData(item, headers){
  const requestOptions = {
    method: 'POST',
    headers: headers ? headers : authHeader(),
    body: JSON.stringify(item)
  };
  return fetch(GetURL() + 'Diag/DiagnosticData', requestOptions).then(handleStandResponse).catch(handleCatch);
}
