import React, { useState } from 'react';
import { Input, TextField, Tooltip } from '@mui/material';
import { MuiButton as Button } from '@common/MUI';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import './styles/CustomTimePicker.css';

//props
//minHour : number
//maxHour : number
//minMinutes : number
//maxMinutes : number
//onChange = (newTime: string eg: ("13:00"))
//value: string eg: ("13:00")

export class CustomTimePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timeString: props.value || '10:00'
        }
        this.maxHour = props.maxHour ? props.maxHour : 23;
        this.minHour = props.minHour ? props.minHour : 0;
        this.maxMinutes = props.maxMinutes ? props.maxMinutes : 59;
        this.minMinutes = props.minMinutes ? props.minMinutes : 0;
    }

    onChange = (newValue, save = false) => {
        if (this.props.onChange) {
            this.props.onChange(newValue, save);
        }
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    onInputChange = (e) => {
        if (e.target.value) {
            this.setState({
                timeString: e.target.value
            }, () => {
                //this.onChange(e.target.value);
            });
        }
    }

    changeHour = (increase = false) => {
        var times = this.state.timeString.split(":");
        var newHour = parseInt(times[0]);
        if (increase) {
            newHour++;
            if (newHour > this.maxHour) {
                newHour = this.maxHour;
            }
        } else {
            newHour--;
            if (newHour < this.minHour) {
                newHour = this.minHour;
            }
        }
        if (newHour < 10) {
            newHour = "0" + newHour;
        }

        var newTime = newHour + ":" + times[1];
        this.setState({
            timeString: newTime
        }, () => {
            //this.onChange(newTime);
        });
    }

    changeMinutes = (increase = false) => {
        var times = this.state.timeString.split(":");
        var newMinutes = parseInt(times[1]);
        if (increase) {
            newMinutes++;
            if (newMinutes > this.maxMinutes) {
                newMinutes = this.maxMinutes;
            }
        } else {
            newMinutes--;
            if (newMinutes < this.minMinutes) {
                newMinutes = this.minMinutes;
            }
        }
        if (newMinutes < 10) {
            newMinutes = "0" + newMinutes;
        }

        var newTime = times[0] + ":" + newMinutes;
        this.setState({
            timeString: newTime
        }, () => {
            //this.onChange(newTime);
        });
    }

    render() {
        return (
            <div key={this.props.key} style={{ position: 'relative' }}>
                <div className='timepicker-button-container'>
                    <Tooltip title='+ 1 Hour' placement='left'>
                        <span className='timepicker-hourCol link' onMouseDown={() => this.changeHour(true)}>
                            <ArrowDropUpIcon style={{ fontSize: '2em' }} />
                        </span>
                    </Tooltip>
                    <Tooltip title='+ 1 Minute' placement='right'>
                        <span className='timepicker-minCol link' onMouseDown={() => this.changeMinutes(true)}>
                            <ArrowDropUpIcon style={{ fontSize: '2em' }} />
                        </span>
                    </Tooltip>
                </div>
                <TextField
                    label={this.props.label ? this.props.label : 'Meeting time'}
                    type='time'
                    value={this.state.timeString}
                    onChange={this.onInputChange}
                    variant='standard'
                    fullWidth={true}
                />
                <div className='timepicker-button-container'>
                    <Tooltip title='- 1 Hour' placement='left'>
                        <span className='timepicker-hourCol link' onMouseDown={() => this.changeHour(false)}>
                            <ArrowDropDownIcon style={{ fontSize: '2em' }} />
                        </span>
                    </Tooltip>
                    <Tooltip title='- 1 Minute' placement='right'>
                        <span className='timepicker-minCol link' onMouseDown={() => this.changeMinutes(false)}>
                            <ArrowDropDownIcon style={{ fontSize: '2em' }} />
                        </span>
                    </Tooltip>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 0' }}>
                    <Button color='error' variant='outlined' onMouseDown={() => { this.onClose() }}>Cancel</Button>
                    <Button variant='contained' onMouseDown={() => { this.onChange(this.state.timeString, true) }}>Ok</Button>
                </div>
            </div>
        );
    }
}