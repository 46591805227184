import { adminPermissionsConstants } from "../../constants/admin";
import { AdminMembershipType } from "../../reducers/admin/adminPermissions.reducer";
import { adminPermissionsService } from '../../services/admin';

export const adminPermissionsActions = {
    setNewBoardPermission,
    copyBoardPermissions,
    addPermission,
    removePermission,
    updatePermissionByUserId,
    shouldDeletePermission,
    getPermissions,
    saveChanges,
}

function setNewBoardPermission(boardId) {
    return (dispatch, getState) => {
        dispatch({
            type: adminPermissionsConstants.SET_NEW_BOARD_PERMISSIONS,
            payload: { boardId }
        });
    }
}

// copy admin permissions from one board id to another 
function copyBoardPermissions(oldBoardId, newBoardId, deleteOld = true) {
    return (dispatch, getState) => {
        dispatch({
            type: adminPermissionsConstants.COPY_BOARD_PERMISSIONS,
            payload: { oldBoardId, newBoardId, deleteOld }
        });
    }
}

function getPermissions(boardId) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            adminPermissionsService.getAdminMembershipsForBoard(boardId)
                .then((result) => {
                    dispatch({
                        type: adminPermissionsConstants.SET_PERMISSIONS,
                        payload: { permissions: result, boardId: boardId }
                    });
                    resolve();
                })
                .catch((e) => { reject(); })
        })
    }
}

function addPermission(userId, boardId, properties = {}) {
    return (dispatch, getState) => {
        dispatch({
            type: adminPermissionsConstants.ADD_PERMISSION,
            payload: { userId, boardId, properties }
        });
    }
}

function saveChanges(boardId) {
    return (dispatch, getState) => {
        var allPermissions = getState().adminPermissions && getState().adminPermissions[boardId] ? getState().adminPermissions[boardId] : null;
        if (!allPermissions) { return Promise.reject(); }

        var permissionsToSave = allPermissions.filter(p => !p.isSaved);
        var permissionsToUpdate = allPermissions.filter(p => p.shouldUpdate && p.isSaved);
        var permissionsToDelete = allPermissions.filter(p => p.shouldDelete);
        var allPromises = [];

        permissionsToSave.forEach(p => {
            console.log('save', p);
            allPromises.push(new Promise((resolve, reject) => {
                adminPermissionsService.savePermission({
                    id: p.id,
                    type: p.type,
                    boardId: p.boardId,
                    userId: p.userId
                })
                    .then((result) => {
                        dispatch(updatePermissionByUserId(p.boardId, p.userId, { isSaved: true, shouldUpdate: false, shouldDelete: false }));
                        resolve();
                    })
                    .catch((e) => { reject(p); })
            }));
        });

        permissionsToUpdate.forEach(p => {
            console.log('update', p);
            allPromises.push(new Promise((resolve, reject) => {
                adminPermissionsService.updatePermission({
                    id: p.id,
                    type: p.type,
                    boardId: p.boardId,
                    userId: p.userId
                })
                    .then((result) => {
                        dispatch(updatePermissionByUserId(p.boardId, p.userId, { isSaved: true, shouldUpdate: false, shouldDelete: false }));
                        resolve();
                    })
                    .catch((e) => { reject(p); })
            }));
        });

        permissionsToDelete.forEach(p => {
            console.log('delete', p);
            allPromises.push(dispatch(removePermission(p)));
        });

        return Promise.all(allPromises);
    }
}

function updatePermissionByUserId(boardId, userId, newProperties = {}) {
    return (dispatch, getState) => {
        dispatch({
            type: adminPermissionsConstants.UPDATE_PERMISSION_BY_USERID,
            payload: { boardId, userId, newProperties }
        })
    }
}

function shouldDeletePermission(permission, shouldDelete = true) {
    return (dispatch, getState) => {
        dispatch({
            type: adminPermissionsConstants.SHOULD_DELETE_PERMISSION,
            payload: { permission, shouldDelete }
        })
    }
}

function removePermission(permission) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (permission.isSaved) {
                adminPermissionsService.removePermission(permission.id)
                    .then((result) => {
                        dispatch({
                            type: adminPermissionsConstants.REMOVE_PERMISSION,
                            payload: { permission }
                        });
                        resolve();
                    })
                    .catch((e) => { reject(permission); })
            } else {
                dispatch({
                    type: adminPermissionsConstants.REMOVE_PERMISSION,
                    payload: { permission }
                });
                resolve();
            }
        })
    }
}