import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { MuiButton } from '@common/MUI';
import { kvpConstants } from "../../constants/admin";
import { v4 as uuidv4 } from 'uuid';

// Key Value pair redux actions

export const kvpActions = {
    set_kvp,
    delete_kvp,
    reset_kvp,
    clearAll_kvp,
    selectFileId,
    selectFileIds,
    deselectFileIds,
    togglePrimaryNeedPopup,
    setIsAnnotatedItemId,
}

function set_kvp(kvp) {
    return (dispatch, getState) => {
        dispatch({
            type: kvpConstants.SET_KVP,
            payload: {
                key: kvp.key,
                value: kvp.value
            }
        });
    }
}

function delete_kvp(key) {
    return (dispatch, getState) => {
        dispatch({
            type: kvpConstants.DELETE_KVP,
            payload: {
                key: key
            }
        });
    }
}

function reset_kvp(key) {
    return (dispatch, getState) => {
        dispatch({
            type: kvpConstants.RESET_KVP,
            payload: {
                key: key
            }
        });
    }
}

function clearAll_kvp() {
    return (dispatch, getState) => {
        dispatch({ type: kvpConstants.CLEAR_ALL_KVP });
    }
}

function selectFileIds(ids, select = true) {
    return (dispatch, getState) => {
        var selectedFileIds = getState().keyValuePair.filepage_selected_file_ids;
        if (select) {
            selectedFileIds = [...selectedFileIds, ...ids];
        } else {
            selectedFileIds = [];
        }
        dispatch({
            type: kvpConstants.SET_KVP,
            payload: {
                key: 'filepage_selected_file_ids',
                value: selectedFileIds
            }
        });
    }
}

function deselectFileIds(ids) {
    return (dispatch, getState) => {
        var selectedFileIds = getState().keyValuePair.filepage_selected_file_ids;
        selectedFileIds = selectedFileIds.filter(id => !ids.includes(id));

        dispatch({
            type: kvpConstants.SET_KVP,
            payload: {
                key: 'filepage_selected_file_ids',
                value: selectedFileIds
            }
        });
    }
}

function selectFileId(id, select = true) {
    return (dispatch, getState) => {
        var selectedFileIds = getState().keyValuePair.filepage_selected_file_ids;
        if (select) {
            selectedFileIds = [...selectedFileIds, id];
        } else {
            selectedFileIds = selectedFileIds.filter(f => f !== id);
        }
        dispatch({
            type: kvpConstants.SET_KVP,
            payload: {
                key: 'filepage_selected_file_ids',
                value: selectedFileIds
            }
        });
    }
}

function setIsAnnotatedItemId(id, select = true) {
    return (dispatch, getState) => {
        var ids = getState().keyValuePair.itemIdHasAnnotations;
        if (select) {
            if (ids.indexOf(id) < 0) {
                ids = [...ids, id];
            }
        } else {
            ids = ids.filter(f => f !== id);
        }
        dispatch({
            type: kvpConstants.SET_KVP,
            payload: {
                key: 'itemIdHasAnnotations',
                value: ids
            }
        });
    }
}

function togglePrimaryNeedPopup(show = false) {
    return (dispatch, getState) => {
        var primaryNeedPopupState = getState().keyValuePair['freemium_primary_need'];
        if(!primaryNeedPopupState || (primaryNeedPopupState.dismissed && show)) { return; }
        primaryNeedPopupState = {...primaryNeedPopupState};
        primaryNeedPopupState.show = show;
        primaryNeedPopupState.dismissed = !show;
        dispatch({
            type: kvpConstants.SET_KVP,
            payload: {
                key: 'freemium_primary_need',
                value: primaryNeedPopupState
            }
        });
    }
}