import { pdfjs } from "react-pdf";
import { PDFDocument } from 'pdf-lib';

import {
  DownloadBlob,
} from '@lib/simpletools';
//var PdfjsWorker = require('worker-loader!../../lib/pdf.worker.min.js');
//pdfjs.GlobalWorkerOptions.workerPort = new PdfjsWorker();
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = './lib/pdf.worker.js';

export default class PDF
{
  constructor() {
    this.pdf = null;
    this.srcDoc = null;
    pdfjs.GlobalWorkerOptions.workerSrc = './lib/pdf.worker.js';
  }

  checkPDFValidation(src){
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onloadend = (event) => {
        const firstDonorPdfBytes = new Uint8Array(event.target.result);
        PDFDocument.load(firstDonorPdfBytes)
        .then((pdf)=>{
          var t = pdf.getPageIndices()
          resolve()
        }, function (reason) {
          // PDF loading error
          console.log(reason);
          if(reason.message.includes("Input document to `PDFDocument.load` is encrypted")){
            reject("PDF Encrypted")
            /*var s = URL.createObjectURL(src)

            PDFNet.PDFDoc.createFromURL(s)
            .then((doc)=>{
              debugger
              doc.initSecurityHandler()
            })

            /*
            var loadingTask = pdfjs.getDocument(s).promise
            loadingTask.onPassword = function(updatePassword, reason) {
              loadingTask.destroy();
              // or throw new Error("");
            };
            loadingTask.then((pdfDocument) => {


              pdfDocument.getData()
              .then((d) => {
              //  URL.revokeObjectURL(s);

                PDFDocument.load(d)
                .then((pdf)=>{
                  var t = pdf.getPageIndices()
                  reject("PDF Encrypted")
                }, function (reason) {
                  debugger
                  reject("PDF Encrypted")
                });
                //reject("PDF Encrypted")
              })
              .catch((e)=>{
                console.log(e)
                URL.revokeObjectURL(s);
              })


            })
            .catch((e)=>{
              console.log(e)
              URL.revokeObjectURL(s);
            })*/
            return
          }
          reject(reason)
        });
      }
      reader.readAsArrayBuffer(src)
    });
  }

  checkAndLoadPDF(file){
    this.srcDoc = URL.createObjectURL(file);
    return new Promise((resolve, reject) => {
      pdfjs.getDocument(this.srcDoc)
      .promise.then((pdfDocument) => {
       this.pdf = pdfDocument;
       resolve();
      }, function (reason) {
         // PDF loading error
         console.log(reason);
         var reader = new FileReader();
         reader.onloadend = (event) => {
           const firstDonorPdfBytes = new Uint8Array(event.target.result);
           PDFDocument.load(firstDonorPdfBytes)
           .then((pdf)=>{
             var t = pdf.getPageIndices()
             resolve()
           }, function (reason) {
             // PDF loading error
             console.log(reason);
             if(reason.message.includes("Input document to `PDFDocument.load` is encrypted")){
               reject("PDF Encrypted")
               return
             }
             reject(reason)
           });
         }
         reader.readAsArrayBuffer(file)
//       reject()
     });
    });
  }

  closePdf(){
    if(this.srcDoc !== null)
      URL.revokeObjectURL(this.srcDoc);
  }

  Load(src){
    return new Promise((resolve, reject) => {
      pdfjs.getDocument(src)
     .promise.then((pdfDocument) => {
       this.pdf = pdfDocument;
       resolve();
     }, function (reason) {
       // PDF loading error
       console.log(reason);
       reject()
     });
    });
  }

  GetPageCount(){
    if(this.pdf === null) return -1;
    if(!this.pdf._pdfInfo === undefined) return -1;
    if(!this.pdf._pdfInfo.numPages === undefined) return -1;
    return this.pdf._pdfInfo.numPages;
  }

  GenerateThumbnail(){
    return new Promise((resolve, reject) => {
      this.pdf.getPage(1).then((page) => {
        var canvas = document.createElement("canvas");
        canvas.style.display = 'none';

        //var canvas = document.getElementById('the-canvas');
        var context = canvas.getContext('2d');
        canvas.height = 160;//viewport.height;
        canvas.width = 110;//viewport.width;

        var scale = canvas.width / page.getViewport({scale: 1.0}).width;
        var viewport = page.getViewport({scale: scale});
        var task = page.render({canvasContext: context, viewport: viewport})
        task.promise.then(() => {
          if(canvas.toBlob !== undefined){
            canvas.toBlob((myBlob) => {
              resolve(myBlob);
            }, "image/png");
          }else{
            var binStr = atob( canvas.toDataURL('image/png', 0.92).split(',')[1] );
            var len = binStr.length;
            var arr = new Uint8Array(len);

            for (var i = 0; i < len; i++ ) {
              arr[i] = binStr.charCodeAt(i);
            }

            resolve(new Blob( [arr], {type: 'image/png'} ));
          }
        })
        .catch((error)=>{
          console.log("GenerateThumbnail err1",error);
          reject();
        });
      })
      .catch((error)=>{
        console.log("GenerateThumbnail err2",error);
        reject();
      });
    });
  }
}
