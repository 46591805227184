import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../../lib/index';
import { Button as AnsaradaButton, Tooltip } from '@ansarada/ace-react';

export class Button extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        isActive: false,
        isFocus: false,
        isHover: false,
    }

    this.textRef = null
  }

  loaderColour(){
    const { type } = this.props
    switch(type){
      case 'back': return '#999999'
      case 'clear': return '#999999'
      default:
        return '#ffffff'
    }
  }

  isAnsaradaButton(){
    const { type } = this.props
    switch(type){
      case undefined:
      case '':
      case 'login':
      case 'clear':
      case 'red':
        return true
    }

    return false
  }

  type(){
  /*  const { isHover, isActive } = this.state
    const { type, isDisabled, className, isError } = this.props
    var c = 'btn-bg', css = ""
    switch(type){
      case 'login': c = 'btn-login'; break;
      case 'back': c = 'btn-arrow'; break;
      case 'clear': c = 'btn-clear'; break;
      case 'org': c = 'btn-org'; break;
      case 'tang': c = 'btn-tang'; break;
      case 'red': c = 'btn-red'; break;
      case 'high': c = 'btn-high'; break;
      case 'cleargray': c = 'btn-gray'; break;
      case 'tile': c = 'btn-tile'; break;
      case 'none': c = 'btn-none'; break;
    }
    css += c
    if(isError) css += ' btn-lockerror'
    //else if(isDisabled) css += ' btn-disable'
    //else if(isActive) css += ' '+c+'active'
    //else if(isHover) css += ' '+c+'hover'
    if(className) css += ' '+className
    return css*/

    const { type } = this.props
    var c = "Primary"
    switch(type){
      case '':
      case undefined:
      case 'login': c = 'Primary'; break;
      //case 'back': c = 'Ghost'; break;
      //case 'back': c = 'btn-arrow'; break;
      case 'clear': c = 'Default'; break;
      //case 'org': c = 'btn-org'; break;
      //case 'tang': c = 'btn-tang'; break;
      case 'red': c = 'Caution'; break;
      //case 'high': c = 'btn-high'; break;
      //case 'cleargray': c = 'btn-gray'; break;
      //case 'tile': c = 'btn-tile'; break;
      //case 'none': c = 'btn-none'; break;
    }
    return c
  }

  css(){
    const { type, className, isError, isDisabled } = this.props
    const { isHover, isActive } = this.state

    var c = ""

    switch(type){
      case 'back': c = 'btn-arrow';  break;
      case 'org': c = 'btn-org'; break;
      case 'tang': c = 'btn-tang'; break;
      case 'high': c = 'btn-high'; break;
      case 'cleargray': c = 'btn-gray'; break;
      case 'tile': c = 'btn-tile'; break;
      case 'none': c = 'btn-none'; break;
      case 'white': c = 'btn-white'; break;
      case 'svg': c = 'btn-svg'; break;
    }

    if(isError) c += ' btn-lockerror'
    else if(isDisabled) c += ' btn-disable'
    else if(isActive) c += ' '+c+'active'
    else if(isHover) c += ' '+c+'hover'
    if(className) c += ' '+className

    return c
  }

  onClick(event){
    if(this.props.isError)
      return this.props.onError(event)
    if(this.props.isDisabled)
      if(this.props.onReject)
        return this.props.onReject(event)
      else if(this.props.block === true || this.props.block === undefined)
        return
    if(this.props.link){
      history.push({
        pathname: this.props.link.pathname,
        query: this.props.link.query
      })
      return
    }
    if(this.props.onClick) this.props.onClick(event)
  }

  onMouseEnter(event){
    this.setState({ isHover: true });
    if(this.props.onMouseEnter) this.props.onMouseEnter(event)
  }

  onMouseLeave(event){
    this.setState({ isHover: false });
    if(this.props.onMouseLeave) this.props.onMouseLeave(event)
  }

  onMouseDown(event){
    this.setState({ isActive: true });
    if(this.props.onMouseDown) this.props.onMouseDown(event)
  }

  onMouseUp(event){
    this.setState({ isActive: false });
    if(this.props.onMouseUp) this.props.onMouseUp(event)
  }

  onFocus(event){
    this.setState({ isFocus: true });
    if(this.props.onFocus) this.props.onFocus(event)
  }

  onBlur(event){
    this.setState({ isFocus: false });
    if(this.props.onBlur) this.props.onBlur(event)
  }

  renderChildren(){
    var a = this.props, children = a.children
    if(a.iconLeft)
      return (
        <div className="boardpanel spacebetween maxWidth centerpanel">
          {a.iconLeft}
          <label>{children}</label>
        </div>
      )
    else if(a.iconRight)
      return (
        <div className="boardpanel spacebetween maxWidth centerpanel">
          <label style={{marginRight: 5}}>{children}</label>
          {a.iconRight}
        </div>
      )
    else if(a.iconTop)
      return (
        <div className="page centerpanel spacebetween maxHeight">
          <div style={{marginTop: 5}}>
            {a.iconTop}
          </div>
          <label>{children}</label>
        </div>
      )
    return children
  }

  render(){
    //Todo isDisabled isLoading iconAfter iconBefore
    var a = this.props, height = a.height, id = a.id, isLoading = a.isLoading, tooltip = a.tooltip, isDisabled = a.isDisabled, tooltipPosition = a.tooltipPosition, icon = a.icon
    var style = {}, outerStyle = {}
    if(a.style) style = a.style
    if(a.outerStyle) outerStyle = a.outerStyle
    if(height) style.height = height
    var adj = 0

    if(this.textRef && isLoading){
      adj = this.textRef.clientWidth
    }

    var tpPosition = "Bottom"
    if(tooltipPosition){
      tpPosition = tooltipPosition
    }

    const newType = this.isAnsaradaButton()

    const renderButton = () => {
      if(newType)
        return (
          <AnsaradaButton
            id={id}
            variant={this.type()}
            className={this.css()}
            style={style}
            onMouseEnter={this.onMouseEnter.bind(this)}
            onMouseLeave={this.onMouseLeave.bind(this)}
            onMouseDown={this.onMouseDown.bind(this)}
            onMouseUp={this.onMouseUp.bind(this)}
            onFocus={this.onFocus.bind(this)}
            onBlur={this.onBlur.bind(this)}
            onClick={this.onClick.bind(this)}

            icon={icon}

            loading={isLoading}
            disabled={isDisabled===true?true:false}
            >
              {this.renderChildren()}
          </AnsaradaButton>
        )
      return (
        <button
          id={id}
          className={this.css()}
          style={style}
          onMouseEnter={this.onMouseEnter.bind(this)}
          onMouseLeave={this.onMouseLeave.bind(this)}
          onMouseDown={this.onMouseDown.bind(this)}
          onMouseUp={this.onMouseUp.bind(this)}
          onFocus={this.onFocus.bind(this)}
          onBlur={this.onBlur.bind(this)}
          onClick={this.onClick.bind(this)}
          >
            {this.renderChildren()}
        </button>
      )
    }

    return (
      <div style={outerStyle}>
        {tooltip !== undefined ?
          <Tooltip
            position={tpPosition}
            text={tooltip}
          >
            {renderButton()}
          </Tooltip>
          :
          <div style={style}>
            {renderButton()}
          </div>
        }
      </div>
    )
  }
}
