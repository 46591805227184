import { authHeader, GetURL, handleJsonRes, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch, viewAs } from '@lib/';

export const statusService = {
  getStatus,
  setStatus,
  uploadUsageLogs,
  uploadUsageLog
}

function getStatus(){
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch(GetURL() + 'StatusSync'+viewAs('?'), requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function setStatus(items){
  const requestOptions = {
    method: 'PATCH',
    headers: authHeader(),
    body: JSON.stringify(items)
  };
  return fetch(GetURL() + 'StatusSync', requestOptions).then(handleStandResponse).catch(handleCatch);
}

function uploadUsageLogs(items){
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(items)
  };
  return fetch(GetURL() + 'Usage/Populated', requestOptions).then(handleStandResponse).catch(handleCatch);
}

function uploadUsageLog(item){
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(item)
  };
  return fetch(GetURL() + 'Usage', requestOptions).then(handleStandResponse).catch(handleCatch);
}

