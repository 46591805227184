import fetch from '../lib/fetch-retry';
import { viewAs, authHeader, GetURL, LogoutAndRedirect, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch, handleCodeCatch, CodeError } from '@lib';

export const baseMarketingService = {
    GetMarketingMessages,
}

function GetMarketingMessages() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(GetURL() + 'Marketing', requestOptions)
        .then((response) => {
            if (response && response.ok) {
                return response.json();
            }
            return [];
        })
        .catch((e) => {
            return e;
        });
}