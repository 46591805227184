import React, { useState, useEffect } from 'react';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { ValidateEmail, roleOptions, meetingFrequencyOptions, primaryNeedOptions } from '@lib/simpletools';
import { TextField, Stack, Chip, FormControlLabel } from "@mui/material";
import { MuiButton, MuiSelect, CustomDateTimePicker, VideoWithTracking, MuiCheckbox } from '@common/MUI';
import moment from "moment";
import './freemium.css';

import demoVid from '../../../public/video/Ansarada Board Admin Portal demoSM.mp4';

import ios from '../../assets/images/download/app-store-button.svg';
import mac from '../../assets/images/download/mac-store-button.svg';
import microsoft from '../../assets/images/download/microsoft-store-button.jpg';
import { DatePicker } from '@mui/lab';
import { userActions } from '../../actions/admin';
import { userService } from '../../services/admin/user.service';


export const FreemiumUserDetails = (props) => {
    const checkValid = () => {
        return props.role !== ""
    }

    return (
        <div>
            <h1>Tell us what you do</h1>
            <div className='bottom10'>By telling us what you do, we can cater to your specific needs</div>
            {props.error !== undefined && props.error !== "" && (
                <div className="login-error-msgapp-freemium input-container">
                    <span className="animated fadeInUp">
                        <span>{props.error}</span>
                    </span>
                </div>
            )}
            <div className='top35 input-container'>
                <div>
                    <MuiSelect
                        label="What do you do? *"
                        options={roleOptions()}
                        onChange={value => props.setRole(value)}
                        value={props.role}
                        fullWidth
                        disabled={props.loading}
                    />
                </div>
            </div>
            <div className='top35'>
                <Stack direction='row' spacing={2}>
                    <MuiButton
                        variant='outlined'
                        disabled={props.loading}
                        onClick={() => props.handleBack()}
                    >
                        Back
                    </MuiButton>
                    <MuiButton
                        variant='contained'
                        disabled={!checkValid()}
                        loading={props.loading}
                        onClick={() => {
                            if (checkValid()) props.handleSubmit()
                        }}
                    >
                        Next
                    </MuiButton>
                </Stack>
            </div>
        </div>
    )
}

export const FreemiumSayHelloToBoard = (props) => {
    try {
        if (_wq) {
            _wq.push({
                id: "btsl1t9cpc",
                options: {
                    time: "9m15s",
                }
            });
        }
    } catch (e) { }

    return (
        <div>
            <h1>Say hello to Ansarada Board</h1>
            <div className='app-video-freemium-flow'>
                {/* <VideoWithTracking src={demoVid} /> */}
                <span className="wistia_embed wistia_async_btsl1t9cpc popover=true popoverAnimateThumbnail=true"
                    style={{ display: 'inline-block', height: '100%', position: 'relative', width: '100%' }}>&nbsp;
                </span>
            </div>
            <div className='app-try-it-yourself'>
                <h1>Try it yourself</h1>
                <div>Download the Director application to your device and enter demo as your username.</div>
            </div>
            <div className="appRow">
                <a className="appPanel" href="https://itunes.apple.com/au/app/athena-board/id1439126398?ls=1&mt=8" target="_blank">
                    <img src={ios} className="iosLogo" />
                </a>
                <a className="appPanel" href="https://apps.apple.com/au/app/id1482354553" target="_blank">
                    <img src={mac} className="macLogo" />
                </a>
                <a className="appPanel" href="https://www.microsoft.com/store/apps/9PDVCTH74VTJ" target="_blank">
                    <img src={microsoft} className="winLogo" />
                </a>
            </div>
        </div>
    )
}

export const FreemiumWhatsNext = (props) => {
    const [adminEmail, setAdminEmail] = useState("");
    const [checkingEmail, setChekingEmail] = useState("");
    const [emailError, setEmailError] = useState(null);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isCosecInviteSent, setIsCosecInviteSent] = useState({
        sending: false,
        error: false,
        success: false,
    });
    const [isDemoRequestSent, setIsDemoRequestSent] = useState({
        sending: false,
        success: false,
    });
    const { executeRecaptcha } = useGoogleReCaptcha();

    const emailBlur = () => {
        var validEmail = ValidateEmail(adminEmail);
        setIsEmailValid(validEmail);
        if (!validEmail) {
            !setEmailError("Invalid Email address");
        }
    }

    const handleEmailChange = (e) => {
        const value = e.target.value.split('').filter(char => /[a-zA-Z0-9@+'._~-]/.test(char)).join('')
        setAdminEmail(value);
        setEmailError(null);
        setIsEmailValid(false);
        setIsCosecInviteSent({
            error: false,
            success: false,
            sending: false
        });
    }

    return (
        <div>
            <h1>What's next?</h1>
            <div className='top35'>
                <h2 className='freemium-whats-next-h2'>Board administrator email</h2>
                <div className='top25'>Please introduce us to your company’s board administrator by entering their company email address below. We will send them an invitation to setup an Ansarada Board account for your company.</div>
                <div className='top25'>
                    <TextField
                        label="Email"
                        name='email'
                        onChange={handleEmailChange}
                        onBlur={emailBlur}
                        fullWidth
                        error={Boolean(emailError)}
                        helperText={emailError}
                        value={adminEmail}
                        disabled={checkingEmail || isCosecInviteSent.sending}
                        inputProps={{ autoComplete: 'email' }}
                    />
                </div>
                <div className='freemium-invite-sent-error' hidden={isCosecInviteSent.error == false}>{isCosecInviteSent.error}</div>
                {isCosecInviteSent.success
                    ? <div className='freemium-invite-sent-success'>Invite sent!</div>
                    : <div>
                        <MuiButton
                            sx={{ marginTop: '25px' }}
                            variant='contained'
                            disabled={!isEmailValid || isCosecInviteSent.success}
                            loading={checkingEmail || isCosecInviteSent.sending}
                            onClick={() => {
                                setIsCosecInviteSent({ error: false, success: false, sending: true });
                                executeRecaptcha("emailcheck").then(token => {
                                    props.dispatch(userActions.inviteCosecViaDirector({
                                        inviteeEmail: adminEmail,
                                        sentByEmail: props.sentByEmail,
                                        googleRecaptchaResponse: token
                                    }))
                                        .then((response) => {
                                            setIsCosecInviteSent({ error: false, success: true, sending: false });
                                        })
                                        .catch((error) => {
                                            if (error && error.code) {
                                                if (error.code == 116) {
                                                    setIsCosecInviteSent({ error: 'This email is already registered', success: false, sending: false });
                                                } else if (error.code == 234) {
                                                    setIsCosecInviteSent({ error: 'Please use a company email', success: false, sending: false });
                                                }
                                                else {
                                                    setIsCosecInviteSent({ error: "Error sending invite", success: false, sending: false });
                                                }
                                            } else {
                                                setIsCosecInviteSent({ error: error ? error : "Invalid Email", success: false, sending: false });
                                            }
                                        })
                                }).catch(err => setIsCosecInviteSent({ error: "Captcha error", success: false, sending: false }))
                            }}
                        >
                            Send Invite
                        </MuiButton>
                    </div>
                }
            </div>
            <div className='top45'>
                <h2 className='freemium-whats-next-h2'>Request a demo</h2>
                <div className='top25'>Ansarada sales will reach out to your team and help you through this process.</div>
                {isDemoRequestSent.success
                    ? <div className='freemium-invite-sent-success'>Demo requested!</div>
                    : <MuiButton
                        sx={{ marginTop: '25px' }}
                        variant='contained'
                        loading={isDemoRequestSent.sending}
                        onClick={() => {
                            setIsDemoRequestSent({ sending: true, success: false });
                            let country = '';
                            try {
                                country = Intl.DateTimeFormat().resolvedOptions().timeZone;
                            } catch { }

                            userService.freemiumRequestDemo({
                                email: props.sentByEmail,
                                role: 'Director',
                                country
                            }).then((response) => {
                                setIsDemoRequestSent({ sending: false, success: true });
                            }).catch((error) => {
                                setIsDemoRequestSent({ sending: false, success: false });
                            });
                        }}
                    >
                        Send Request
                    </MuiButton>
                }
            </div>
            <div className='top45'>
                <h2 className='freemium-whats-next-h2'>Have more questions?</h2>
                <div className='top25'>Our dedicated teams are here to help. <a href="mailto:support@ansarada.com" target="_blank" className='link freemium-contact-us'>Contact us.</a></div>
            </div>
        </div>
    );
}

export const FreemiumCosecDetails = (props) => {
    const [error, setError] = useState("")

    const emailOutFocus = () => {
        if (props.email !== "") {
            let s = false
            if (ValidateEmail(props.email)) {
                s = true
            }
            setError(s ? "" : "Invalid email")
        }
    }

    const checkValid = () => {
        return props.email !== "" && ValidateEmail(props.email) && props.firstName.trim() !== ""
            && props.lastName.trim() !== "" && props.company.trim() !== ""
    }

    const handleEmailChange = (e) => {
        const value = e.target.value.split('').filter(char => /[a-zA-Z0-9@+'._~-]/.test(char)).join('')
        props.setEmail(value)
        setError("")
    }

    return (
        <div>
            <h1>Tell us about your company secretary</h1>
            <div className='bottom10'>
                <div style={{ paddingBottom: '10px' }}>As a director you will use the Ansarada Board applications to consume the information provided by your company secretary / board administrator.</div>
                <div style={{ paddingBottom: '10px' }}>If you could please provide us with the contact details of your <span>company secretary / board administrator</span> and we will contact them to setup an account.</div>
                <div>If you click ‘Next’ we will provide the links for you to download the Ansarada Board applications to evaluate.</div>
            </div>
            {props.error !== undefined && props.error !== "" && (
                <div className="login-error-msgapp-freemium input-container">
                    <span className="animated fadeInUp">
                        <span>{props.error}</span>
                    </span>
                </div>
            )}
            <div className='top35 input-container'>
                <div>
                    <TextField
                        label="Enter your company secretary first name"
                        name='firstName'
                        onChange={e => props.setFirstName(e.target.value)}
                        fullWidth
                        value={props.firstName}
                        disabled={props.loading}
                        inputProps={{ autoComplete: 'new-password' }}
                    />
                </div>
                <div className='top35'>
                    <TextField
                        label="Enter your company secretary last name"
                        name='lastName'
                        onChange={e => props.setLastName(e.target.value)}
                        fullWidth
                        value={props.lastName}
                        disabled={props.loading}
                        inputProps={{ autoComplete: 'new-password' }}
                    />
                </div>
                <div className='top35'>
                    <TextField
                        label="Enter your company secretary email address"
                        name='email'
                        onChange={handleEmailChange}
                        onBlur={emailOutFocus}
                        fullWidth
                        error={error !== ""}
                        helperText={error}
                        value={props.email}
                        disabled={props.loading}
                        inputProps={{ autoComplete: 'new-password' }}
                    />
                </div>
                <div className='top35'>
                    <TextField
                        label="Enter your company name"
                        name='company'
                        onChange={e => props.setCompany(e.target.value)}
                        fullWidth
                        value={props.company}
                        disabled={props.loading}
                        inputProps={{ autoComplete: 'new-password' }}
                    />
                </div>
            </div>
            <div className='top35 input-container'>
                <Stack direction='row' spacing={2}>
                    <MuiButton
                        variant='outlined'
                        disabled={props.loading}
                        onClick={() => props.handleBack()}
                    >
                        Back
                    </MuiButton>
                    <MuiButton
                        variant='contained'
                        loading={props.loading}
                        onClick={() => {
                            if (checkValid()) {
                                props.handleSubmit()
                            } else {
                                props.handleNext();
                            }
                        }}
                    >
                        Next
                    </MuiButton>
                </Stack>
            </div>
        </div>
    )
}

export const AppVideo = (props) => {
    return (
        <div>
            <h1>Introduction to Ansarada Board App</h1>

            <div className='app-video'>
                <video style={{ width: '100%', height: '100%' }} autoPlay={true} controls={false} src={demoVid} />
            </div>

            <div style={{ display: 'flex', marginTop: '50px' }}>
                <MuiButton
                    variant='contained'
                    onClick={() => props.handleNext()}
                >
                    Next
                </MuiButton>
            </div>
        </div>
    )
}

export const AppDownload = (props) => {
    return (
        <div>
            <h1>Download Ansarada Board</h1>
            <div className='top25'>
                <label style={{ marginRight: '5px' }}>Click on the button below that is appropriate for your device.</label>
                <label>Ansarada Board is provided for iOS/iPadOS, macOS and Windows devices.</label>
                <label>Once you have downloaded the correct app to your device, you may enter ‘demo’ as the username to download demonstration content to evaluate Ansarada Board.</label>
            </div>
            <div className="appRow">
                <a className="appPanel" href="https://itunes.apple.com/au/app/athena-board/id1439126398?ls=1&mt=8" target="_blank">
                    <img src={ios} className="iosLogo" />
                </a>
                <a className="appPanel" href="https://apps.apple.com/au/app/id1482354553" target="_blank">
                    <img src={mac} className="macLogo" />
                </a>
                <a className="appPanel" href="https://www.microsoft.com/store/apps/9PDVCTH74VTJ" target="_blank">
                    <img src={microsoft} className="winLogo" />
                </a>
            </div>
            {/* <div className='top35'>
                <MuiButton
                    variant='outlined'
                    onClick={() => props.handleBack()}
                >
                    Back
                </MuiButton>
            </div> */}
        </div>
    )
}

export const FreemiumNextMeeting = (props) => {
    return (
        <div style={{ paddingTop: '30px', maxWidth: '500px', paddingBottom: '20px' }}>
            <h1 style={{ color: '#018436' }}>How frequent are your board meetings?</h1>
            <div className='top25'>
                <MuiSelect
                    label="What is the frequency of your board meetings? *"
                    options={meetingFrequencyOptions()}
                    onChange={value => props.setFrequency(value)}
                    value={props.frequency}
                    fullWidth
                    disabled={props.loading}
                />
            </div>
            <div className='top25'>
                <DatePicker
                    label='When is your next meeting? *'
                    value={props.meetingDate}
                    renderInput={(params) => <TextField {...params} fullWidth={true} />}
                    clearable={true}
                    disabled={props.loading}
                    onChange={props.setMeetingDate}
                    minDate={moment().subtract(1, 'day')}
                    inputFormat="DD/MM/YYYY"
                    PaperProps={{
                        sx: {
                            '.Mui-selected': {
                                background: 'var(--ansarada-green) !important'
                            }
                        }
                    }}
                />
                {/* <CustomDateTimePicker
                    label='When is your next meeting? *'
                    meetingDate={props.meetingDate}
                    tempMeetingDate={tempMeetingDate}
                    error={!isMeetingDateValid()}
                    onMeetingDateChange={onMeetingDateChange}
                    onOpen={() => setDatePickerOpen(true)}
                    readonly={props.loading}
                    meetingDatePickerOpen={datePickerOpen}
                    onClose={() => {
                        setDatePickerOpen(false)
                        setTempMeetingDate(props.meetingDate)
                    }}
                    onCustomMeetingTimeChange={onCustomMeetingTimeChange}
                    tempMeetingTime={tempMeetingTime}
                    onTempMeetingDateChange={onTempMeetingDateChange}
                /> */}
            </div>
        </div>
    );
}

export const FreemiumPrimaryNeed = (props) => {
    let NeedList = primaryNeedOptions()
    NeedList.forEach(o => o.selected = o.value == props.selectedNeed);
    const [needList, setNeedList] = useState(NeedList)

    const handleNeed = (item) => {
        needList.forEach(o => {
            if (item.value === o.value) {
                o.selected = true
            } else {
                o.selected = false
            }
        })
        setNeedList(needList)
        props.setNeed(item.value)
    }

    return (
        <div>
            <h1 style={{ color: '#018436' }}>What is your primary reason for using Board?</h1>
            {props.error !== undefined && props.error !== "" && (
                <div className="login-error-msgapp-freemium input-container">
                    <span className="animated fadeInUp">
                        <span>{props.error}</span>
                    </span>
                </div>
            )}
            <div className='top35'>
                {needList.map((item, index) => {
                    return <Chip
                        key={index}
                        label={item.label}
                        variant='outlined'
                        disabled={props.loading}
                        className={item.selected ? 'chip-need-selected' : 'chip-need'}
                        onClick={() => handleNeed(item)}
                    />
                })}
            </div>
        </div>
    )
}
export const GetCustomerLogo = (props) => {
    const [logo, setLogo] = useState("");

    useEffect(() => {
        if (!props.email) { return; }
        try {
            const domain = props.email.substring(props.email.lastIndexOf("@") + 1);
            const url = appConfig.clearbitLogo + domain + "?size=80";
            fetch(url)
                .then(response => response.blob())
                .then(blob => new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.readAsDataURL(blob)
                    reader.onloadend = () => resolve(reader.result)
                    reader.onerror = reject
                }))
                .then(base64String => {
                    setLogo(base64String);
                    props.setLogo(base64String);
                })
                .catch((e) => {
                    log(e, 'logo not found');
                })
        } catch (e) { log(e, 'Error fetching logo') }
    }, [props.email]);

    if (!logo) { return null; }

    return (
        <div style={{ paddingTop: '30px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                <div className='link' onClick={(e) => {}}>
                    <FormControlLabel
                        control={<MuiCheckbox checked={props.useLogo} onChange={(e) => {if (e) { e.stopPropagation(); } props.toggleLogoUse();  }} />}
                        label={'Use logo'}
                        sx={{ margin: 0, paddingTop: '0px' }}
                    />
                </div>
                <img style={{ width: '60px', height: '60px' }} src={logo} />
            </div>
            <div className='text14' style={{ paddingTop: '10px', paddingLeft: '10px' }}>
                <a style={{ color: 'var(--ansarada-green)' }} href="https://clearbit.com" target="_blank">Logo provided by Clearbit</a>
            </div>
        </div>
    )
}