import React from "react";
import { TrackEvent } from "../../../lib/simpletools";

export class VideoWithTracking extends React.Component {
    constructor(props) {
        super(props);

        this.videoRef = React.createRef();
    }

    componentDidMount() {
        if (this.videoRef && this.videoRef.current) {
            this.videoRef.current.onSeeked = (a, b, c, d) => { 
                console.log('seek', a, b, c, d); 
                TrackEvent('b_freemium_video_seek', {

                });
            }
            this.videoRef.current.onseeking = (a, b, c, d) => { 
                console.log('seeking', a, b, c, d); 
                TrackEvent('b_freemium_video_seeking', {

                });
            }
            this.videoRef.current.onpause = (a, b, c, d) => { 
                console.log('pause', a, b, c, d); 
                TrackEvent('b_freemium_video_pause', {

                });
            }
            this.videoRef.current.onplay = (a, b, c, d) => { 
                console.log('play', a, b, c, d); 
                TrackEvent('b_freemium_video_play', {

                });
            }
        }
    }

    render() {
        return (
            <video ref={this.videoRef} style={{ width: '100%', height: '100%' }} autoPlay={true} src={this.props.src} />
        )
    }
}