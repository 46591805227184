import React from 'react';

import { Button } from '@common/appuser';
import iconAnsarada from '@image/logo/Ansarada short.svg';

export const AppDialog = (props) => {
  return (
    <div className="bDialog" onClick={(e)=>{e.stopPropagation();}}>
      <div className="bLogo">
        <img src={iconAnsarada}/>
      </div>
      <label className="bold">{props.title}</label>
      <label>{props.message}</label>
      <div className="bLogo">
        <Button
          type="clear"
          onClick={(e)=>{
            e.stopPropagation();
            props.onAccept()
          }}
          >OK</Button>
      </div>
    </div>
  )
}
