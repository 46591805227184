import { authHeader, GetURL, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch } from '@lib';

import fetch from '@lib/fetch-retry';
import { baseFileService } from '@services/file.base.service';

export const fileService = {
  GetImage,
  Download,
};

function Download(id) {
  return baseFileService.Download(id);
}

function GetImage(imageid) {
  return baseFileService.GetImage(imageid);
}