/* global appConfig */
import React from 'react';
import { connect } from 'react-redux';

import createAuth0Client from '@auth0/auth0-spa-js';

import { AnsaradaLoading } from '@lib/simpletools';

async function Auth0Connect(alias){
  var auth0 = await createAuth0Client({
    domain: appConfig.auth.domain,
    client_id: appConfig.auth.clientId,
    audience: appConfig.auth.audience,
    scope: appConfig.auth.scope,
    redirect_uri: appConfig.auth.redirectUri,
  });
  var l = await auth0.loginWithRedirect({
    login_hint: alias,
    prompt: 'login',
    redirect_uri: appConfig.auth.redirectUri,
  })
}

export const SignInPageHook = (props) => {
  Auth0Connect(props.alias);

  return (
    <div>
      <AnsaradaLoading />
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    alias,
  } = state.authentication;

  return {
    alias,
  };
};

export const SignInPage = connect(
  mapStateToProps
)(SignInPageHook);
