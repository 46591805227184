import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '../../actions/admin';
import { TextInput } from './textinput';
import { Button } from './index';
import { MuiButton } from '@common/MUI';

import {
  FaTimes as ExitIcon
} from 'react-icons/fa';
import {
  MdTextsms as SMSIcon,
  MdEmail as EmailIcon
} from 'react-icons/md';

import IconTick from '../../assets/images/icon/tick.svg';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
//TODO import IconTick from '@image/icon/tick.svg';

export class AuthCode extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authCode: '',
      authAll: false,
      showResend: false,
      resendOpt: false,
      codeSent: false,
      errorMsg: "",
      errorClear: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.authSubmit = this.authSubmit.bind(this);
    this.timerCode = null
  }

  componentDidMount() {
    this.timerCode = setTimeout(() => {
      this.setState({ showResend: true })
    }, 30000)
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.alert.message !== undefined) {
      if (nextProps.alert.hasOwnProperty('code'))
        state.errorMsg = nextProps.alert.message;
      else
        state.errorMsg = 'Please enter correct authentication code';
      return state
    }
    return null
  }

  handleChange(e) {
    const { name, value } = e.target;

    var v = "";
    var letterNumber = /^[0-9a-zA-Z]+$/;
    if (value.match(letterNumber))
      v = value.toUpperCase();

    var authAll = false;
    if (v.length >= 6) authAll = true;

    this.setState({ [name]: v, authAll: authAll, errorClear: true });
  }

  authSubmit() {
    if (this.props.hasOwnProperty('onSubmit')) {
      this.props.onSubmit(this.state.authCode);
    }
  }

  onExitBut() {
    this.props.onExit();
  }

  openOption() {
    this.setState({ resendOpt: true })
  }

  sendBySms() {
    this.props.dispatch(userActions.sendMFA(this.props.mfaId, 1))
    this.setState({ resendOpt: false, showResend: false, codeSent: true })
  }

  sendByEmail() {
    this.props.dispatch(userActions.sendMFA(this.props.mfaId, 3))
    this.setState({ resendOpt: false, showResend: false, codeSent: true })
  }

  render() {
    var name = "your"
    if (this.props.name !== undefined && this.props.name !== null && this.props.name !== "") {
      name = <boldspan>{this.props.name}</boldspan>
    }

    return (
      <Dialog open={true} maxWidth='sm' fullWidth={true}>
        <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px', paddingBottom: '20px' }}>
          Enter verification code
          <IconButton aria-label="close" onClick={this.onExitBut.bind(this)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            {this.props.isFreemium === true
              ? <div className="text14n colorLightGray authmsg">Please enter the code that has been sent to {name} {this.state.codeSent ? "email" : "registered mobile number."}</div>
              : <div className="text14n colorLightGray authmsg">Please enter the code that has been sent to {name} registered mobile number, email, or device.</div>}
            <span className='authcode' style={{ padding: '20px 0' }}>
              <TextInput
                name="authCode"
                stylenormal="f-control"
                styleempty="f-control"
                style={{ fontWeight: '700' }}
                autoComplete="one-time-code"
                onChange={this.handleChange}
                onReturn={this.authSubmit}
                value={this.state.authCode}
                autoFocus={true}
                initial=""
              />
              {this.state.errorMsg !== "" && !this.state.errorClear &&
                <div className="error" style={{ textAlign: 'left' }}>{this.state.errorMsg}</div>
              }
            </span>
          </Stack>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between', paddingLeft: '24px' }}>
          <Stack direction='row' spacing={2} className="centerVFlex">
            {this.state.showResend && this.props.mfaId !== undefined && this.props.mfaId !== "" && !this.state.resendOpt &&
              <span className="size18">Didn’t receive a code?</span>
            }
            {this.state.resendOpt &&
              <div className="boardpanel centerVFlex">
                <span className="size18" style={{ marginRight: 10 }}>Send via</span>
                <div className="boardpanel">
                  {this.props.mfaType !== undefined && this.props.mfaType === 'sms' &&
                    <MuiButton
                      variant='outlined'
                      style={{ marginRight: 10 }}
                      startIcon={<SMSIcon size={16} color="#4680ff" />}
                      onClick={this.sendBySms.bind(this)}>SMS</MuiButton>
                  }
                  <MuiButton
                    variant='outlined'
                    startIcon={<EmailIcon size={16} color="#4680ff" />}
                    onClick={this.sendByEmail.bind(this)}>Email</MuiButton>
                </div>
              </div>
            }
            {this.state.codeSent &&
              <div className="boardpanel">
                <img className='codesent' src={IconTick} />
                <label className="size18 centerVFlex">Code Sent</label>
              </div>
            }
            <div className="boardpanel">
              {this.state.showResend && this.props.mfaId !== undefined && this.props.mfaId !== "" && !this.state.resendOpt && this.props.isFreemium !== true &&
                <MuiButton
                  variant='outlined'
                  style={{ marginRight: 10 }}
                  onClick={this.openOption.bind(this)}>Resend Code</MuiButton>
              }
              {this.state.showResend && this.props.mfaId !== undefined && this.props.mfaId !== "" && this.props.isFreemium === true &&
                <MuiButton
                  variant='outlined'
                  style={{ marginRight: 10 }}
                  onClick={this.sendByEmail.bind(this)}>Resend via email</MuiButton>
              }
            </div>
          </Stack>
          <MuiButton
            variant='contained'
            disabled={this.state.authAll === false}
            onClick={this.authSubmit}>Confirm</MuiButton>
        </DialogActions>
      </Dialog>
    );
  }
}
