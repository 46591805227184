import React, { Component } from 'react';

class AlphabetItem extends Component {
  componentDidMount() {
    this.props.registerPos(this.props.id, this.fix.offsetTop)
  }
  render() {
    const { id, suffix, renderIcon, subClick } = this.props
    return (
      <div
        style={{
          textAlign: 'left',
        }}
        ref={(ref) => { this.fix = ref }}
      >
        <div
          onClick={()=>{
            if(subClick) subClick()
          }}
          style={{
            fontWeight: 'bold'
          }}>
          {`${id}${suffix}`} {renderIcon}
        </div>
        <div
          style={{
            marginTop: 4,
            marginBottom: 8
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
export default AlphabetItem;
