import { authHeader, GetURL, LogoutAndRedirect, viewAs, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch, handleCodeCatch, CodeError } from '@lib';
import fetch from '@lib/fetch-retry';
import * as CrytpoLib from '@lib/cryptojs';
import { baseBinderService } from '@services/binder.base.service';

export const binderService = {
  getBinderContent,
  populateBinderContent,
  populateUserBinderContent,
  getItemRange,
  getVote,
  setVote,
};

function getBinderContent(binderId) {
    return baseBinderService.getBinderContent(binderId, true);
}

function populateBinderContent(binderId, excludeItems = false) {
    return baseBinderService.populateBinderContent(binderId, excludeItems, true);
}

function populateUserBinderContent(binderId, userId) {
    return baseBinderService.populateUserBinderContent(binderId, userId, true);
}

function getItemRange(binderId, startpos, range, option, template = false) {
    return baseBinderService.getItemRange(binderId, startpos, range, option, template, true);
}

function getVote(item){
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    };

    fetch(GetURL() + 'Document/'+item.documentId+viewAs('?'), requestOptions)
      .then(handleJsonResponse)
      .then((data)=>{
        resolve(data.data)
      })
      .catch((error)=>{
        reject(error)
      })
  })
  .then((data)=>{
    return new Promise(async(resolve, reject) => {
      try{
        const kImpersonatorAESKey = await CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, item.kUser, CrytpoLib.base64StringToArrayBuffer(item.key))
        const voteJson = await CrytpoLib.AESDecrypt(kImpersonatorAESKey, CrytpoLib.base64StringToArrayBuffer(data))
        const d = CrytpoLib.arrayBufferToText(voteJson)
        resolve(JSON.parse(d))
      }catch(e){
        reject(e)
      }
    })
  })
  .catch(handleCatch);
}

function setVote(item){
  return new Promise(async(resolve, reject) => {
    try{
      const d = CrytpoLib.textToArrayBuffer(JSON.stringify({
        answer: item.answer,
        id: item.voteId,
      }))
      const kImpersonatorAESKey = await CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, item.kUser, CrytpoLib.base64StringToArrayBuffer(item.key))
      const voteData = await CrytpoLib.AESEncrypt(kImpersonatorAESKey, d)
      resolve(CrytpoLib.arrayBufferToBase64String(voteData))
    }catch(e){
      reject(e)
    }
  })
  .then((voteData)=>{
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          id: item.voteId,
          answer: voteData
        })
      };
      return fetch(GetURL() + 'Results/Vote/'+item.voteId, requestOptions)
      .then(handleStandResponse)
      .then(()=>{
        resolve(voteData)
      })
      .catch((e)=>{
        reject(e)
      });
    })
  })
  .catch(handleCatch);
}