import { authHeader, GetURL, handleJsonResponse, LogoutAndRedirect, handleDataResponse, handleStandResponse, handleCatch } from '@lib';
import * as CrytpoLib from '@lib/cryptojs';
import {MinuteStorage} from '@lib/indexeddb';
import fetch from '@lib/fetch-retry';
import { BinderItemType } from '@constants/common.constants';
import {
  mergeTypedArraysUnsafe,
  BLANK_GUID,
  CheckImpersonationPath,
  cmpWord,
} from '@lib/simpletools';
import {
  CHUNKSIZE,
} from '@lib/limits';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { v4 as uuidv4 } from 'uuid';

export const minutesService = {
  populateMinutes,
  getMinutes,
  //newMinutes,
  updateMinutes,
  deleteMinute,
  publishMinutes,
  saveCachedMinutes,
  getAllCachedMinutes,
  deleteCachedMinute,
  renameCachedMinute,
  generateExport,
  getFeedback,
};

function populateMinutes(item){
  return new Promise(function(resolve, reject) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    fetch(GetURL() + 'Minutes/'+item.minutesId, requestOptions)
    .then(handleJsonResponse)
    .then((data)=>{
      resolve(data);
    })
    .catch(function(error) {
      reject(error);
    });
  })
  .then((data)=>{
    return new Promise(function(resolveDoc, rejectDoc) {
      if(data.documentId === undefined || data.documentId === "" ||
         data.key === undefined || data.key === "" ||
         data.size === undefined || data.size === ""){
        resolveDoc(data);
      }

      var promisearry = [];
      var chunks = Math.floor(data.size / CHUNKSIZE)+1;
      for(var x=0; x<chunks; x++){
        promisearry.push(
          new Promise(function(resolve, reject) {
            var addheader = {
              DocumentChunkSize: CHUNKSIZE,
              'Content-Type': 'application/json',
            }

            const requestOptions = {
                method: 'GET',
                headers: authHeader(addheader),
            };
            fetch(GetURL() + 'Document/'+data.documentId+"/"+x.toString(), requestOptions)
            .then(handleJsonResponse)
            .then((chunkdata)=>{
              resolve({
                x,
                data: chunkdata
              })
            })
            .catch(function(error) {
              reject(error);
            });
          })
        )
      }

      Promise.all(promisearry)
      .then((dataChunks)=>{
        var DataFile = new Uint8Array();
        dataChunks.forEach(function(chunk){
          DataFile = mergeTypedArraysUnsafe(DataFile,CrytpoLib.base64StringToArrayBuffer(chunk.data.data));
        })

        CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, item.kUser, CrytpoLib.base64StringToArrayBuffer(data.key))
        .then(function(decryptedKey) {
          CrytpoLib.AESDecrypt(decryptedKey, DataFile)
          .then(function(decryptedData){
            var jsonStr = CrytpoLib.arrayBufferToText(decryptedData);
            try{
              var json = JSON.parse(jsonStr);
              resolveDoc(Object.assign({}, data, json));
            }catch(error){
              rejectDoc("failed to parse json" + error);
            }
          })
          .catch(function(error) {
            rejectDoc("importaes" + error);
          });
        })
        .catch(function(error) {
          rejectDoc("importPublicKey7" + error);
        });
      })
      .catch((e)=>{
        rejectDoc(e)
      });
    });
  })
  .catch(handleCatch);
}

function getMinutes(minutesId){
  const requestOptions = {
      method: 'GET',
      headers: authHeader(),
  };
  return fetch(GetURL() + 'Minutes/'+minutesId, requestOptions)
  .then(handleJsonResponse)
  .catch(handleCatch);
}

function updateMinutes(minutes){
  return new Promise(function(resolve, reject) {
    //Upload the data we have into a array buffer
    if(minutes.worker || !minutes.documentUpdate){
      if(minutes.worker || (minutes.documentId !== undefined && minutes.key !== undefined && minutes.size !== undefined &&
          minutes.documentId !== "" && minutes.key !== "" && minutes.size !== 0)){
        return resolve();
      }
    }

    var aeskey = CrytpoLib.GenerateRandom(32);
    CrytpoLib.AESEncrypt(aeskey, minutes.uploadData)
    .then(function(encryptedData){
      CrytpoLib.importPublicKey(minutes.kUserGenSec, CrytpoLib.defaultRSAAlgorithmMethod)
      .then(function(iGenKey) {
        CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, iGenKey, aeskey)
        .then(function(KAESEncpytGen) {
          var key = CrytpoLib.arrayBufferToBase64String(KAESEncpytGen);
          resolve({
            document: encryptedData,
            key: CrytpoLib.arrayBufferToBase64String(KAESEncpytGen)
          });
        })
        .catch(function(error) {
          reject("RSAEncrypt " + error);
        })
      })
      .catch(function(error) {
        reject("importPublicKey3 " + error);
      });
    })
    .catch(function(error) {
      reject("AESEncrypt " + error);
    })
  })
  .then(data => {
    return new Promise(function(resolve, reject) {
      //Upload the data
      if(!minutes.documentUpdate){
        if(minutes.documentId !== undefined && minutes.key !== undefined && minutes.size !== undefined &&
            minutes.documentId !== "" && minutes.key !== "" && minutes.size !== 0){
          return resolve({
            documentId: minutes.documentId,
            key: minutes.key,
            size: minutes.size,
          });
        }
      }
      if(minutes.worker){
        return resolve({});
      }

      var documentId = uuidv4();
      var size = data.document.byteLength;

      var hash = CrytpoLib.MD5(data.document);
      var addheader = {
        DocumentChunkSize: size,
        DocumentHash: hash,
        ChunkHash: hash,
        customerId: minutes.customerId,
        'IsLastChunk': 'true',
        'Content-Type': 'application/json',
      }

      var Data = {
        data: CrytpoLib.arrayBufferToBase64String(data.document)
      }

      const requestOptions = {
          method: 'POST',
          headers: authHeader(addheader),
          body: JSON.stringify(Data)
      };
      fetch(GetURL() + 'Document/'+documentId, requestOptions)
      .then((response) => {
        if (!response.ok) {
          if(response.status === 401){
            LogoutAndRedirect();
          }else if(response.status === 400){
            return response.text().then(data => {
              try{
                var j = JSON.parse(data.toLowerCase());
                if(j.hasOwnProperty('code'))
                  if(j.code === 107)
                    Promise.resolve({
                      documentId: documentId,
                      size: size,
                      key: data.key,
                    });
                return Promise.reject(j);
              }catch(err){

              }

              return Promise.reject(response.statusText);
            });
          }
          reject("Fail to upload");
          return;
        }
        if(response.status === 201 || response.status === 200){
          resolve({
            documentId: documentId,
            size: size,
            key: data.key,
          });
        }else reject();
      })
      .catch(function(error) {
        reject(error);
      });
    });
  })
  .then(data => {
    return new Promise(function(resolve, reject) {
      var updateitem = {
        name: minutes.name,
        boardId: minutes.boardId,
        meetingDate: minutes.meetingDate,
        locationName: minutes.locationName,
        itemCount: minutes.itemCount,
        status: minutes.status,
      //TODO  lockedByUserId: '00000000-0000-0000-0000-000000000000',
      //  lockedDate: "1/01/0001 12:00:00 AM",
      }

      if(minutes.id !== "") updateitem.id = minutes.id

      if(minutes.binderId !== undefined && minutes.binderId !== "")
        updateitem.binderId = minutes.binderId;
      //if(minutes.actionIds !== undefined)
      //  updateitem.actionIds = minutes.actionIds;
      //if(minutes.actions !== undefined)
      //  updateitem.actions = minutes.actions;
      if(minutes.drafts !== undefined)
        updateitem.drafts = minutes.drafts;

      if(data.documentId !== undefined && data.key !== undefined && data.size !== undefined){
        updateitem.documentId = data.documentId;
        updateitem.key = data.key;
        updateitem.size = data.size;
      }
      if(minutes.exportedToBinderId !== undefined)
        updateitem.exportedToBinderId = minutes.exportedToBinderId;

      const requestOptions = {
          method: minutes.id===""?'POST':'PATCH',
          headers: authHeader(),
          body: JSON.stringify(updateitem)
      };

      if(minutes.id===""){
        fetch(GetURL() + 'Minutes', requestOptions)
        .then(handleJsonResponse)
        .then((newMinutes)=>{
          resolve(newMinutes.id);
        })
        .catch(function(error) {
          reject(error);
        });
      }else{
        fetch(GetURL() + 'Minutes/'+minutes.id, requestOptions)
        .then(handleStandResponse)
        .then(()=>{
          resolve(minutes.id);
        })
        .catch(function(error) {
          reject(error);
        });
      }
    });
  })
  .then(minutesId => {
    //Post all Actions
    return new Promise(function(resolve, reject) {
      var promisearry = [];
      minutes.actions.forEach(function(action){
        promisearry.push(
          new Promise(function(resolveAction, rejectAction) {
            var userIds = [], userDescriptions = [];
            action.assignee.forEach(function(assignee){
              if(assignee.userId !== "")
                userIds.push(assignee.userId)
              else if(assignee.firstName !== "" && assignee.lastName !== "")
                userDescriptions.push(assignee.firstName+" "+assignee.lastName)
            })

            var updateitem = {
              id: action.id,
              minutesId: minutesId,
              reference: action.itemref,
              position: action.position,
              userIds: userIds,
              userDescriptions: userDescriptions,
              description: action.description,
              //dueDate: ,
            }

            const requestOptions = {
                method: action.initial?'PATCH':'POST',
                headers: authHeader(),
                body: JSON.stringify(updateitem)
            };

            var options = ""
            if(action.initial) options = "/"+action.id

            fetch(GetURL() + 'Minutes/Actions'+options, requestOptions)
            .then(handleStandResponse)
            .then(()=>{
              resolveAction(action);
            })
            .catch(function(error) {
              rejectAction(error);
            });
          })
        )
      })

      Promise.all(promisearry)
      .then((actions)=>{
        resolve({
          actions: actions,
          id: minutesId,
        })
      })
      .catch((e)=>{
        console.log("erro "+e);
        reject(e)
      });
    });
  })
  .catch(handleCatch);
}

function publishMinutes(minutes){
  return new Promise(function(resolve, reject) {
    //Encrypt data
    var Uint8View = new Uint8Array(minutes.publish.pdf)
    //encrypt the file AES
    var aeskey = CrytpoLib.GenerateRandom(32)

    CrytpoLib.AESEncrypt(aeskey, Uint8View)
    .then(function(encryptedData){
      resolve({data: encryptedData, size: encryptedData.byteLength, aeskey: aeskey})
    }).
    catch((error) => {
      console.log('ProcessDocument error',error)
      reject('AES Encryption Failed')
    })
  })
  .then((encrypted)=>{
    return new Promise(function(resolve, reject) {
      //upload Doc
      var documentId = uuidv4()

      var hash = CrytpoLib.MD5(encrypted.data);
      var addheader = {
        DocumentChunkSize: encrypted.size,
        DocumentHash: hash,
        ChunkHash: hash,
        customerId: minutes.customerId,
        'Content-Type': 'application/json',
      }

      var Data = {
        data: CrytpoLib.arrayBufferToBase64String(encrypted.data)
      }

      const requestOptions = {
          method: 'POST',
          headers: authHeader(addheader),
          body: JSON.stringify(Data)
      };
      fetch(GetURL() + 'Document/'+documentId, requestOptions)
      .then((response) => {
        if (!response.ok) {
          if(response.status === 401){
            LogoutAndRedirect();
          }
          reject();
          return;
        }
        if(response.status === 201 || response.status === 200){
          encrypted.documentId = documentId
          resolve(encrypted);
        }else reject();
      })
      .catch(function(error) {
        reject(error);
      });
    })
  })
  .then((data)=>{
    return new Promise(function(resolve, reject) {
      //upload each draft
      var promiseArray = []
      minutes.publish.userDrafts.forEach((userItem)=>{
        var k = minutes.keyList.find(o => o.userId === userItem.userId)
        if(k){
          promiseArray.push(
            new Promise(function(resolveImport, rejectImport) {
              CrytpoLib.importPublicKey(k.key, CrytpoLib.defaultRSAAlgorithmMethod)
              .then(function(userkey) {
                CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, userkey, data.aeskey)
                .then(function(KAESEncpyt) {
                  var j = {
                    userId: userItem.userId,
                    documentId: data.documentId,
                    key: CrytpoLib.arrayBufferToBase64String(KAESEncpyt),
                    size: data.size,
                  }
                  if(minutes.signing)
                    j.mustRespond = true

                  if(k.isAdmin){
                    CrytpoLib.importPublicKey(minutes.kUserGenSec, CrytpoLib.defaultRSAAlgorithmMethod)
                    .then(function(iGenKey) {
                      CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, iGenKey, data.aeskey)
                      .then(function(KAESEncpytGen) {
                        j.resultKey = CrytpoLib.arrayBufferToBase64String(KAESEncpytGen);
                        resolveImport(j)
                      })
                      .catch(function(error) {
                        rejectImport("RSAEncrypt" + error);
                      })
                    })
                    .catch(function(error) {
                      rejectImport("importPublicKey" + error);
                    });
                  }else
                    resolveImport(j)
                })
                .catch(function(error) {
                  rejectImport("RSAEncrypt" + error)
                })
              })
              .catch(function(error) {
                rejectImport("importPublicKey4" + error)
              })
            })
          )
        }
      })

      promiseArray.push(
        new Promise(function(resolveImport, rejectImport) {
          CrytpoLib.importPublicKey(minutes.kUserGenSec, CrytpoLib.defaultRSAAlgorithmMethod)
          .then(function(userkey) {
            CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, userkey, data.aeskey)
            .then(function(KAESEncpyt) {
              resolveImport({
                userId: BLANK_GUID,
                documentId: data.documentId,
                key: CrytpoLib.arrayBufferToBase64String(KAESEncpyt),
                size: data.size,
              })
            })
            .catch(function(error) {
              rejectImport("RSAEncrypt" + error);
            })
          })
          .catch(function(error) {
            rejectImport("importPublicKey4" + error);
          })
        })
      )

      Promise.all(promiseArray)
      .then((userDrafts)=>{
        resolve(userDrafts);
      })
      .catch((e)=>{
        console.log("processItem error",e);
        reject("generating key error"+e);
      })
    })
  })
  .then((userDrafts)=>{
    return new Promise(function(resolve, reject) {

      var updateitem = {
        id: uuidv4(),
        boardId: minutes.boardId,
        objectId: minutes.id,
        objectType: BinderItemType.minutes,
        revisionCount: minutes.revisionCount,
        userDrafts: userDrafts,
      }

      if(minutes.publish.dueDate !== null)
        updateitem.dueDate = minutes.publish.dueDate

      if(minutes.signing){
        updateitem.signOnly = true
      }

      const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(updateitem)
      };

      fetch(GetURL() + 'Drafts', requestOptions)
      .then(handleStandResponse)
      .then(()=>{
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
    })
  })
  .catch(handleCatch);
}

function deleteMinute(minutesId) {
    const requestOptions = {
      method: 'DELETE',
      headers: authHeader(),
    };

    return fetch(GetURL() + 'Minutes/'+minutesId, requestOptions)
    .then((response)=>{
      if (!response.ok) {
        if(response.status === 404){
          return;
        }
        if(response.status === 401){
           LogoutAndRedirect();
        }

        if(response.status === 500){
          return Promise.reject(response.statusText);
        }
        return Promise.reject("Unknown");
      }
    })
    .catch(handleCatch);
}

function saveCachedMinutes(userkey, id, data){
  return new Promise(function(resolve, reject) {
    MinuteStorage.SetMinute(userkey, id, data).then(()=>{
      resolve();
    })
    .catch(function(error) {
      reject();
    });
  })
  .catch(handleCatch);
}

function getAllCachedMinutes(boardId, userId){
  return new Promise(function(resolve, reject) {
    MinuteStorage.QueryMinutes(boardId, userId)
    .then((data)=>{
      resolve(data)
    })
    .catch((e)=>{
      reject()
    })
  })
  .catch(handleCatch);
}

function deleteCachedMinute(id){
  return new Promise(function(resolve, reject) {
    MinuteStorage.DeleteMinute(id)
    .then(()=>{
      resolve();
    })
    .catch(()=>{
      resolve();//if error, dont worry just delink it
    })
  })
  .catch(handleCatch);
}

function renameCachedMinute(from, to){
  return new Promise(function(resolve, reject) {
    MinuteStorage.GetMinuteNoDecrypt(from)
    .then((data)=>{
      data.binderId = to;
      MinuteStorage.SetMinuteNoDecrypt(to, data)
      .then(()=>{
        MinuteStorage.DeleteMinuteOnlyMain(from)
        .then(()=>{
          resolve(true);
        })
        .catch(function(error) {
          resolve(false);
        });
      })
      .catch(function(error) {
        resolve(false);
      });
    })
    .catch(function(error) {
      resolve(false);
    });
  })
  .catch(handleCatch);
}

function getFeedback(dataitem) {
  return new Promise(function(resolve, reject) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    fetch(GetURL() + 'UserKeys/Impersonation/'+dataitem.targetUserId, requestOptions)
    .then(handleJsonResponse)
    .then(data => {
      resolve(data);
    })
    .catch(function(error) {
      reject(error);
    });
  })
  .then(data => {
    console.log("step 1",data, dataitem);
    return new Promise(function(resolve, reject) {
      var listpaths = [];
      var userId = dataitem.targetUserId;
      for(var id of dataitem.myIds){
        var detail = CheckImpersonationPath(data, id, userId, data.length);
        if(detail !== false){
          for(var direction of detail){
            listpaths.push(direction);
          }
        }
      }

      if(listpaths.length === 0) return;

      var lengths = listpaths.map(function(a){return a.length;});
      var index = lengths.indexOf(Math.min.apply(Math, lengths));
      resolve(listpaths[index]);
    });
  })
  .then(data => {
    console.log("step 2",data);

    //function going to decrypt the data
    function asyncFunc(e, last) {
      return new Promise((resolve, reject) => {
        var v_promise = null;
        if(last === undefined){
          v_promise = new Promise((resolve, reject) => {
            resolve(dataitem.kUser);
          });
        }else{
          v_promise = new Promise((resolve, reject) => {
            CrytpoLib.importPrivateKey(last, CrytpoLib.defaultRSAAlgorithmMethod)
            .then(function(privatekey) {
              resolve(privatekey);
            })
            .catch(function(error) {
              reject("importrsakey "+error);
            });
          });
        }

        v_promise
        .then((key) => {
          //kImpersonatorAESKey = RSA(Your kUser, kImpersonatorWrap)
          CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, key, CrytpoLib.base64StringToArrayBuffer(e.kImpersonatorWrap))
          .then(function(kImpersonatorAESKey) {
            //Target KUser = AES(kImpersonatorWrap, Target KUser)
            CrytpoLib.AESDecrypt(kImpersonatorAESKey, CrytpoLib.base64StringToArrayBuffer(e.kUser))
            .then(function(TargetKUser) {
              resolve(CrytpoLib.arrayBufferToText(TargetKUser));
            })
            .catch(function(error) {
              reject("aesdecypt1 "+error);
            })
          })
          .catch(function(error) {
            reject("rsadecypt "+error);
          });
        });

      });
    }

    const arr = data;
    let final = [];

    function workMyCollection(arr) {
      return arr.reduce((promise, pditem) => {
        return promise
          .then((result) => {
            if(result === false) return "failed";
            return asyncFunc(pditem, result)
              .then(result => {final.push(result); return result;} )
              .catch((error) => {
                return false;
              });
          })
          .catch((error)=>{return false;});
      }, Promise.resolve());
    }

    return new Promise((resolve, reject) => {
      workMyCollection(arr)
      .then((finalpem) => {
        resolve({
          kUserGenSec: final[0],
          kUserTarget: final[final.length-1],
        });
        //resolve(finalpem);
      });
    });
  })
  .then(data => {
    console.log("step 3",data);

    return new Promise((resolve, reject) => {
      CrytpoLib.importPrivateKey(data.kUserTarget, CrytpoLib.defaultRSAAlgorithmMethod)
      .then(function(key) {
        resolve({
          newKey: key,
        })
        /*CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, key, CrytpoLib.base64StringToArrayBuffer(item.password))
        .then(function(password) {
          resolve({
            kUserGenSec: data.kUserGenSec,
            password: password,
            kUserTarget: data.kUserTarget,
          });
        })
        .catch(function(error) {
          reject("rsadecypt "+error);
        });*/
      })
      .catch(function(error) {
        reject("importrsakeya "+error);
      });
    });
  })
  .catch(handleCatch);
}

function generateExport(item, currentState){
  return new Promise(function(resolve, reject) {
    var data = {
      companyName: currentState.authentication.companyName,
      customerId: currentState.authentication.customerId,
      abn: "",
      acn: "",
      boardName: "",
      athenaLogo: false,
      myName: currentState.authentication.firstName +" "+ currentState.authentication.lastName,

      name: item.name,
      location: item.locationName,
      meetingDate: null,

      actions: [],
      minutes: [],

      filename: item.name,
    }

    if(currentState.company[data.customerId] !== undefined){
      if(currentState.company[data.customerId].abn !== undefined && currentState.company[data.customerId].abn !== "")
        data.abn = currentState.company[data.customerId].abn
      else if(currentState.company[data.customerId].acn !== undefined && currentState.company[data.customerId].acn !== "")
        data.acn = currentState.company[data.customerId].acn
    }

    if(currentState.board.boards !== undefined && currentState.board.boards[item.boardId] !== undefined){
      data.boardName = currentState.board.boards[item.boardId].name
    }

    const { customerSettings } = currentState.authentication
    if(customerSettings[data.customerId] !== undefined){
      if(customerSettings[data.customerId].companyName !== undefined)
        data.companyName = customerSettings[data.customerId].companyName
      if(customerSettings[data.customerId].abn !== undefined)
        data.abn = customerSettings[data.customerId].abn
      else if(customerSettings[data.customerId].acn !== undefined)
        data.acn = customerSettings[data.customerId].acn
      if(customerSettings[data.customerId].logoImageId !== undefined){
        if(currentState.file[customerSettings[data.customerId].logoImageId] !== undefined && currentState.file[customerSettings[data.customerId].logoImageId] !== "")
          if(currentState.file[customerSettings[data.customerId].logoImageId].data !== null)
            data.logo = "data:image/png;base64,"+currentState.file[customerSettings[data.customerId].logoImageId].data
      }
    }

    try{
      data.meetingDate = moment(item.meetingDate)
      data.filename = item.name +" "+ data.meetingDate.format('LL')
    }catch(e){

    }

    data.listInvitees = item.invitees.map(o => o.name)
    data.listInvitees.sort(function(a, b) {
      return cmpWord(a,b)
    })

    const users = currentState.users.data
    const sortUser = currentState.authentication.displaySettings.userSort

    var apologies = [], attendees = [], actions = [];
    item.attendees.forEach((item)=>{
      var userId = item.userId
      var firstName = "", lastName = ""
      if(users !== undefined)
        if(users[userId] !== undefined)
          if(!users[userId].loading){
            firstName = users[userId].firstName;
            lastName = users[userId].lastName;
          }

      if(item.apologies){
        apologies.push({
          userId: userId,
          firstName: firstName,
          lastName: lastName,
        });
      }else{
        attendees.push({
          userId: userId,
          firstName: firstName,
          lastName: lastName,
        });
      }
    })

    //Sort the list in first name last name order
    if(sortUser){
      apologies.sort(function(a, b) {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
      })
      attendees.sort(function(a, b) {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
      })
    }else{
      apologies.sort(function(a, b) {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.userId,b.userId);
      })
      attendees.sort(function(a, b) {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.userId,b.userId);
      })
    }

    data.attendees = attendees.map(function(o){ if(sortUser) return o.firstName+" "+o.lastName; return o.lastName+", "+o.firstName})
    data.apologies = apologies.map(function(o){ if(sortUser) return o.firstName+" "+o.lastName; return o.lastName+", "+o.firstName})

    item.actions.forEach((item) => {
      var assignee = [];
      item.assignee.forEach((a) => {
        if(sortUser) assignee.push(a.firstName+" "+a.lastName)
        else assignee.push(a.lastName+" "+a.firstName)
      })
      if(sortUser){
        assignee.sort(function(a, b) {
          return cmpWord(a,b);
        })
      }else{
        assignee.sort(function(a, b) {
          return cmpWord(b,a);
        })
      }

      data.actions.push({
        itemref: item.itemref,
        description: item.description,
        assignee
      })
    })

    item.agendaItems.forEach((o) => {
      var obj = {
        id: o.id,
        position: o.position,
        positionString: o.positionString,
        indentCount: o.indentCount,
        name: o.name,
        editorState: EditorState.createWithContent(convertFromRaw(o.editorStateRaw)),
        duration: o.duration,
        userItems: o.userItems,
        canSubmit: o.canSubmit,
      }

      data.minutes.push(obj)
    })

    resolve(data)
  })
  .catch(handleCatch);
}
