import { Checkbox } from '@mui/material';
import React from 'react';

export function MuiCheckbox(props) {
    return <Checkbox
        sx={{
            '&.Mui-checked:not(.Mui-disabled)': {
                color: 'var(--ansarada-green)',
            },
            '& .MuiSvgIcon-root' : {
                background: props.background ? props.background : 'var(--very-light-grey)',
            }
        }}
        {...props}
    />
}