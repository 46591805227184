import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '../../actions/appuser';
import { TextInput } from '../common/textinput';
import { Button } from '../common/index';
import {
  FaTimes as ExitIcon
} from 'react-icons/fa';
import {
  MdTextsms as SMSIcon,
  MdEmail as EmailIcon
} from 'react-icons/md';

import IconTick from '../../assets/images/icon/tick.svg';
import IconRefresh from '@image/icon/refresh.png';

import './loginapp.css';

const TIMEOUT = 30000

export class AuthCode extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authCode: '',
      authAll: false,
      showResend: false,
      resendOpt: false,
      codeSent: false,
      errorMsg: "",
      errorClear: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.authSubmit = this.authSubmit.bind(this);
    this.timerCode = null
  }

  componentDidMount() {
    this.timerCode = setTimeout(() => {
      this.setState({ showResend: true })
    }, TIMEOUT)
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.alert.message !== undefined) {
      if (nextProps.alert.hasOwnProperty('code'))
        state.errorMsg = nextProps.alert.message;
      else
        state.errorMsg = 'Please enter correct authentication code';
      return state
    }
    return null
  }

  handleChange(e) {
    const { name, value } = e.target;

    var v = "";
    var letterNumber = /^[0-9a-zA-Z]+$/;
    if (value.match(letterNumber))
      v = value.toUpperCase();

    var authAll = false;
    if (v.length >= 6) authAll = true;

    this.setState({ [name]: v, authAll: authAll, errorClear: true });
  }

  authSubmit() {
    if (this.props.hasOwnProperty('onSubmit')) {
      this.props.onSubmit(this.state.authCode);
    }
  }

  onExitBut() {
    this.props.onExit();
  }

  openOption() {
    this.setState({ resendOpt: true })
  }

  sendBySms() {
    this.props.dispatch(userActions.sendMFA(this.props.mfaId, 1))
    this.setState({ resendOpt: false, showResend: false, codeSent: true })
  }

  sendByEmail() {
    this.props.dispatch(userActions.sendMFA(this.props.mfaId, 3))
    this.setState({ resendOpt: false, showResend: false, codeSent: true })
  }

  getMfaType(){
    if(this.props.mfaType === "sms") return "SMS"
    if(this.props.mfaType !== undefined && this.props.mfaType !== "") return this.props.mfaType
    if(this.props.sms === true) return "SMS"
    return "Email"
  }

  render() {
    var name = "your"
    if (this.props.name !== undefined && this.props.name !== null && this.props.name !== "") {
      name = <boldspan>{this.props.name}</boldspan>
    }

    return (
      <div className="page authcode-info-bg">
        {/* <div className="auth-panel">
            <div className="boardpanel spacebetween">
              <h1 className="colorWhite">Enter verification code</h1>
              <ExitIcon onClick={this.onExitBut.bind(this)} size={24} className="bpanel-Exit"/>
            </div>
          </div> */}
        <div className="authmargApp">
          <div className="page">
              <div className="centerFlex">
                <div style={{width: 200}}>
                  <TextInput
                    name="authCode"
                    stylenormal="authcodelog-input"
                    styleempty="authcodelog-empty"
                    autoComplete="one-time-code"
                    onChange={this.handleChange}
                    onReturn={this.authSubmit}
                    value={this.state.authCode}
                    initial="Authentication code"
                  />
                </div>
              </div>
            {this.state.errorMsg !== "" && !this.state.errorClear &&
              <div className="error" style={{ textAlign: 'left' }}>{this.state.errorMsg}</div>
            }
          </div>
          <div className="authtext">
            <label>Please enter the authentication code that we've sent to you via {this.getMfaType()}.</label>
            <label>This code is valid for 30 minutes.</label>
          </div>
          <div className="page">
            <div className="boardpanel spacebetween" style={{ marginBottom: 30 }}>
              <div className="boardpanel">
                {this.state.showResend && this.props.mfaId !== undefined && this.props.mfaId !== "" && !this.state.resendOpt &&
                  <img
                    className="authrefresh"
                    src={IconRefresh}
                    onClick={this.sendByEmail.bind(this)}
                    />
                }
              </div>
              <div className="centerVFlex">
                {this.state.resendOpt &&
                  <div className="boardpanel centerVFlex">
                    <div className="boardpanel">
                      {/*this.props.mfaType !== undefined && this.props.mfaType === 'sms' &&
                        <Button
                          type="clear"
                          style={{ marginRight: 10 }}
                          iconLeft={<SMSIcon size={16} color="#4680ff" />}
                          onClick={this.sendBySms.bind(this)}>SMS</Button>
                      */}
                      <img
                        className="authrefresh"
                        src={IconRefresh}
                        onClick={this.sendByEmail.bind(this)}
                        />
                    </div>
                  </div>
                }
                {this.state.showResend && this.props.mfaId !== undefined && this.props.mfaId !== "" && !this.state.resendOpt &&
                  <label className="size18 colorLightGray">If the authentication code fails to arrive, click to resend via email</label>
                }
              </div>
            </div>
            {this.state.codeSent &&
              <div className="centerFlex" style={{ marginBottom: 30 }}>
                <img className='codesent' src={IconTick}/>
                <label className="size18 centerVFlex">Code Sent</label>
              </div>
            }
            <div className="page maxwidth login-button-parent">
              <Button variant="Primary" className="login-button"
                isDisabled={this.state.authAll === false}
                block={true}
                onClick={this.authSubmit}>Confirm</Button>
              <Button type="white" className="login-button"
                block={true}
                onClick={this.onExitBut.bind(this)}>Back</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
