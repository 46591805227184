import React, { useState } from "react";
import { connect } from "react-redux";
import { kvpActions } from '@actions/admin';

import { Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import MuiButton from "./MuiButton";
import demoVid from '../../../../public/video/Ansarada Board Admin Portal demoSM.mp4';
import './styles/PrimaryNeedPopup.css';
import { CustomDateTimePicker } from "./CustomDateTimePicker";
import moment from "moment";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { customerService } from "../../../services/admin/customer.service";

function BookADemoModal(props) {
    const [demoDate, setDemoDate] = useState(null, moment());
    const [demoTime, setDemoTime] = useState(null, "10:00");
    const [isDatePickerOpen, setDatePickerIsOpen] = useState(null);
    const [demoRequested, setDemoRequested] = useState({
        sending: false,
        success: false,
    });

    const setDemoTimeAndDate = (newTime) => {
        setDemoTime(newTime);
        if (newTime && demoDate && moment(demoDate).isValid()) {
            var [hrs, min] = newTime.split(":");
            var tempNewDate = demoDate.hours(hrs).minutes(min);
            setDemoDate(tempNewDate);
        }
    }

    return (
        <Dialog fullWidth={true} maxWidth='sm' open={true}>
            <DialogTitle>{demoRequested.success ? 'Demo requested' : 'When would you like to book a demo?'}</DialogTitle>
            <DialogContent>
                {demoRequested.success
                    ? <div>
                        <div>Thank you for requesting a demo.</div>
                        <div>Our team will reach out to confirm the date and time for a demo.</div>
                    </div>
                    : <div style={{ padding: '20px 0' }}>
                        <div>
                            <CustomDateTimePicker
                                key='freemium-demo-date-and-time'
                                label='Demo date and time'
                                meetingDate={demoDate}
                                tempMeetingDate={demoDate}
                                error={demoDate && !moment(demoDate).isValid()}
                                onMeetingDateChange={setDemoDate}
                                onOpen={() => { setDatePickerIsOpen(true) }}
                                minDate={moment()}
                                meetingDatePickerOpen={isDatePickerOpen}
                                onClose={() => setDatePickerIsOpen(false)}
                                onCustomMeetingTimeChange={setDemoTimeAndDate}
                                tempMeetingTime={demoTime}
                                onTempMeetingDateChange={setDemoDate}
                            />
                        </div>
                    </div>
                }
            </DialogContent>
            <DialogActions>
                {!demoRequested.sending &&
                    <MuiButton
                        variant='outlined'
                        onClick={() => { props.onClose(demoRequested.success); }}
                    >
                        Close
                    </MuiButton>
                }
                {!demoRequested.success &&
                    <MuiButton
                        variant='contained'
                        // disabled={!demoDate || !moment(demoDate).isValid()}
                        loading={demoRequested.sending}
                        onClick={() => {
                            setDemoRequested({ sending: true, success: false });
                            let comments = demoDate ? `Freemium signup requested demo date: ${moment(demoDate).format()}` : 'Freemium signup request for a demo';
                            if (props.primaryNeed) {
                                comments += "<br>\r\n<br>\r\n";
                                comments += `Primary need: ${props.primaryNeed}`;
                            }
                            customerService.sendUpgrade(props.customerId, comments)
                                .then((response) => {
                                    setDemoRequested({ sending: false, success: true });
                                })
                                .catch(() => {
                                    setDemoRequested({ sending: false, success: false });
                                });
                        }}
                    >
                        Request Demo
                    </MuiButton>
                }
            </DialogActions>
        </Dialog>
    )
}

class PrimaryNeedPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showDemoModal: false,
            demoRequested: false
        };
    }

    showDemoModal = (show = false, demoRequested = false) => {
        this.setState({
            showDemoModal: show,
            demoRequested
        });
    }

    letMeTry = () => {
        this.props.kvpActions.togglePrimaryNeedPopup(false); 
    }

    render() {
        if (!this.props.freemiumPrimaryNeed || !this.props.freemiumPrimaryNeed.show || this.props.freemiumPrimaryNeed.dismissed) { return null; }

        return (
            <Dialog
                fullWidth={true}
                maxWidth='md'
                open={true}
                PaperProps={{
                    sx: {
                        '.MuiDialogContent-root': {
                            padding: 0
                        }
                    }
                }}
            >
                <DialogContent>
                    <div className='primaryNeedPopup-video-section' style={{ height: '400px', maxHeight: '55vh' }}>
                        <span className="wistia_embed wistia_async_btsl1t9cpc popover=true popoverAnimateThumbnail=true" 
                            style={{display:'inline-block', height:'100%', position:'relative', width:'100%'}}>&nbsp;
                        </span>
                    </div>
                    <div className="primaryNeedPopup-text-section">
                        {/* <div>{this.props.freemiumPrimaryNeed.primaryNeed}</div> */}
                        <h2>Present a simplified experience to Board directors</h2>
                        <div style={{ textAlign: 'center', fontSize: '16px' }}>
                            The video above is an overview of the board administration system.<br />
                            We have pre-populated your demonstration environment with sample board data so you may learn about the product, it’s features and the options available to you.<br />
                            You can also create a board, add binders and users so you can publish your board materials.
                        </div>
                        <div style={{ paddingTop: '30px' }}>
                            <Stack spacing={6} direction='row'>
                                <MuiButton
                                    variant='contained'
                                    onClick={() => { this.letMeTry(); }}
                                    sx={{ width: '150px' }}
                                >
                                    Let me try
                                </MuiButton>
                                {this.state.demoRequested
                                    ? <span className="freemium-book-a-demo">Demo Requested <CheckCircleOutlineIcon color="success" /></span>
                                    : <MuiButton
                                        variant='outlined'
                                        onClick={() => { this.showDemoModal(true) }}
                                        sx={{ width: '150px' }}
                                    >
                                        Book a demo
                                    </MuiButton>
                                }
                            </Stack>
                        </div>
                    </div>
                    {this.state.showDemoModal && <BookADemoModal onClose={(demoRequested) => { this.showDemoModal(false, demoRequested) }} customerId={this.props.customerId} primaryNeed={this.props.freemiumPrimaryNeed.primaryNeed} />}
                </DialogContent>
            </Dialog>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        kvpActions: {
            DeleteKVP: (key) => dispatch(kvpActions.delete_kvp(key)),
            togglePrimaryNeedPopup: (show) => dispatch(kvpActions.togglePrimaryNeedPopup(show))
        },
    }
}

function mapStateToProps(state) {
    return {
        freemiumPrimaryNeed: state.keyValuePair && state.keyValuePair['freemium_primary_need'] ? state.keyValuePair['freemium_primary_need'] : null,
    };
}

const connectedPrimaryNeedPopup = connect(mapStateToProps, mapDispatchToProps)(PrimaryNeedPopup);
export { connectedPrimaryNeedPopup as PrimaryNeedPopup };