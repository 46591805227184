/* global appConfig */
import React from 'react';
import { connect } from 'react-redux';

import ansaradaLogo from '@image/logo/Ansarada Logo Inline Black RGB.svg';
import '../loginpage/loginapp.css';
import { UserTypeEnum, RoutesConstants } from '@constants/common.constants';

import { LoadingIndicator } from '@ansarada/ace-react';
import {keysStorage} from '@lib/indexeddb';

import logoBoard from '../../assets/images/logo/Ansarada Board Product Logo Black RGB.svg';


class DirectTrialClass extends React.Component {
  constructor(props) {
    super(props);

  }

  componentDidMount() {
    //log("login direct", window.location.search)
    if(window.location.search !== ""){
      //log("redirecting to",'/admin.html'+window.location.search+"&redirect=directtrial")

      const urlParams = new URLSearchParams(window.location.search);
      //log(urlParams)
      setTimeout(()=>{
        //log("D")
        keysStorage.Put({id: 'lastUser', key: {
          platformAccountId: urlParams.get('platformAccountId'),
          profileId: urlParams.get('profileId'),
          apiToken: urlParams.get('apiToken'),
          parameters: window.location.search,
          userType: UserTypeEnum.Publish,
          hasDevice: true,
          canRegister: true,
          type: 'directtrail'
        }}).then(()=>{
          //log("A")
          setTimeout(()=>{
            //log("R")
            window.location = '/admin.html'+window.location.search+"&redirect=directtrial"
          },1000)
        });
      },1000)
    }else{
      //log("redirecting to main login")
      window.location = RoutesConstants.login
    }
  }

  render(){
    return (
      <div className="page spacebetween content logContent shadeWhite">
        <div className="logboxContent page">
          <div className="loginbox">
            <div className="login-lock-header">
              <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img style={{ width: '50%', marginTop: '20px' }} src={ansaradaLogo} />
                <img style={{ width: '25%', marginTop: '30px', marginBottom: '50px' }} src={logoBoard} />
              </span>
              <h1>We are creating your free account</h1>
              <LoadingIndicator size={48} dark={true}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {

  };
}

const connectedDirectPage = connect(mapStateToProps)(DirectTrialClass);
export { connectedDirectPage as DirectTrailLogin };
