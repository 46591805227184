export const fileConstants = {
  DOWNLOAD_IMAGE_REQUEST: 'IMAGE_DOWNLOAD_REQUEST',
  DOWNLOAD_IMAGE_SUCCESS: 'IMAGE_DOWNLOAD_SUCCESS',
  DOWNLOAD_IMAGE_FAILURE: 'IMAGE_DOWNLOAD_FAILURE',

  DOWNLOAD_REQUEST: 'FILE_DOWNLOAD_REQUEST',
  DOWNLOAD_SUCCESS: 'FILE_DOWNLOAD_SUCCESS',
  DOWNLOAD_FAILURE: 'FILE_DOWNLOAD_FAILURE',

  COMPLETE_DOWNLOAD_FILE_REQUEST: 'COMPLETE_DOWNLOAD_FILE_REQUEST',
};
