import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { LoadingIndicator } from '@ansarada/ace-react';
import { ProgressBar } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

class LabelFade extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: this.props.children,
      css: "fadeInText",
    }

    this.clearFadeIn = this.clearFadeIn.bind(this);
    this.timeFadeIn = null;
  }

  componentDidMount() {
    clearTimeout(this.timeFadeIn);
    this.timeFadeIn = setTimeout(this.clearFadeIn, 2000);
  }

  componentWillUnmount() {
    clearTimeout(this.timeFadeIn);
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(state.text !== nextProps.children){;
      return {
        text: nextProps.children,
        css: "fadeInText",
      }
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevState.text !== this.state.text){
      clearTimeout(this.timeFadeIn);
      this.timeFadeIn = setTimeout(this.clearFadeIn, 2000)
    }
  }

  clearFadeIn(){
    this.setState({css: ""})
  }

  render(){
    return(
      <label className={`${this.state.css}`}>{this.state.text}</label>
    )
  }
}

class LoadingIconDialog extends React.Component {
  constructor(props) {
    super(props);

    var list = [
      "Please wait..."
    ];
    var show = false;
    if(this.props.message !== undefined){
      list.unshift(this.props.message);
      show = true;
    }

    if(this.props.messageList !== undefined){
      list = this.props.messageList.concat(list);
      show = true;
    }

    this.initialTimer = 30000;
    this.cycleTimer = 5000;
    if(list.length > 2){
      this.cycleTimer = Math.floor(this.initialTimer / list.length);
    }

    this.state = {
      pos: 0,
      list: list,
      show: show,
    }

    this.populateDelayList = this.populateDelayList.bind(this);
    this.rotateMessage = this.rotateMessage.bind(this);

    this.timerSwap = null;
    this.timerDelay = null;
  }

  componentDidMount() {
    clearInterval(this.timerSwap);
    clearTimeout(this.timerDelay);
    this.timerDelay = setTimeout(this.populateDelayList, this.initialTimer);
    this.timerSwap = setInterval(this.rotateMessage, this.cycleTimer);
  }

  componentWillUnmount() {
    clearInterval(this.timerSwap);
    clearTimeout(this.timerDelay);
    clearTimeout(this.timerPercentage);
  }

  populateDelayList(){
    clearInterval(this.timerSwap);
    clearTimeout(this.timerDelay);
    var list = this.state.list;
    if(this.props.largeData === true){
      list.unshift("Retrieving large amounts of data...");
    }else{
      list.unshift("Taking a little longer then expected...");
    }

    this.setState({list: list, pos: 0})
    this.timerDelay = setTimeout(() => {
      clearInterval(this.timerSwap);
      clearTimeout(this.timerDelay);
      this.timerSwap = setInterval(this.rotateMessage, this.cycleTimer);
    }, 10000);
  }

  rotateMessage(){
    var pos = this.state.pos;
    pos++;
    if(pos >= this.state.list.length)
      pos = 0;

    this.setState({pos: pos});
  }

  getCurrentMsg(){
    var pos = this.state.pos;
    if(pos >= this.state.list.length){
      pos = 0;
    }
    if(this.state.list.length < 1) return "Fetching data...";

    return this.state.list[this.state.pos];
  }

  render(){
    if(this.props.progress !== undefined && this.props.progress !== null || this.props.progressOnly){
      var text = "Loading binder"
      if(this.props.title !== undefined) text = this.props.title
      var now = this.props.progress ? Math.floor(this.props.progress.p) : 100
      return (
        <div className="aBoxTop-overlay">
          <div className="loading-box">
            <div>
              <ProgressBar variant="success" className="loading-per" now={now} label={`${now}%`} />
            </div>
            <h3>{text}</h3>
          </div>
        </div>
      )
    }

    return (
      <div className="loadingIcon">
        {this.state.show &&
          <LabelFade>{this.getCurrentMsg()}</LabelFade>
        }
        {(this.props.progress === undefined || this.props.progress === null) &&
          <div>
            <LoadingIndicator
              size={this.props.size===undefined?48:this.props.size}
              dark={true}
              />
          </div>
        }
      </div>
    )
  }
}

function mapStateToProps(state) {
  const  {progress} = state.alert;

  return {
    progress,
  };
}

const connectedLoadingIcon = connect(mapStateToProps)(LoadingIconDialog);
export { connectedLoadingIcon as LoadingIcon };
