import { LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';

export const DemoPopulateCountDown = (props) => {
    const [countdownSeconds, setCountdownSeconds] = useState(props.seconds || 0);

    useEffect(() => {
        var countdownSeconds = props.seconds;
        const interval = setInterval(() => {
            countdownSeconds -= 1;
            setCountdownSeconds(countdownSeconds)
        }, 1000);

        return () => clearInterval(interval);
    }, [props.seconds]);

    let minutes = Math.floor(countdownSeconds / 60);
    return (
        <div>
            <div style={{ paddingBottom: '10px' }}>This may take some time.</div>
            {
                countdownSeconds > 0 ?
                    <LinearProgress color='success' variant="determinate" value={100 - Math.floor(countdownSeconds * 100 / props.seconds)} />
                    : <LinearProgress color='success' variant='indeterminate' />
            }

            <div style={{ paddingTop: '10px' }} hidden={props.isReset}>
                {minutes > 0 ? <span style={{ marginRight: '10px' }}>{minutes} minute{minutes > 1 ? 's' : ''}</span> : null}
                {countdownSeconds >= 0 ? <span>{countdownSeconds % 60} second{countdownSeconds % 60 > 1 ? 's' : ''} remaining</span> : <span>Process is taking longer than usual...</span>}
            </div>
        </div>
    );
}