import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {SettingStorage} from '@lib/indexeddb';
import { Button } from '@common/admin';
import JQuery from 'jquery'

import '@styles/alert.css';

export class HelperItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      dismiss: false,
      margin: "",
      arrow: "",
    }

    this.dismissExpiryDate = this.dismissExpiryDate.bind(this);
    this.openHelper = this.openHelper.bind(this);
    this.boxref = null;
  }

  componentDidMount() {
    SettingStorage.Get(this.props.prefix+this.props.id)
    .then((data)=>{
      if(!data.key || data.key === undefined){
        this.openHelper();
      }
    }).catch((e)=>{
      this.openHelper();
    })
  }

  openHelper(){
    var highlight = JQuery(this.props.highlight);
    if (highlight && typeof highlight[0] !== 'undefined') {
      highlight.toggleClass('helperZ');
      var rect = highlight[0].getBoundingClientRect();
      if(rect.x > 500 && (rect.y + 300) < document.documentElement.offsetHeight){
        //left align
        this.setState({margin: "0px 0px -200px -450px", arrow: "arrow-right"})
        setTimeout(() => {
          this.setState({margin: "0px 0px -"+this.boxref.clientHeight+"px -450px"})
        },50)
      }else if((rect.x + 500) < document.documentElement.offsetWidth && (rect.y + 300) < document.documentElement.offsetHeight){
        //right align 0px
        this.setState({margin: "0px -"+(rect.width+460)+"px 0px "+(rect.width+10)+"px", arrow: "arrow-left"})
      }else if((rect.y + 300) < document.documentElement.offsetHeight){
        //top align
        setTimeout(() => {
          this.setState({margin: "-"+this.boxref.clientHeight+"px -450px 0px 0px"})
        },50)
        this.setState({margin: "-300px -450px 0px 0px", arrow: "arrow-bottom"})
      }else{
        //bottom align
        this.setState({margin: "60px -450px "+rect.height+"px 0px", arrow: "arrow-top"})
      }
    }

    this.setState({show: true});
  }

  dismissExpiryDate(){
    SettingStorage.Put({
      id: this.props.prefix+this.props.id,
      key: true})
    .then(()=>{}).catch((e)=>{
    });
  }

  onCheckboxChange(event){
    this.setState({dismiss: event.target.checked})
  }

  onClose(){
    if(this.state.dismiss){
      this.dismissExpiryDate();
    }

    var highlight = JQuery(this.props.highlight);
    if (highlight && typeof highlight[0] !== 'undefined') {
      highlight.toggleClass('helperZ');
    }

    this.setState({show: false})
  }

  onSupport(){
    window.open(getSupportURL(), '_blank').focus();
  }

  render(){
    if(!this.state.show) return (<div/>);
    return(
      <div>
        <div
            ref={(r) => {this.boxref = r}}
            className="confirm-helper helperZ"
            style={{margin: this.state.margin}}>
          <div className={this.state.arrow}/>
          <h1>{this.props.title}</h1>
          <p>{this.props.message}</p>
          <div className="boardpanel spacebetween" style={{marginTop:20}}>
            {this.props.id !== undefined &&
              <label className="centerVFlex"><input type="checkbox" onChange={this.onCheckboxChange.bind(this)}/>Do not show again</label>
            }
            <div className="boardpanel">
              {this.props.zendeskId !== undefined &&
                <Button type="clear" style={{marginRight: 20}} onClick={this.onSupport.bind(this)}>Help?</Button>
              }
              <Button onClick={this.onClose.bind(this)}>OK</Button>
            </div>
          </div>
        </div>
        <div className="blackout">
        </div>
      </div>
    )
  }
}
