import { baseBoardService } from '@services/board.base.service';

export const boardService = {
  previewBoard,
  previewBinder,
  populateBoard,
  populateFiles,
  getBoardCalendars,
};

function previewBoard() {
  return baseBoardService.getBoardsPreview('', true);
}

function previewBinder(boardId) {
  return baseBoardService.previewBoardBinders(boardId, true);
}

function populateBoard(boardId) {
  return baseBoardService.getBoard(boardId, true);
}

function populateFiles(boardId) {
  return baseBoardService.getUserFileList(boardId, '', true);
}


function getBoardCalendars(query) {
  return baseBoardService.getBoardCalendars(query);
}
