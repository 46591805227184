import React from 'react';

function getAnglePoint(startAngle, endAngle, radius, x, y) {
	var x1, y1, x2, y2;

	x1 = x + radius * Math.cos(Math.PI * startAngle / 180);
	y1 = y + radius * Math.sin(Math.PI * startAngle / 180);
	x2 = x + radius * Math.cos(Math.PI * endAngle / 180);
	y2 = y + radius * Math.sin(Math.PI * endAngle / 180);

	return { x1, y1, x2, y2 };
}

class Slice extends React.Component {
  constructor(props) {
    super(props);

		var initial = {
			path: '',
			x: 0,
			y: 0,
		}

		if(this.props.noAnimation!==undefined && this.props.noAnimation === false){
			var p = this.props, path = [], a, b, c, self = this, step

			step = p.angle / (37.5 / 2)
			var s = p.angle

			// Get angle points
			a = getAnglePoint(p.startAngle, p.startAngle + s, p.radius, p.radius, p.radius)
			b = getAnglePoint(p.startAngle, p.startAngle + s, p.radius - p.hole, p.radius, p.radius)

			path.push('M' + a.x1 + ',' + a.y1)
			path.push('A'+ p.radius +','+ p.radius +' 0 '+ (s > 180 ? 1 : 0) +',1 '+ a.x2 + ',' + a.y2)
			path.push('L' + b.x2 + ',' + b.y2)
			path.push('A'+ (p.radius- p.hole) +','+ (p.radius- p.hole) +' 0 '+ (s > 180 ? 1 : 0) +',0 '+ b.x1 + ',' + b.y1)

			// Close
			path.push('Z')

			initial.path = path.join(' ')

			if (p.showLabel) {
				c = getAnglePoint(p.startAngle, p.startAngle + (p.angle / 2), (p.radius / 2 + p.trueHole / 2), p.radius, p.radius)

				initialx = c.x2
				initialy = c.y2
			}
		}

		this.state = {
			path: initial.path,
			x: initial.x,
			y: initial.y,
			o: 1,
			h: false,
			animate: this.props.noAnimation!==undefined?this.props.noAnimation:true,
		}
		this.animate()
	}

  componentDidMount() {
		this.animate()
	}

  componentWillReceiveProps(nextProps) {
		this.setState({ path: '' })
		this.animate()
		if(nextProps.angle !== this.angle)
			setTimeout(()=>{ this.draw(0) } , 16)
	}

	animate() {
		this.draw(0)
	}

	draw(s) {
		var p = this.props, path = [], a, b, c, self = this, step

		step = p.angle / (37.5 / 2)

		if (s + step > p.angle || !this.state.animate) {
			s = p.angle;
			this.setState({animate: false})
		}

		// Get angle points
		a = getAnglePoint(p.startAngle, p.startAngle + s, p.radius, p.radius, p.radius)
		b = getAnglePoint(p.startAngle, p.startAngle + s, p.radius - p.hole, p.radius, p.radius)

		path.push('M' + a.x1 + ',' + a.y1)
		path.push('A'+ p.radius +','+ p.radius +' 0 '+ (s > 180 ? 1 : 0) +',1 '+ a.x2 + ',' + a.y2)
		path.push('L' + b.x2 + ',' + b.y2)
		path.push('A'+ (p.radius- p.hole) +','+ (p.radius- p.hole) +' 0 '+ (s > 180 ? 1 : 0) +',0 '+ b.x1 + ',' + b.y1)

		// Close
		path.push('Z')

		this.setState({ path: path.join(' ') })

		if (s < p.angle) {
			setTimeout(function () { self.draw(s + step) } , 16)
		} else if (p.showLabel) {
			c = getAnglePoint(p.startAngle, p.startAngle + (p.angle / 2), (p.radius / 2 + p.trueHole / 2), p.radius, p.radius)

			this.setState({
				x: c.x2,
				y: c.y2
			})
		}
	}

	onMouseOver(){
		this.setState({o: 0.7, h: true})
		if(this.props.onHover) this.props.onHover(this.props.index)
	}

	onMouseLeave(){
		this.setState({o: 1, h: false})
		if(this.props.onHover) this.props.onHover('')
	}

	render() {
		return (
			<g overflow="hidden">
				<path
					onMouseEnter={this.onMouseOver.bind(this)}
          onMouseLeave={this.onMouseLeave.bind(this)}
					d={ this.state.path }
					fill={ this.props.fill }
					fillOpacity={this.state.o}
					stroke={ this.props.stroke }
					strokeWidth={ this.props.strokeWidth ? this.props.strokeWidth : 3 }
					 />
				{ this.props.showLabel && this.props.percentValue > 5 ?
					<text x={ this.state.x } y={ this.state.y } fill="#fff" textAnchor="middle">
						{ this.props.percent ? this.props.percentValue + '%' : this.props.value }
					</text>
				: null }
			</g>
		)
	}
}

export default class Pie extends React.Component {
	render() {
		var labels = this.props.labels,
			hole = this.props.hole,
			radius = this.props.radius,
			diameter = radius * 2,
			self = this,
			sum, startAngle, d = null;
		var m = this.props.data.map(o => o.value)
		sum = m.reduce((carry, current)=>{ return carry + current }, 0);
		startAngle = 0;

		return (
			<svg width={ diameter } height={ diameter } viewBox={ '0 0 ' + diameter + ' ' + diameter } xmlns="http://www.w3.org/2000/svg" version="1.1">
				{ this.props.data.map((item, sliceIndex) => {
					var slice = item.value
					var angle, nextAngle, percent;

					nextAngle = startAngle;
					angle = (slice / sum) * 360;
					percent = (slice / sum) * 100;
					if(angle === 360) angle = 359.9
					startAngle += angle;

					return <Slice
						key={ self.props.key + sliceIndex.toString() }
						value={ slice }
						index={ item.name }
						percent={ self.props.percent }
						percentValue={ percent.toFixed(1) }
						startAngle={ nextAngle }
						angle={ angle }
						radius={ radius }
						hole={ radius - hole }
						trueHole={ hole }
						showLabel= { labels }
						fill={ item.colour }
						stroke={ self.props.stroke }
						strokeWidth={ self.props.strokeWidth }
						onHover={self.props.onHover}
						noAnimation={self.props.noAnimation}
					/>
				}) }
				{this.props.textl1 !== undefined && this.props.textl1 !== "" &&
					<text x="50%" y="50%" fontSize="16">
						<tspan dominantBaseline="middle" textAnchor="middle">{this.props.textl1}</tspan>
					</text>
				}
				{this.props.textl2 !== undefined && this.props.textl2 !== "" &&
					<text x="50%" y="60%" fontSize="16">
						<tspan dominantBaseline="middle" textAnchor="middle">{this.props.textl2}</tspan>
					</text>
				}
			</svg>
		)
	}
}
