export const BINDERITEMLIMIT = 25 //100
export const BINDERITEMFILESIZE = 10 * 1024 * 1024 //100 * 1024 * 1024 //
export const FILESIZELIMIT = 5 * 1024 * 1024
export const INDENTLIMIT = 1 //5 //
export const BINDERLIMIT = 12 //50 //
export const TEMPLATE_LIMIT = 2 //10 //
export const UNPUBLISH_LIMIT = 2 //10 //
export const BOARD_LIMIT = 1 //50
export const COMPANY_LIMIT = 2 //10
export const MINUTELIMIT = 5 //999
export const BINDER_DATASIZE_LIMIT = 100 * 1024 * 1024 //1073741824 //
export const ITEMNAMELIMIT = 128
export const TEXTLIMIT = 256
export const MEMOLIMIT = 3 * 1024
export const ADMINTIMEOUT = 900
export const REFLIMIT = 10
export const MAX_FILES_LIMIT = 20  //default 75
export const EXPIRING_LIMIT = 7
export const CHUNKSIZE = 3 * 1024 * 1024
export const RETRYCOUNT = 3
export const CHUNKRETRYINTERNAL = 2000
export const CHUNKRATELIMIT = 30000
export const XHRTIMEOUT = 60000
export const CARDLOGINCHECK = 15
export const ITEMCHUCKLIMIT = 20
export const BINDEREPXIRE = 60
export const BINDERSIMUPLOAD = 5
export const MULTITASKLIMIT = 10
export const SESSIONREPLACEMENTTIMEOUT = 30000
export const ALPHABETLIMIT = 20
export const BINDERCREATELIMIT = 6
export const USAGESTATUS = 2 //usageFeatureStatus 1=enabled, 2=hidden, 3=locked
export const USAGELOGSTATUS = 2 //usageLogsFeatureStatus
export const BINDEREXPORT = 2 //binderExportFeatureStatus
export const BINDERPREVIOUSSHOW = 2 //binderToPreviousSettingsFeatureStatus
