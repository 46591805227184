import { binderConstants, alertConstants, webConstants } from '@constants/appuser';
import { queueActions } from '@actions/appuser'
import { binderService } from '@services/appuser';
import { checkDemo, CheckInternet } from '@lib/simpletools';
import { history } from '@lib';
import * as CrytpoLib from '@lib/cryptojs';
import { BinderStatus } from '@constants/common.constants';

import {
  BINDERSIMUPLOAD,
  ITEMCHUCKLIMIT,
} from '@lib/limits';

export const binderActions = {
  getBinderContent,
  populateBinderContent,
  popBinderContentShow,
  populateBinder,
  CompletedDocumentDownload,
  DownloadDocument,
  populateDocument,
  updateDocState,
  decryptVote,
  getVote,
  setVote,
}

function getBinderContent(binderId){
  return dispatch => {
    if(checkDemo('getBinderContent'+binderId))return
    dispatch(request(binderId));
    binderService.getBinderContent(binderId)
      .then(
        item => dispatch(success(item)),
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(binderId, error))
        }
      );
  };

  function request(binderId) { return { type: binderConstants.GET_BINDERCONTENT_REQUEST, binderId } }
  function success(item) { return { type: binderConstants.GET_BINDERCONTENT_SUCCESS, item } }
  function failure(binderId, error) { return { type: binderConstants.GET_BINDERCONTENT_FAILURE, binderId, error } }
}

function populateBinderContent(binderId, excludeItems = false){
  return dispatch => {
    if(checkDemo('populateBinderContent'+binderId))return
    dispatch(request(binderId));
    binderService.populateBinderContent(binderId, excludeItems)
      .then(
        item => dispatch(success(item)),
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(binderId, error))
        }
      );
  };

  function request(binderId) { return { type: binderConstants.POPULATE_BINDERCONTENT_REQUEST, binderId } }
  function success(item) { return { type: binderConstants.POPULATE_BINDERCONTENT_SUCCESS, item } }
  function failure(binderId, error) { return { type: binderConstants.POPULATE_BINDERCONTENT_FAILURE, binderId, error } }
}

function downloadbinder(dispatch, getState, id, num = -1){
  var binders = getState().binder
  var binder = null
  var r = [0], count = [0]
  var itemsCount = 0
  if(num !== -1){
    itemsCount = num
  }else{
    if(binders[id] !== undefined){
      binder = binders[id]
      if(binder.itemCount > 0 && !window.demo){
        itemsCount = binder.itemCount
      }
    }
  }
  if(itemsCount !== 0){
    var myNum = itemsCount / ITEMCHUCKLIMIT;
    var fraction = itemsCount - (Math.floor(myNum) * ITEMCHUCKLIMIT);
    myNum = Math.floor(myNum)
    if(myNum >= 1){
      var d = [...Array(myNum)].fill(ITEMCHUCKLIMIT)
      r = r.concat(d)
      count = Array(myNum+1).fill(0)
    }
    if(fraction > 0) r.push(fraction)
    dispatch(loading_init(itemsCount+10));
  }

  function c(count){
    return count.reduce(function (accumulator, item) {
      return accumulator + item
    }, 0)
  }

  function RetriveItem(range, index){
    if(range === 0 && index === 0){
      if(num !== -1){
        return {}
      }
      return RetriveBinder()
    }
    return new Promise((resolve, reject) => {
      var retries = 3;
      var retryDelay = 10000;
      var opt = {'Content-Type': 'application/json'}

      const i = index;//num === -1? index: index+1

      var wrappedFetch = function(n) {
        binderService.getItemRange(id, (i-1) * ITEMCHUCKLIMIT, range, opt, false)
          .then(
            item => {
              count[i+1] = item.length
              dispatch(loading_prog(c(count)))
              resolve(item)
            },
            error => {
              if(error.code !== undefined && error.code === "F206"){
                opt['AsyncToken'] = error.asyncToken
                error.response.json().then((data) => {
                  count[i+1] = data.length
                  dispatch(loading_prog(c(count)))
                })
                retry(retries)
                return
              }
              reject(error)
            }
          );
      }

      function retry(n) {
        setTimeout(function() {
            wrappedFetch(--n);
          }, retryDelay);
      }

      wrappedFetch(retries);
    })
  }

  function RetriveBinder(){
    return new Promise((resolve, reject) => {
      binderService.populateBinderContent(id, binder!==null?true:false)
        .then(
          item => {
            count[0] = 10
            dispatch(loading_prog(c(count)))
            resolve(item)
          },
          error => {
            reject(error)
          }
        );
    })
  }

  var promiseLimit = require('promise-limit')
  var limit = promiseLimit(BINDERSIMUPLOAD)


  var timerPercentage = setTimeout(() => {
    dispatch(loading_done());
  }, 4 * 60 * 1000);

  return Promise.all(r.map((range, index) => {
    return limit(() => RetriveItem(range, index))
  }))
  .then((binderData) => {
    clearTimeout(timerPercentage);
    var b = binderData[0]
    if(binderData.length > 1){
      b.items = []
      for(var i=1; i < binderData.length; i++){
        b.items = b.items.concat(binderData[i])
      }
    }
    dispatch(loading_done());
    return b
  })

  function loading_init(total) { return { type: alertConstants.LOADING_INDICATOR_START, total } }
  function loading_prog(progress) { return { type: alertConstants.LOADING_INDICATOR_PROGRESS, progress } }
  function loading_done() { return { type: alertConstants.LOADING_INDICATOR_SUCCESS } }
}

function popBinderContentShow(id, boardId, redirect){
  return (dispatch, getState) => {
    if(checkDemo('popBinderContentShow'+id+boardId)) return
    dispatch(request(id));
    downloadbinder(dispatch, getState, id)
    .then((binder) => {
      if(binder.binderStatus !== BinderStatus.current) return binder

      return binder
      // return new Promise((resolve, reject) => {
      //   boardService.getBinderCalendars(id)
      //   .then(
      //     list => {
      //       //binder.calendarId = list.id
      //       //dispatch(calendarsuccess(list, id, boardId))
      //       resolve(binder)
      //     },
      //     error => {
      //       resolve(binder)
      //     }
      //   )
      // })
    })
    .then(b => {
      dispatch(success(b));
      if(redirect !== ""){
        history.push({
          pathname: redirect,
          query: {
            boardId: boardId,
            binderId: id,
          }
        });
      }
    })
    .catch(error => {
      error = CheckInternet(error, dispatch)
      dispatch(failure(id, error))
    })
  };

  //function calendarsuccess(payload, binderId, boardId) { return { type: boardConstants.GET_CALENDARBINDER_SUCCESS, payload, binderId, boardId } }

  function request(binderId) { return { type: binderConstants.POPULATE_BINDERCONTENT_REQUEST, binderId } }
  function success(item) { return { type: binderConstants.POPULATE_BINDERCONTENT_SUCCESS, item } }
  function failure(binderId, error) { return { type: binderConstants.POPULATE_BINDERCONTENT_FAILURE, binderId, error } }
}

function populateBinder(id, boardId, redirect){
  return (dispatch, getState) => {
    if(checkDemo('popBinderContentShow'+id+boardId)) return
    dispatch(request(id));

    binderService.populateBinderContent(id, false)
      .then(
        binder => {
          downloadbinder(dispatch, getState, id, binder.itemCount)
          .then((items) => {
            binder.items = items.items
            return binder
          })
          .then(b => {
            dispatch(success(b));
            if(redirect !== ""){
              history.push({
                pathname: redirect,
                query: {
                  boardId: boardId,
                  binderId: id,
                }
              });
            }
          })
          .catch(error => {
            error = CheckInternet(error, dispatch)
            dispatch(failure(id, error))
          })
        },
        error => {
          dispatch(failure(id, error))
        }
      );
  };

  function request(binderId) { return { type: binderConstants.POPULATE_BINDERCONTENT_REQUEST, binderId } }
  function success(item) { return { type: binderConstants.POPULATE_BINDERCONTENT_SUCCESS, item } }
  function failure(binderId, error) { return { type: binderConstants.POPULATE_BINDERCONTENT_FAILURE, binderId, error } }
}

function DownloadDocument(documentitem) {
  return (dispatch, getState) => {
    documentitem.processType = webConstants.DOWNLOAD_DOCUMENT;
    dispatch(queueActions.downloadDocument(documentitem));
  };
}

function populateDocument(documentitem) {
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    const viewAs = getState().authentication.viewAs
    const keys = getState().authentication.keys

    let id = viewAs !== '' ? viewAs : customerId
    if(customerId === undefined || customerId === "") return
    if(keys[id].kUser === undefined || keys[id].kUser === undefined) return

    documentitem.kUser = keys[id].kUser

    documentitem.processType = webConstants.DOWNLOAD_POPULATE;
    dispatch(queueActions.downloadDocument(documentitem));
  };
}

function CompletedDocumentDownload(documentitem){
  return dispatch => {
    dispatch(request(documentitem));
  };

  function request(documentitem) { return { type: binderConstants.COMPLETE_DOWNLOAD_DOCUMENT_REQUEST, documentitem } }
}

function decryptVote(itemId){
  return async(dispatch, getState) => {
    const customerId = getState().authentication.customerId
    const viewAs = getState().authentication.viewAs
    const keys = getState().authentication.keys

    let id = viewAs !== '' ? viewAs : customerId
    if(customerId === undefined || customerId === "") return
    if(keys[id].kUser === undefined || keys[id].kUser === undefined) return

    const kUser = keys[id].kUser
    const item = getState().binderItems[itemId]

    if(item === undefined || item.voteResponse === undefined){
      dispatch(success(itemId, false))
      return
    }

    try{
      const decryptedKey = await CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, kUser, CrytpoLib.base64StringToArrayBuffer(item.key))
      const decryptedData = await CrytpoLib.AESDecrypt(decryptedKey, CrytpoLib.base64StringToArrayBuffer(item.voteResponse))
      var j = JSON.parse(CrytpoLib.arrayBufferToText(decryptedData));
      //if(j.id === item.id)
        dispatch(success(itemId, j.answer))
    }catch(e){
      dispatch(failure(itemId, e))
    }
  }

  function request() { return { type: binderConstants.GET_VOTEANSWER_REQUEST } }
  function success(itemId, answer) { return { type: binderConstants.GET_VOTEANSWER_SUCCESS, itemId, answer } }
  function failure(itemId, error) { return { type: binderConstants.GET_VOTEANSWER_FAILURE, itemId, error } }
}

function getVote(itemId){
  return async(dispatch, getState) => {
    const customerId = getState().authentication.customerId
    const viewAs = getState().authentication.viewAs
    const keys = getState().authentication.keys

    let id = viewAs !== '' ? viewAs : customerId
    if(customerId === undefined || customerId === "") return
    if(keys[id].kUser === undefined || keys[id].kUser === undefined) return

    const kUser = keys[id].kUser
    const item = getState().binderItems[itemId]

    if(item === undefined) return

    dispatch(request())
    binderService.getVote({
      documentId: item.documentId,
      key: item.key,
      kUser,
    })
      .then(
        details => {
          dispatch(success(itemId, details))
        },
        error => {
          dispatch(failure(itemId, error))
        }
      )
  }

  function request() { return { type: binderConstants.GET_VOTE_REQUEST } }
  function success(itemId, details) { return { type: binderConstants.GET_VOTE_SUCCESS, itemId, details } }
  function failure(itemId, error) { return { type: binderConstants.GET_VOTE_FAILURE, itemId, error } }
}

function setVote(itemId, answer){
  return async(dispatch, getState) => {
    const customerId = getState().authentication.customerId
    const viewAs = getState().authentication.viewAs
    const keys = getState().authentication.keys

    let id = viewAs !== '' ? viewAs : customerId
    if(customerId === undefined || customerId === "") return
    if(keys[id].kUser === undefined || keys[id].kUser === undefined) return

    const kUser = keys[id].kUser
    const item = getState().binderItems[itemId]

    if(item.data === null) return

    dispatch(request())
    //answer, id
    binderService.setVote({
      key: item.key,
      answer,
      itemId,
      voteId: item.data.id,
      kUser,
    })
      .then(
        voteResponse => {
          dispatch(success(itemId, answer, voteResponse))
        },
        error => {
          dispatch(failure(itemId, error))
        }
      )
  }

  function request() { return { type: binderConstants.SET_VOTE_REQUEST } }
  function success(itemId, answer, voteResponse) { return { type: binderConstants.SET_VOTE_SUCCESS, itemId, answer, voteResponse } }
  function failure(itemId, error) { return { type: binderConstants.SET_VOTE_FAILURE, itemId, error } }
}

function updateDocState (newDocState) {
  return async(dispatch, getState) => {
    dispatch({
      type: binderConstants.UPDATE_DOC_STATE,
      payload: newDocState
    })
  }
}