export const queueConstants = {
  DOWNLOAD_QUEUE_FILE_REQUEST: 'DOWNLOAD_QUEUE_FILE_REQUEST',

  DOWNLOAD_QUEUE_DOCUMENT_REQUEST: 'DOWNLOAD_QUEUE_DOCUMENT_REQUEST',

  CANCEL_QUEUE_ITEM_REQUEST: 'CANCEL_QUEUE_ITEM_REQUEST',
  CANCEL_QUEUEERROR_ITEM_REQUEST: 'CANCEL_QUEUEERROR_ITEM_REQUEST',

  COMPLETE_QUEUE_ITEM_REQUEST: 'COMPLETE_QUEUE_ITEM_REQUEST',

  PROGRESS_QUEUE_ITEM_REQUEST: 'PROGRESS_QUEUE_ITEM_REQUEST',

  START_QUEUE_ITEM_REQUEST: 'START_QUEUE_ITEM_REQUEST',
  PAUSE_QUEUE_ITEM_REQUEST: 'PAUSE_QUEUE_ITEM_REQUEST',
  RESUME_QUEUE_ITEM_REQUEST: 'RESUME_QUEUE_ITEM_REQUEST',
  RETRY_QUEUE_ITEM_REQUEST: 'RETRY_QUEUE_ITEM_REQUEST',

  ERROR_QUEUE_ITEM_REQUEST: 'ERROR_QUEUE_ITEM_REQUEST',
};
