import { createBrowserHistory } from 'history';
import { isViewAs } from './auth-header';
import { RoutesConstants } from '@constants/common.constants';

export const history = createBrowserHistory();
export let currentLocation = null
export let lastLocation = null

/* global appConfig, Appcues */

export function lastPage(){
  if(lastLocation !== null && lastLocation.pathname !== undefined && lastLocation.pathname !== "")
    return lastLocation.pathname
  return "/"
}

export function lastPageName(){
  if(lastLocation !== null && lastLocation.pathname !== undefined && lastLocation.pathname !== "")
    switch(lastLocation.pathname){
      case RoutesConstants.binderboard: return 'Binders'
      case RoutesConstants.binderdash: return 'Binder Details'
      case '/': return 'Home'
      case RoutesConstants.boards: return 'Boards'
      case RoutesConstants.admins: return 'Admins'
      case RoutesConstants.audit: return 'Audit'
      case RoutesConstants.people: return 'People'
      case RoutesConstants.settingsnew: return 'People'
      case RoutesConstants.minutes: return 'Minutes'
      case RoutesConstants.minuteboard: return 'Minutes'
    }
  return "Go Back"
}

history.listen(location => {
  lastLocation = currentLocation
  currentLocation = location

  try{
    if(appConfig.segment && typeof analytics != 'undefined'){
      const c = currentLocation;//Object.assign({}, currentLocation, { siteType: window.athenaAppID==='AppWeb'?"director":"admin", isViewAs: isViewAs() })
      analytics.page(c)
    }
  }catch(e){}
});
