import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  cumulativeOffset,
} from '@lib/simpletools';

import IconTick from '@image/icon/icon-info.svg';
import AlertIcon from '@image/icon/icon-warning.svg';

export class InfroIcon extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      pos: null,
      alignment: 'device-infoL',
    }

    this.infoRef = null
  }

  onEnterHint(){
    var pos = cumulativeOffset(this.infoRef)
    var alignment = 'device-infoL'
    if(pos.left+300 > window.innerWidth){
      pos.left -= 300
      alignment = 'device-infoR'
    }else{
      pos.left -= 35
    }
    this.setState({pos, alignment})
  }

  onLeaveHint(){
    this.setState({pos: null})
  }

  render(){
    var style = {}
    if(this.props.style !== undefined) style = this.props.style
    return (
      <div ref={(node) => { this.infoRef = node}} style={style}>
        <img className='infoIcon'
          src={IconTick}
          onMouseEnter={this.onEnterHint.bind(this)}
          onMouseLeave={this.onLeaveHint.bind(this)}
        />
        {this.state.pos !== null &&
          <div className={'device-info text14s '+this.state.alignment} style={{left: this.state.pos.left, top: this.state.pos.top, marginTop: 30}}>
            {this.props.hint}
          </div>
        }
      </div>
    )
  }
}
