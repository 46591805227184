import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { userActions } from '@actions/login/';
import { LoadingIndicator } from '@ansarada/ace-react';
import logo from '../../assets/images/logo/ansarada-board-logo-black.svg';

const LogoutPage = (props) => {
  useEffect(()=>{
    props.dispatch(userActions.logout(props.deviceId))
  },[])

  return (
    <div className="content">
      <div className="middlePage">
        <div className="middleColumn">
          <img className='Athena-logo' style={{ width: '450px', marginBottom: '10px', marginRight: '7px' }} src={logo} />
          <h2 className='bottom20'>You are logging out</h2>
          <LoadingIndicator
            size={48}
            dark={true}
          />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { loggedIn, loading, deviceId } = state.authentication;
  return {
      loggedIn,
      loading,
      deviceId
  };
}

const connectedLogoutPage = connect(mapStateToProps)(LogoutPage);
export { connectedLogoutPage as LogoutPage };
