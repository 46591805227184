import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Button } from '@common';
import {
  FaInfoCircle as InfroIcon,
} from 'react-icons/fa';
//import Tour from 'reactour'
import Tour from '@common/tour'
import { getHelp, SettingStorage } from '@lib';
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import AthenaHelp from '@image/logo/athena-logo-blue.svg';

class Flags extends React.Component {
  constructor(props) {
    super(props);

    var key = this.props.username +"-helper"+this.props.name

    this.state = {
      key: key,
      steps: this.props.steps,
      showFlag: false,
      showAvatar: false,
      isTourOpen: false,
      newUser: false,
    }

    this.conditions = this.conditions.bind(this)
    this.check = this.check.bind(this)
  }

  componentDidMount() {
    this.check()
  }

  check(){
    if(this.state.steps){
      SettingStorage.Get(this.state.key)
      .then((data)=>{
        this.conditions(data.key)
      }).catch((e)=>{
        if(e === "pending"){
          setTimeout(this.check, 1000)
          return
        }
        this.setState({newUser: true})
        //we dont have key set
        this.conditions(0.0)
      })
    }
  }

  conditions(current){
    if(this.state.steps){
      if(this.state.steps.some(item => item.version > current)){
        var steps = this.state.steps.filter(o => o.version > current)
        if(steps.length)
          this.setState({showFlag: true, steps})
      }
    }
  }

  disableBody(target){
    disableBodyScroll(target);
  }

  enableBody(target){
    enableBodyScroll(target);
  }

  closeTour(){
    this.setState({isTourOpen: false, showAvatar: false, showFlag: false})
    SettingStorage.Put({id: this.state.key, key: getHelp()}).then(()=>{}).catch((e)=>{
    });
    if(this.props.end) this.props.end();
  }

  onTourStart(){
    this.setState({
      isTourOpen: true,
      showAvatar: false,
      showFlag: false,
    })
    if(this.props.start) this.props.start();
  }

  render(){
    return (
      <div>
        {!this.state.isTourOpen && this.state.showFlag &&
          <div className="centerFlex">
            <div className="flag boardpanel">
              <InfroIcon size={20} style={{marginRight: 20}}/>
              <div className="boardpanel spacebetween maxWidth">
                <label>{this.state.newUser?'Would you like a guided Tour':"See what's new?"}</label>
                <div className="boardpanel">
                  <Button type="high" onClick={this.onTourStart.bind(this)}>Show Me</Button>
                  <Button type="high" style={{marginLeft: 20}} onClick={this.closeTour.bind(this)}>No Thanks</Button>
                </div>
              </div>
            </div>
          </div>
        }
        <Tour
          steps={this.state.steps}
          isOpen={this.state.isTourOpen}
          onRequestClose={this.closeTour.bind(this)}
          onAfterOpen={this.disableBody}
          onBeforeClose={this.enableBody}
          showDots={this.props.showDots}
        />
        {this.state.showAvatar &&
          <div className='helperAvatar toolp' onClick={this.onTourStart.bind(this)}>
            <img className='helperImage' src={AthenaHelp}/>
            <span className="toolptext toolp-right">Click for guided tour on how to create binder</span>
          </div>
        }
      </div>
    )
  }
}


function mapStateToProps(state) {
  const { username } = state.authentication;
  return {
    username,
  };
}

const connectedFlags = connect(mapStateToProps)(Flags);
export { connectedFlags as Flags };
