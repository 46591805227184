import React from 'react';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import { FaRegQuestionCircle as QuestionIcon } from 'react-icons/fa';

const StyledSwitch = styled((props) => <Switch {...props}/>)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#1e8c1a',
        },
      },
      
    },
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      opacity: 1,
      backgroundColor: '#d4d4d7',
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        left: 12,
      },
      '&:after': {
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

const MuiSwitch = (props) => {
    const {name, label, detail, value, readonly, onChange} = props;
    var styles = {
      alignItems: 'center'
    };
    if (props.style) {
      styles = { ...styles, ...props.style };
    }
    return (
        <div className='boardpanel' style={{...styles}}>
            <div>
                <FormControlLabel
                    control={
                        <StyledSwitch checked={value} onClick={() => {
                            if(readonly !== true && onChange){ 
                                onChange(name, !value) 
                            }
                        }} />
                    }
                    label={label}
                />
            </div>
            {detail !== undefined && detail !== "" && (
                <div>
                    <Tooltip title={detail} enterTouchDelay={100} leaveTouchDelay={5000}>
                        <div><QuestionIcon className="link" color="#999999" /></div>
                    </Tooltip>
                </div>
            )}
        </div>
    )
}

export default MuiSwitch;