import { viewAs, authHeader, GetURL, LogoutAndRedirect, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch, handleCodeCatch, CodeError } from '@lib';
import moment from 'moment';

export const baseBoardService = {
  previewBoardBinders,
  getBoardCalendars,
  getBoardsPreview,
  getUserFileList,
  getBoard,
}

function previewBoardBinders(boardId, appUserMode = false) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const url = 'BoardDocuments/Preview/' + boardId;
  const param = appUserMode ? '?includeSettings=true' + viewAs() : '?includeTransactionIds=true';

  return fetch(GetURL() + url + param, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function getBoardCalendars(query) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  var dateStart = null, dateFinish = null;

  var URL = GetURL() + 'Calendar/' + query.calendarId + '?';
  if (query.startDate !== "") {
    var dateStart = moment(new Date(query.startDate));
    dateStart.set({
      hour: 0,
      minute: 0,
      second: 0
    });
    var ds = dateStart.utc().format("YYYY-MM-DD HH:mm:ss");
    URL += "startDate=" + ds;
  }
  if (query.finishDate !== "") {
    var dateFinish = moment(new Date(query.finishDate));
    dateFinish.set({
      hour: 23,
      minute: 59,
      second: 59
    });
    var ds = dateFinish.utc().format("YYYY-MM-DD HH:mm:ss");
    if (query.startDate !== "") {
      URL += "&";
    }
    URL += "endDate=" + ds;
  }
  return fetch(URL, requestOptions)
    .then(handleJsonResponse)
    .then(handleDataResponse)
    .then((data) => {
      var filtered = data.filter(obj => {
        var current = moment(obj.startTime);
        if (dateFinish) {
          if (current > dateFinish) return false;
        }
        if (dateStart) {
          if (current < dateStart) return false;
        }

        return true;
      });
      return filtered;

    })
    .then(handleDataResponse)
    .catch(handleCatch);
}

function getBoardsPreview(customerId, appUserMode = false) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  if (appUserMode) {
    return fetch(GetURL() + 'Boards/preview?includeSettings=true' + viewAs(), requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
  } else {
    return fetch(GetURL() + 'Boards/Preview/Customer/' + customerId, requestOptions).then(handleJsonResponse).catch(handleCatch);
  }
}

function getUserFileList(boardId, userId, appUserMode = false) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  let params = '';
  if (appUserMode) {
    params += viewAs('?');
  } else {
    params += '?viewAsUser=' + userId;
  }

  return fetch(GetURL() + 'BoardDocuments/Populated/Files/' + boardId + params, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function getBoard(boardId, appUserMode = false) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  let params = '';
  if(appUserMode) {
    params += viewAs('?');
  }

  return fetch(GetURL() + 'Boards/' + boardId + params, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}