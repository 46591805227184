import moment from 'moment';
import * as CrytpoLib from '@lib/cryptojs';
import { v4 as uuidv4 } from 'uuid';
import { marketingReducerConstants } from '../reducers/marketing.reducer';
import { baseMarketingService } from '../services/marketing.base.service';

export const baseMarketingActions = {
    getMarketingMessages,
    showMarketing,
};

function showMarketing(show) {
    return (dispatch, getState) => {
        var currentState = getState().marketingMessages.showMarketing;
        dispatch({
            type: marketingReducerConstants.SHOW_MARKETING,
            payload: show || !currentState
        });
    }
}

function getMarketingMessages(id, details, annotations) {
    return (dispatch, getState) => {
        try {
            if (getState().marketingMessages.loading || getState().marketingMessages.marketingMessages.length > 0) {
                return;
            }
        } catch { }
        return new Promise((resolve, reject) => {
            dispatch({ type: marketingReducerConstants.SET_LOADING, payload: true });
            baseMarketingService.GetMarketingMessages()
                .then(
                    response => {
                        dispatch({
                            type: marketingReducerConstants.POPULATE_MESSAGES,
                            payload: response || []
                        });
                        resolve(response || []);
                    },
                    error => {
                        reject();
                    }
                ).catch(() => { })
                .finally(() => {
                    dispatch({ type: marketingReducerConstants.SET_LOADING, payload: false });
                });
        });
    };
}