import React from 'react';

import {
    FaEllipsisV as MoreOptionsIcon,
} from 'react-icons/fa';
import MenuIcon from '@mui/icons-material/Menu';

import { ButtonWithDropdownMenu } from './ButtonWithDropdownMenu';

export class OptionsMenu extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.isHidden) {
            return <div style={{ height: '40px', width: '40px' }}></div>   
        }
        return (
            <ButtonWithDropdownMenu {...this.props} icon={<MenuIcon fontSize="20px" />} />
        );
    }
}