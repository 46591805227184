/* global appConfig */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import ansaradaLogo from '@image/logo/Ansarada Logo Inline Black RGB.svg';
import '../loginpage/loginapp.css';

import { userActions } from '@actions/admin';

import { DeviceFingerprint, convertCountryToCode } from '@lib/simpletools';
import {keysStorage} from '@lib/indexeddb';
import { Button } from '@common/admin';
import { LoadingIndicator, TextInput as AnsaradaTextInput, Autocomplete } from '@ansarada/ace-react';

import logoBoard from '../../assets/images/logo/Ansarada Board Product Logo Black RGB.svg';
import { Checkbox, FormControlLabel } from '@mui/material';
import { MuiCheckbox } from '../common/MUI/MuiCheckbox';

class DirectTrialClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      signupData: {},
      fieldList: [],
      hasFieldMissing: false,
      searchCity: '',
      citiesList: [],
      loadingSearch: false,
      city: '',
      country: '',
      completed: false,
      tcChecked: false,
    }

    this.list = []
    this.initialTimeout = null;
    this.searchTimer = null;

    this.filterList = this.filterList.bind(this)
  }

  componentDidMount() {
    log("admin direct",window.location.search)
    this.loadNames();
    const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.get('platformAccountId') !== null && urlParams.get('profileId') !== null) {
      DeviceFingerprint('login')
      .then((hash) => {
        const signupData = {
          platformAccountId: urlParams.get('platformAccountId'),
          userProfileId: urlParams.get('profileId'),
          apiToken: urlParams.get('apiToken'),
          deviceHash: hash,
        }
        this.setState({ signupData })
        this.props.dispatch(userActions.directSignup(signupData))
        //this.setState({deviceHash: hash});
      })
      .catch(function(error) {
      });
      return
    }

    this.initialTimeout = setTimeout(this.initialRun.bind(this),200);
//    this.setState({ hasError: true })
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.error !== undefined && nextProps.error !== ""){
      if(nextProps.error["H119"] !== undefined && nextProps.error["H119"].length > 0){
        state.fieldList = nextProps.error["H119"]
        state.hasFieldMissing = true
      }else state.hasError = true
    }

    if(state.fieldList.length > 0){
      state.completed = false
      for (let item of state.fieldList) {
        if (item.name !== 'state' && (state[item.name] === "" || state[item.name] === undefined)) {
          state.completed = false
          break
        } else {
          state.completed = true
        }
      }
    }
    
    return state
  }

  async loadNames() {
    const response = await fetch('/lib/countries.min.json');
    const names = await response.json();

    var l = []
    for(const country in names){
      names[country].forEach((city) => {
        if(l.indexOf(city+", "+country) === -1)
          l.push({
            label: city+", "+country,
            value: city+", "+country,
            country: country,
            city: city,
          })
      });

    }

    this.list = l
  }

  filterList(){
    const citiesList = this.list.filter(o => o.city.toLowerCase().startsWith(this.state.searchCity.toLowerCase()))
    this.setState({citiesList, loadingSearch: false});
  }

  onTcChange = () => {
    this.setState({
      tcChecked: !this.state.tcChecked
    });
  }

  initialRun(){
    const checkIndex = (data) => {
      log("checkIndex", data)
      if(data.key.type !== undefined){
        if(data.key.type === 'directtrail'){
          DeviceFingerprint('login')
          .then((hash) => {
            const signupData = {
              platformAccountId: data.key.platformAccountId,
              userProfileId: data.key.profileId,
              apiToken: data.key.apiToken,
              deviceHash: hash,
            }
            this.setState({ signupData })
            this.props.dispatch(userActions.directSignup(signupData))
            //keysStorage.Delete('lastUser')
          })
          .catch(function(error) {
          });
        }
      }else{

      }
    }

    keysStorage.Get('lastUser')
    .then((data)=>{
      checkIndex(data)
    })
    .catch((e)=>{
      if(e === "pending"){
        this.initialTimeout = setTimeout(() => {
          keysStorage.Get('lastUser')
          .then((data)=>{
            checkIndex(data)
          })
          .catch((e)=>{
          })
        },1000);
      }else{
      }
    })
  }

  handleChange(name, value) {
    if(/^\s/.test(value)) return;//no space in the start of input
    let signupData = this.state.signupData
    signupData[name] = value
    this.setState({ 
      [name]: value,
      signupData: signupData
    })
  }

  requiredFieldIcon(state){
    if(this.state[state] === "" || this.state[state] === undefined)
      return <span style={{color: 'red'}}> *</span>
  }

  render(){
    return (
      <div>
        {this.state.hasError ? (
          <div className="page spacebetween content logContent shadeWhite">
            <div className="logboxContent page">
              <div className="loginbox">
                <div className="login-lock-header">
                  <img className='Athena-logo' src={logoBoard} />
                  <h1>Failed to create free account, please go back and try again</h1>
                  {/* {appConfig.env === 'dev' &&
                    <label>{this.props.error}</label>
                  } */}
                </div>
              </div>
            </div>
          </div>
          ) : this.state.hasFieldMissing ? (
            <div className="page spacebetween maxHeight logContent shadeWhite" style={{ minHeight: '100vh', width: '100%' }}>
              <div>
                <div className="signs1logo">
                  <img className='Athena-logo' src={logoBoard} />
                </div>
                <div className="signs1start">
                  <h4 style={{marginBottom: 30}}>Complete your profile</h4>
                  <div className="signUpPanel">
                    {this.state.fieldList.length > 0 && this.state.fieldList.map((item, index) => {
                      if (item.name === 'city') {
                        return (
                          <div className="signq" key={index}>
                            <label>City{this.requiredFieldIcon('city')}</label>
                            <Autocomplete
                              name="city"
                              selected={this.state.city}
                              onSelected={(e) => {
                                this.handleChange('city', e.city)
                                this.handleChange('country', e.country)
                                this.setState({ loadingSearch: false, searchCity: e.value })
                              }}
                              onChangeValue={(e) => {
                                if (e === "") return
                                this.setState({ loadingSearch: true, searchCity: e })
                                clearTimeout(this.searchTimer)
                                this.searchTimer = setTimeout(this.filterList, 1000)
                              }}
                              onToggle={open=>{
                                if(!open) this.setState({ loadingSearch: false, searchCity: "" })
                              }}
                              placeholder="Enter and select your location or closest city"
                              items={this.state.citiesList}
                              defaultSelected={null}
                              filter="none"
                              insertMode={false}
                              loading={this.state.loadingSearch}
                              value={this.state.searchCity}
                              multiple={false}
                              size="Medium"
                              status="Good"
                            />
                          </div>
                        )
                      } else if (item.name === 'state' || item.name === 'country') {
                        return null
                      } else {
                        return (
                          <div className="signq" key={index}>
                            <label style={{textTransform: 'capitalize'}}>{item.description}{this.requiredFieldIcon(item.name)}</label>
                            <AnsaradaTextInput
                              value={this.state[item.name]}
                              onChangeValue={(e) => { this.handleChange(item.name, e) }}
                            />
                          </div>
                        )
                      }
                    })}
                  </div>
                  <div>
                    <FormControlLabel control={<MuiCheckbox background='white' checked={this.state.tcChecked} onChange={this.onTcChange} />} label={<div>
                      <span>I have read and I agree to the&nbsp;
                        <a href="https://www.ansarada.com/terms-conditions" target="_blank">Terms and Conditions</a>,&nbsp;
                        <a href="https://www.ansarada.com/cookie-policy" target="_blank">Cookie Policy</a>&nbsp;
                        and <a href="https://www.ansarada.com/privacy" target="_blank">Privacy Policy</a>.
                      </span>
                    </div>}
                    />
                  </div>
                  <div className="signs1but">
                    <Button
                      isDisabled={!this.state.completed || !this.state.tcChecked}
                      onClick={() => {
                        if(this.state.signupData.hasOwnProperty('companyName')){
                          this.state.signupData.companyName = this.state.signupData.companyName.trim()
                        }
                        if(this.state.signupData.hasOwnProperty('country')){
                          this.state.signupData.country = convertCountryToCode(this.state.signupData.country)
                        }
                        this.setState({ hasFieldMissing: false });
                        this.props.dispatch(userActions.directSignup(this.state.signupData))
                      }}
                    >Complete profile</Button>
                  </div>
                </div>
              </div>
            </div>
          ):(
          <div className="page spacebetween content logContent shadeWhite">
            <div className="logboxContent page">
              <div className="loginbox">
                <div className="login-lock-header">
                  <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img style={{ width: '50%', marginTop: '20px' }} src={ansaradaLogo} />
                    <img style={{ width: '25%', marginTop: '30px', marginBottom: '50px' }} src={logoBoard} />
                  </span>
                  <h1>We are creating your free account</h1>
                  <LoadingIndicator size={48} dark={true}/>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    error: state.authentication.error
  };
}

const connectedDirectPage = connect(mapStateToProps)(DirectTrialClass);
export { connectedDirectPage as DirectTrail };
