import React from 'react';
import { TextField, Autocomplete } from "@mui/material";

export class CityCountrySelect extends React.Component {
    constructor(props) {
        super(props);

        this.list = []

        this.state = {
            location: "",
            searchCity: "",
            citiesList: [],
        }
    }

    componentDidMount() {
        this.loadList()
    }

    async loadList() {
        if (window.freemium && window.freemium.countryList) {
            this.list = window.freemium.countryList;
            return;
        }
        const response = await fetch('/lib/countries.min.json');
        const names = await response.json();

        let l = []
        Object.entries(names).map(([country, cities]) => {
            let uniqueArray = cities.filter(function (item, pos, self) {
                return self.indexOf(item) == pos;
            })
            uniqueArray.forEach((city) => {
                l.push({
                    label: city + ", " + country,
                    value: city + ", " + country,
                    country: country,
                    city: city,
                })
            })
        })

        this.list = l;
        window.freemium = {
            countryList: l
        };
    }

    filterList = () => {
        const citiesList = this.state.searchCity ? this.list.filter(o => o.city.toLowerCase().startsWith(this.state.searchCity.toLowerCase())) : [];
        this.setState({ citiesList, loadingSearch: false });
    }

    handleLocation = (newValue) => {
        this.setState({
            searchCity: newValue !== null ? newValue.value : ''
        })
        this.props.onChange(newValue)
    }

    render() {
        return (
            <div>
                <Autocomplete
                    value={this.props.value}
                    noOptionsText="Start typing to search for your closest city"
                    onChange={(event, newValue) => this.handleLocation(newValue)}
                    inputValue={this.state.searchCity}
                    onInputChange={(event, newInputValue) => {
                        this.setState({ searchCity: newInputValue }, () => {
                            this.filterList();
                        })
                    }}
                    options={this.state.citiesList.slice(0, 20)}
                    isOptionEqualToValue={(option, value) => option.value === value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={this.props.label}
                            fullWidth={this.props.fullWidth || false}
                            required={this.props.required || false}
                            error={this.props.error !== ''}
                            helperText={this.props.error}
                            inputProps={{ ...params.inputProps, autoComplete: 'new-password', "data-lpignore": true }}
                        />
                    )}
                />
            </div>
        )
    }
}