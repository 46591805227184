import React from 'react';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import { userActions, alertActions } from '@actions/appuser';
import { Button, TextInput, GeneratePassword, AppLoader } from '@common';
import { AuthCode } from '../loginpage/LoginAuthCode'
import { LoadingIndicator } from '@ansarada/ace-react';
import {
  FaTimes as ExitIcon,
  FaCheckCircle as TickCircle,
  FaQrcode as QrCamera,
  FaCheckCircle as TickIcon,
  FaTimesCircle as CrossIcon,
} from 'react-icons/fa';
import QrReader from 'react-qr-scanner'
import { AccountType } from '@constants/enums';

import {keysStorage} from '@lib/indexeddb';
import RSACrypto, * as CrytpoLib from '@lib/cryptojs';
import { AnsaradaLoading, regexSpliter, isValidRecoveryCard, checkBadPassword } from '@lib/simpletools';
import { RenderPasswordPolicy } from '@common/changepassword';
import { v4 as uuidv4 } from 'uuid';

import iconAnsarada from '@image/logo/Ansarada short.svg';

import '../loginpage/loginapp.css'
import { commonConstants } from '@constants/login';
import { commonErrorMessages } from '@constants';
import LoadingOverlay from '../common/MUI/LoadingOverlay';

export const ForgotAnsarada = (props) => {

  const dispatch = useDispatch()
  if(props.sendResetEmail === true){
    return (
      <div className="aBoxTop-overlay">
        <div className="bDialog" onClick={(e)=>{e.stopPropagation();}}>
          <div className="bLogo">
            <img src={iconAnsarada}/>
          </div>
          <label className="bold">Password reset</label>
          <label>We've just sent you an email to reset your password</label>
          <div className="bLogo">
            <Button
              type="clear"
              onClick={(e)=>{
                e.stopPropagation();
                props.onExit()
              }}
              >OK</Button>
          </div>
        </div>
      </div>
    )
  }

  if(props.loggingIn === true){
    return (
      <div className="aBoxTop-overlay">
        <AppLoader
          text="One moment..."
        />
      </div>
    )
  }

  return (
    <div className="aBoxTop-overlay">
      <div className="bDialog" onClick={(e)=>{e.stopPropagation();}}>
        <div className="bLogo">
          <img src={iconAnsarada}/>
        </div>
        <label className="bold">Password reset</label>
        <label>We will send you an email to reset your password</label>
        <div className="bLogo boardpanel maxWidth spacebetween">
          <Button
            type="clear"
            onClick={(e)=>{
              e.stopPropagation();
              dispatch(userActions.sendResetLink(props.username))
            }}
            >Send link</Button>
            <Button
              type="clear"
              onClick={(e)=>{
                e.stopPropagation();
                props.onExit()
              }}
              >Cancel</Button>
        </div>
      </div>
    </div>
  )
}

class ForgotPage extends React.Component {
  constructor(props) {
    super(props);

    var newstate = {
      passwordPolicyRegex: '',
      passwordPolicy: null,
      username: "",
    }

    if(this.props.passwordPolicy !== undefined){
      newstate.passwordPolicy = this.props.passwordPolicy
      newstate.passwordPolicyRegex = this.props.passwordPolicy.regex
    }else if(this.props.hasOwnProperty('passwordPolicyRegex') && this.props.passwordPolicyRegex !== undefined){
      if(this.props.passwordPolicyRegex !== ""){
        newstate.passwordPolicyRegex = this.props.passwordPolicyRegex;
        newstate.passwordPolicy = {
          text: "",
          parts: regexSpliter(newstate.passwordPolicyRegex, this.props.passwordPolicyDescription)
        }
      }
    }

    if(this.props.athenname !== undefined && this.props.athenname !== ""){
      newstate.username = this.props.athenname
    }

    if(newstate.passwordPolicyRegex === ''){
      this.props.dispatch(userActions.checkAlias(newstate.username, {ansarada: true}));
    }

    this.state = {
      username: newstate.username,
      password: '',
      password2: '',

      deviceId: '',

      passwordPolicy: newstate.passwordPolicy,
      passwordPolicyRegex: newstate.passwordPolicyRegex,

      errNewPass: '',

      page: 0,
    };

    this.onExit = this.onExit.bind(this);
    this.handleChange = this.handleChange.bind(this)
    this.CheckSubmit = this.CheckSubmit.bind(this)
  }

  componentDidMount() {
    this.props.dispatch(userActions.clearErrorMsg());
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.passwordPolicy !== undefined && nextProps.passwordPolicy.regex !== undefined){
      //state.canSubmit = false
      state.passwordPolicyRegex = nextProps.passwordPolicy.regex
      state.passwordPolicy = nextProps.passwordPolicy
    }else if(nextProps.passwordPolicyRegex !== undefined){
      if(nextProps.passwordPolicyRegex !== ""){
        //state.canSubmit = false
        state.passwordPolicyRegex = nextProps.passwordPolicyRegex
        state.passwordPolicy = {
          text: "",
          parts: regexSpliter(nextProps.passwordPolicyRegex, nextProps.passwordPolicyDescription)
        }
      }
    }

    /*if(nextProps.wrongDevice === true && nextProps.onExit !== undefined){
      nextProps.onExit()
    }*/

    return state
  }

  handleChange(e){
    const { name, value } = e.target;

    var newvalue = value.replace(/[&\/\\#,+()$~%.!^@'":*?<>=\[\]_{}]/g,'').trim();
    var j = {
      [name]: newvalue
    }
    if(name === 'password' || name === 'password2'){
      j[name] = value.trim();
      j.errNewPass = ''
    }

    this.setState(j);
    if(name === 'password' || name === 'password2'){
      setTimeout(this.CheckSubmit, 200);
    }
  }

  onExit(){
    this.setState({password: "", password2: ""})
    if(this.props.hasOwnProperty('onExit'))
      this.props.onExit();
  }

  onForgotNext(){
    this.setState({ page: 1 })
  }

  CheckSubmit(){
    var res = true;

    if(this.state.passwordPolicyRegex !== ""){
      if(this.state.password !== this.state.password2){
        res = false;
      }
      if(this.state.password.match(this.state.passwordPolicyRegex) === null){
        res = false;
      }
    }

    var wordsList = [
        this.state.username.replace(/[0-9]/g, '').toLowerCase().trim(),
    ];

    var regex = new RegExp(wordsList.join('|'));
    if(this.state.password.toLowerCase().match(regex)){
      this.setState({errNewPass: commonErrorMessages.password.namePasswordError});
      res = false;
    }

    if(checkBadPassword(this.state.password.toLowerCase())){
      this.setState({errNewPass: commonErrorMessages.password.commonPasswordError});
      res = false;
    }

    if(this.state.password !== this.state.password2 && !this.state.showPassword){
      this.setState({errNewPass: commonErrorMessages.password.mismatchedPasswordError});
      res = false;
    }

    this.setState({canSubmit: res})
  }

  onForgotSubmit(e){
    e.stopPropagation()
    const { username } = this.props;
    const { dispatch } = this.props;

    if (username) {
      dispatch(userActions.forgotNC(username));
    }
  }

  onAuthSubmit(authCode){
    const { username, password } = this.state;
    const { dispatch } = this.props;

    if (username && password) {
      var regReqest = {
        alias: username,
        username: this.props.username,
        TwoFactorAuthCode: authCode.toUpperCase(),
        password: password,
      }

      dispatch(userActions.forgotNewPass(regReqest));
    }
  }

  doAskforCode(){
    if(!this.props.hasOwnProperty('askforCode')) return false;
    return this.props.askforCode;
  }

  completePassword(){
    if(!this.props.hasOwnProperty('resetComplete')) return false;
    return this.props.resetComplete;
  }

  getErrorMessage() {
    if (this.props.alert) {
      if (this.props.alert.message) {
        if (this.props.alert.message === 'F103') {
          return 'New password cannot be same as previous password';
        }
        return this.props.alert.message;
      }
    }
    return 'Problem has occurred with password reset, please try again later or contact your administrator.';
  }

  render() {
    if(this.props.mode === AccountType.AnsaradaAccount && (this.props.universalRedirect !== false || this.props.universalLogin !== false)){
      return (
        <ForgotAnsarada
          username={this.state.username}
          onExit={()=>{this.onExit()}}
          {...this.props}
          />
      )
    }
    if(this.props.error !== "" && this.props.error !== undefined){
      return (
        <div className="aBoxTop-overlay2 forgotPanel" onClick={this.onExit.bind(this)}>
          <div onClick={(e)=>{ e.stopPropagation() }}>
            <div className="regGap"></div>
            <div className="aBoxTop-overlay">
              <div className="regPass" style={{width: 500}}>
                <div style={{marginBottom: 15}}>
                  <img src={iconAnsarada}/>
                </div>
                <h4>Error has Occurred</h4>
                <p>{this.getErrorMessage()}</p>
                <div className="regBut">
                  <Button className="regButL" onClick={this.onExit.bind(this)}>OK</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className="aBoxTop-overlay2 forgotPanel" onClick={this.onExit.bind(this)}>
        {this.state.page === 0 &&
          <div onClick={(e)=>{ e.stopPropagation() }}>
            {this.state.passwordPolicyRegex === '' &&
              <div className="regPass">
                <LoadingIndicator
                  dark={true}
                  size={48}
                />
              </div>
            }
            {this.state.passwordPolicyRegex !== '' &&
              <div className="regPass forgotPass">
                <label>{commonConstants.forgotPassword.title}</label>
                <div>
                  <TextInput
                    name="password"
                    type="password"
                    stylenormal="f-controlReg"
                    styleempty="f-controlRefempty"
                    onChange={this.handleChange}
                    value={this.state.password}
                    placeholder="Password"
                    refpoint={(node) => { this.refPassCode1 = node;}}
                  />
                </div>
                <div>
                  <TextInput
                    name="password2"
                    type="password"
                    stylenormal="f-controlReg"
                    styleempty="f-controlRefempty"
                    onChange={this.handleChange}
                    onFocusOut={this.CheckSubmit}
                    value={this.state.password2}
                    placeholder="Re-enter password"
                    refpoint={(node) => { this.refPassCode2 = node;}}
                  />
                </div>
                <div className="page passPol" style={{marginTop: 15}}>
                  <RenderPasswordPolicy
                    policy={this.state.passwordPolicy}
                    password={this.state.password}
                    />
                </div>
                <div className="error passError">{this.state.errNewPass || ''}</div>
                <div className="regBut">
                  {this.props.mode === AccountType.AthenaAccount &&
                    <Button className="regButL" isDisabled={!this.state.canSubmit} onClick={this.onForgotNext.bind(this)}>OK</Button>
                  }
                  {this.props.mode === AccountType.AnsaradaAccount &&
                    <Button className="regButL" isDisabled={!this.state.canSubmit} onClick={(e)=>{
                      this.setState({page: 1});
                      this.onForgotSubmit(e);
                    }}>OK</Button>
                  }
                </div>
              </div>
            }
          </div>
        }
        {this.state.page === 1 && this.props.mode == AccountType.AthenaAccount && !this.doAskforCode() && !this.completePassword() &&
          <div className="regPass forgotPass" style={{width: 400}} onClick={(e)=>{ e.stopPropagation() }}>
            <p>Each of the administrators of the boards of which you are a member will be notified of the requirement for a password reset.</p>
            <p>They much each approve this reset for their board, you will have access to each board in turn as the approvals are made.</p>
            <p>You will receive a notification when the process has been completed.</p>
            <div className="regBut">
              <Button className="regButL" onClick={this.onForgotSubmit.bind(this)}>OK</Button>
            </div>
          </div>
        }
        {this.doAskforCode() &&
          <div className="regPass forgotPass" style={{width: 400}} onClick={(e)=>{ e.stopPropagation() }}>
            <AuthCode
              onSubmit={this.onAuthSubmit.bind(this)}
              onExit={() => { this.dispatch(userActions.clearAuthCode());this.onExit();}}
              {...this.props}
            />
          </div>
        }
        {this.completePassword() &&
          <div onClick={(e)=>{ e.stopPropagation() }}>
            <div className="regGap"></div>
            <div className="aBoxTop-overlay">
              <div className="regPass" style={{width: 500}}>
                <div style={{marginBottom: 15}}>
                  <img src={iconAnsarada}/>
                  <h4>Password reset</h4>
                </div>
                <p>Your new password has been set.</p>
                <div className="regBut">
                  <Button className="regButL" onClick={this.onExit.bind(this)}>OK</Button>
                </div>
              </div>
            </div>
          </div>
        }
        {(this.props.loggingIn) &&
          <LoadingOverlay />
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loggingIn: state.authentication.loggingIn,
    error: state.authentication.error,
    askforCode: state.authentication.askforCode,
    askforLogin: state.authentication.askforLogin,
    askforPassword: state.authentication.askforPassword,
    resetComplete: state.authentication.resetComplete,
    askforCard: state.authentication.askforCard,
    passwordPolicyDescription: state.authentication.passwordPolicyDescription,
    passwordPolicyRegex: state.authentication.passwordPolicyRegex,
    canResetPasswordWithAdmin: state.authentication.canResetPasswordWithAdmin,
    mode: state.authentication.mode,
    universalLogin: state.authentication.universalLogin,
    universalRedirect: state.authentication.universalRedirect,
    wrongDevice: state.authentication.wrongDevice,
    username: state.authentication.username,
    alert: state.alert,
    sendResetEmail: state.authentication.sendResetEmail,
  };
}

const connectedForgotPage = connect(mapStateToProps)(ForgotPage);
export { connectedForgotPage as ForgotPage };
