import React from 'react';

const CheckboxHook = ({ label, isSelected, onCheckboxChange, style, outerStyle }) => {
  if(style === undefined) style = {};
  if(outerStyle === undefined) outerStyle = {};
  return (
    <label className="checkbox" style={outerStyle} onClick={(e)=>{
        if(e.defaultPrevented) return
        e.preventDefault();
        e.stopPropagation();
        onCheckboxChange(e,!isSelected);
      }}>
      {label}
      <input
        type="checkbox"
        checked={isSelected}
        onChange={(e)=>{
          if(e.defaultPrevented) return
          e.preventDefault();
          e.stopPropagation();
          onCheckboxChange(e,!isSelected);
        }}
      />
      <span className="checkbox-mark" style={style} name={label}></span>
    </label>
  )
};

export { CheckboxHook as CheckBox2 };
