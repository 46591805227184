import React, { useState } from 'react';
import { cmpWord, userNameOrder } from '@lib/simpletools';
import { FaAngleRight as RightIcon } from 'react-icons/fa';
import './styles/CustomAccordion.css';

export default class CustomAccordion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    render() {
        return (
            <div className='custom-accordion-container' style={this.props.style}>
                <span className='custom-accordion-container-title-container' onClick={() => this.setState({ isOpen: !this.state.isOpen })}>
                    <span className='custom-accordion-container-arrow'><RightIcon className={'folder-arrow-icon ' + (this.state.isOpen ? 'folder-arrow-icon-open' : '')} /></span>
                    <span className='custom-accordion-container-title'>{this.props.title}</span>
                </span>
                <span className='custom-accordion-container-body'>
                    {this.state.isOpen && this.props.children}
                </span>
            </div>
        )
    }
}