import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { LoadingIndicator } from '@ansarada/ace-react';

export class Loader extends React.Component {
  constructor(props) {
    super(props);

  }

  render(){
    const { small, size, colour } = this.props
    var s = small===true?24:48, c = '#999999';
    if(size !== undefined) s = size
    if(colour !== undefined) c = colour

    if(small === true){
      return (
        <div className="loader-small">
          <LoadingIndicator
            size={s}
            dark={true}/>
          <label>{this.props.text}</label>
        </div>
      )
    }
    return (
      <div className="loader">
        <LoadingIndicator
          size={s}
          dark={true}/>
        <h4 className="colorLightGray">{this.props.text}</h4>
      </div>
    )
  }
}

export class AppLoader extends React.Component {
  constructor(props) {
    super(props);

  }

  render(){

    return (
      <div className="apploader">
        <div>
          <LoadingIndicator
            size={180}
            dark={false}/>
          <label>{this.props.text}</label>
        </div>
      </div>
    )
  }
}
