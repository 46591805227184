export const binderConstants = {
  GET_BINDERCONTENT_REQUEST: 'GET_BINDERCONTENT_REQUEST',
  GET_BINDERCONTENT_SUCCESS: 'GET_BINDERCONTENT_SUCCESS',
  GET_BINDERCONTENT_FAILURE: 'GET_BINDERCONTENT_FAILURE',

  POPULATE_BINDERCONTENT_REQUEST: 'POPULATE_BINDERCONTENT_REQUEST',
  POPULATE_BINDERCONTENT_SUCCESS: 'POPULATE_BINDERCONTENT_SUCCESS',
  POPULATE_BINDERCONTENT_UPDATE: 'POPULATE_BINDERCONTENT_UPDATE',
  POPULATE_BINDERCONTENT_PROGRESS: 'POPULATE_BINDERCONTENT_PROGRESS',
  POPULATE_BINDERCONTENT_FAILURE: 'POPULATE_BINDERCONTENT_FAILURE',

  GET_VOTE_REQUEST: 'GET_VOTE_REQUEST',
  GET_VOTE_SUCCESS: 'GET_VOTE_SUCCESS',
  GET_VOTE_FAILURE: 'GET_VOTE_FAILURE',

  SET_VOTE_REQUEST: 'SET_VOTE_REQUEST',
  SET_VOTE_SUCCESS: 'SET_VOTE_SUCCESS',
  SET_VOTE_FAILURE: 'SET_VOTE_FAILURE',

  GET_VOTEANSWER_REQUEST: 'GET_VOTEANSWER_REQUEST',
  GET_VOTEANSWER_SUCCESS: 'GET_VOTEANSWER_SUCCESS',
  GET_VOTEANSWER_FAILURE: 'GET_VOTEANSWER_FAILURE',

  BINDER_ADDITION_SUCCESS: 'BINDER_ADDITION_SUCCESS',
  BINDER_UPDATE_SUCCESS: 'BINDER_UPDATE_SUCCESS',
  BINDER_REMOVAL_SUCCESS: 'BINDER_REMOVAL_SUCCESS',

  COMPLETE_DOWNLOAD_DOCUMENT_REQUEST: 'COMPLETE_DOWNLOAD_DOCUMENT_REQUEST',
  UPDATE_DOC_STATE: 'UPDATE_DOC_STATE',
}
