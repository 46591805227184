export const boardConstants = {
  GETMEMBERSHIP_REQUEST: 'BOARD_GETMEMBERSHIP_REQUEST',
  GETMEMBERSHIP_SUCCESS: 'BOARD_GETMEMBERSHIP_SUCCESS',
  GETMEMBERSHIP_UPDATE: 'GETMEMBERSHIP_UPDATE',
  GETMEMBERSHIP_FAILURE: 'BOARD_GETMEMBERSHIP_FAILURE',

  SETMEMBERSHIP_REQUEST: 'BOARD_SETMEMBERSHIP_REQUEST',
  SETMEMBERSHIP_SUCCESS: 'BOARD_SETMEMBERSHIP_SUCCESS',
  SETMEMBERSHIP_FAILURE: 'BOARD_SETMEMBERSHIP_FAILURE',

  SETGROUPMEMBERSHIP_REQUEST: 'BOARD_SETGROUPMEMBERSHIP_REQUEST',

  DELETEMEMBERSHIP_REQUEST: 'BOARD_DELETEMEMBERSHIP_REQUEST',
  DELETEMEMBERSHIP_SUCCESS: 'BOARD_DELETEMEMBERSHIP_SUCCESS',
  DELETEMEMBERSHIP_FAILURE: 'BOARD_DELETEMEMBERSHIP_FAILURE',

  GETBOARD_REQUEST: 'BOARD_GETBOARD_REQUEST',
  GETBOARD_SUCCESS: 'BOARD_GETBOARD_SUCCESS',
  GETBOARD_FAILURE: 'BOARD_GETBOARD_FAILURE',

  BOARD_GETBOARDS_REQUEST: 'BOARD_GETBOARDS_REQUEST',
  BOARD_GETBOARDS_SUCCESS: 'BOARD_GETBOARDS_SUCCESS',
  BOARD_GETBOARDS_FAILURE: 'BOARD_GETBOARDS_FAILURE',

  GETBOARD_SETTING_REQUEST: 'BOARD_GETBOARD_SETTING_REQUEST',
  GETBOARD_SETTING_SUCCESS: 'BOARD_GETBOARD_SETTING_SUCCESS',
  GETBOARD_SETTING_FAILURE: 'BOARD_GETBOARD_SETTING_FAILURE',

  DELETEBOARD_REQUEST: 'BOARD_DELETEBOARD_REQUEST',
  DELETEBOARD_SUCCESS: 'BOARD_DELETEBOARD_SUCCESS',
  DELETEBOARD_FAILURE: 'BOARD_DELETEBOARD_FAILURE',

  SETBOARD_REQUEST: 'BOARD_SETBOARD_REQUEST',
  SETBOARD_SUCCESS: 'BOARD_SETBOARD_SUCCESS',
  SETBOARD_FAILURE: 'BOARD_SETBOARD_FAILURE',

  BOARD_FILELIST_REQUEST: 'BOARD_FILELIST_REQUEST',
  BOARD_FILELIST_SUCCESS: 'BOARD_FILELIST_SUCCESS',
  BOARD_FILELIST_FAILURE: 'BOARD_FILELIST_FAILURE',

  BOARD_POPULATEFILELIST_REQUEST: 'BOARD_POPULATEFILELIST_REQUEST',
  BOARD_POPULATEFILELIST_SUCCESS: 'BOARD_POPULATEFILELIST_SUCCESS',
  BOARD_POPULATEFILELIST_FAILURE: 'BOARD_POPULATEFILELIST_FAILURE',

  BOARD_DELETEFILE_REQUEST: 'BOARD_DELETEFILE_REQUEST',
  BOARD_ADDFILE_REQUEST: 'BOARD_ADDFILE_REQUEST',

  GET_CALENDAR_REQUEST: 'GET_CALENDAR_REQUEST',
  GET_CALENDAR_SUCCESS: 'GET_CALENDAR_SUCCESS',
  GET_CALENDAR_FAILURE: 'GET_CALENDAR_FAILURE',

  GET_CALENDARITEM_REQUEST: 'GET_CALENDARITEM_REQUEST',
  GET_CALENDARITEM_SUCCESS: 'GET_CALENDARITEM_SUCCESS',
  GET_CALENDARITEM_FAILURE: 'GET_CALENDARITEM_FAILURE',

  GET_CALENDARBINDER_REQUEST: 'GET_CALENDARBINDER_REQUEST',
  GET_CALENDARBINDER_SUCCESS: 'GET_CALENDARBINDER_SUCCESS',
  GET_CALENDARBINDER_FAILURE: 'GET_CALENDARBINDER_FAILURE',

  DELETE_CALENDAR_REQUEST: 'DELETE_CALENDAR_REQUEST',
  DELETE_CALENDAR_SUCCESS: 'DELETE_CALENDAR_SUCCESS',
  DELETE_CALENDAR_FAILURE: 'DELETE_CALENDAR_FAILURE',

  SET_CALENDAR_REQUEST: 'SET_CALENDAR_REQUEST',
  SET_CALENDAR_SUCCESS: 'SET_CALENDAR_SUCCESS',
  SET_CALENDAR_FAILURE: 'SET_CALENDAR_FAILURE',

  EDIT_CALENDAR_REQUEST: 'EDIT_CALENDAR_REQUEST',
  EDIT_CALENDAR_SUCCESS: 'EDIT_CALENDAR_SUCCESS',
  EDIT_CALENDAR_FAILURE: 'EDIT_CALENDAR_FAILURE',

  POPULATE_BINDERS_REQUEST: 'POPULATE_BINDERS_REQUEST',
  POPULATE_BINDERS_SUCCESS: 'POPULATE_BINDERS_SUCCESS',
  POPULATE_BINDERS_FAILURE: 'POPULATE_BINDERS_FAILURE',

  POPULATE_TEMPLATES_REQUEST: 'POPULATE_TEMPLATES_REQUEST',
  POPULATE_TEMPLATES_SUCCESS: 'POPULATE_TEMPLATES_SUCCESS',
  POPULATE_TEMPLATES_FAILURE: 'POPULATE_TEMPLATES_FAILURE',

  GET_BINDERS_REQUEST: 'GET_BINDERS_REQUEST',
  GET_BINDERS_SUCCESS: 'GET_BINDERS_SUCCESS',
  GET_BINDERS_FAILURE: 'GET_BINDERS_FAILURE',

  GET_TEMPLATES_REQUEST: 'GET_TEMPLATES_REQUEST',
  GET_TEMPLATES_SUCCESS: 'GET_TEMPLATES_SUCCESS',
  GET_TEMPLATES_FAILURE: 'GET_TEMPLATES_FAILURE',

  GET_BINDERS_CURRENT_REQUEST: 'GET_BINDERS_CURRENT_REQUEST',
  GET_BINDERS_CURRENT_SUCCESS: 'GET_BINDERS_CURRENT_SUCCESS',
  GET_BINDERS_CURRENT_FAILURE: 'GET_BINDERS_CURRENT_FAILURE',

  GET_BINDERS_PREVIOUS_REQUEST: 'GET_BINDERS_PREVIOUS_REQUEST',
  GET_BINDERS_PREVIOUS_SUCCESS: 'GET_BINDERS_PREVIOUS_SUCCESS',
  GET_BINDERS_PREVIOUS_FAILURE: 'GET_BINDERS_PREVIOUS_FAILURE',

  GET_BINDERS_ARCHIVE_REQUEST: 'GET_BINDERS_ARCHIVE_REQUEST',
  GET_BINDERS_ARCHIVE_SUCCESS: 'GET_BINDERS_ARCHIVE_SUCCESS',
  GET_BINDERS_ARCHIVE_FAILURE: 'GET_BINDERS_ARCHIVE_FAILURE',

  GET_BINDERS_UNPUBLISHED_REQUEST: 'GET_BINDERS_UNPUBLISHED_REQUEST',
  GET_BINDERS_UNPUBLISHED_SUCCESS: 'GET_BINDERS_UNPUBLISHED_SUCCESS',
  GET_BINDERS_UNPUBLISHED_FAILURE: 'GET_BINDERS_UNPUBLISHED_FAILURE',

  GET_BINDERS_USER_REQUEST: 'GET_BINDERS_USER_REQUEST',
  GET_BINDERS_USER_SUCCESS: 'GET_BINDERS_USER_SUCCESS',
  GET_BINDERS_USER_FAILURE: 'GET_BINDERS_USER_FAILURE',

  GET_FILELIST_USER_REQUEST: 'GET_FILELIST_USER_REQUEST',
  GET_FILELIST_USER_SUCCESS: 'GET_FILELIST_USER_SUCCESS',
  GET_FILELIST_USER_FAILURE: 'GET_FILELIST_USER_FAILURE',

  GET_ATTENDEE_REQUEST: 'GET_ATTENDEE_REQUEST',
  GET_ATTENDEE_SUCCESS: 'GET_ATTENDEE_SUCCESS',
  GET_ATTENDEE_FAILURE: 'GET_ATTENDEE_FAILURE',

  GET_RECIPIENT_REQUEST: 'GET_RECIPIENT_REQUEST',
  GET_RECIPIENT_SUCCESS: 'GET_RECIPIENT_SUCCESS',
  GET_RECIPIENT_FAILURE: 'GET_RECIPIENT_FAILURE',

  GET_INVITEE_REQUEST: 'GET_INVITEE_REQUEST',
  GET_INVITEE_SUCCESS: 'GET_INVITEE_SUCCESS',
  GET_INVITEE_FAILURE: 'GET_INVITEE_FAILURE',

  POPULATE_ATTENDEE_REQUEST: 'POPULATE_ATTENDEE_REQUEST',
  POPULATE_ATTENDEE_SUCCESS: 'POPULATE_ATTENDEE_SUCCESS',
  POPULATE_ATTENDEE_FAILURE: 'POPULATE_ATTENDEE_FAILURE',

  POPULATE_RECIPIENT_REQUEST: 'POPULATE_RECIPIENT_REQUEST',
  POPULATE_RECIPIENT_SUCCESS: 'POPULATE_RECIPIENT_SUCCESS',
  POPULATE_RECIPIENT_FAILURE: 'POPULATE_RECIPIENT_FAILURE',

  DELETE_ATTENDEE_REQUEST: 'DELETE_ATTENDEE_REQUEST',
  DELETE_ATTENDEE_SUCCESS: 'DELETE_ATTENDEE_SUCCESS',
  DELETE_ATTENDEE_FAILURE: 'DELETE_ATTENDEE_FAILURE',

  DELETE_RECIPIENT_REQUEST: 'DELETE_RECIPIENT_REQUEST',
  DELETE_RECIPIENT_SUCCESS: 'DELETE_RECIPIENT_SUCCESS',
  DELETE_RECIPIENT_FAILURE: 'DELETE_RECIPIENT_FAILURE',

  DELETE_INVITEE_REQUEST: 'DELETE_INVITEE_REQUEST',
  DELETE_INVITEE_SUCCESS: 'DELETE_INVITEE_SUCCESS',
  DELETE_INVITEE_FAILURE: 'DELETE_INVITEE_FAILURE',

  GET_BOARD_USAGE_REQUEST: 'GET_BOARD_USAGE_REQUEST',
  GET_BOARD_USAGE_SUCCESS: 'GET_BOARD_USAGE_SUCCESS',
  GET_BOARD_USAGE_FAILURE: 'GET_BOARD_USAGE_FAILURE',

  PROCESSING_BINDER_REQUEST: 'PROCESSING_BINDER_REQUEST',
  PROCESSING_BINDER_SUCCESS: 'PROCESSING_BINDER_SUCCESS',
  PROCESSING_BINDER_FAILURE: 'PROCESSING_BINDER_FAILURE',

  GET_BOARD_MINUTES_REQUEST: 'GET_BOARD_MINUTES_REQUEST',
  GET_BOARD_MINUTES_SUCCESS: 'GET_BOARD_MINUTES_SUCCESS',
  GET_BOARD_MINUTES_FAILURE: 'GET_BOARD_MINUTES_FAILURE',

  GET_BOARD_DRAFTS_REQUEST: 'GET_BOARD_DRAFTS_REQUEST',
  GET_BOARD_DRAFTS_SUCCESS: 'GET_BOARD_DRAFTS_SUCCESS',
  GET_BOARD_DRAFTS_FAILURE: 'GET_BOARD_DRAFTS_FAILURE',

  //CHECK_MEMBERSHIP_REQUEST: 'CHECK_MEMBERSHIP_REQUEST',
  //CHECK_MEMBERSHIP_SUCCESS: 'CHECK_MEMBERSHIP_SUCCESS',
  //CHECK_MEMBERSHIP_FAILURE: 'CHECK_MEMBERSHIP_FAILURE',

  ISDELETE_MEMBERSHIP_REQUEST: 'ISDELETE_MEMBERSHIP_REQUEST',
  ISDELETE_MEMBERSHIP_SUCCESS: 'ISDELETE_MEMBERSHIP_SUCCESS',
  ISDELETE_MEMBERSHIP_FAILURE: 'ISDELETE_MEMBERSHIP_FAILURE',

  BOARD_CURRENTPREVIOUS_REQUEST: 'BOARD_CURRENTPREVIOUS_REQUEST',
  BOARD_CURRENTPREVIOUS_SUCCESS: 'BOARD_CURRENTPREVIOUS_SUCCESS',
  BOARD_CURRENTPREVIOUS_FAILURE: 'BOARD_CURRENTPREVIOUS_FAILURE',

  CLEAR_ERROR_REQUEST: 'CLEAR_ERROR_REQUEST',

  BOARD_SETCURRENT_SUCCESS: 'BOARD_SETCURRENT_SUCCESS',

  BOARD_GETCURRENT_REQUEST: 'BOARD_GETCURRENT_REQUEST',
  BOARD_GETCURRENT_SUCCESS: 'BOARD_GETCURRENT_SUCCESS',
  BOARD_GETCURRENT_FAILURE: 'BOARD_GETCURRENT_FAILURE',

  BOARD_GETPREVIEW_REQUEST: 'BOARD_GETPREVIEW_REQUEST',
  BOARD_GETPREVIEW_SUCCESS: 'BOARD_GETPREVIEW_SUCCESS',
  BOARD_GETPREVIEW_FAILURE: 'BOARD_GETPREVIEW_FAILURE',

  POPULATE_ALL_BINDERS_TEMPLATES_REQUEST: 'POPULATE_ALL_BINDERS_TEMPLATES_REQUEST',
  POPULATE_ALL_BINDERS_TEMPLATES_SUCCESS: 'POPULATE_ALL_BINDERS_TEMPLATES_SUCCESS',
  POPULATE_ALL_BINDERS_TEMPLATES_FAILURE: 'POPULATE_ALL_BINDERS_TEMPLATES_FAILURE',

  CREATE_UNPUBLISHED_BINDER_STATE: 'CREATE_UNPUBLISHED_BINDER_STATE',
  
  UPDATE_BOARD_VISIBILITY: 'UPDATE_BOARD_VISIBILITY',

  BOARD_SETTINGS_UPDATE: 'BOARD_SETTINGS_UPDATE',
};
