import { minutesConstants, binderConstants, webConstants, userConstants } from '@constants/admin';
import { minutesService, binderService, userService } from '@services/admin';
import { BinderItemType, RoutesConstants } from '@constants/common.constants';
import { alertActions, queueActions, boardActions, customerActions } from '@actions/admin';
import { history } from '@lib';
import {
  DownloadBlob,
  TrackEvent,
} from '@lib/simpletools';

import * as reportLib from '@common/reports';


export const minutesActions = {
  populateMinutes,
  getMinutes,
  newMinutes,
  updateMinutes,
  publishMinutes,
  deleteMinute,
  deleteMinuteNR,
  deleteMinuteGroups,
  saveCachedMinutes,
  getAllCachedMinutes,
  deleteCachedMinute,
  copyBinderToMinutes,
  copyMinutes,
  exportMinutes,
  downloadUserFeedBack,
};

function populateMinutes(boardId, minutesId){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    var keys = getState().authentication.keys;
    var kUser = null, pUserGenSec = null;
    if(keys !== undefined){
      if(keys[customerId] !== undefined){
        if(keys[customerId].kUser !== undefined){
          kUser = keys[customerId].kUser;
        }
        if(keys[customerId].pUserGenSec !== undefined){
          pUserGenSec = keys[customerId].pUserGenSec;
        }
      }
    }

    function getMinutes(item){
      minutesService.populateMinutes(item)
        .then(
            items => {
              dispatch(success(item.boardId, items))
              dispatch(customerActions.setLock({
                objectType: BinderItemType.Minutes,
                objectId: minutesId,
                detail: "Edit"
              }))
              history.push({
                pathname: RoutesConstants.minutenew,
                query: { board: {
                  boardId: item.boardId,
                  minutesId: item.minutesId,
                }}
              });
            },
            error => dispatch(failure(item.minutesId, error))
        );
    }

    dispatch(request(minutesId));
    if(pUserGenSec === null){
      //dispatch(requestUser({customerId: customerId, kUser: null}));

      userService.getGenPrivKey(customerId, kUser)
      .then(
        payload => {
          var item = {
            kUser: payload.kUser,
            minutesId: minutesId,
            boardId: boardId,
          }

          getMinutes(item);
          dispatch(successUser(payload))
        },
        error => {
          dispatch(failureUser(error));
        }
      );
    }else{
      var item = {
        kUser: pUserGenSec,
        minutesId: minutesId,
        boardId: boardId,
      }

      getMinutes(item);
    }
  };

  function requestUser(payload) { return { type: userConstants.GET_PGENERIC_KEY_REQUEST, payload } }
  function successUser(payload) { return { type: userConstants.GET_PGENERIC_KEY_SUCCESS, payload } }
  function failureUser(error) { return { type: userConstants.GET_PGENERIC_KEY_FAILURE, error } }

  function request(minutesId) { return { type: minutesConstants.GET_MINUTES_REQUEST, minutesId } }
  function success(boardId, minutes) { return { type: minutesConstants.GET_MINUTES_SUCCESS, boardId, minutes } }
  function failure(minutesId, error) { return { type: minutesConstants.GET_MINUTES_FAILURE, minutesId, error } }
}

function getMinutes(boardId, minutesId){
  return dispatch => {
    dispatch(request(minutesId)),
    minutesService.getMinutes(minutesId)
      .then(
        item => dispatch(success(boardId, item)),
        error => {
          dispatch(failure(minutesId, error))
        }
      );
  };

  function request(minutesId) { return { type: minutesConstants.GET_MINUTES_REQUEST, minutesId } }
  function success(boardId, minutes) { return { type: minutesConstants.GET_MINUTES_SUCCESS, boardId, minutes } }
  function failure(minutesId, error) { return { type: minutesConstants.GET_MINUTES_FAILURE, minutesId, error } }
}

function deleteCachedMinute(boardId, id){
  return dispatch => {
    minutesService.deleteCachedMinute(id)
      .then(
        item => dispatch(success(boardId, id)),
        error => {

        }
      );
  };

  function success(boardId, id) { return { type: minutesConstants.DELETE_MINUTECACHED_SUCCESS, boardId, id } }
}

function newMinutes(minutes){
  return dispatch => {
    dispatch(request(minutes.cacheId));
    minutesService.updateMinutes(minutes)
      .then(
          items => {
            minutes.id = items.id;
            if(minutes.worker){
              minutes.processType = webConstants.ADD_MINUTES
              dispatch(queueActions.uploadMinutes(minutes));
              if(minutes.cacheId !== "")
                minutesService.renameCachedMinute(minutes.cacheId, items.id)
                .then(
                  rename => {
                  },
                  error => {
                  }
                );
            }else{
              dispatch(deleteCachedMinute(minutes.boardId, minutes.cacheId));
            }
            dispatch(success(minutes));
            if(minutes.redirect !== undefined && !minutes.redirect){
              return
            }
            history.push({
              pathname: RoutesConstants.minuteboard,
              query: { boardId: minutes.boardId }
            });
          },
          error => dispatch(failure(minutes.cacheId, error))
      );
  };

  function request(minutesId) { return { type: minutesConstants.SET_MINUTES_REQUEST, minutesId } }
  function success(minutes) { return { type: minutesConstants.SET_MINUTES_SUCCESS, minutes } }
  function failure(minutesId, error) { return { type: minutesConstants.SET_MINUTES_FAILURE, minutesId, error } }
}

function updateMinutes(minutes){
  return dispatch => {
    dispatch(request(minutes.id));
    minutesService.updateMinutes(minutes)
      .then(
          items => {
            //minutes.actions = items.actions;
            if(minutes.worker){
              minutes.processType = webConstants.ADD_MINUTES
              dispatch(queueActions.uploadMinutes(minutes));
            }else{
              dispatch(deleteCachedMinute(minutes.boardId, minutes.cacheId));
            }
            dispatch(success(minutes));
            if(minutes.redirect !== undefined && !minutes.redirect){
              return
            }
            history.push({
              pathname: RoutesConstants.minuteboard,
              query: { boardId: minutes.boardId }
            });
          },
          error => dispatch(failure(minutes.id, error))
      );
  };

  function request(minutesId) { return { type: minutesConstants.SET_MINUTES_REQUEST, minutesId } }
  function success(minutes) { return { type: minutesConstants.SET_MINUTES_SUCCESS, minutes } }
  function failure(minutesId, error) { return { type: minutesConstants.SET_MINUTES_FAILURE, minutesId, error } }
}

function publishMinutes(minutes){
  return dispatch => {
    dispatch(request(minutes.id));
    dispatch(requestUpdate(minutes.id));
    minutesService.updateMinutes(minutes)
      .then(
          items => {
            if(minutes.id !== items.id) minutes.id = items.id
            dispatch(successUpdate(minutes.id));
            if(minutes.worker){
              minutes.processType = webConstants.ADD_MINUTES
              dispatch(queueActions.uploadMinutes(minutes));

              history.push({
                pathname: RoutesConstants.minuteboard,
                query: { boardId: minutes.boardId }
              });
            }else{
              dispatch(deleteCachedMinute(minutes.boardId, minutes.cacheId));

              minutesService.publishMinutes(minutes)
              .then(
                  items => {
                    history.push({
                      pathname: RoutesConstants.minuteboard,
                      query: { boardId: minutes.boardId }
                    });
                  },
                  error => dispatch(failureUpdate(minutes.id, error))
              );
            }
          },
          error => dispatch(failureUpdate(minutes.id, error))
      );
  };

  function requestUpdate(minutesId) { return { type: minutesConstants.SET_MINUTES_REQUEST, minutesId } }
  function successUpdate(minutes) { return { type: minutesConstants.SET_MINUTES_SUCCESS, minutes } }
  function failureUpdate(minutesId, error) { return { type: minutesConstants.SET_MINUTES_FAILURE, minutesId, error } }

  function request(minutesId) { return { type: minutesConstants.PUBLISH_MINUTES_REQUEST, minutesId } }
  function success(minutes) { return { type: minutesConstants.PUBLISH_MINUTES_SUCCESS, minutes } }
  function failure(minutesId, error) { return { type: minutesConstants.PUBLISH_MINUTES_FAILURE, minutesId, error } }
}

function saveCachedMinutes(userkey, id, minutes){
  return dispatch => {
    dispatch(request(id, minutes.id))
    minutesService.saveCachedMinutes(userkey, id, minutes)
      .then(
        item => dispatch(success(id, minutes.id)),
        error => {

        }
      );
  };

  function request(id, minutesId) { return { type: minutesConstants.SAVE_MINUTECACHED_REQUEST, id, minutesId } }
  function success(id, minutesId) { return { type: minutesConstants.SAVE_MINUTECACHED_SUCCESS, id, minutesId } }
}

function getAllCachedMinutes(boardId, userId){
  return dispatch => {
    minutesService.getAllCachedMinutes(boardId, userId)
      .then(
        item => dispatch(success(boardId, item)),
        error => {

        }
      );
  };

  function success(boardId, item) { return { type: minutesConstants.GET_MINUTESCACHED_SUCCESS, boardId, item } }
}

function deleteMinuteNR(boardId, minutesId, cacheId = ''){
  return dispatch => {
    dispatch(request(minutesId));
    minutesService.deleteMinute(minutesId)
      .then(
        item => {
          if(cacheId !== ""){
            minutesService.deleteCachedMinute(cacheId)
              .then(
                item => {
                  dispatch(successCache(boardId, cacheId));
                  dispatch(success(minutesId, boardId));
                  dispatch(boardActions.getBoardMinutes(boardId));
                },
                error => {
                  dispatch(success(minutesId, boardId));
                  dispatch(boardActions.getBoardMinutes(boardId));
                }
              );
          }else{
            dispatch(success(minutesId, boardId));
            dispatch(boardActions.getBoardMinutes(boardId));
          }
        },
        error => dispatch(failure(error))
      );
  };

  function successCache(boardId, id) { return { type: minutesConstants.DELETE_MINUTECACHED_SUCCESS, boardId, id } }

  function request(minutesId) { return { type: minutesConstants.DELETE_MINUTECONTENT_REQUEST, minutesId } }
  function success(minutesId, boardId) { return { type: minutesConstants.DELETE_MINUTECONTENT_SUCCESS, minutesId, boardId } }
  function failure(error) { return { type: minutesConstants.DELETE_MINUTECONTENT_FAILURE, error } }
}

function deleteMinuteGroups(boardId, minutesIds){
  return dispatch => {

    var promiseArray = []
    minutesIds.forEach((minutesId)=>{
      promiseArray.push(
        new Promise(function(resolve, reject) {
          dispatch(request(minutesId));
          if(minutesId.includes("draft")){
            minutesService.deleteCachedMinute(minutesId)
              .then(
                item => {
                  dispatch(successCache(boardId, minutesId));
                  resolve();
                },
                error => {
                  reject(error)
                }
              );
          }else{
            minutesService.deleteMinute(minutesId)
              .then(
                item => {
                  // if(cacheId !== ""){
                  //   minutesService.deleteCachedMinute(cacheId)
                  //     .then(
                  //       item => {
                  //         dispatch(successCache(boardId, cacheId));
                  //         dispatch(success(minutesId, boardId));
                  //       },
                  //       error => {
                  //         dispatch(success(minutesId, boardId));
                  //       }
                  //     );
                  // }else{
                    dispatch(success(minutesId, boardId));
                    resolve();
                  //}
                },
                error => {
                  reject(error)
                }
              );
          }
        })
      )
    })

    Promise.all(promiseArray)
    .then(()=>{
      dispatch(boardActions.getBoardMinutes(boardId));
    })
    .catch((e)=>{
      dispatch(failure(e))
      dispatch(boardActions.getBoardMinutes(boardId));
    })
  };

  function successCache(boardId, id) { return { type: minutesConstants.DELETE_MINUTECACHED_SUCCESS, boardId, id } }

  function request(minutesId) { return { type: minutesConstants.DELETE_MINUTECONTENT_REQUEST, minutesId } }
  function success(minutesId, boardId) { return { type: minutesConstants.DELETE_MINUTECONTENT_SUCCESS, minutesId, boardId } }
  function failure(error) { return { type: minutesConstants.DELETE_MINUTECONTENT_FAILURE, error } }
}

function deleteMinute(boardId, minutesId){
  return (dispatch, getState) => {
    const auth = getState().authentication
    minutesService.deleteMinute(minutesId)
      .then(
        item => {
          // TrackEvent("f_board_minutesdeletedbutton.clicked",{
          //   user_id: auth.userId,
          //   person_id: auth.personId,
          //   company_id: auth.customerId,
          //   alias: auth.alias,
          //   minutes_id: minutesId,
          //   board_id: boardId,
          //   minutes_selected_state: minutes.status,
          // })

          history.push({
            pathname: RoutesConstants.minuteboard,
            query: { boardId: boardId }
          });
        },
        error => dispatch(failure(error))
      );
  };
}

function copyBinderToMinutes(copy){
  return dispatch => {
    dispatch(binderrequest(copy.binderId));
    dispatch(request());
    binderService.populateBinderContent(copy.binderId)
      .then(
        item => {
          dispatch(bindersuccess(item));
          dispatch(success());
          history.push({
            pathname: RoutesConstants.minutenew,
            query: { board: {
              boardId: copy.boardId,
              boardname: copy.boardName,
              binderId: copy.binderId,
              copy: true,
              options: copy.copyOptions
            }}
          });
        },
        error => {
          dispatch(binderfailure(id, error))
          dispatch(failure(error))
        }
      );
  };

  function binderrequest(binderId) { return { type: binderConstants.POPULATE_BINDERCONTENT_REQUEST, binderId } }
  function bindersuccess(item) { return { type: binderConstants.POPULATE_BINDERCONTENT_SUCCESS, item } }
  function binderfailure(binderId, error) { return { type: binderConstants.POPULATE_BINDERCONTENT_FAILURE, binderId, error } }

  function request() { return { type: minutesConstants.COPY_BINDERFORMINTUES_REQUEST } }
  function success() { return { type: minutesConstants.COPY_BINDERFORMINTUES_SUCCESS } }
  function failure(error) { return { type: minutesConstants.COPY_BINDERFORMINTUES_FAILURE, error } }
}

function copyMinutes(copy){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    var keys = getState().authentication.keys;
    var kUser = null, pUserGenSec = null;
    if(keys !== undefined){
      if(keys[customerId] !== undefined){
        if(keys[customerId].kUser !== undefined){
          kUser = keys[customerId].kUser;
        }
        if(keys[customerId].pUserGenSec !== undefined){
          pUserGenSec = keys[customerId].pUserGenSec;
        }
      }
    }

    function getMinutes(item){
      minutesService.populateMinutes(item)
        .then(
            items => {
              dispatch(success(item.boardId, items))
              history.push({
                pathname: RoutesConstants.minutenew,
                query: { board: {
                  boardId: item.boardId,
                  //boardname: copy.boardName,
                  minutesId: item.minutesId,
                  copy: true,
                  options: copy.copyOptions
                }}
              });
            },
            error => dispatch(failure(item.minutesId, error))
        );
    }

    dispatch(request(copy.minutesId));
    if(pUserGenSec === null){
      //dispatch(requestUser({customerId: customerId, kUser: null}));

      userService.getGenPrivKey(customerId, kUser)
      .then(
        payload => {
          var item = {
            kUser: payload.kUser,
            minutesId: copy.minutesId,
            boardId: copy.boardId,
          }

          getMinutes(item);
          dispatch(successUser(payload))
        },
        error => {
          dispatch(failureUser(error));
        }
      );
    }else{
      var item = {
        kUser: pUserGenSec,
        minutesId: copy.minutesId,
        boardId: copy.boardId,
      }

      getMinutes(item);
    }
  };

  function requestUser(payload) { return { type: userConstants.GET_PGENERIC_KEY_REQUEST, payload } }
  function successUser(payload) { return { type: userConstants.GET_PGENERIC_KEY_SUCCESS, payload } }
  function failureUser(error) { return { type: userConstants.GET_PGENERIC_KEY_FAILURE, error } }

  function request(minutesId) { return { type: minutesConstants.GET_MINUTES_REQUEST, minutesId } }
  function success(boardId, minutes) { return { type: minutesConstants.GET_MINUTES_SUCCESS, boardId, minutes } }
  function failure(minutesId, error) { return { type: minutesConstants.GET_MINUTES_FAILURE, minutesId, error } }
}

function downloadUserFeedBack(documentitem){
  return dispatch => {
    if(documentitem.original){
      documentitem.processType = webConstants.DOWNLOAD_STORE;
      dispatch(queueActions.downloadDocument(documentitem));
    }else{
      minutesService.getFeedback(documentitem)
        .then(
          newData => {
            documentitem.kUser = newData.newKey;
            documentitem.processType = webConstants.DOWNLOAD_STORE;
            dispatch(queueActions.downloadDocument(documentitem));
          },
          error => {
            //dispatch(failure(documentitem.documentId, error))
          }
        );
    }
  };
}

function exportMinutes(minutesId, boardId, type){
  return (dispatch, getState) => {
    const state = getState()
    const customerId = state.authentication.customerId
    var keys = state.authentication.keys;
    var kUser = null, pUserGenSec = null;
    if(keys !== undefined){
      if(keys[customerId] !== undefined){
        if(keys[customerId].kUser !== undefined){
          kUser = keys[customerId].kUser;
        }
        if(keys[customerId].pUserGenSec !== undefined){
          pUserGenSec = keys[customerId].pUserGenSec;
        }
      }
    }

    dispatch(request(minutesId));

    function getMinutes(item){
      minutesService.populateMinutes(item)
        .then(
          items => {
            minutesService.generateExport(items, state)
              .then(
                exportDoc => {
                  if (type === "docx") {
                    var MyDoc = new reportLib.SummaryReportDocx();
                    MyDoc.Generate(exportDoc)
                      .then((blob) => {
                        DownloadBlob(exportDoc.filename + ".docx", blob)
                        dispatch(successPop(item.boardId, items))
                        dispatch(success(minutesId))
                      })
                      .catch(e => {
                        log("generateDocX", e)
                      })
                  } else {
                    var MyDoc = new reportLib.SummarReportPDFMake()
                    MyDoc.Generate(exportDoc)
                      .then((pdfcode) => {
                        // const pdfDocGenerator = pdfMake.createPdf(pdfcode)
                        reportLib.GeneratePDFBuffer(pdfcode)
                          .then((data) => {
                            var blob = new Blob([data], { type: "application/pdf" })
                            DownloadBlob(exportDoc.filename + ".pdf", blob)
                            dispatch(successPop(item.boardId, items))
                            dispatch(success(minutesId))
                          });
                      })
                      .catch(e => {
                        log("generatePdf", e)
                      })
                  }
                },
                    error => dispatch(failurePop(item.minutesId, error))
                );
            },
            error => dispatch(failurePop(item.minutesId, error))
        );
    }

    dispatch(requestPop(minutesId));
    if(pUserGenSec === null){
      //dispatch(requestUser({customerId: customerId, kUser: null}));

      userService.getGenPrivKey(customerId, kUser)
      .then(
        payload => {
          var item = {
            kUser: payload.kUser,
            minutesId: minutesId,
            boardId: boardId,
          }

          getMinutes(item);
          dispatch(successUser(payload))
        },
        error => {
          dispatch(failureUser(error));
        }
      );
    }else{
      var item = {
        kUser: pUserGenSec,
        minutesId: minutesId,
        boardId: boardId,
      }

      getMinutes(item);
    }
  };

  function requestUser(payload) { return { type: userConstants.GET_PGENERIC_KEY_REQUEST, payload } }
  function successUser(payload) { return { type: userConstants.GET_PGENERIC_KEY_SUCCESS, payload } }
  function failureUser(error) { return { type: userConstants.GET_PGENERIC_KEY_FAILURE, error } }

  function requestPop(minutesId) { return { type: minutesConstants.GET_MINUTES_REQUEST, minutesId } }
  function successPop(boardId, minutes) { return { type: minutesConstants.GET_MINUTES_SUCCESS, boardId, minutes } }
  function failurePop(minutesId, error) { return { type: minutesConstants.GET_MINUTES_FAILURE, minutesId, error } }

  function request(minutesId) { return { type: minutesConstants.EXPORT_MINTUES_REQUEST, minutesId } }
  function success(minutes) { return { type: minutesConstants.EXPORT_MINTUES_SUCCESS, minutes } }
  function failure(minutesId, error) { return { type: minutesConstants.EXPORT_MINTUES_FAILURE, minutesId, error } }

}
