import { boardConstants, userConstants } from '@constants/appuser/';
import { boardService } from '@services/appuser/';
import { statusActions } from '@actions/appuser/'
import { history } from '@lib';
import { checkDemo, STATUS_ITEM } from '@lib/simpletools';
import { RoutesConstants } from '@constants/common.constants';

export const boardActions = {
  previewBoard,
  previewBoardHome,
  previewBinder,
  populateBoard,
  populateFiles,
  getBoardCalendars,
  openBoard,
  closeBoard,
  setBoard,
};

function previewBoard() {
  return (dispatch) => {
    dispatch(request());

    boardService.previewBoard()
      .then(
        payload => {
          dispatch(success(payload))
        },
        error => {
          dispatch(failure(error));
        }
      );
  }

  function request() { return { type: boardConstants.PREVIEW_BOARD_REQUEST } }
  function success(payload) { return { type: boardConstants.PREVIEW_BOARD_SUCCESS, payload } }
  function failure(error) { return { type: boardConstants.PREVIEW_BOARD_FAILURE, error } }
}

function previewBoardHome() {
  return (dispatch) => {
    dispatch(request());

    dispatch(selectCustomer("", ""))

    boardService.previewBoard()
      .then(
        payload => {
          dispatch(success(payload))

          let timeout = 3 * 60
          payload.forEach(o => {
            if(timeout < o.boardSettings.secondsForInAppTimeout)
              timeout = o.boardSettings.secondsForInAppTimeout
          })
          dispatch(setTimeout(timeout))
        },
        error => {
          dispatch(failure(error));
        }
      );
  }

  function request() { return { type: boardConstants.PREVIEW_BOARD_REQUEST } }
  function success(payload) { return { type: boardConstants.PREVIEW_BOARD_SUCCESS, payload } }
  function failure(error) { return { type: boardConstants.PREVIEW_BOARD_FAILURE, error } }

  function selectCustomer(customerId, boardId, secondsForInAppTimeout) { return { type: userConstants.SWITCH_CUSTOMER, customerId, boardId, secondsForInAppTimeout }}
  function setTimeout(secondsForInAppTimeout) { return { type: userConstants.SET_TIMEOUT, secondsForInAppTimeout }}
}

function previewBinder(boardId) {
  return (dispatch, getState) => {
    const board = getState().board.boards[boardId]
    dispatch(request(boardId));

    boardService.previewBinder(boardId)
      .then(
        payload => {
          dispatch(success(boardId, payload))
        },
        error => {
          dispatch(failure(boardId, error));
        }
      );
  }

  function request(boardId) { return { type: boardConstants.PREVIEW_BOARDBINDER_REQUEST, boardId } }
  function success(boardId, payload) { return { type: boardConstants.PREVIEW_BOARDBINDER_SUCCESS, boardId, payload } }
  function failure(boardId, error) { return { type: boardConstants.PREVIEW_BOARDBINDER_FAILURE, boardId, error } }
}

function openBoard(boardId, redirect = "") {
  return (dispatch, getState) => {
    const board = getState().board.boards[boardId]
    dispatch(request(boardId));

    boardService.previewBinder(boardId)
      .then(
        payload => {
          dispatch(success(boardId, payload))
          if(redirect !== ""){
            const secondsForInAppTimeout = board.boardSettings.secondsForInAppTimeout
            dispatch(selectCustomer(board.customerId, boardId, secondsForInAppTimeout))
            dispatch(statusActions.changeStatus(boardId, STATUS_ITEM.READ))
            history.push({
              pathname: RoutesConstants.board,
              query: {
                boardId: boardId,
              }
            });
          }
        },
        error => {
          dispatch(failure(boardId, error));
        }
      );
  }

  function request(boardId) { return { type: boardConstants.PREVIEW_BOARDBINDER_REQUEST, boardId } }
  function success(boardId, payload) { return { type: boardConstants.PREVIEW_BOARDBINDER_SUCCESS, boardId, payload } }
  function failure(boardId, error) { return { type: boardConstants.PREVIEW_BOARDBINDER_FAILURE, boardId, error } }

  function selectCustomer(customerId, boardId, secondsForInAppTimeout) { return { type: userConstants.SWITCH_CUSTOMER, customerId, boardId, secondsForInAppTimeout }}
}

function closeBoard() {
  return (dispatch, getState) => {
    const boards = getState().board.boards

    let timeout = 3 * 60
    for (const [key, value] of Object.entries(boards)) {
      if(timeout < value.boardSettings.secondsForInAppTimeout)
        timeout = value.boardSettings.secondsForInAppTimeout
    }

    dispatch(selectCustomer("", "", timeout))
  }

  function selectCustomer(customerId, boardId, secondsForInAppTimeout) { return { type: userConstants.SWITCH_CUSTOMER, customerId, boardId, secondsForInAppTimeout }}
}

function populateBoard(boardId) {
  return (dispatch) => {
    dispatch(request(boardId));

    boardService.populateBoard(boardId)
      .then(
        payload => {
          dispatch(success( payload))
        },
        error => {
          dispatch(failure(boardId, error));
        }
      );
  }

  function request(boardId) { return { type: boardConstants.POPULATE_BOARD_REQUEST, boardId } }
  function success(payload) { return { type: boardConstants.POPULATE_BOARD_SUCCESS, payload } }
  function failure(boardId, error) { return { type: boardConstants.POPULATE_BOARD_FAILURE, boardId, error } }
}

function populateFiles(boardId) {
  return (dispatch) => {
    dispatch(request(boardId));

    boardService.populateFiles(boardId)
      .then(
        payload => {
          dispatch(success(boardId, payload))
        },
        error => {
          dispatch(failure(boardId, error));
        }
      );
  }

  function request(boardId) { return { type: boardConstants.POPULATE_BOARDFILES_REQUEST, boardId } }
  function success(boardId, payload) { return { type: boardConstants.POPULATE_BOARDFILES_SUCCESS, boardId, payload } }
  function failure(boardId, error) { return { type: boardConstants.POPULATE_BOARDFILES_FAILURE, boardId, error } }
}

function getBoardCalendars(query) {
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    if(checkDemo('getBoardCalendars'+query.calendarId)){
      const boards = getState().board.boards
      if(query.boardId !== undefined && boards[query.boardId] && boards[query.boardId].calendar !== undefined){
        let list = []

        var dateStart = moment("2015-01-01 00:00:00");
        var dateFinish = moment();
        try{
          if(query.startDate !== ""){
            dateStart = moment(new Date(query.startDate));
            dateStart.set({
                hour:   0,
                minute: 0,
                second: 0
            });
          }

          if(query.finishDate !== ""){
            dateFinish = moment(new Date(query.finishDate));
            dateFinish.set({
                hour:   23,
                minute: 59,
                second: 59
            });
          }
        }catch(e){

        }

        boards[query.boardId].calendar.forEach(item => {
          try{
            const c = moment(new Date(item.startTime));
            if(c.isAfter(dateStart) && c.isBefore(dateFinish))
              list.push(item)
          }catch(e){

          }
        })
        dispatch(success(list, query.boardId, customerId))
        return
      }
    }
    dispatch(request(query, customerId));
    boardService.getBoardCalendars(query)
      .then(
        list => dispatch(success(list, query.boardId, customerId)),
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(error, customerId))
        }
      );
  };

  function request(query, customerId) { return { type: boardConstants.GET_CALENDAR_REQUEST, query, customerId } }
  function success(list, boardId, customerId) { return { type: boardConstants.GET_CALENDAR_SUCCESS, list, boardId, customerId } }
  function failure(error, customerId) { return { type: boardConstants.GET_CALENDAR_FAILURE, error, customerId } }
}

function setBoard(customerId, boardId) {
  return (dispatch) => {
    dispatch(selectCustomer(customerId, boardId));
  }

  function selectCustomer(customerId, boardId, secondsForInAppTimeout) { return { type: userConstants.SWITCH_CUSTOMER, customerId, boardId, secondsForInAppTimeout }}
}
