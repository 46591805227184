import fetch from '@lib/fetch-retry';
import { authHeader, GetURL, GetWebSocURL, getVersion, LogoutAndRedirect, handleJsonRes, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch, handleCatchCode, ErrorType, BlockLogout } from '@lib/';

export const adminPermissionsService = {
    getAdminMembershipsForBoard,
    savePermission,
    updatePermission,
    removePermission,
}

function getAdminMembershipsForBoard(boardId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(GetURL() + `AdminMemberships/Board/${boardId}`, requestOptions)
        .then((response) => {
            if (response.status === 403) {
                return Promise.reject("")
            }
            return response.json();
        })
        .catch(handleCatch);
}

function savePermission(permission) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(permission)
    };

    return fetch(GetURL() + 'AdminMemberships', requestOptions)
        .then(handleJsonResponse)
        .catch(handleCatch);
}

function updatePermission(permission) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(permission)
    };

    return fetch(GetURL() + `AdminMemberships/${permission.id}`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                return Promise.reject();
            }
            return response.ok;
        })
        .catch(handleCatch);
}

function removePermission(adminPermissionId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(GetURL() + `AdminMemberships/${adminPermissionId}`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                return Promise.reject();
            }
            return response.ok;
        })
        .catch(handleCatch);
}
