import { fileConstants, webConstants } from '@constants/appuser';
import { fileService } from '@services/appuser';
import { queueActions } from './';
import { CheckInternet, DownloadBlob } from '@lib/simpletools';

export const fileActions = {
  demoGetImage,
  GetImage,
  DownloadFile,
  PopulateFile,
  CompletedFileDownload,
};

function demoGetImage(id, data){
  return dispatch => {
    dispatch(success({
      image: data, id: id
    }))
  };

  function success(payload) { return { type: fileConstants.DOWNLOAD_IMAGE_SUCCESS, payload } }
}

function GetImage(imageid) {
  return dispatch => {
    dispatch(request(imageid));
    fileService.GetImage(imageid)
      .then(
        payload => dispatch(success(payload)),
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(imageid,error))
        }
      );
  };

  function request(imageid) { return { type: fileConstants.DOWNLOAD_IMAGE_REQUEST, imageid } }
  function success(payload) { return { type: fileConstants.DOWNLOAD_IMAGE_SUCCESS, payload } }
  function failure(imageid, error) { return { type: fileConstants.DOWNLOAD_IMAGE_FAILURE, imageid, error } }
}

function DownloadFile(fileitem) {
  return (dispatch) => {
    if(window.demo){
      fileService.Download(fileitem.documentId)
      .then(
        file => {
          fileitem.data = file.data
          dispatch(CompletedFileDownload(fileitem))
          DownloadBlob(
            fileitem.fileName,
            file.data
          );
        },
          error => dispatch(failure(error))
      );
      return
    }
    fileitem.processType = webConstants.DOWNLOAD_FILE
    dispatch(queueActions.downloadFile(fileitem));
  };
}

function PopulateFile(fileitem) {
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    const viewAs = getState().authentication.viewAs
    const keys = getState().authentication.keys

    let id = viewAs !== '' ? viewAs : customerId
    if(customerId === undefined || customerId === "") return
    if(keys[id].kUser === undefined || keys[id].kUser === undefined) return

    fileitem.kUser = keys[id].kUser

    fileitem.processType = webConstants.DOWNLOAD_STORE
    dispatch(queueActions.downloadFile(fileitem));
  };
}

function CompletedFileDownload(fileitem){
  return dispatch => {
    dispatch(request(fileitem));
  };

  function request(fileitem) { return { type: fileConstants.COMPLETE_DOWNLOAD_FILE_REQUEST, fileitem } }
}
