/* global appConfig */
import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import { userActions } from '@actions/admin';

import { DeviceFingerprint, ValidateEmail } from '@lib/simpletools';
import { Button, TextInput } from '@common/admin';
import { LoadingIndicator, TextInput as AnsaradaTextInput } from '@ansarada/ace-react';
import {getVersion} from '@lib/url';

import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { RoutesConstants } from '@constants/common.constants';

import {keysStorage} from '@lib/indexeddb';

import logo from '../../assets/images/logo/Ansarada Board Product Logo Black RGB.svg';
import ansaradaLogo from '../../assets/images/logo/Ansarada Logo Inline Black RGB.svg';
import '../loginpage/loginapp.css';

const EmailPage = (props) => {
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [validEmail, setValidEmail] = useState(false)
  const [isCheckingEmail, setCheckingEmail] = useState(false)

  const dispatch = useDispatch()
  const { executeRecaptcha } = useGoogleReCaptcha();

  const emailOutFocus = () => {
    if(email !== ""){
      let s = false
      if(ValidateEmail(email)){
        s = true
      }
      setError(s?"":"Invalid email")
      setValidEmail(s)
    }
  }

  const handleEmailChange = (e) => {
    const value = e.split('').filter(char => /[a-zA-Z0-9@+'._~-]/.test(char)).join('')
    setEmail(value)
    setError("")
    if(value === "") setValidEmail(false)
    else {
      let s = false
      if(ValidateEmail(value)){
        s = true
      }
      setValidEmail(s)
    }
  }

  const handleSubmit = email => {
    executeRecaptcha("homepage").then(token=>{
      dispatch(userActions.trailCheckEmail(email, token))
    }).catch(err=>console.log(err))
  };

  useEffect(()=>{
    setCheckingEmail(props.checkEmail !== undefined && props.checkEmail === true)
    if(props.existingUser === true && props.username !== undefined && props.username !== "" &&
        props.clientId !== undefined && props.clientId !== ""){
      props.history.push(RoutesConstants.signupexistinguser)
    }
  },[props.checkEmail, props.existingUser, props.username, props.clientId])

  return (
    <div className="logboxContent page">
      <div className="loginbox">
        <div className="login-lock-header">
          <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img style={{ width: '50%', marginTop: '20px' }} src={ansaradaLogo} />
            <img style={{ width: '25%', marginTop: '30px', marginBottom: '50px' }} src={logo} />
          </span>
          <h1>Board management software designed to give boards total confidence</h1>
          <div className="signInput">
            {props.error !== undefined && props.error !== "" && (
              <div className="login-error-msgapp-freemium">
                <span className="animated fadeInUp">
                  <span>{props.error}</span>
                </span>
              </div>
            )}
            <AnsaradaTextInput
              placeholder="Enter your email address"
              type="Email"
              name="email"
              value={email}
              onChangeValue={handleEmailChange}
              onBlur={emailOutFocus}
              />
          </div>
          <div className="signUpError">
            {error}
          </div>
          <div>
            <Button
              isLoading={isCheckingEmail}
              isDisabled={!validEmail}
              onClick={()=>{
                if(validEmail) handleSubmit(email)
              }}
              >Sign up</Button>
          </div>
          <label className="signUpFooter">No credit card required</label>
        </div>
      </div>
      <div className="signUpAlready">
        <label>Already have an account? <a href={RoutesConstants.login}>Log in</a>.</label>
        <label>By signing up, you agree to our <a href="https://www.ansarada.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>, <a href="https://www.ansarada.com/terms-conditions" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and <a href="https://www.ansarada.com/gdpr" target="_blank" rel="noopener noreferrer">GDPR information</a>.</label>
        <label>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a> apply.</label>
      </div>
    </div>
  )
}

const SendEmail = (props) => {
  return (
    <div className="logboxContent page">
      <div className="loginbox">
        <div className="login-lock-header">
          <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img style={{ width: '50%', marginTop: '20px' }} src={ansaradaLogo} />
            <img style={{ width: '25%', marginTop: '30px', marginBottom: '50px' }} src={logo} />
          </span>
          <h1>You're ready to start</h1>
          <label>We have sent an email to {props.username} with a link to verify your email address.</label>
        </div>
      </div>
      <div className="signUpAlready">
        <label>Already have an account? <a href={RoutesConstants.login}>Log in</a></label>
        <label>By signing up, you agree to our <a href="https://www.ansarada.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>, <a href="https://www.ansarada.com/terms-conditions" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and <a href="https://www.ansarada.com/gdpr" target="_blank" rel="noopener noreferrer">GDPR information</a>.</label>
      </div>
    </div>
  )
}

const ExistingUserLogin = (props) => {
  return (
    <div className="logboxContent page">
      <div className="loginbox">
        <div className="login-lock-header">
          <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img style={{ width: '50%', marginTop: '20px' }} src={ansaradaLogo} />
            <img style={{ width: '25%', marginTop: '30px', marginBottom: '50px' }} src={logo} />
          </span>
          <h1>Hello {props.username}</h1>
          <label><a href={RoutesConstants.login} style={{color: "#1e8c0d", fontSize: 'bold'}}>Log in</a> to access your Ansarada profile</label>
        </div>
      </div>
    </div>
  )
}

class SignUpClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      hasAccepted: false,
    }
  }

  componentDidMount() {
    ////keysStorage.Delete('lastUser')
    //keysStorage.Put({id: 'lastUser', key: {}}).then(()=>{})

    if(localStorage.getItem('acceptCookies'))
      this.setState({hasAccepted: true})
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.existingUser === false && nextProps.username !== undefined && nextProps.username !== ""){
      return {
        page: 1
      }
    }else if(nextProps.existingUser === true && nextProps.username !== undefined && nextProps.username !== "" && nextProps.clientId === undefined){
      return {
        page: 2
      }
    }

    return null
  }

  acceptCookies(){
    localStorage.setItem('acceptCookies', "true")
    this.setState({ hasAccepted: true })
  }

  render(){
    return (
      <div className="page spacebetween content logContent shadeWhite">
        {this.state.page === 0 &&
          <GoogleReCaptchaProvider reCaptchaKey={appConfig.googleRecaptchaSiteKey}>
            <EmailPage
              {...this.props}
            />
          </GoogleReCaptchaProvider>
        }
        {this.state.page === 1 &&
          <SendEmail
          {...this.props}
          />
        }
        {this.state.page === 2 &&
          <ExistingUserLogin
          {...this.props}
          />
        }
        {this.state.hasAccepted &&
          <div className="cookieAccept">
            <div className="cookieText">
              <label>Use of this site accepts the <a href="https://www.ansarada.com/cookie-policy" className="cookieLink">use of cookies</a>.</label>
              <div className="cookieBut">
                <Button type="clear" onClick={this.acceptCookies.bind(this)}>Ok</Button>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    checkEmail: state.authentication.checkEmail,
    username: state.authentication.username,
    existingUser: state.authentication.existingUser,
    clientId: state.authentication.clientId,
    error: state.authentication.error
  };
}

const connectedSignUpPage = connect(mapStateToProps)(SignUpClass);
export { connectedSignUpPage as SignUp };
