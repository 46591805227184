import { authHeader, GetURL, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch } from '@lib';

import fetch from '@lib/fetch-retry';

export const deviceService = {
  getDeviceDetails,
  setEnableDisableDevice,
  giveNewDeviceApproval,
};

function getDeviceDetails(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch(GetURL() + 'Devices/' + id, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function setEnableDisableDevice(item) {
  const requestOptions = {
    method: 'PATCH',
    headers: authHeader(),
    body: JSON.stringify(item)
  };
  return fetch(GetURL() + 'Devices/' + item.id, requestOptions).then(handleStandResponse).catch(handleCatch);
}

function giveNewDeviceApproval(item) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(item)
  };
  return fetch(GetURL() + 'Register/2FA/Approve', requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}
