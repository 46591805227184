import React from 'react';
import { connect } from 'react-redux';

import { LoginBoxDialogBase } from './loginBox'

import { userActions } from '@actions/appuser';
import { DeviceFingerprint } from '@lib/simpletools';
import { RoutesConstants } from '@constants/common.constants';

class LoginBoxApp extends LoginBoxDialogBase {
  constructor(props) {
    super(props);

    this.onExit = this.onExit.bind(this);
    this.Submit = this.Submit.bind(this);
  }

  componentDidMount() {
    this.timerId = setTimeout(this.getKey, 20);
//console.log("LoginBoxDialog",this.props)
    if (this.props.universalLogin === true) { //&& this.props.universalRedirect === true){
      this.props.dispatch(userActions.Auth0Refresh())
    }
  }

  onExit(){
    this.setState({
      loggingOut: true
    }, () => {
      this.props.dispatch(userActions.logout(this.state.deviceId));
    })
    // window.location.href=RoutesConstants.logout;
    // if(this.props.history !== undefined) this.props.history.push("/login");
  }

  SendPost(username, deviceId, deviceHash, phash){
    const send = (id, hash) => {
      var loginReqest = {
        alias: this.props.alias,
        username: username,
        deviceId: id,
        deviceHash: hash,
        password: phash,
        keys: true,
        mode: this.props.mode,
      }
      this.props.dispatch(userActions.loginWithOutRedirect(loginReqest));
      this.setState({phash: ''});
    }
    if(deviceHash === ""){
      DeviceFingerprint('lock')
      .then((ihash) => {
        if(!this.state.block)
          this.setState({deviceHash: ihash});

        send(deviceId, ihash)
      })
      .catch(function(error) {
      });
    }else{
      send(deviceId, deviceHash)
    }
  }

  render(){
    return super.render()
  }
}

function mapStateToProps(state) {
  const  { alias, customerId, username, deviceId, deviceHash, error, failed, keys, loggedIn, loggingIn, lockScreen, mode, universalLogin, universalRedirect } = state.authentication;
  return {
    alias,
    customerId,
    username,
    deviceId,
    deviceHash,
    keys,
    error,
    failed,
    loggingIn,
    lockScreen,
    loggedIn,
    mode,
    universalLogin,
    universalRedirect,
  };
}

const connectedLoginBoxDialogApp = connect(mapStateToProps)(LoginBoxApp);
export { connectedLoginBoxDialogApp as LoginBoxDialog };
