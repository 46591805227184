import { binderConstants, userConstants, boardConstants, customerConstants } from '@constants/admin/';
import { commonConstants } from '@constants';
import { BinderStatus } from '@constants/common.constants';

export var BINDER_INITIAL_STATE = {
  id:"",
  loading: true,
  populating: false,
  hasPopulated: false,
//  processing: false,
  error:"",

  boardId: '',
  //cacheId: '',
  activeTransactionIds: [],
  transactionEvents: [],
  isCached: false,
  minuteId: '',
  saving: false,
  name: '',
  modifiedName: '',
  modifiedMeetingDate: '',
  modifiedThumbnailImageDownloadId: '',
  meetingDate: '',
  expiryDate: '',
  creationDate: '',
  updateDate: '',
  locationName: '',
  locationLatitude: '',
  locationLongitude: '',
  itemIds: [],
  itemCount: 0,
  modifiedItemCount: null,
  settings: null,
  attendeeIds: [''],
  recipientIds: [''],
  inviteeIds: [''],
  countryBlacklist: [],
  calendarId: "",
  thumbnailImageDownloadId: '',
  usageIds: [],
  binderStatus: '',
  published: false,
  deleteNotesAfterMeeting: false,
  deleteAnnotationsAfterMeeting: false,
  deleteNotesAfterDays: 0,
  deleteAnnotationsAfterDays: 0,
  isDeleted: false,
  lockUserId: '',
  lockExpired: '',
  lockType: '',
  lockProgress: 0,
  lastErrorCode: '',
  lastErrorDate: '',
  lastErrorDetail: null,
  dto: null,
  isDocumentsCopying: false
};

function SetBinders(state, items){
  if(items.binders !== undefined){
    for(var key in items.binders){
      state = SetBinderDetail(state, items.binders[key])
    }
  }
  return state
}

function SetBinder(state, binderId, populating){//, processing = undefined){
  if(state[binderId] === undefined)
    state[binderId] = Object.assign({}, BINDER_INITIAL_STATE);
  state[binderId].id = binderId;
  state[binderId].loading = true;
  if(populating)
    state[binderId].populating = true;
  state[binderId].error = '';
  //if(processing !== undefined){
  //  state[binderId].processing = processing;
  //}
  return state;
}

function SetBinderDetail(state, item){
  if(item === undefined) return state;
  if(item.id === undefined) return state;
  if(state[item.id] === undefined){
    state[item.id] = Object.assign({}, BINDER_INITIAL_STATE);
    state[item.id].id = item.id;
  }
  state[item.id].error = "";
  state[item.id].loading = true;

  if(item.hasOwnProperty('name'))
    state[item.id].name = item.name;
  if(item.hasOwnProperty('modifiedThumbnailImageDownloadId'))
    state[item.id].modifiedThumbnailImageDownloadId = item.modifiedThumbnailImageDownloadId;
  if(item.hasOwnProperty('modifiedName'))
    state[item.id].modifiedName = item.modifiedName;
  if(item.hasOwnProperty('modifiedMeetingDate'))
    state[item.id].modifiedMeetingDate = item.modifiedMeetingDate;
  if(item.hasOwnProperty('meetingDate'))
    state[item.id].meetingDate = item.meetingDate;
  if(item.hasOwnProperty('expiryDate'))
    state[item.id].expiryDate = item.expiryDate;
  if(item.hasOwnProperty('modifiedItemCount'))
    state[item.id].modifiedItemCount = item.modifiedItemCount;
  if(item.hasOwnProperty('displayItemCount')){
    state[item.id].displayItemCount = item.displayItemCount;
  }
  if(item.hasOwnProperty('itemIds'))
    state[item.id].itemCount = item.itemIds.length;
  else if(item.hasOwnProperty('itemCount'))
    state[item.id].itemCount = item.itemCount;
  else if(item.hasOwnProperty('items'))
    state[item.id].itemCount = item.items.length;
  if(item.hasOwnProperty('binderStatus'))
    state[item.id].binderStatus = item.binderStatus.toLowerCase();
  if(item.hasOwnProperty(BinderStatus.published))
    state[item.id].published = item.published;
  if(item.hasOwnProperty('isDeleted'))
    state[item.id].isDeleted = item.isDeleted;
  if(item.hasOwnProperty('activeTransactionIds'))
    state[item.id].activeTransactionIds = item.activeTransactionIds;
  if (item.hasOwnProperty('transactionEvents'))
    state[item.id].transactionEvents = item.transactionEvents;
  if(item.hasOwnProperty('lockType'))
    state[item.id].lockType = item.lockType;

  if(item.addedItemCount !== undefined && item.updatedItemCount !== undefined && item.deletedItemCount !== undefined &&
      item.addedItemsProcessedCount !== undefined && item.updatedItemsProcessedCount !== undefined && item.deletedItemsProcessedCount !== undefined){
    const total = item.addedItemCount + item.updatedItemCount +item.deletedItemCount
    const done = item.addedItemsProcessedCount + item.updatedItemsProcessedCount +item.deletedItemsProcessedCount

    state[item.id].lockProgress = Math.floor(done/total*100)
    if(state[item.id].lockProgress > 100) state[item.id].lockProgress = 100
  }

  return state;
}

function BinderDashUpdate(state, item) {
  if(!state[item.id]) return;

  if (item.hasOwnProperty('excludeFromCurrentToPreviousMove')) {
    state[item.id].excludeFromCurrentToPreviousMove = item.excludeFromCurrentToPreviousMove;
  }
  if (item.hasOwnProperty('binderMoveDate') && item.binderMoveDate !== commonConstants.SET_NULL_DATE) {
    state[item.id].binderMoveDate = item.binderMoveDate;
  } else {
    if (item.binderMoveDate == commonConstants.SET_NULL_DATE) {
      state[item.id].binderMoveDate = null;
    }
  }

  return state;
}

function ClearBinderMoveDate(state, item) {
  if (!item) { return; }

  if (state[item.id]) {
    state[item.id].binderMoveDate = null;
  }

  return state;
}

function UpdateBinder(state, item, populating, resetLoading = true, changeModifiedItem = true){
  if(state[item.id] === undefined){
    state[item.id] = Object.assign({}, BINDER_INITIAL_STATE);
    if(!resetLoading)
      state[item.id].loading = false;
  }
  state[item.id].error = "";
  var update = true;
  if(resetLoading)
    state[item.id].loading = false;
  else{
    if(state[item.id].loading)
      update = false;
  }

  if(populating){
    state[item.id].populating = false;
    state[item.id].hasPopulated = true;
    state[item.id].dto = Object.assign({}, item);
    if(state[item.id].dto.transactionEvents)
      delete state[item.id].dto.transactionEvents
  }

  //Object.keys(item).map(e => state[item.id][e] = item[e]);
  if(item.hasOwnProperty('id'))
    state[item.id].id = item.id;
  if(item.hasOwnProperty('boardId'))
    state[item.id].boardId = item.boardId;
  if(item.hasOwnProperty('name') && update)
    state[item.id].name = item.name;
  if(item.hasOwnProperty('modifiedThumbnailImageDownloadId') && update && changeModifiedItem)
    state[item.id].modifiedThumbnailImageDownloadId = item.modifiedThumbnailImageDownloadId;
  if(item.hasOwnProperty('modifiedName') && update && changeModifiedItem)
    state[item.id].modifiedName = item.modifiedName;
  if(item.hasOwnProperty('modifiedMeetingDate') && update && changeModifiedItem)
    state[item.id].modifiedMeetingDate = item.modifiedMeetingDate;
  if(item.hasOwnProperty('meetingDate') && update)
    state[item.id].meetingDate = item.meetingDate;
  if(item.hasOwnProperty('expiryDate') && update)
    state[item.id].expiryDate = item.expiryDate;
  if(item.hasOwnProperty('creationDate'))
    state[item.id].creationDate = item.creationDate;
  if(item.hasOwnProperty('updateDate'))
    if(item.updateDate !== commonConstants.SET_NULL_DATE)
      state[item.id].updateDate = item.updateDate;
  if(item.hasOwnProperty('locationName'))
    state[item.id].locationName = item.locationName;
  else if(state[item.id].locationName === undefined)
    state[item.id].locationName = "";
  if(item.hasOwnProperty('activeTransactionIds'))
    state[item.id].activeTransactionIds = item.activeTransactionIds;
  if (item.hasOwnProperty('transactionEvents'))
    state[item.id].transactionEvents = item.transactionEvents;
  if(item.hasOwnProperty('locationLatitude'))
    state[item.id].locationLatitude = item.locationLatitude;
  if(item.hasOwnProperty('locationLongitude'))
    state[item.id].locationLongitude = item.locationLongitude;
  if(item.hasOwnProperty(BinderStatus.published))
    state[item.id].published = item.published;
  if(item.hasOwnProperty('deleteNotesAfterMeeting'))
    state[item.id].deleteNotesAfterMeeting = item.deleteNotesAfterMeeting;
  if(item.hasOwnProperty('deleteAnnotationsAfterMeeting'))
    state[item.id].deleteAnnotationsAfterMeeting = item.deleteAnnotationsAfterMeeting;
  if(item.hasOwnProperty('deleteNotesAfterDays'))
    state[item.id].deleteNotesAfterDays = item.deleteNotesAfterDays;
  if(item.hasOwnProperty('deleteAnnotationsAfterDays'))
    state[item.id].deleteAnnotationsAfterDays = item.deleteAnnotationsAfterDays;
  if(item.hasOwnProperty('itemCount') && update)
    state[item.id].itemCount = item.itemCount;
  if(item.hasOwnProperty('modifiedItemCount') && update && changeModifiedItem)
    state[item.id].modifiedItemCount = item.modifiedItemCount;
  if(item.hasOwnProperty('displayItemCount')){
    state[item.id].displayItemCount = item.displayItemCount;
  }
  if(item.hasOwnProperty('itemIds') && update){
    state[item.id].itemIds = item.itemIds;
    state[item.id].itemCount = item.itemIds.length;
  }
  if(item.hasOwnProperty('items') && update){
    state[item.id].itemIds = [];
    item.items.forEach(function(object){
      state[item.id].itemIds.push(object.id);
    });
    state[item.id].itemCount = item.items.length;
  }
  if(item.hasOwnProperty('settings'))
    state[item.id].settings = item.settings;
  if(item.hasOwnProperty('attendeeIds'))
    state[item.id].attendeeIds = item.attendeeIds;
  if(item.hasOwnProperty('attendees')){
    state[item.id].attendeeIds = [];
    item.attendees.forEach(function(object){
      state[item.id].attendeeIds.push(object.id);
    });
  }
  if(item.hasOwnProperty('inviteeIds'))
    state[item.id].inviteeIds = item.inviteeIds;
  if(item.hasOwnProperty('invitees')){
    state[item.id].inviteeIds = [];
    item.invitees.forEach(function(object){
      state[item.id].inviteeIds.push(object.id);
    });
  }
  if(item.hasOwnProperty('recipientIds'))
    state[item.id].recipientIds = item.recipientIds;
  if(item.hasOwnProperty('recipients')){
    state[item.id].recipientIds = [];
    item.recipients.forEach(function(object){
      state[item.id].recipientIds.push(object.id);
    });
  }
  if(item.hasOwnProperty('countryBlacklist'))
    state[item.id].countryBlacklist = item.countryBlacklist;
  if(item.hasOwnProperty('thumbnailImageDownloadId'))
    state[item.id].thumbnailImageDownloadId = item.thumbnailImageDownloadId;
  if(item.hasOwnProperty('usageIds'))
    state[item.id].usageIds = item.usageIds;
  if(item.hasOwnProperty('usage')){
    state[item.id].usageIds = [];
    item.usage.forEach(function(object){
      state[item.id].usageIds.push(object.id);
    });
  }
  if (item.hasOwnProperty('isSample')) {
    state[item.id].isSample = item.isSample;
  }

  if (item.hasOwnProperty('excludeFromCurrentToPreviousMove')) {
    state[item.id].excludeFromCurrentToPreviousMove = item.excludeFromCurrentToPreviousMove;
  }
  if (item.hasOwnProperty('binderMoveDate')) {
    state[item.id].binderMoveDate = item.binderMoveDate;
  }

  if(item.hasOwnProperty('lockUserId'))
    state[item.id].lockUserId = item.lockUserId;
  if(item.hasOwnProperty('lockExpired')){
    state[item.id].lockExpired = item.lockExpired;
  }
  if(item.hasOwnProperty('lockType'))
    state[item.id].lockType = item.lockType;
  if(item.calendarId !== undefined){
    state[item.id].calendarId = item.calendarId;
  }
  if(item.hasOwnProperty('binderStatus'))
    state[item.id].binderStatus = item.binderStatus.toLowerCase();
  if(item.hasOwnProperty('isDeleted'))
    state[item.id].isDeleted = item.isDeleted;
  if(item.calendarId !== undefined){
    state[item.id].calendarId = item.calendarId;
  }

  if(item.hasOwnProperty('lastErrorCode'))
    state[item.id].lastErrorCode = item.lastErrorCode;
  else state[item.id].lastErrorCode = ""
  if(item.hasOwnProperty('lastErrorDate'))
    state[item.id].lastErrorDate = item.lastErrorDate;
  else state[item.id].lastErrorDate = ""
  if(item.hasOwnProperty('lastErrorDetail')){
    try{
      const j = JSON.parse(item.lastErrorDetail)
      state[item.id].lastErrorDetail = j
    }catch(e){

    }
  }else state[item.id].lastErrorDetail = null

  return state;
}

function UpdateCalendar(state, item){
  if(state[item.id] === undefined){
    state[item.id] = Object.assign({}, BINDER_INITIAL_STATE);
  }

  if(item.calendarId !== undefined){
    state[item.id].calendarId = item.calendarId;
  }

  return state;
}


function SetErrorBinder(state, binderId, error){
  if(state[binderId] === undefined)
    state[binderId] = Object.assign({}, BINDER_INITIAL_STATE);
  state[binderId].id = binderId;
  state[binderId].loading = false;
  state[binderId].error = error;

  return state;
}

function DeleteBinderSettings(state, binderId) {
  var newState = { ...state };
  if (newState[binderId]) {
    newState[binderId].settings = null;
  }
  return newState;
}
function UpdateBinderSettings(state, binderId, binderSettings) {
  var newState = { ...state };
  if (newState[binderId]) {
    newState[binderId].settings = { ...binderSettings };
  }
  return newState;
}

function UpdateBinderItems(state, item, loading = false){
  if(item === undefined) return state;
  if(item.id === undefined) return state;
  if(state[item.id] === undefined){
    state[item.id] = Object.assign({}, BINDER_INITIAL_STATE);
    state[item.id].id = item.id;
  }
  state[item.id].error = "";
  state[item.id].loading = loading;

  if (item.willMoveToPrevious) {
    item.binderStatus = BinderStatus.previous;
  }

  if(item.hasOwnProperty('name'))
    state[item.id].name = item.name;
  if(item.hasOwnProperty('locationName'))
    state[item.id].locationName = item.locationName;
  if(item.hasOwnProperty('modifiedThumbnailImageDownloadId'))
    state[item.id].modifiedThumbnailImageDownloadId = item.modifiedThumbnailImageDownloadId;
  if(item.hasOwnProperty('modifiedName'))
    state[item.id].modifiedName = item.modifiedName;
  if(item.hasOwnProperty('modifiedMeetingDate'))
    state[item.id].modifiedMeetingDate = item.modifiedMeetingDate;
  if(item.hasOwnProperty('meetingDate'))
    state[item.id].meetingDate = item.meetingDate;
  if(item.hasOwnProperty('expiryDate'))
    state[item.id].expiryDate = item.expiryDate;
  if(item.hasOwnProperty('itemCount')){
    state[item.id].itemCount = item.itemCount;
  }else if(item.hasOwnProperty('itemIds')){
    state[item.id].itemIds = item.itemIds;
    if(item.processType === undefined)
      state[item.id].itemCount = item.itemIds.length;
  }
  if(item.hasOwnProperty('modifiedItemCount'))
    state[item.id].modifiedItemCount = item.modifiedItemCount;

  if(item.hasOwnProperty('displayItemCount')){
    state[item.id].displayItemCount = item.displayItemCount;
  }
  
  if(item.hasOwnProperty('settings'))
    state[item.id].settings = item.settings;
  if(item.hasOwnProperty('attendeeIds'))
    state[item.id].attendeeIds = item.attendeeIds;
  if(item.hasOwnProperty('inviteeIds'))
    state[item.id].inviteeIds = item.inviteeIds;
  if(item.hasOwnProperty('recipientIds'))
    state[item.id].recipientIds = item.recipientIds;
  if(item.hasOwnProperty('countryBlacklist'))
    state[item.id].countryBlacklist = item.countryBlacklist;
  if(item.hasOwnProperty('binderStatus'))
    state[item.id].binderStatus = item.binderStatus.toLowerCase();
  if(item.hasOwnProperty(BinderStatus.published))
    state[item.id].published = item.published;

  if(item.hasOwnProperty('deleteNotesAfterMeeting'))
    state[item.id].deleteNotesAfterMeeting = item.deleteNotesAfterMeeting;
  if(item.hasOwnProperty('deleteAnnotationsAfterMeeting'))
    state[item.id].deleteAnnotationsAfterMeeting = item.deleteAnnotationsAfterMeeting;
  if(item.hasOwnProperty('deleteNotesAfterDays'))
    state[item.id].deleteNotesAfterDays = item.deleteNotesAfterDays;
  if(item.hasOwnProperty('deleteAnnotationsAfterDays'))
    state[item.id].deleteAnnotationsAfterDays = item.deleteAnnotationsAfterDays;

  if(item.hasOwnProperty('thumbnailImageDownloadId'))
    state[item.id].thumbnailImageDownloadId = item.thumbnailImageDownloadId;

  if(item.hasOwnProperty('isCreating'))
  state[item.id].isCreating = item.isCreating;
  if(item.hasOwnProperty('isDeleted'))
    state[item.id].isDeleted = item.isDeleted;

  if(item.hasOwnProperty('lockUserId'))
    state[item.id].lockUserId = item.lockUserId;
  if(item.hasOwnProperty('lockExpired')){
    state[item.id].lockExpired = item.lockExpired;
  }
  if(item.hasOwnProperty('lockType'))
    state[item.id].lockType = item.lockType;
  if(item.hasOwnProperty('demo')){
    state[item.id].demo = item.demo;
  }
  if(item.hasOwnProperty('boardId'))
    state[item.id].boardId = item.boardId;

  if(item.hasOwnProperty('lastErrorCode'))
    state[item.id].lastErrorCode = item.lastErrorCode;
  if(item.hasOwnProperty('lastErrorDate'))
    state[item.id].lastErrorDate = item.lastErrorDate;
  if(item.hasOwnProperty('lastErrorDetail')){
    try{
      const j = JSON.parse(item.lastErrorDetail)
      state[item.id].lastErrorDetail = j
    }catch(e){

    }
  }

  if(item.hasOwnProperty('imageId')){
    if(item.imageId === 'done'){
      if(item.newimageId !== undefined)
        if(item.newimageId !== "") state[item.id].thumbnailImageDownloadId = item.newimageId;
    }else
      state[item.id].thumbnailImageDownloadId = item.imageId;
  }

  if(item.hasOwnProperty('updateDate'))
    if(item.updateDate !== commonConstants.SET_NULL_DATE)
      state[item.id].updateDate = item.updateDate;
  if(item.activeTransactionIds !== undefined){
    state[item.id].activeTransactionIds = item.activeTransactionIds;
    state[item.id].isCached = true;
    if(item.activeTransactionIds.length === 0){
      state[item.id].isCached = false
    }
  }

  return state;
}

function UpdateBinderItemsErr(state, binderId, error){
  if(state[binderId] !== undefined){
    state[binderId].error = error
    state[binderId].loading = false
  }
  state.saveError = true
  state.loading = false
  return state
}
function RemoveBinderImage(state, binderItem) {
  try {
    if(binderItem.deleteModifiedThumbnail){
      state[binderItem.id].modifiedThumbnailImageDownloadId = '';
    } else{
      state[binderItem.id].thumbnailImageDownloadId = '';
    }
  } catch {}
  return state;
}

function UpdateBinderImage(state, binderItem) {
  if (binderItem.hasOwnProperty('id')) {
    if (state[binderItem.id] !== undefined) {
      if (binderItem.hasOwnProperty('imageId')) {
        if (binderItem.imageId === 'done') {
          if (binderItem.newimageId !== undefined) {
            if (binderItem.newimageId !== "") state[binderItem.id].thumbnailImageDownloadId = binderItem.newimageId;
          }
        } else {
          state[binderItem.id].thumbnailImageDownloadId = binderItem.imageId;
          state[binderItem.id].modifiedThumbnailImageDownloadId = '';
        }
      }
      if (binderItem.hasOwnProperty('modifiedThumbnailImageDownloadId') && binderItem.modifiedThumbnailImageDownloadId) {
        state[binderItem.id].modifiedThumbnailImageDownloadId = binderItem.modifiedThumbnailImageDownloadId;
      }
    }
  }
  return state;
}

function PopulateAllBinderAndTemplate(state, items){
  items.forEach(item => {
    if(item.binders){
      state = PopulateAllBinder(state, item.binders)
    }
    if(item.templates){
      state = PopulateBinder(state, item.templates)
    }
  })
  return state;
}

function PopulateAllBinder(state, items){
  for(var key in items){
    for(var x=0; x<items[key].length; x++){
      state = UpdateBinder(state, items[key][x], false, false);
    };
  }
  return state;
}

function PopulateBinder(state, items){
  items.forEach(item =>{
    state = UpdateBinder(state, item, false, false);
  })
  return state;
}

function PopulateArchiveBinder(state, items){
  for(var key in items){
    for(var x=0; x<items[key].length; x++){
      state = UpdateArchiveBinder(state, items[key][x]);
    };
  }
  return state;
}

function UpdateArchiveBinder(state, item){
  if(state[item.id] === undefined) return state;

  var update = true;
  if(state[item.id].loading)
    update = false;

  if(item.hasOwnProperty('id'))
    state[item.id].id = item.id;
  if(item.hasOwnProperty('boardId'))
    state[item.id].boardId = item.boardId;
  if(item.hasOwnProperty('name') && update)
    state[item.id].name = item.name;
  if(item.hasOwnProperty('modifiedName') && update)
    state[item.id].modifiedName = item.modifiedName;
  if(item.hasOwnProperty('modifiedMeetingDate') && update)
    state[item.id].modifiedMeetingDate = item.modifiedMeetingDate;
  if(item.hasOwnProperty('meetingDate') && update)
    state[item.id].meetingDate = item.meetingDate;
  if(item.hasOwnProperty('expiryDate') && update)
    state[item.id].expiryDate = item.expiryDate;
  if(item.hasOwnProperty('creationDate'))
    state[item.id].creationDate = item.creationDate;
  if(item.hasOwnProperty('updateDate'))
    if(item.updateDate !== commonConstants.SET_NULL_DATE)
      state[item.id].updateDate = item.updateDate;
  if(item.hasOwnProperty('locationName'))
    state[item.id].locationName = item.locationName;
  else if(state[item.id].locationName !== "")
    state[item.id].locationName = "";
  if(item.hasOwnProperty('locationLatitude'))
    state[item.id].locationLatitude = item.locationLatitude;
  if(item.hasOwnProperty('locationLongitude'))
    state[item.id].locationLongitude = item.locationLongitude;
  if(item.hasOwnProperty(BinderStatus.published))
    state[item.id].published = item.published;
  if(item.hasOwnProperty('deleteNotesAfterMeeting'))
    state[item.id].deleteNotesAfterMeeting = item.deleteNotesAfterMeeting;
  if(item.hasOwnProperty('deleteAnnotationsAfterMeeting'))
    state[item.id].deleteAnnotationsAfterMeeting = item.deleteAnnotationsAfterMeeting;
  if(item.hasOwnProperty('deleteNotesAfterDays'))
    state[item.id].deleteNotesAfterDays = item.deleteNotesAfterDays;
  if(item.hasOwnProperty('deleteAnnotationsAfterDays'))
    state[item.id].deleteAnnotationsAfterDays = item.deleteAnnotationsAfterDays;
  if(item.hasOwnProperty('itemCount') && (update || state[item.id].itemCount === 0))
    state[item.id].itemCount = item.itemCount;
  if(item.hasOwnProperty('modifiedItemCount') && (update || state[item.id].modifiedItemCount === 0))
    state[item.id].modifiedItemCount = item.modifiedItemCount;

  if(item.hasOwnProperty('displayItemCount')){
    state[item.id].displayItemCount = item.displayItemCount;
  }
  
  if(item.hasOwnProperty('itemIds') && update){
    state[item.id].itemIds = item.itemIds;
    state[item.id].itemCount = item.itemIds.length;
  }
  if(item.hasOwnProperty('items') && update ){
    state[item.id].itemIds = [];
    item.items.forEach(function(object){
      state[item.id].itemIds.push(object.id);
    });
    state[item.id].itemCount = item.items.length;
  }
  if(item.hasOwnProperty('settings'))
    state[item.id].settings = item.settings;
  if(item.hasOwnProperty('attendeeIds'))
    state[item.id].attendeeIds = item.attendeeIds;
  if(item.hasOwnProperty('attendees')){
    state[item.id].attendeeIds = [];
    item.attendees.forEach(function(object){
      state[item.id].attendeeIds.push(object.id);
    });
  }
  if(item.hasOwnProperty('inviteeIds'))
    state[item.id].inviteeIds = item.inviteeIds;
  if(item.hasOwnProperty('invitees')){
    state[item.id].inviteeIds = [];
    item.invitees.forEach(function(object){
      state[item.id].inviteeIds.push(object.id);
    });
  }
  if(item.hasOwnProperty('recipientIds'))
    state[item.id].recipientIds = item.recipientIds;
  if(item.hasOwnProperty('recipients')){
    state[item.id].recipientIds = [];
    item.recipients.forEach(function(object){
      state[item.id].recipientIds.push(object.id);
    });
  }
  if(item.hasOwnProperty('countryBlacklist'))
    state[item.id].countryBlacklist = item.countryBlacklist;
  if(item.hasOwnProperty('thumbnailImageDownloadId'))
    state[item.id].thumbnailImageDownloadId = item.thumbnailImageDownloadId;
  if(item.hasOwnProperty('usageIds'))
    state[item.id].usageIds = item.usageIds;
  if(item.hasOwnProperty('usage')){
    state[item.id].usageIds = [];
    item.usage.forEach(function(object){
      state[item.id].usageIds.push(object.id);
    });
  }

  return state;
}

function PopulateCachedBinder(state, items){
  items.forEach((o)=>{
    if(state[o.binderId] !== undefined){
      state[o.binderId].cacheId = o.binderId;
      state[o.binderId].isCached = true;
    }else if(o.binderId.includes('draft') || o.binderId.includes('template')){
      if(state[o.binderId] === undefined){
        state[o.binderId] = Object.assign({}, BINDER_INITIAL_STATE);
        state[o.binderId].id = o.binderId;
      }
      state[o.binderId].error = "";
      state[o.binderId].loading = false;

      state[o.binderId].cacheId = o.binderId;
      state[o.binderId].isCached = true;

      if(o.hasOwnProperty('binderName'))
        state[o.binderId].name = o.binderName;
      if(o.hasOwnProperty('boardId'))
        state[o.binderId].boardId = o.boardId;
      if(o.hasOwnProperty('itemCount'))
        state[o.binderId].itemCount = o.itemCount;
      if(o.hasOwnProperty('newdate'))
        state[o.binderId].meetingDate = o.newdate;
    }
  })
  return state;
}

function PopulateMinutes(state, boardId, payload){
  payload.forEach((o)=>{
    if(o.binderId === undefined) return
    var binderId = o.binderId
    if(state[binderId] === undefined){
      state[binderId] = Object.assign({}, BINDER_INITIAL_STATE)
      state[binderId].id = binderId
    }

    state[binderId].minuteId = o.id
    state[binderId].loading = false
  })
  return state;
}

function DeleteCachedBinder(state, binderId){
  if(state[binderId] !== undefined){
    // if(binderId.includes('draft') || binderId.includes('template')){
    //   delete state[binderId];
    // }else{
      state[binderId].activeTransactionIds = [];
      state[binderId].transactionEvents = [];
      state[binderId].isCached = false;
    // }
  }
  return state;
}

function RequestBinderCached(state, id, binderId){
  var bid = binderId;
  if(binderId === "" && (id.includes('draft') || id.includes('template'))){
    bid = id;
    state[bid] = Object.assign({}, BINDER_INITIAL_STATE);
  }
  if(state[bid] !== undefined){
    state[bid].saving = true;
  }
  return state;
}

function SavedBinderCached(state, id, binderId, data){
  var bid = binderId;
  if(binderId === "")
    bid = id;
  if(state[bid] !== undefined){
    state[bid].activeTransactionIds = [id];
    state[bid].transactionEvents.push(data)
    state[bid].isCached = true;
    state[bid].saving = false;
    state[bid].isCreating = false;
  }
  return state;
}

function renameBinderCached(state, from, to){
  var bid = to;
  if(state[bid] !== undefined){
    state[bid].activeTransactionIds = [to];
    state[bid].isCached = true;
    state[bid].saving = false;
  }
  if(state[from] !== undefined){
    delete state[from];
  }
  return state;
}

function SetLoading(state, binderId) {
  if (state[binderId] !== undefined) {
    state[binderId].loading = true;
  }
  return state;
}

function SetDeleting(state, binderId) {
  if (state[binderId]) {
    state[binderId].isDeleting = true;
    state[binderId].loading = true;
  }
  return state;
}

function UpdatePopulateAttendee(state, binderId, item){
  if(state[binderId] === undefined){
    state[binderId] = Object.assign({}, BINDER_INITIAL_STATE);
    state[binderId].error = "";
    state[binderId].id = item.id;
  }
  state[binderId].loading = false;

  state[binderId].attendeeIds = [];
  item.forEach(function(object){
    state[binderId].attendeeIds.push(object.id);
  });

  return state;
}

function UpdatePopulateRecipient(state, binderId, item){
  if(state[binderId] === undefined){
    state[binderId] = Object.assign({}, BINDER_INITIAL_STATE);
    state[binderId].error = "";
    state[binderId].id = item.id;
  }
  state[binderId].loading = false;

  state[binderId].recipientIds = [];
  item.forEach(function(object){
    state[binderId].recipientIds.push(object.id);
  });

  return state;
}

function RemoveBinder(state, binderId){
  delete state[binderId];
  return state;
}

function UpdateUsage(state, payload, id){
  if(state[id] === undefined){
    state[id] = Object.assign({}, BINDER_INITIAL_STATE);
    state[id].error = "";
    state[id].id = id;
  }

  payload.forEach(function(object){
    if(state[id].usageIds.indexOf(object.id) === -1)
      state[id].usageIds.push(object.id);
  });
  return state;
}

function removeAttendee(state, binderId, id){
  if(state[binderId] !== undefined){
    var p = state[binderId].attendeeIds.indexOf(id);
    if(p !== -1){
      state[binderId].attendeeIds.splice(p,1);
    }
  }

  return state;
}

function removeRecipient(state, binderId, id){
  if(state[binderId] !== undefined){
    var p = state[binderId].recipientIds.indexOf(id);
    if(p !== -1){
      state[binderId].recipientIds.splice(p,1);
    }
  }

  return state;
}

function UpdateLocks(state, items){
  items.forEach((item) => {
    if(item.objectType !== "Binder") return
    if(state[item.objectId] === undefined){
      state[item.objectId] = Object.assign({}, BINDER_INITIAL_STATE);
      state[item.objectId].id = item.objectId;
      state[item.objectId].loading = false
    }

    state[item.objectId].lockUserId = item.userId;
    state[item.objectId].lockExpired = item.expiryTime;
    state[item.objectId].lockType = item.detail;
  });

  return state
}

export function binder(state = {}, action) {
  switch (action.type) {
    case binderConstants.GET_BINDERCONTENT_REQUEST:
      return Object.assign({}, SetBinder(state, action.binderId, false));
    case binderConstants.GET_BINDERCONTENT_SUCCESS:
      return Object.assign({}, UpdateBinder(state, action.item, false));
    case binderConstants.GET_BINDERCONTENT_FAILURE:
      return Object.assign({}, SetErrorBinder(state, action.binderId, action.error));

    case binderConstants.POPULATE_BINDERCONTENT_REQUEST:
      return Object.assign({}, SetBinder(state, action.binderId, true));
    case binderConstants.POPULATE_BINDERCONTENT_SUCCESS:
      return Object.assign({}, UpdateBinder(state, action.item, true, true, action.changeModifiedItem));
    case binderConstants.POPULATE_BINDERCONTENT_UPDATE:
      return Object.assign({}, SetBinderDetail(state, action.item));
    case binderConstants.POPULATE_BINDERCONTENT_PROGRESS:
      return Object.assign({}, SetBinderDetail(state, action.item));
    case binderConstants.POPULATE_BINDERCONTENT_FAILURE:
      return Object.assign({}, SetErrorBinder(state, action.binderId, action.error));
    case boardConstants.POPULATE_BINDERS_SUCCESS:
      return Object.assign({}, PopulateAllBinder(state, action.data));
    case boardConstants.POPULATE_ALL_BINDERS_TEMPLATES_SUCCESS:
      return Object.assign({}, PopulateAllBinderAndTemplate(state, action.data));
    case boardConstants.GET_BINDERS_ARCHIVE_SUCCESS:
      return Object.assign({}, PopulateArchiveBinder(state, action.list));

    case binderConstants.UPDATE_BINDERINFOSTATUS_SUCCESS:
      return Object.assign({}, UpdateBinder(state, action.binderItem));

    case binderConstants.UPDATE_BINDERDASHSTATUS_SUCCESS:
      return Object.assign({}, BinderDashUpdate(state, action.binderItem));

    case binderConstants.GET_TEMPLATECONTENT_REQUEST:
      return Object.assign({}, SetBinder(state, action.templateId, false));
    case binderConstants.GET_TEMPLATECONTENT_SUCCESS:
      return Object.assign({}, UpdateBinder(state, action.item, false));
    case binderConstants.GET_TEMPLATECONTENT_FAILURE:
      return Object.assign({}, SetErrorBinder(state, action.templateId, action.error));

    case binderConstants.POPULATE_TEMPLATECONTENT_REQUEST:
      return Object.assign({}, SetBinder(state, action.templateId, true));
    case binderConstants.POPULATE_TEMPLATECONTENT_SUCCESS:
      return Object.assign({}, UpdateBinder(state, action.item, true));
    case binderConstants.POPULATE_TEMPLATECONTENT_FAILURE:
      return Object.assign({}, SetErrorBinder(state, action.templateId, action.error));
    case boardConstants.POPULATE_TEMPLATES_SUCCESS:
      return Object.assign({}, PopulateBinder(state, action.data));

    case binderConstants.GET_BINDERUSAGE_REQUEST:
      return Object.assign({}, state);
    case binderConstants.GET_BINDERUSAGE_SUCCESS:
      return Object.assign({}, UpdateUsage(state, action.payload, action.id));
    case binderConstants.GET_BINDERUSAGE_FAILURE:
      return Object.assign({}, state, {error: action.error, loading: false});

    case binderConstants.GET_BINDERCACHED_SUCCESS:
      return Object.assign({}, PopulateCachedBinder(state, action.item));
    case binderConstants.DELETE_BINDERCACHED_SUCCESS:
      return Object.assign({}, DeleteCachedBinder(state, action.id));
    case binderConstants.SAVE_BINDERCACHED_REQUEST:
      return Object.assign({}, RequestBinderCached(state, action.id, action.binderId));
    case binderConstants.SAVE_BINDERCACHED_SUCCESS:
      return Object.assign({}, SavedBinderCached(state, action.id, action.binderId, action.data));
    case binderConstants.RENAME_BINDERCACHED_SUCCESS:
      return Object.assign({}, renameBinderCached(state, action.from, action.to));

    case binderConstants.DELETE_BINDERTRANSACTION_SUCCESS:
      return Object.assign({}, DeleteCachedBinder(state, action.id));

    case binderConstants.NEW_TEMPLATECONTENT_REQUEST:
      return Object.assign({}, state, {loading: true});
    case binderConstants.NEW_TEMPLATECONTENT_SUCCESS:
      return Object.assign({}, UpdateBinderItems(state, action.templateItem), {loading: false});
    case binderConstants.NEW_TEMPLATECONTENT_FAILURE:
      return Object.assign({}, state, {error: action.error, loading: false});
    case binderConstants.DELETE_TEMPLATECONTENT_REQUEST:
      return Object.assign({}, SetDeleting(state, action.binderId));
    case binderConstants.DELETE_TEMPLATECONTENT_SUCCESS:
      return Object.assign({}, RemoveBinder(state, action.binderId));
    case binderConstants.DELETE_TEMPLATECONTENT_FAILURE:
      return Object.assign({}, state);
    case binderConstants.DELETE_BINDERCONTENT_REQUEST:
      return Object.assign({}, SetDeleting(state, action.binderId));
    case binderConstants.DELETE_BINDERCONTENT_SUCCESS:
      return Object.assign({}, RemoveBinder(state, action.binderId));
    case binderConstants.DELETE_BINDERCONTENT_FAILURE:
      return Object.assign({}, state);
    case binderConstants.UPDATE_TEMPLATECONTENT_REQUEST:
      return Object.assign({}, state, {loading: true});
    case binderConstants.UPDATE_TEMPLATECONTENT_SUCCESS:
      return Object.assign({}, state, UpdateBinderItems(state, action.templateItem), {loading: false});
    case binderConstants.UPDATE_TEMPLATECONTENT_FAILURE:
      return Object.assign({}, UpdateBinderItemsErr(state, action.templateItem.id, action.error));
    case binderConstants.UPDATE_BINDERSTATUS_REQUEST:
      return Object.assign({}, SetBinderDetail(state, action.binderItem), {loading: true});
    case binderConstants.UPDATE_BINDERSTATUS_DETAIL_REQUEST:
      return Object.assign({}, SetBinderDetail(state, action.binderItem), {loading: true});
    case binderConstants.UPDATE_BINDERSTATUS_SUCCESS:
      return Object.assign({}, UpdateBinderItems(state, action.binderItem), {loading: false});
    case binderConstants.UPDATE_BINDERSTATUS_SUCCESS_DONOTHING:
      return Object.assign({}, UpdateBinderItems(state, action.binderItem), {loading: false});
    case binderConstants.UPDATE_BINDERSTATUS_SUCCESS_MOVE:
      return Object.assign({}, UpdateBinderItems(state, action.binderItem, true), {loading: false});
    case binderConstants.UPDATE_BINDERSTATUS_FAILURE:
      return Object.assign({}, UpdateBinderItemsErr(state, action.binderItem.id, action.error), {loading: false});
    case binderConstants.NEW_BINDERSTATUS_REQUEST:
      return Object.assign({}, state, {loading: true});
    case binderConstants.NEW_BINDERSTATUS_SUCCESS:
      return Object.assign({}, UpdateBinderItems(state, action.binderItem), {loading: false});
    case binderConstants.CLEAR_BINDER_MOVE_DATE:
      return Object.assign({}, ClearBinderMoveDate(state, action.payload));
    case binderConstants.NEW_BINDERSTATUS_FAILURE:
      return Object.assign({}, state, {loading: false});
    case binderConstants.UPDATE_BINDERIMAGE_REQUEST:
      return Object.assign({}, state);
    case binderConstants.UPDATE_BINDERIMAGE_SUCCESS:
      return Object.assign({}, UpdateBinderImage(state, action.binderItem));
    case binderConstants.REMOVE_BINDER_IMAGE:
      return Object.assign({}, RemoveBinderImage(state, action.binderItem));
    case binderConstants.UPDATE_BINDERIMAGE_FAILURE:
      return Object.assign({}, state);
    case binderConstants.COMPLETE_UPLOAD_BINDER_REQUEST:
      return Object.assign({}, state, UpdateBinderItems(state, action.data.updateitem), {loading: false});
    case binderConstants.UPDATE_BINDERINFOSTATUS_REQUEST:
      return Object.assign({}, UpdateBinderItems(state, action.binderItem), {loading: false});
    case binderConstants.UPDATE_TEMPLATEINFOSTATUS_REQUEST:
      return Object.assign({}, UpdateBinderItems(state, action.templateItem), {loading: false});
    case binderConstants.UPDATE_TEMPLATEINFOSTATUS_SUCCESS:
      return Object.assign({}, UpdateBinder(state, action.templateItem));

    case binderConstants.DELETE_ATTENDEE_SUCCESS:
      return Object.assign({}, state, removeAttendee(state, action.binderId, action.attendeId));
    case binderConstants.DELETE_RECIPIENT_SUCCESS:
      return Object.assign({}, state, removeRecipient(state, action.binderId, action.recipientId));

    case binderConstants.CLEAR_ERROR_SUCCESS:
      return Object.assign({}, state, {saveError: undefined})

    case binderConstants.BINDER_WEBSOCKET_LOCK:
      return Object.assign({}, state, UpdateBinderItems(state, {id: action.payload.objectId, lockUserId: action.payload.userId, lockExpired: action.payload.expiryTime, lockType: action.payload.detail}));

    case binderConstants.BINDER_SETTINGS_UPDATE:
      return Object.assign({}, state, UpdateBinderSettings(state, action.payload.binderId, action.payload.binderSettings));
    case binderConstants.BINDER_SETTINGS_DELETE:
      return Object.assign({}, state, DeleteBinderSettings(state, action.payload.binderId)); 


    case customerConstants.DELETE_LOCKS_SUCCESS:
    case customerConstants.SET_LOCKS_SUCCESS:
      if(action.payload.objectType !== "Binder") return state
      return Object.assign({}, state, UpdateBinderItems(state, {id: action.payload.objectId, lockUserId: action.payload.userId, lockType: action.payload.detail}));

    case customerConstants.GET_ALL_LOCKS_SUCCESS:
      return Object.assign({}, state, UpdateLocks(state, action.payload));

    case boardConstants.POPULATE_ATTENDEE_REQUEST:
      return Object.assign({}, SetBinder(state, action.binderId));
    case boardConstants.POPULATE_ATTENDEE_SUCCESS:
      return Object.assign({}, UpdatePopulateAttendee(state, action.binderId, action.item));
    case boardConstants.POPULATE_ATTENDEE_FAILURE:
      return Object.assign({}, SetErrorBinder(state, action.binderId, action.error));
    case boardConstants.POPULATE_RECIPIENT_REQUEST:
      return Object.assign({}, SetBinder(state, action.binderId));
    case boardConstants.POPULATE_RECIPIENT_SUCCESS:
      return Object.assign({}, UpdatePopulateRecipient(state, action.binderId, action.item));
    case boardConstants.POPULATE_RECIPIENT_FAILURE:
      return Object.assign({}, SetErrorBinder(state, action.binderId, action.error));

    case boardConstants.GET_CALENDARBINDER_SUCCESS:
      return Object.assign({}, UpdateCalendar(state, {id: action.binderId, calendarId: action.payload.id}));

    case boardConstants.GET_BOARD_MINUTES_SUCCESS:
      return Object.assign({}, PopulateMinutes(state, action.boardId, action.payload));
    //case boardConstants.AUDIT_POPULATEFILELIST_SUCCESS:
    //  return Object.assign({}, SetBinders(state, action.allItem))

//    case customerConstants.SWITCH_CUSTOMER:
    case userConstants.LOGOUT_REQUEST:
      return Object.assign({});
    default:
      return state
  }
}
