import { binderConstants, webConstants, userConstants, boardConstants, fileConstants, alertConstants, customerConstants } from '@constants/admin';
import { binderService, boardService, userService, fileService } from '@services/admin';
import { boardActions, userActions, fileActions, queueActions, alertActions, customerActions, companyActions } from '@actions/admin';
import { v4 as uuidv4 } from 'uuid';
import { getBinderType, CheckInternet, BLANK_GUID, BLANK_PDF, BLANK_VIDEO, checkDemo, TrackEvent } from '@lib/simpletools';
//import { PostWebWorker } from './../webworker/webcontroller';
import moment from 'moment';
import * as CrytpoLib from '@lib/cryptojs';
import { UserTypeEnum, BinderItemType, BinderStatus, FreemiumType, UserAccountTask, RoutesConstants } from '@constants/common.constants';

import { authHeader, GetURL, history } from '@lib';
import WorkerPool from '@worker/workerpool';
let bgAdminTask = require("worker-loader?name=admin.worker.js!../../pages/common/adminTask.js");

import {
  BINDEREPXIRE,
  BINDERSIMUPLOAD,
  ITEMCHUCKLIMIT,
  MULTITASKLIMIT,
} from '@lib/limits';
import { kvpActions } from './kvp.actions';

var taskpool = new WorkerPool(MULTITASKLIMIT)

export const binderActions = {
  getBinderContent,
  populateBinderContent,
  populateUserBinderContent,
  popBinderContentShow,
  getTemplateContent,
  populateTemplateContent,
  newTemplateContent,
  updateTemplateContent,
  updateTemplatePreviewInfo,
  deleteTemplate,
  saveCachedTemplate,
  getBinderStatus,
  getBinderItem,
  deleteBinderItem,
  updateBinder,
  clearBinderMoveDate,
  updateBinderDash,
  updateBinderPreviewInfo,
  updateImage,
  removeImage,
  replaceImage,
  //getAllCachedBinders,
  // deleteCachedBinder,
  copyDocuments,
  deleteBinderTransaction,
  saveBinderTransactionEvent,
  saveCachedBinder,
  renameCachedBinder,
  commitPublish,
  commitPublishTransaction,
  publishBinder,
  moveBinder,
  archiveBinder,
  newBinder,
  openBinder,
  openTemplate,
  copyTemplate,
  copyBinder,
  deleteBinder,
  deleteBinderNR,
  DownloadDocument,
  populateDocument,
  CallbackDocumentDownload,
  downloadUserDocument,
  downloadAdminDocument,
  getAllResolutions,
  CompletedDocumentDownload,
  CompletedBinderTemplateUpload,
  getVote,
  getAllVotes,
  SetErrorDocument,
  getBinderUsage,
  //SetErrorBinder,
  //UpdateProgressTemplate,
  //UpdateProgressBinder,
  workerAllBinderFileUpdate,
  workerBinderFileUpdate,
  runAdminBgTask,
  CacheBinderTemplatePreview,
  clearLock,
  clearError,
  setResolution,
  deleteBinderSettings,
  updateBinderSettings,
};

function getBinderContent(binderId) {
  return dispatch => {
    if (checkDemo('getBinderContent' + binderId)) return
    dispatch(request(binderId));
    binderService.getBinderContent(binderId)
      .then(
        item => dispatch(success(item)),
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(binderId, error))
        }
      );
  };

  function request(binderId) { return { type: binderConstants.GET_BINDERCONTENT_REQUEST, binderId } }
  function success(item) { return { type: binderConstants.GET_BINDERCONTENT_SUCCESS, item } }
  function failure(binderId, error) { return { type: binderConstants.GET_BINDERCONTENT_FAILURE, binderId, error } }
}

function populateBinderContent(binderId, excludeItems = false) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (checkDemo('populateBinderContent' + binderId)) return
      dispatch(request(binderId));
      binderService.populateBinderContent(binderId, excludeItems)
        .then(
          item => {
            resolve(item);
            dispatch(success(item));
          },
          error => {
            reject(error);
            error = CheckInternet(error, dispatch)
            dispatch(failure(binderId, error))
          }
        );
    });
  };

  function request(binderId) { return { type: binderConstants.POPULATE_BINDERCONTENT_REQUEST, binderId } }
  function success(item) { return { type: binderConstants.POPULATE_BINDERCONTENT_SUCCESS, item } }
  function failure(binderId, error) { return { type: binderConstants.POPULATE_BINDERCONTENT_FAILURE, binderId, error } }
}

function populateUserBinderContent(boardId, binderId, userId) {
  return dispatch => {
    if (checkDemo('populateUserBinderContent' + boardId + binderId + userId)) return
    dispatch(request(boardId, binderId, userId));
    binderService.populateUserBinderContent(binderId, userId)
      .then(
        item => dispatch(success(boardId, binderId, userId, item)),
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(binderId, error))
        }
      );
  };

  function request(boardId, binderId, userId) { return { type: binderConstants.POPULATE_USERBINDERCONTENT_REQUEST, boardId, binderId, userId } }
  function success(boardId, binderId, userId, item) { return { type: binderConstants.POPULATE_USERBINDERCONTENT_SUCCESS, boardId, binderId, userId, item } }
  function failure(binderId, error) { return { type: binderConstants.POPULATE_USERBINDERCONTENT_FAILURE, binderId, error } }
}

function getTemplateContent(templateId) {
  return dispatch => {
    if (checkDemo('getTemplateContent' + templateId)) return
    dispatch(request(templateId));
    binderService.getTemplateContent(templateId)
      .then(
        item => dispatch(success(item)),
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(templateId, error))
        }
      );
  };

  function request(templateId) { return { type: binderConstants.GET_TEMPLATECONTENT_REQUEST, templateId } }
  function success(item) { return { type: binderConstants.GET_TEMPLATECONTENT_SUCCESS, item } }
  function failure(templateId, error) { return { type: binderConstants.GET_TEMPLATECONTENT_FAILURE, templateId, error } }
}

function populateTemplateContent(templateId) {
  return dispatch => {
    if (checkDemo('populateTemplateContent' + templateId)) return
    dispatch(request(templateId));
    binderService.populateTemplateContent(templateId)
      .then(
        item => dispatch(success(item)),
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(templateId, error))
        }
      );
  };

  function request(templateId) { return { type: binderConstants.POPULATE_TEMPLATECONTENT_REQUEST, templateId } }
  function success(item) { return { type: binderConstants.POPULATE_TEMPLATECONTENT_SUCCESS, item } }
  function failure(templateId, error) { return { type: binderConstants.POPULATE_TEMPLATECONTENT_FAILURE, templateId, error } }
}

function newTemplateContent(templateItem) {
  return (dispatch) => {
    if (window.demo) {
      templateItem.id = uuidv4();
      templateItem = demoData(templateItem, dispatch)
      dispatch(success(templateItem));
      // dispatch(deleteBinderTransaction(templateItem.id))
      dispatch(success_delete_transaction(templateItem.boardId, templateItem.id))

      setTimeout(() => {
        history.push({
          pathname: RoutesConstants.binderboard,
          query: { boardId: templateItem.boardId }
        }, 1000);
      })
      return
    }
    dispatch(request());
    dispatch(alertActions.recordDiagnosticData('templateNew', { binder: templateItem }))

    binderService.newTemplateContent(templateItem)
      .then(
        TemplateId => {
          templateItem.id = TemplateId;
          dispatch(success(templateItem));
          //dispatch(this.getBinderItem());
          binderService.renameCachedBinder(templateItem.cacheId, templateItem.id)
            .then(
              result => {
                templateItem.cacheId = templateItem.id
                if (templateItem.items.length && templateItem.worker) {
                  templateItem.processType = webConstants.ADD_TEMPLATE;
                  dispatch(customerActions.setLock({
                    objectType: "BinderTemplate",
                    objectId: templateItem.id,
                    detail: "Upload",
                  }))
                  dispatch(queueActions.uploadTemplate(templateItem));
                  //PostWebWorker({type:webConstants.ADD_TEMPLATE, data: templateItem, sessionToken: sessionToken});
                } else {
                  // dispatch(deleteBinderTransaction(templateItem.id))
                  dispatch(success_delete_transaction(templateItem.boardId, templateItem.id))
                }
                history.push({
                  pathname: RoutesConstants.binderboard,
                  query: { boardId: templateItem.boardId }
                });

              },
              error => {
                log("INDEXEDDB", error);
                dispatch(alertActions.errorDiagnosticData(new CodeError("newTemplate", '701', 'INDEXEDDB', {
                  objectId: templateItem.id,
                  binder: templateItem,
                  error: error,
                }, error)))
                dispatch(failure('Failed to rename template cache'))
              }
            );
        },
        error => {
          dispatch(alertActions.errorDiagnosticData(error))
          dispatch(failure(error.message))
        }
      );
  };

  function request() { return { type: binderConstants.NEW_TEMPLATECONTENT_REQUEST } }
  function success(templateItem) { return { type: binderConstants.NEW_TEMPLATECONTENT_SUCCESS, templateItem } }
  function success_delete_transaction(boardId, id) { return { type: binderConstants.DELETE_BINDERTRANSACTION_SUCCESS, boardId, id } }
  function failure(error) { return { type: binderConstants.NEW_TEMPLATECONTENT_FAILURE, error } }
}

function updateTemplateContent(templateItem) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let transactionIds = []
      const auth = getState().authentication
      const customerId = auth.customerId
      const b = getState().binder[templateItem.id]
      if (b !== undefined && b.activeTransactionIds !== undefined)
        transactionIds = b.activeTransactionIds

      if (window.demo) {
        templateItem = demoData(templateItem, dispatch)
        dispatch(success(templateItem));
        // dispatch(deleteBinderTransaction(templateItem.id))
        dispatch(success_delete_transaction(templateItem.boardId, templateItem.id))

        setTimeout(() => {
          history.push({
            pathname: RoutesConstants.binderboard,
            query: { boardId: templateItem.boardId }
          }, 1000);
        })
        return
      }
      // templateItem.commitTransactionIds = transactionIds
      dispatch(request());

      binderService.saveBinderTransactionEvent(customerId)
        .then(() => {
          binderService.updateTemplateContent(templateItem)
            .then(
              item => {
                // TrackEvent("f_board_binder-template.saved",{
                //   user_id: auth.userId,
                //   person_id: auth.personId,
                //   company_id: auth.customerId,
                //   alias: auth.alias,
                //   template_id: templateItem.id,
                //   board_id: templateItem.boardId,
                //   //binder_selected_type: ,
                //   template_total_agendaitems_count: templateItem.listItems.filter((obj) => obj.binderType !== BinderItemType.multipleDoc).length,
                //   template_doc_agendaitems_count: templateItem.listItems.filter((obj) => obj.binderType === BinderItemType.document && !obj.multidoc).length,
                //   template_mutltidoc_agendaitems_count: templateItem.listItems.filter((obj) => obj.binderType === BinderItemType.document && obj.multidoc).length,
                //   template_header_agendaitems_count: templateItem.listItems.filter((obj) => obj.binderType === BinderItemType.header).length,
                //   template_minutes_agendaitems_count: templateItem.listItems.filter((obj) => obj.binderType === BinderItemType.minutes).length,
                //   template_resolution_agendaitems_count: templateItem.listItems.filter((obj) => obj.binderType === BinderItemType.resolution).length,
                //   template_video_agendaitems_count: templateItem.listItems.filter((obj) => obj.binderType === BinderItemType.video).length,
                //   template_vote_agendaitems_count: templateItem.listItems.filter((obj) => obj.binderType === BinderItemType.vote).length,
                //   template_saved_timestamp: templateItem.updateDate,
                //   template_created_timestamp: templateItem.createDate,
                //   template_lastupdated_timestamp: templateItem.lastUpdateDate,
                // })
                delete templateItem.listItems

                dispatch(alertActions.recordDiagnosticData('templateUpdate', { binder: templateItem }))
                resolve();
                dispatch(success(templateItem));
                //dispatch(this.getBinderItem());
                if (templateItem.items.length && templateItem.worker) {
                  templateItem.processType = webConstants.ADD_TEMPLATE;
                  dispatch(queueActions.uploadTemplate(templateItem));
                  //PostWebWorker({type:webConstants.ADD_TEMPLATE, data: templateItem, sessionToken: sessionToken});
                } else {
                  // dispatch(customerActions.setLock({
                  //   objectType: "Template",
                  //   objectId: templateItem.id,
                  //   detail: "Refresh",
                  //   expirySeconds: 5
                  // }))
                  dispatch(customerActions.deleteLock("BinderTemplate", templateItem.id))
                }
                // dispatch(deleteBinderTransaction(templateItem.id))
                dispatch(success_delete_transaction(templateItem.boardId, templateItem.id))
                history.push({
                  pathname: RoutesConstants.binderboard,
                  query: { boardId: templateItem.boardId }
                });
              },
              error => {
                dispatch(alertActions.errorDiagnosticData(error))
                dispatch(failure(templateItem, error.message))
                reject(error);
              }
              );
            },
            error => {
              dispatch(alertActions.errorDiagnosticData(error))
              dispatch(failure(templateItem, error.message))
              reject(error);
          }
        )
    });
  };

  function request() { return { type: binderConstants.UPDATE_TEMPLATECONTENT_REQUEST } }
  function success(templateItem) { return { type: binderConstants.UPDATE_TEMPLATECONTENT_SUCCESS, templateItem } }
  function success_delete_transaction(boardId, id) { return { type: binderConstants.DELETE_BINDERTRANSACTION_SUCCESS, boardId, id } }
  function failure(templateItem, error) { return { type: binderConstants.UPDATE_TEMPLATECONTENT_FAILURE, templateItem, error } }
}

function updateTemplatePreviewInfo(templateItem) {
  return dispatch => {
    dispatch(request());
    // dispatch(alertActions.recordDiagnosticData('binderUploadPreviewInfo', { binder: binderItem }))
    binderService.updateTemplatePreviewInfo(templateItem)
      .then(
        id => {
          dispatch(success(templateItem));
        },
        error => {
          // dispatch(alertActions.errorDiagnosticData(error))
          dispatch(failure(templateItem, error.message))
        }
      );
  };

  function request() { return { type: binderConstants.UPDATE_TEMPLATEINFOSTATUS_REQUEST } }
  function success(templateItem) { return { type: binderConstants.UPDATE_TEMPLATEINFOSTATUS_SUCCESS, templateItem } }
  function failure(templateItem, error) { return { type: binderConstants.UPDATE_TEMPLATEINFOSTATUS_FAILURE, templateItem, error } }
}

function deleteTemplate(boardId, binderId) {
  return (dispatch, getState) => {
    const auth = getState().authentication
    dispatch(request(binderId));
    binderService.deleteTemplate(binderId)
      .then(
        () => {
          // TrackEvent("f_board_binder-template.deleted",{
          //   user_id: auth.userId,
          //   person_id: auth.personId,
          //   company_id: auth.customerId,
          //   alias: auth.alias,
          //   template_id: binderId,
          //   board_id: boardId,
          // })
          dispatch(success(binderId));
          dispatch(boardActions.getBoardTemplates(boardId));
        },
        error => dispatch(failure(error))
      );
  };

  function request(binderId) { return { type: binderConstants.DELETE_TEMPLATECONTENT_REQUEST, binderId } }
  function success(boardId, binderId) { return { type: binderConstants.DELETE_TEMPLATECONTENT_SUCCESS, boardId, binderId } }
  function failure(error) { return { type: binderConstants.DELETE_TEMPLATECONTENT_FAILURE, error } }
}

function deleteBinderNR(boardId, binderId) {
  return (dispatch, getState) => {
    const auth = getState().authentication

    dispatch(request(binderId));
    if (window.demo) {
      dispatch(success(binderId, boardId));
      dispatch(boardActions.getBoardBinders(boardId));
      return
    }

    const customerId = getState().authentication.customerId
    const customers = getState().authentication.customers.find(o => o.id === customerId)
    const isFreeium = customers !== undefined && (customers.accountType === FreemiumType.freemium || customers.accountType === FreemiumType.freemium_internal)? true : false

    binderService.deleteBinder(binderId)
      .then(
        () => {
          if (isFreeium){
            dispatch(companyActions.getCompanyUserLimits())
          }

          TrackEvent("f_board_binder.deleted",{
            user_id: auth.userId,
            person_id: auth.personId,
            company_id: auth.customerId,
            alias: auth.alias,
            binder_id: binderId,
            board_id: boardId,
            deleted_from: 'Delete icon'
          })
          dispatch(success(binderId, boardId));
          //dispatch(boardActions.getBoardBinders(boardId));
        },
        error => dispatch(failure(error))
      );
  };

  function request(binderId) { return { type: binderConstants.DELETE_BINDERCONTENT_REQUEST, binderId } }
  function success(binderId, boardId) { return { type: binderConstants.DELETE_BINDERCONTENT_SUCCESS, binderId, boardId } }
  function failure(error) { return { type: binderConstants.DELETE_BINDERCONTENT_FAILURE, error } }
}

function deleteBinder(boardId, binderId) {
  return (dispatch, getState) => {
    const auth = getState().authentication

    function Redirect() {
      dispatch(success(binderId, boardId));
      //dispatch(boardActions.getBoardBinders(boardId));
      history.push({
        pathname: RoutesConstants.binderboard,
        query: { boardId: boardId }
      });
    }

    dispatch(request(binderId));
    if (window.demo) {
      Redirect();
      return
    }

    var binder = getState().binder[binderId];
    if (!binder) { return; }

    binderService.deleteBinder(binderId)
      .then(
        () => {
          TrackEvent("f_board_binder.deleted",{
            user_id: auth.userId,
            person_id: auth.personId,
            company_id: auth.customerId,
            alias: auth.alias,
            binder_id: binderId,
            board_id: boardId,
            deleted_from: 'Delete button'
          })
          Redirect()
        },
        error => dispatch(failure(error))
      );
  };

  function request(binderId) { return { type: binderConstants.DELETE_BINDERCONTENT_REQUEST, binderId } }
  function success(binderId, boardId) { return { type: binderConstants.DELETE_BINDERCONTENT_SUCCESS, binderId, boardId } }
  function failure(error) { return { type: binderConstants.DELETE_BINDERCONTENT_FAILURE, error } }
}

function getBinderStatus(usageId) {
  return dispatch => {
    dispatch(request(usageId));
    binderService.getBinderStatus(usageId)
      .then(
        item => dispatch(success(item)),
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(error))
        }
      );
  };

  function request(usageId) { return { type: binderConstants.GET_BINDERSTATUS_REQUEST, usageId } }
  function success(item) { return { type: binderConstants.GET_BINDERSTATUS_SUCCESS, item } }
  function failure(usageId, error) { return { type: binderConstants.GET_BINDERSTATUS_FAILURE, usageId, error } }
}
/*
function getAllCachedBinders(boardId, userId) {
  return dispatch => {
    binderService.getAllCachedBinders(boardId, userId)
      .then(
        item => dispatch(success(item, boardId)),
        error => {

        }
      );
  };

  function success(item, boardId) { return { type: binderConstants.GET_BINDERCACHED_SUCCESS, item, boardId } }
}

function deleteCachedBinder(boardId, id) {
  return dispatch => {
    binderService.deleteCachedBinder(id)
      .then(
        item => dispatch(success(boardId, id)),
        error => {

        }
      );
  };

  function success(boardId, id) { return { type: binderConstants.DELETE_BINDERCACHED_SUCCESS, boardId, id } }
}*/

function copyDocuments(list){
  return (dispatch, getState) => {
    dispatch(request())
    binderService.copyDocuments(list)
      .then(
        item => dispatch(success()),
        error => {
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: binderConstants.COPY_DOCUMENTS_REQUEST } }
  function success() { return { type: binderConstants.COPY_DOCUMENTS_SUCCESS } }
  function failure(error) { return { type: binderConstants.COPY_DOCUMENTS_FAILURE, error } }
}

function deleteBinderTransaction(id) {
  return (dispatch, getState) => {
    const transactionIds = getState().binder[id].activeTransactionIds
    const boardId = getState().binder[id].boardId
    if(transactionIds.length === 0){
      dispatch(success(boardId, id))
      return
    }

    binderService.deleteBinderTransaction(transactionIds[0])
      .then(
        item => dispatch(success(boardId, id)),
        error => {
          dispatch(success(boardId, id))
        }
      );
  };

  function success(boardId, id) { return { type: binderConstants.DELETE_BINDERTRANSACTION_SUCCESS, boardId, id } }
}

function saveBinderTransactionEvent() {
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId

    if(window.binderSave === undefined || window.binderSave.length === 0 || window.transactionId === undefined){
      return
    }
    dispatch(request())
    binderService.saveBinderTransactionEvent(customerId)
      .then(
        binderNameChanges => {
          if(binderNameChanges.length){
            binderNameChanges.forEach((item) => {
              var eventtime = item.newdate;
              // var time = item.newtime;
              // var words = time.split(':');
              // if (words.length === 2) {
              //   eventtime.minutes(parseInt(words[1]));
              //   eventtime.hours(parseInt(words[0]));
              //   eventtime.seconds(0);
              // }

              let changeData = {
                id: item.binderId,
                modifiedName: item.binderName,
                modifiedMeetingDate: eventtime,
                modifiedItemCount: item.itemCount,
                displayItemCount: item.displayItemCount || 0,
              }

              dispatch(updateBinderPreviewInfo(changeData));
            });
          }
          dispatch(success())
        },
        error => {
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: binderConstants.SAVE_BINDERTRANSACTIONEVENT_REQUEST } }
  function success() { return { type: binderConstants.SAVE_BINDERTRANSACTIONEVENT_SUCCESS } }
  function failure(error) { return { type: binderConstants.SAVE_BINDERTRANSACTIONEVENT_FAILURE, error } }
}

function saveCachedTemplate(id, data) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let transactionIds = []
      dispatch(request(id))
      if (getState().binder[id] === undefined) {
        dispatch(create({
          id: id,
          name: data.binderName,
          boardId: data.boardId
        }))
        //Post binderId
        binderService.createBlankTemplate(data)
          .then(
            binderId => {
              dispatch(customerActions.setLock({ objectType: "BinderTemplate", objectId: data.binderId }))
              binderService.saveCachedBinder(id, transactionIds, data, "BinderTemplate")
                .then(
                  item => {
                    dispatch(success(item[0], data.binderId, data))
                    dispatch(saveBinderTransactionEvent())
                    resolve();
                  },
                  error => {
                    reject();
                  })
            },
            error => {

            }
          )
      } else {
        resolve();
        transactionIds = getState().binder[id].activeTransactionIds
        binderService.saveCachedBinder(id, transactionIds, data, "BinderTemplate")
          .then(
            item => {
              dispatch(success(item[0], data.binderId, data))
              if (transactionIds.length >= 0) {
                dispatch(saveBinderTransactionEvent())
              }
            },
            error => {

            }
          );
      }
    });
  };

  function request(id) { return { type: binderConstants.SAVE_BINDERCACHED_REQUEST, id } }
  function success(id, binderId, data) { return { type: binderConstants.SAVE_BINDERCACHED_SUCCESS, id, binderId, data } }
  function create(binderItem) { return { type: binderConstants.NEW_BINDERSTATUS_SUCCESS, binderItem } }
}

function saveCachedBinder(id, data) {
  return (dispatch, getState) => {
    let transactionIds = []
    dispatch(request(id))
    if (getState().binder[id] === undefined || (!getState().binder[id].name && !getState().binder[id].boardId && !getState().binder[id].binderStatus)) {
      dispatch(create({
        id: id,
        name: data.binderName,
        boardId: data.boardId,
        isCreating: true,
      }))
      //Post binderId
      binderService.createBlankBinder(data)
        .then(
          binderId => {
            dispatch(customerActions.setLock({objectType: "Binder", objectId: data.binderId}))
            binderService.saveCachedBinder(id, transactionIds, data)
              .then(
                item => {
                  dispatch(saveBinderTransactionEvent())
                  dispatch(success(item[0], data.binderId, data))
                },
                error => {

                })
          },
          error => {

          }
        )
    } else {
      var waitForTransactionIds = setInterval(function () {
        transactionIds = getState().binder[id].activeTransactionIds;
        if ((transactionIds && transactionIds.length) || getState().binder[id].published) {
          clearInterval(waitForTransactionIds);
          binderService.saveCachedBinder(id, transactionIds, data)
            .then(
              item => {
                dispatch(success(item[0], data.binderId, data))
                if (transactionIds.length >= 0) {
                  dispatch(saveBinderTransactionEvent())
                }
              },
              error => {
                log(error);
              }
            );
        }
      }, 500);
    }
  };

  function request(id) { return { type: binderConstants.SAVE_BINDERCACHED_REQUEST, id } }
  function success(id, binderId, data) { return { type: binderConstants.SAVE_BINDERCACHED_SUCCESS, id, binderId, data } }

  function create(binderItem) { return { type: binderConstants.NEW_BINDERSTATUS_SUCCESS, binderItem } }
}

function renameCachedBinder(from, to, boardId) {
  return dispatch => {
    binderService.renameCachedBinder(from, to)
      .then(
        item => dispatch(success(from, to, boardId)),
        error => {

        }
      );
  };

  function success(from, to, boardId) { return { type: binderConstants.RENAME_BINDERCACHED_SUCCESS, from, to, boardId } }
}

function commitPublish(binderId){
  return (dispatch,getState) => {
    let transactionIds = []
    const b = getState().binder[binderId]
    if(b !== undefined && b.activeTransactionIds !== undefined)
      transactionIds = b.activeTransactionIds

    dispatch(request())
    if(transactionIds.length === 0) return

    binderService.commitPublish(transactionIds[0])
      .then(
        item => dispatch(success(binderId)),
        error => {
          dispatch(failure())
        }
      );
  };

  function request() { return { type: binderConstants.CLEAR_BINDERTRANSACTIONEVENT_REQUEST} }
  function success(binderId) { return { type: binderConstants.CLEAR_BINDERTRANSACTIONEVENT_SUCCESS, binderId } }
  function failure() { return { type: binderConstants.CLEAR_BINDERTRANSACTIONEVENT_FAILURE} }
}

function commitPublishTransaction(binderId, transactionIds){
  return (dispatch) => {
    dispatch(request())
    if(transactionIds.length === 0) return

    binderService.commitPublish(transactionIds[0])
      .then(
        item => dispatch(success(transactionIds)),
        error => {
          dispatch(failure())
        }
      );
  };

  function request() { return { type: binderConstants.CLEAR_BINDERTRANSACTIONEVENT_REQUEST} }
  function success(binderId) { return { type: binderConstants.CLEAR_BINDERTRANSACTIONEVENT_SUCCESS, binderId } }
  function failure() { return { type: binderConstants.CLEAR_BINDERTRANSACTIONEVENT_FAILURE} }
}

function removeImage(binderItem, publish = false) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      binderService.removeImage(binderItem, publish).then(() => {
        if (!publish) {
          binderItem.deleteModifiedThumbnail = true;
        }
        dispatch(success(binderItem));
        resolve();
      }).catch(() => { reject(); });
    })
  };

  function success(binderItem) { return { type: binderConstants.REMOVE_BINDER_IMAGE, binderItem } }
}

function replaceImage(binderItem) {
  return dispatch => {
    dispatch(success(binderItem));
  };

  function success(binderItem) { return { type: binderConstants.UPDATE_BINDERIMAGE_SUCCESS, binderItem } }
}

function updateImage(binderItem, sendPatch = true, publish = false) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(request(binderItem.id));
      binderService.updateImage(binderItem, sendPatch, publish)
        .then(
          imageId => {
            if (publish) {
              binderItem.imageId = imageId;
            } else {
              binderItem.modifiedThumbnailImageDownloadId = imageId;
            }
            resolve(imageId);
            dispatch(success(binderItem))
          },
          error => {
            dispatch(failure(binderItem.id))
          }
        );
    })
  };

  function request(id) { return { type: binderConstants.UPDATE_BINDERIMAGE_REQUEST, id } }
  function success(binderItem) { return { type: binderConstants.UPDATE_BINDERIMAGE_SUCCESS, binderItem } }
  function failure(id, error) { return { type: binderConstants.UPDATE_BINDERIMAGE_FAILURE, id, error } }
}

function demoData(binderItem, dispatch) {
  binderItem.demo = true
  let imageFound = binderItem.imageId !== "" ? true : false
  if (binderItem.attendeeIds !== undefined) {
    binderItem.attendeeIds = binderItem.attendeeIds.map(o => o.id)
  }
  if (binderItem.recipientIds !== undefined) {
    binderItem.recipientIds = binderItem.recipientIds.map(o => o.id)
  }
  binderItem.attendees.forEach(o => {
    o.id = uuidv4()
    binderItem.attendeeIds.push(o.id)
  })
  binderItem.recipients.forEach(o => {
    o.id = uuidv4()
    binderItem.recipientIds.push(o.id)
  })
  binderItem.inviteeIds = []
  binderItem.invitees.forEach(o => {
    o.id = uuidv4()
    let [first, ...second] = o.name.split(" ")
    second = second.join(" ")
    o.firstName = first
    o.lastName = second
    binderItem.inviteeIds.push(o.id)
  })

  if (binderItem.itemIds === undefined) binderItem.itemIds = []
  for (var x = 0; x < binderItem.items.length; x++) {
    if (binderItem.items[x].id === "") {
      binderItem.items[x].id = uuidv4()
    }
    if (binderItem.itemIds.find(o => o === binderItem.items[x].id) === undefined) {
      binderItem.itemIds.push(binderItem.items[x].id)
    }
    if (binderItem.items[x].documentId === "") {
      binderItem.items[x].documentId = binderItem.items[x].binderType !== BinderItemType.video ? BLANK_PDF : BLANK_VIDEO
      binderItem.items[x].fileName = binderItem.items[x].filename
      if (binderItem.items[x].binderType === BinderItemType.vote) {
        binderItem.items[x].fetch = true
        binderItem.items[x].data = binderItem.items[x].itemdata
      }
      binderItem.items[x].key = "AAA=="
    }
    binderItem.items[x].userItems.forEach(o => {
      if (o.id !== undefined && o.id !== "") return
      o.id = uuidv4()
      if (o.enable) {
        o.documentId = binderItem.items[x].documentId
        o.key = binderItem.items[x].key
        o.size = binderItem.items[x].size
      }
    })
    if (binderItem.items[x].userItems.find(o => o.userId === BLANK_GUID) === undefined) {
      binderItem.items[x].userItems.push({
        id: uuidv4(),
        userId: BLANK_GUID,
        documentId: binderItem.items[x].documentId,
        size: binderItem.items[x].size,
        key: binderItem.items[x].key,
        enabled: true,
        locked: false,
        viewArchived: true
      })
    }
    if (binderItem.items[x].binderType !== BinderItemType.resolution &&
      binderItem.items[x].binderType !== BinderItemType.minutes &&
      binderItem.items[x].binderType !== BinderItemType.document) continue;

    if (binderItem.items[x].image !== null && !imageFound) {
      if (binderItem.items[x].image.byteLength > 1) {
        //found an image
        binderItem.imageId = uuidv4()
        var Data = CrytpoLib.arrayBufferToBase64String(binderItem.items[x].image)

        dispatch(successImage({
          id: binderItem.imageId,
          documentId: binderItem.imageId,
          image: Data,
          type: "image/png"
        }))
        imageFound = true
      }
    }
  }
  function successImage(payload) { return { type: fileConstants.DOWNLOAD_IMAGE_SUCCESS, payload } }
  return binderItem
}

function updateBinder(binderItem, redirect = true) {
  return (dispatch, getState) => {
    dispatch(request(binderItem));
    let transactionIds = []
    const customerId = getState().authentication.customerId
    const b = getState().binder[binderItem.id]
    if(b !== undefined && b.activeTransactionIds !== undefined)
      transactionIds = b.activeTransactionIds

    if (window.demo) {
      binderItem = demoData(binderItem, dispatch)
      dispatch(success(binderItem));
      dispatch(success_delete_transaction(binderItem.boardId, binderItem.id))
      // dispatch(deleteBinderTransaction(binderItem.id))
      setTimeout(() => {
        history.push({
          pathname: RoutesConstants.binderboard,
          query: { boardId: binderItem.boardId }
        });
      }, 1000)
      return
    }

    // if(binderItem.published)
    //   binderItem.commitTransactionIds = transactionIds

    dispatch(alertActions.recordDiagnosticData('binderUpload', { binder: binderItem }))
    binderService.saveBinderTransactionEvent(customerId)
      .then(() => {
        binderService.updateBinder(binderItem)
          .then(
            item => {
              if (item.hasOwnProperty('imageId'))
                if (item.imageId !== "")
                  binderItem.imageId = item.imageId;
              if (binderItem.items.length && binderItem.worker) {
                binderItem.processType = webConstants.ADD_BINDER;
                dispatch(queueActions.uploadBinder(binderItem));
                //PostWebWorker({type:webConstants.ADD_BINDER, data: binderItem, sessionToken: sessionToken});
                dispatch(success_nothing(binderItem));

                if (redirect)
                  history.push({
                    pathname: RoutesConstants.binderboard,
                    query: { boardId: binderItem.boardId }
                  });
              } else {
                dispatch(success(binderItem));
                //if(binderItem.notification !== undefined && binderItem.notification.length > 0)
                //  dispatch(alertActions.sendPushNotifications(binderItem.notification));
                // dispatch(customerActions.setLock({
                //   objectType: "Binder",
                //   objectId: binderItem.id,
                //   detail: "Refresh",
                //   expirySeconds: 5
                // }))

                //dispatch(deleteBinderTransaction(binderItem.id))
                dispatch(success_delete_transaction(binderItem.boardId, binderItem.id))
                dispatch(success_delete_lock({ id: binderItem.id, objectType: "", lockUserId: "" }))
                if (redirect)
                  history.push({
                    pathname: RoutesConstants.binderboard,
                    query: { boardId: binderItem.boardId }
                  });

                // if(binderItem.published && transactionIds.length > 0){
                //   binderService.commitPublish(transactionIds[0])
                //   .then(
                //     item => {
                //       dispatch(deleteBinderTransaction(binderItem.id))
                //       dispatch(customerActions.deleteLock("Binder", binderItem.id))
                //       if (redirect)
                //         history.push({
                //           pathname: '/binderboard',
                //           query: { boardId: binderItem.boardId }
                //         });
                //     },
                //     error => {
                //       dispatch(alertActions.errorDiagnosticData(error))
                //       dispatch(failure(binderItem, error.message))
                //       if (redirect)
                //         history.push({
                //           pathname: '/binderboard',
                //           query: { boardId: binderItem.boardId }
                //         });
                //     }
                //   )
                // }else{
                //   dispatch(deleteBinderTransaction(binderItem.id))
                //   dispatch(customerActions.deleteLock("Binder", binderItem.id))
                //   if (redirect)
                //     history.push({
                //       pathname: '/binderboard',
                //       query: { boardId: binderItem.boardId }
                //     });
                // }
              }
            },
            error => {
              dispatch(alertActions.errorDiagnosticData(error))
              dispatch(failure(binderItem, error.message))
            }
          )
      },
        error => {
          dispatch(alertActions.errorDiagnosticData(error))
          dispatch(failure(binderItem, error.message))
        })
  };

  function request(binderItem) { return { type: binderConstants.UPDATE_BINDERSTATUS_DETAIL_REQUEST, binderItem } }
  function success_nothing(binderItem) { return { type: binderConstants.UPDATE_BINDERSTATUS_SUCCESS_DONOTHING, binderItem } }
  function success(binderItem) { return { type: binderConstants.UPDATE_BINDERSTATUS_SUCCESS, binderItem } }
  function success_delete_transaction(boardId, id) { return { type: binderConstants.DELETE_BINDERTRANSACTION_SUCCESS, boardId, id } }
  function success_delete_lock(payload) { return { type: customerConstants.DELETE_LOCKS_SUCCESS, payload } }
  function failure(binderItem, error) { return { type: binderConstants.UPDATE_BINDERSTATUS_FAILURE, binderItem, error } }
}

function clearBinderMoveDate(binderItem) {
  return dispatch => {
    dispatch({
      type: binderConstants.CLEAR_BINDER_MOVE_DATE,
      payload: binderItem
    })
  }
}

function updateBinderDash(binderItem) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      dispatch(alertActions.recordDiagnosticData('binderUpload', { binder: binderItem }))
      binderService.updateBinderDash(binderItem)
        .then(
          id => {
            dispatch(success(binderItem));
            resolve(binderItem);
            history.push({
              pathname: RoutesConstants.binderboard,
              query: { boardId: binderItem.boardId }
            });
          },
          error => {
            dispatch(alertActions.errorDiagnosticData(error))
            dispatch(failure(binderItem, error.message))
            reject();
          }
        );
    })
  };

  function request() { return { type: binderConstants.UPDATE_BINDERDASHSTATUS_REQUEST } }
  function success(binderItem) { return { type: binderConstants.UPDATE_BINDERDASHSTATUS_SUCCESS, binderItem } }
  function failure(binderItem, error) { return { type: binderConstants.UPDATE_BINDERDASHSTATUS_FAILURE, binderItem, error } }
}

function updateBinderPreviewInfo(binderItem) {
  return dispatch => {
    dispatch(request());
    // dispatch(alertActions.recordDiagnosticData('binderUploadPreviewInfo', { binder: binderItem }))
    binderService.updateBinderPreviewInfo(binderItem)
      .then(
        id => {
          dispatch(success(binderItem));
        },
        error => {
          // dispatch(alertActions.errorDiagnosticData(error))
          dispatch(failure(binderItem, error.message))
        }
      );
  };

  function request() { return { type: binderConstants.UPDATE_BINDERINFOSTATUS_REQUEST } }
  function success(binderItem) { return { type: binderConstants.UPDATE_BINDERINFOSTATUS_SUCCESS, binderItem } }
  function failure(binderItem, error) { return { type: binderConstants.UPDATE_BINDERINFOSTATUS_FAILURE, binderItem, error } }
}

function publishBinder(binderItem, redirect = true) {
  return (dispatch, getState) => {
    const auth = getState().authentication

    let transactionIds = []
    const customerId = getState().authentication.customerId
    const b = getState().binder[binderItem.id]
    if(b !== undefined && b.activeTransactionIds !== undefined)
      transactionIds = b.activeTransactionIds

    if (window.demo) {
      binderItem = demoData(binderItem, dispatch)
      // dispatch(deleteBinderTransaction(binderItem.id))
      dispatch(success_delete_transaction(binderItem.boardId, binderItem.id))
      dispatch(success(binderItem));
      if (redirect)
        setTimeout(() => {
          history.push({
            pathname: RoutesConstants.binderboard,
            query: { boardId: binderItem.boardId }
          });
        }, 1000)
      return
    }
    // binderItem.commitTransactionIds = transactionIds
    dispatch(request(binderItem));
    //dispatch(alertActions.recordDiagnosticData('binderUpload', { binder: binderItem }))
    binderService.saveBinderTransactionEvent(customerId)
      .then(() => {
          binderService.updateBinder(binderItem)
            .then(
              item => {
                TrackEvent("f_board_binder.published",{
                  user_id: auth.userId,
                  person_id: auth.personId,
                  company_id: auth.customerId,
                  alias: auth.alias,
                  binder_id: binderItem.id,
                  board_id: binderItem.boardId,
                  //binder_selected_type: ,
                  binder_total_agendaitems_count: binderItem.listItems.filter((obj) => obj.binderType !== BinderItemType.multipleDoc).length,
                  binder_doc_agendaitems_count: binderItem.listItems.filter((obj) => obj.binderType === BinderItemType.document && !obj.multidoc).length,
                  binder_multidoc_agendaitems_count: binderItem.listItems.filter((obj) => obj.binderType === BinderItemType.document && obj.multidoc).length,
                  binder_header_agendaitems_count: binderItem.listItems.filter((obj) => obj.binderType === BinderItemType.header).length,
                  binder_minutes_agendaitems_count: binderItem.listItems.filter((obj) => obj.binderType === BinderItemType.minutes).length,
                  binder_resolution_agendaitems_count: binderItem.listItems.filter((obj) => obj.binderType === BinderItemType.resolution).length,
                  binder_video_agendaitems_count: binderItem.listItems.filter((obj) => obj.binderType === BinderItemType.video).length,
                  binder_vote_agendaitems_count: binderItem.listItems.filter((obj) => obj.binderType === BinderItemType.vote).length,
                  binder_published_timestamp: binderItem.updateDate,
                  binder_created_timestamp: binderItem.createDate,
                  binder_lastupdated_timestamp: binderItem.lastUpdateDate,
                  binder_meetingdate: binderItem.meetingDate,
                  is_notifications_sent: binderItem.sendNotification === true? true:false,
                  is_meeting_scheduled: binderItem.confirmMeetingDate === true? true:false,
                })
                delete binderItem.listItems

                if (item.hasOwnProperty('imageId'))
                  if (item.imageId !== "")
                    binderItem.imageId = item.imageId;
                if (binderItem.items.length && binderItem.worker) {
                  binderItem.processType = webConstants.PUBLISH_BINDER;
                  dispatch(queueActions.uploadBinder(binderItem));
                  dispatch(success_nothing(binderItem));
                  //PostWebWorker({type:webConstants.PUBLISH_BINDER, data: binderItem, sessionToken: sessionToken});
                  if (redirect)
                    history.push({
                      pathname: RoutesConstants.binderboard,
                      query: { boardId: binderItem.boardId }
                    });
                } else {
                  dispatch(success(binderItem));

                  //if(binderItem.notification !== undefined && binderItem.notification.length > 0)
                  //  dispatch(alertActions.sendPushNotifications(binderItem.notification));
                  // dispatch(customerActions.setLock({
                  //   objectType: "Binder",
                  //   objectId: binderItem.id,
                  //   detail: "Refresh"
                  // }))

                  // dispatch(deleteBinderTransaction(binderItem.id))
                  dispatch(success_delete_transaction(binderItem.boardId, binderItem.id))
                  dispatch(success_delete_lock({ id: binderItem.id, objectType: "", lockUserId: "" }))
                  if (redirect)
                    history.push({
                      pathname: RoutesConstants.binderboard,
                      query: { boardId: binderItem.boardId }
                    });

                  // binderService.commitPublish(transactionIds[0])
                  // .then(
                  //   item => {
                  //     dispatch(deleteBinderTransaction(binderItem.id))
                  //     dispatch(customerActions.deleteLock("Binder", binderItem.id))
                  //     if (redirect)
                  //       history.push({
                  //         pathname: '/binderboard',
                  //         query: { boardId: binderItem.boardId }
                  //       });
                  //   },
                  //   error => {
                  //     dispatch(alertActions.errorDiagnosticData(error))
                  //     dispatch(failure(binderItem, error.message))
                  //     if (redirect)
                  //       history.push({
                  //         pathname: '/binderboard',
                  //         query: { boardId: binderItem.boardId }
                  //       });
                  //   }
                  // )
                }
              },
              error => {
                dispatch(alertActions.errorDiagnosticData(error))
                dispatch(failure(binderItem, error.message))
              }
            )
        },
        error => {
          dispatch(alertActions.errorDiagnosticData(error))
          dispatch(failure(binderItem, error.message))
        }
      )
  };

  function request(binderItem) { return { type: binderConstants.UPDATE_BINDERSTATUS_REQUEST, binderItem } }
  function success_nothing(binderItem) { return { type: binderConstants.UPDATE_BINDERSTATUS_SUCCESS_DONOTHING, binderItem } }
  function success(binderItem) { return { type: binderConstants.UPDATE_BINDERSTATUS_SUCCESS, binderItem } }
  function success_delete_transaction(boardId, id) { return { type: binderConstants.DELETE_BINDERTRANSACTION_SUCCESS, boardId, id } }
  function success_delete_lock(payload) { return { type: customerConstants.DELETE_LOCKS_SUCCESS, payload } }
  function failure(binderItem, error) { return { type: binderConstants.UPDATE_BINDERSTATUS_FAILURE, binderItem, error } }
}

function archiveBinder(binderItem, redirect = false) {
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    dispatch(requestArchive(customerId));
    dispatch(requestPop(binderItem.id));
    binderService.populateBinderContent(binderItem.id)
      .then(
        item => {
          dispatch(successPop(item))
          dispatch(request(binderItem));

          item.binderStatus = binderItem.binderStatus;
          binderService.archiveBinder(item)
            .then(
              item => {
                dispatch(success(binderItem));
                dispatch(successArchive(customerId));
                if (redirect) {
                  history.push({
                    pathname: RoutesConstants.binderdash,
                    query: { boardId: binderItem.boardId, boardname: binderItem.boardName, binderId: binderItem.id, showArchives: true }
                  });
                }
              },
              error => {
                dispatch(failure(binderItem, error))
                dispatch(failureArchive(customerId))
              }
            );
        },
        error => {
          dispatch(failurePop(binderItem.id, error))
          dispatch(failureArchive(customerId))
        }
      );
  };

  function requestPop(binderId) { return { type: binderConstants.POPULATE_BINDERCONTENT_REQUEST, binderId } }
  function successPop(item) { return { type: binderConstants.POPULATE_BINDERCONTENT_SUCCESS, item } }
  function failurePop(binderId, error) { return { type: binderConstants.POPULATE_BINDERCONTENT_FAILURE, binderId, error } }
  function request(binderItem) { return { type: binderConstants.UPDATE_BINDERSTATUS_REQUEST, binderItem } }
  function success(binderItem) { return { type: binderConstants.UPDATE_BINDERSTATUS_SUCCESS, binderItem } }
  function failure(binderItem, error) { return { type: binderConstants.UPDATE_BINDERSTATUS_FAILURE, binderItem, error } }
  function requestArchive(customerId) { return { type: boardConstants.PROCESSING_BINDER_REQUEST, customerId } }
  function successArchive(customerId) { return { type: boardConstants.PROCESSING_BINDER_SUCCESS, customerId } }
  function failureArchive(customerId) { return { type: boardConstants.PROCESSING_BINDER_FAILURE, customerId } }
}

function moveBinder(binderItem) {
  return dispatch => {
    dispatch(request(binderItem));
    var reqdata = {
      id: binderItem.id,
      binderStatus: binderItem.binderStatus,
      boardId: binderItem.boardId,
    }
    binderService.updateBinderDash(reqdata)
      .then(
        item => {
          dispatch(success(binderItem));
        },
        error => {
          dispatch(failure(binderItem, error))
        }
      );
  };

  function request(binderItem) { return { type: binderConstants.UPDATE_BINDERSTATUS_REQUEST, binderItem } }
  function success(binderItem) { return { type: binderConstants.UPDATE_BINDERSTATUS_SUCCESS, binderItem } }
  function failure(binderItem, error) { return { type: binderConstants.UPDATE_BINDERSTATUS_FAILURE, binderItem, error } }
}

function newBinder(binderItem, redirect = true) {
  return (dispatch, getState) => {
    if (getState().binder[binderItem.id] !== undefined) {
      if(binderItem.published === true)
        dispatch(publishBinder(binderItem, redirect))
      else
        dispatch(updateBinder(binderItem))
      return
    }
    const customerId = getState().authentication.customerId
    const customers = getState().authentication.customers.find(o => o.id === customerId)
    const isFreeium = customers !== undefined && (customers.accountType === FreemiumType.freemium || customers.accountType === FreemiumType.freemium_internal) ? true : false

    dispatch(request());
    if (window.demo) {
      binderItem.id = uuidv4();
      binderItem = demoData(binderItem, dispatch)

      dispatch(success(binderItem));
      // dispatch(deleteBinderTransaction(binderItem.id))
      dispatch(success_delete_transaction(binderItem.boardId, binderItem.id))
      setTimeout(() => {
        history.push({
          pathname: RoutesConstants.binderboard,
          query: { boardId: binderItem.boardId }
        });
      }, 1000)
      return
    }
    dispatch(alertActions.recordDiagnosticData('binderNew', { binder: binderItem }))
    binderService.newBinder(binderItem)
      .then(
        payload => {
          if (isFreeium)
            dispatch(companyActions.getCompanyUserLimits())

          binderItem.id = payload.binderId;
          binderItem.imageId = payload.imageId;
          //pass items to worker and upload in background
          if (binderItem.items.length && payload.binderId !== '' && binderItem.worker) {
            const b = Object.assign({}, binderItem, { binderStatus: BinderStatus.unpublished })
            dispatch(success(b));
            binderItem.processType = webConstants.ADD_BINDER
            if (binderItem.binderStatus === BinderStatus.current) {
              binderItem.processType = webConstants.PUBLISH_BINDER
              if (binderItem.notification !== undefined) {
                binderItem.notification.forEach((n) => {
                  n.detail = payload.binderId
                })
              }
            }
            if (binderItem.cacheId !== "") {
              //              dispatch(renameCachedBinder(binderItem.cacheId, binderItem.id, binderItem.boardId));
              //              binderItem.cacheId = binderItem.id;
              binderService.renameCachedBinder(binderItem.cacheId, binderItem.id)
                .then(
                  result => {
                    dispatch(successRename(binderItem.cacheId, binderItem.id, binderItem.boardId))
                    if (!result) {
                      // dispatch(deleteBinderTransaction(binderItem.id));
                      dispatch(success_delete_transaction(binderItem.boardId, binderItem.id))
                      binderItem.cacheId = "";
                    } else
                      binderItem.cacheId = binderItem.id;
                    dispatch(customerActions.setLock({
                      objectType: "Binder",
                      objectId: binderItem.id,
                      detail: "Upload",
                    }))
                    dispatch(queueActions.uploadBinder(binderItem));
                    history.push({
                      pathname: RoutesConstants.binderboard,
                      query: { boardId: binderItem.boardId }
                    });
                  },
                  error => {
                    dispatch(alertActions.errorDiagnosticData(new CodeError("newBinder", '702', 'INDEXEDDB', {
                      objectId: binderItem.id,
                      binder: binderItem,
                      error: error,
                    }, error)))
                    dispatch(failure('Failed to rename binder cache'))
                    log("INDEXEDDB", error);
                  }
                );
              return;
            }
            dispatch(customerActions.setLock({
              objectType: "Binder",
              objectId: binderItem.id,
              detail: "Upload",
            }))
            dispatch(queueActions.uploadBinder(binderItem));
          } else {
            dispatch(success(binderItem));
            // dispatch(deleteBinderTransaction(binderItem.id))
          }
          history.push({
            pathname: RoutesConstants.binderboard,
            query: { boardId: binderItem.boardId }
          });
        },
        error => {
          dispatch(alertActions.errorDiagnosticData(error))
          dispatch(failure(binderItem, error));
        }
      );
  };
  function successRename(from, to, boardId) { return { type: binderConstants.RENAME_BINDERCACHED_SUCCESS, from, to, boardId } }
  function success_delete_transaction(boardId, id) { return { type: binderConstants.DELETE_BINDERTRANSACTION_SUCCESS, boardId, id } }
  function request() { return { type: binderConstants.NEW_BINDERSTATUS_REQUEST } }
  function success(binderItem) { return { type: binderConstants.NEW_BINDERSTATUS_SUCCESS, binderItem } }
  function failure(binderItem, error) { return { type: binderConstants.NEW_BINDERSTATUS_FAILURE, binderItem, error } }
}

function downloadbinder(dispatch, getState, id, template = false) {
  var binders = getState().binder
  var binder = null
  var r = [0], count = [0]
  if (binders[id] !== undefined) {
    binder = binders[id]
    if (binder.itemCount > 0 && !window.demo) {
      var myNum = binder.itemCount / ITEMCHUCKLIMIT;
      var fraction = binder.itemCount - (Math.floor(myNum) * ITEMCHUCKLIMIT);
      myNum = Math.floor(myNum)
      if (myNum >= 1) {
        var d = [...Array(myNum)].fill(ITEMCHUCKLIMIT)
        r = r.concat(d)
        count = Array(myNum + 1).fill(0)
      }
      if(fraction > 0) r.push(0)
      dispatch(loading_init(binder.itemCount+10));
    }
  }

  function c(count) {
    return count.reduce(function (accumulator, item) {
      return accumulator + item
    }, 0)
  }

  function RetriveItem(range, index) {
    if (range === 0 && index === 0) {
      if (template)
        return RetriveTemplate()
      return RetriveBinder()
    }
    return new Promise((resolve, reject) => {
      var retries = 3;
      var retryDelay = 10000;
      var opt = { 'Content-Type': 'application/json' }

      var wrappedFetch = function (n) {
        binderService.getItemRange(id, (index - 1) * ITEMCHUCKLIMIT, range, opt, template)
          .then(
            item => {
              count[index + 1] = item.length
              dispatch(loading_prog(c(count)))
              resolve(item)
            },
            error => {
              if (error.code !== undefined && error.code === "F206") {
                opt['AsyncToken'] = error.asyncToken
                error.response.json().then((data) => {
                  count[index + 1] = data.length
                  dispatch(loading_prog(c(count)))
                })
                retry(retries)
                return
              }
              reject(error)
            }
          );
      }

      function retry(n) {
        setTimeout(function () {
          wrappedFetch(--n);
        }, retryDelay);
      }

      wrappedFetch(retries);
    })
  }

  function RetriveBinder() {
    return new Promise((resolve, reject) => {
      binderService.populateBinderContent(id, binder !== null ? true : false)
        .then(
          item => {
            count[0] = 10
            dispatch(loading_prog(c(count)))
            resolve(item)
          },
          error => {
            reject(error)
          }
        );
    })
  }

  function RetriveTemplate() {
    return new Promise((resolve, reject) => {
      binderService.populateTemplateContent(id, binder !== null ? true : false)
        .then(
          item => {
            count[0] = 10
            dispatch(loading_prog(c(count)))
            resolve(item)
          },
          error => {
            reject(error)
          }
        );
    })
  }

  var promiseLimit = require('promise-limit')
  var limit = promiseLimit(BINDERSIMUPLOAD)


  var timerPercentage = setTimeout(() => {
    dispatch(loading_done());
  }, 4 * 60 * 1000);

  return Promise.all(r.map((range, index) => {
    return limit(() => RetriveItem(range, index))
  }))
    .then((binderData) => {
      clearTimeout(timerPercentage);
      var b = binderData[0]
      if (binderData.length > 1) {
        b.items = []
        for (var i = 1; i < binderData.length; i++) {
          b.items = b.items.concat(binderData[i])
        }
      }
      dispatch(loading_done());
      return b
    })

  function loading_init(total) { return { type: alertConstants.LOADING_INDICATOR_START, total } }
  function loading_prog(progress) { return { type: alertConstants.LOADING_INDICATOR_PROGRESS, progress } }
  function loading_done() { return { type: alertConstants.LOADING_INDICATOR_SUCCESS } }
}

function popBinderContentShow(id, boardId) {
  return (dispatch, getState) => {
    if (checkDemo('popBinderContentShow' + id + boardId)) return
    dispatch(request(id));
    downloadbinder(dispatch, getState, id)
      .then((binder) => {
        if (binder.binderStatus !== BinderStatus.current) return binder

        return new Promise((resolve, reject) => {
          boardService.getBinderCalendars(id)
            .then(
              list => {
                binder.calendarId = list.id
                dispatch(calendarsuccess(list, id, boardId))
                resolve(binder)
              },
              error => {
                resolve(binder)
              }
            )
        })
      })
      .then((binder) => {
        if (binder.activeTransactionIds.length > 0) {
          return new Promise((resolve, reject) => {
            const transactionId = binder.activeTransactionIds[0];
            log("popBinderContentShow transactionId", transactionId)
            binderService.getBinderTransactionEvent(transactionId)
              .then(
                list => {
                  log("popBinderContentShow List", list === '404' ? 0 : list.length)
                  binder.transactionEvents = []
                  if(list === '404'){
                    binder.activeTransactionIds = []
                  }else if(list.length > 0){
                    list.forEach(l => {
                      if(l.detail) {
                        binder.transactionEvents.push(JSON.parse(l.detail))
                      }
                    })
                    dispatch(transactioneventsuccess(list, transactionId, id, boardId))
                  }
                  resolve(binder)
                },
                error => {
                  reject(error)
                }
              )
          })
        } else return binder
      })
      .then(b => {
        dispatch(success(b, false));
      })
      .catch(error => {
        error = CheckInternet(error, dispatch)
        dispatch(failure(id, error))
        history.push({
          pathname: RoutesConstants.binderboard,
          query: {error: 'open', boardId: boardId}
        });
      })
  };

  function calendarsuccess(payload, binderId, boardId) { return { type: boardConstants.GET_CALENDARBINDER_SUCCESS, payload, binderId, boardId } }
  function transactioneventsuccess(payload, transactionId, binderId, boardId) { return { type: binderConstants.GET_BINDERTRANSACTIONEVENT_SUCCESS, payload, transactionId, binderId, boardId } }

  function request(binderId) { return { type: binderConstants.POPULATE_BINDERCONTENT_REQUEST, binderId } }
  function success(item, changeModifiedItem) { return { type: binderConstants.POPULATE_BINDERCONTENT_SUCCESS, item, changeModifiedItem } }
  function failure(binderId, error) { return { type: binderConstants.POPULATE_BINDERCONTENT_FAILURE, binderId, error } }
}

function openBinder(id, boardId, options, copy = false) {
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    if (window.demo) {
      const binders = getState().binder
      if (binders[id] !== undefined && binders[id].demo === true) {
        history.push({
          pathname: RoutesConstants.bindernewwizard,
          query: { board: { boardId: boardId !== null ? boardId : binder.boardId, boardname: '', binderId: id, copy: copy, options: options } }
        });
        return
      }
    }
    if (checkDemo('openBinder' + id + boardId)) {
      history.push({
        pathname: RoutesConstants.bindernewwizard,
        query: { board: { boardId: boardId !== null ? boardId : binder.boardId, boardname: '', binderId: id, copy: copy, options: options } }
      });
      return
    }
    var binders = getState().binder
    var binder = null
    //var myId = getState().authentication.userId
    //var users = getState().users.data
    //var customerId = getState().authentication.customerId
    //var keys = getState().authentication.keys

    if (binders[id] !== undefined) {
      binder = binders[id]
    }

    dispatch(binderrequest(id));
    dispatch(request(customerId));

    if (binder === null) {
      binderService.populateBinderContent(id, true)
        .then(
          item => {
            dispatch(binderupdate(item))
            getBinder()
          },
          error => {
            dispatch(failure(id, error, customerId))
            history.push({
              pathname: RoutesConstants.binderboard,
              query: { error: 'open' }
            });
          }
        );
    } else {
      getBinder()
    }

    function getBinder() {
      downloadbinder(dispatch, getState, id)
        /*.then((binder) => {
          if(keys === undefined || keys[customerId] === undefined || keys[customerId].kUser === undefined){
            return binder
          }
          return new Promise((resolve, reject) => {
            binderService.checkBinderContents(binder, myId, users, customerId, keys)
            .then((b) => {
              resolve(b)
            })
            .catch(error => {
    debugger
              reject(error)
            })
          })
        })*/
        .then((binder) => {
          if (binder.binderStatus !== BinderStatus.current) return binder

          return new Promise((resolve, reject) => {
            boardService.getBinderCalendars(id)
              .then(
                list => {
                  binder.calendarId = list.id
                  dispatch(calendarsuccess(list, id, boardId))
                  resolve(binder)
                },
                error => {
                  resolve(binder)
                }
              )
          })
        })
        /* TODO add tranaction ID stuff*/
        .then((binder) => {
          if (binder.activeTransactionIds.length > 0) {
            return new Promise((resolve, reject) => {
              const transactionId = binder.activeTransactionIds[0];
              log("openBinder transactionId", transactionId)
              binderService.getBinderTransactionEvent(transactionId)
                .then(
                  list => {
                    log("openBinder List", list === '404' ? 0 : list.length)
                    binder.transactionEvents = []
                    if(list === '404'){
                      binder.activeTransactionIds = []
                    }else if(list.length > 0){
                      list.forEach(l => {
                        if(l.detail) {
                          binder.transactionEvents.push(JSON.parse(l.detail))
                        }
                      })
                      dispatch(transactioneventsuccess(list, transactionId, id, boardId))
                    }
                    resolve(binder)
                  },
                  error => {
                    reject(error)
                  }
                )
            })
          } else return binder
        })
        .then(b => {
          dispatch(bindersuccess(b));
          dispatch(customerActions.setLock({
            objectType: "Binder",
            objectId: id,
            detail: "Edit"
          }))
          dispatch(success(customerId));
          history.push({
            pathname: RoutesConstants.bindernewwizard,
            query: { board: { boardId: boardId !== null ? boardId : binder.boardId, boardname: '', binderId: id, copy: copy, options: options } }
          });
        })
        .catch(error => {
          log("ERROR", error)
          error = CheckInternet(error, dispatch)
          dispatch(failure(id, error, customerId))
          history.push({
            pathname: RoutesConstants.binderboard,
            query: {error: 'open', boardId: boardId !== null ? boardId : binder.boardId}
          });
        })
    }
  };

  function binderrequest(binderId) { return { type: binderConstants.POPULATE_BINDERCONTENT_REQUEST, binderId } }
  function bindersuccess(item) { return { type: binderConstants.POPULATE_BINDERCONTENT_SUCCESS, item } }
  function binderupdate(item) { return { type: binderConstants.POPULATE_BINDERCONTENT_UPDATE, item } }
  function binderfailure(binderId, error) { return { type: binderConstants.POPULATE_BINDERCONTENT_FAILURE, binderId, error } }

  function calendarsuccess(payload, binderId, boardId) { return { type: boardConstants.GET_CALENDARBINDER_SUCCESS, payload, binderId, boardId } }
  function transactioneventsuccess(payload, transactionId, binderId, boardId) { return { type: binderConstants.GET_BINDERTRANSACTIONEVENT_SUCCESS, payload, transactionId, binderId, boardId } }

  function request(customerId) { return { type: binderConstants.COPY_BINDERCONTENT_REQUEST, customerId } }
  function success(customerId) { return { type: binderConstants.COPY_BINDERCONTENT_SUCCESS, customerId } }
  function failure(binderId, error, customerId) { return { type: binderConstants.COPY_BINDERCONTENT_FAILURE, binderId, error, customerId } }
}

function clearLock(binderId) {
  return (dispatch) => {
    dispatch(success({ objectId: binderId, userId: "", expiryTime: "" }))
  }

  function success(payload) { return { type: binderConstants.BINDER_WEBSOCKET_LOCK, payload } }
}

function openTemplate(boardId, boardName, id, draft) {
  return (dispatch, getState) => {
    var binders = getState().binder
    var binder = null
    if (binders[id] !== undefined) {
      binder = binders[id]
    }
    var i = { boardId: boardId !== null ? boardId : binder.boardId, boardname: '', binderId: id, copy: false }

    if (window.demo) {
      const binders = getState().binder
      if (binders[id] !== undefined && binders[id].demo === true) {
        history.push({
          pathname: RoutesConstants.templatenew,
          query: { board: i }
        });
        return
      }
    }
    if (checkDemo('openTemplate' + id + boardId)) {
      history.push({
        pathname: RoutesConstants.templatenew,
        query: { board: i }
      });
      return
    }

    dispatch(request());

    downloadbinder(dispatch, getState, id, true)
    .then((binder) => {
      if (binder.activeTransactionIds.length > 0) {
        return new Promise((resolve, reject) => {
          const transactionId = binder.activeTransactionIds[0];
          binderService.getBinderTransactionEvent(transactionId)
            .then(
              list => {
                binder.transactionEvents = []
                if(list === '404'){
                  binder.activeTransactionIds = []
                }else if(list.length > 0){
                  list.forEach(l => {
                    if(l.detail) {
                      binder.transactionEvents.push(JSON.parse(l.detail))
                    }
                  })
                  dispatch(transactioneventsuccess(list, transactionId, id, boardId))
                }
                resolve(binder)
              },
              error => {
                resolve(binder)
              }
            )
        })
      } else return binder
    })
      .then(
        b => {
          dispatch(customerActions.setLock({
            objectType: "BinderTemplate",
            objectId: id,
            detail: "Edit"
          }))
          dispatch(success(b));
          if (draft) i.draft = true
          history.push({
            pathname: RoutesConstants.templatenew,
            query: { board: i }
          });
        },
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(id, error))
          history.push({
            pathname: RoutesConstants.binderboard,
            query: {error: 'open', boardId: boardId !== null ? boardId : binder.boardId}
          });
        }
      );
  };

  function request(templateId) { return { type: binderConstants.POPULATE_TEMPLATECONTENT_REQUEST, templateId } }
  function success(item) { return { type: binderConstants.POPULATE_TEMPLATECONTENT_SUCCESS, item } }
  function transactioneventsuccess(payload, transactionId, binderId, boardId) { return { type: binderConstants.GET_BINDERTRANSACTIONEVENT_SUCCESS, payload, transactionId, binderId, boardId } }
  function failure(templateId, error) { return { type: binderConstants.POPULATE_TEMPLATECONTENT_FAILURE, templateId, error } }
}

function copyBinder(id, boardId, options, page = false) {
  return (dispatch, getState) => {
    var binders = getState().binder
    const customerId = getState().authentication.customerId
    var binder = null
    if (binders[id] !== undefined) {
      binder = binders[id]
    }

    if (window.demo) {
      if (binder !== undefined && binder.demo === true) {
        dispatch(success(customerId));
        history.push({
          pathname: page ? RoutesConstants.bindernewwizard : RoutesConstants.templatenew,
          query: { board: { boardId: boardId !== null ? boardId : binder.boardId, boardname: '', binderId: id, copy: true, options: options } }
        });
        return
      }
    }

    dispatch(binderrequest(id));
    dispatch(request(customerId));

    downloadbinder(dispatch, getState, id)
      .then(
        b => {
          dispatch(bindersuccess(b));
          dispatch(success(customerId));
          history.push({
            pathname: page ? RoutesConstants.bindernewwizard : RoutesConstants.templatenew,
            query: { board: { boardId: boardId !== null ? boardId : binder.boardId, boardname: '', binderId: id, copy: true, options: options } }
          });
        },
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(id, error, customerId))
        }
      );
  };

  function binderrequest(binderId) { return { type: binderConstants.POPULATE_BINDERCONTENT_REQUEST, binderId } }
  function bindersuccess(item) { return { type: binderConstants.POPULATE_BINDERCONTENT_SUCCESS, item } }
  function binderfailure(binderId, error) { return { type: binderConstants.POPULATE_BINDERCONTENT_FAILURE, binderId, error } }

  function request(customerId) { return { type: binderConstants.COPY_BINDERCONTENT_REQUEST, customerId } }
  function success(customerId) { return { type: binderConstants.COPY_BINDERCONTENT_SUCCESS, customerId } }
  function failure(binderId, error, customerId) { return { type: binderConstants.COPY_BINDERCONTENT_FAILURE, binderId, error, customerId } }
}

function copyTemplate(boardId, boardName, id, options, page = false) {
  return (dispatch, getState) => {
    var binders = getState().binder
    var binder = null
    if (binders[id] !== undefined) {
      binder = binders[id]
    }

    var i = { boardId: boardId !== null ? boardId : binder.boardId, boardname: '', binderId: id, copy: true, options: options }
    if (window.demo) {
      if (binder !== undefined && binder.demo === true) {
        history.push({
          pathname: page ? RoutesConstants.bindernewwizard : RoutesConstants.templatenew,
          query: { board: i }
        });
        return
      }
    }

    dispatch(request());

    downloadbinder(dispatch, getState, id, true)
      .then(
        b => {
          dispatch(success(b));
          history.push({
            pathname: page ? RoutesConstants.bindernewwizard : RoutesConstants.templatenew,
            query: { board: i }
          });
        },
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(id, error))
        }
      );
  };

  function request(templateId) { return { type: binderConstants.POPULATE_TEMPLATECONTENT_REQUEST, templateId } }
  function success(item) { return { type: binderConstants.POPULATE_TEMPLATECONTENT_SUCCESS, item } }
  function failure(templateId, error) { return { type: binderConstants.POPULATE_TEMPLATECONTENT_FAILURE, templateId, error } }
}

function getBinderItem(itemId) {
  return dispatch => {
    dispatch(request(itemId));
    binderService.getBinderItem(itemId)
      .then(
        item => dispatch(success(item)),
        error => {
          error = CheckInternet(error, dispatch)
          dispatch(failure(error))
        }
      );
  };

  function request(itemId) { return { type: binderConstants.GET_ITEM_REQUEST, itemId } }
  function success(item) { return { type: binderConstants.GET_ITEM_SUCCESS, item } }
  function failure(itemId, error) { return { type: binderConstants.GET_ITEM_FAILURE, itemId, error } }
}

function deleteBinderItem(itemId) {
  return dispatch => {
    dispatch(request(itemId));
    binderService.deleteBinderItem(itemId)
      .then(
        item => dispatch(success(item)),
        error => dispatch(failure(error))
      );
  };

  function request(itemId) { return { type: binderConstants.DELETE_ITEM_REQUEST, itemId } }
  function success(item) { return { type: binderConstants.DELETE_ITEM_SUCCESS, item } }
  function failure(itemId, error) { return { type: binderConstants.DELETE_ITEM_FAILURE, itemId, error } }
}

function downloadUserDocument(documentitem) {
  return dispatch => {
    dispatch(request(documentitem.documentId))
    binderService.downloadUserDocument(documentitem)
      .then(
        newData => {
          documentitem.kUser = newData.newKey;
          documentitem.documentId = newData.newDocumentId;
          dispatch(fileActions.DownloadFile(documentitem));
        },
        error => {
          dispatch(failure(documentitem.documentId, error))
        }
      );
  };

  function request(documentId) { return { type: binderConstants.RESOLUTION_DOWNLOAD_REQUEST, documentId } }
  function failure(documentId, error) { return { type: binderConstants.RESOLUTION_DOWNLOAD_FAILURE, documentId, error } }
}

function downloadAdminDocument(documentitem) {
  return dispatch => {
    binderService.downloadAdminDocument(documentitem)
      .then(
        newDocumentId => {
          documentitem.documentId = newDocumentId;
          dispatch(fileActions.DownloadFile(documentitem));
        },
        error => {
        }
      );
  };
}

function getAllResolutions(documentitem, populateOnly = false) {
  return dispatch => {
    let result = documentitem.userItems.reduce((previousPromise, userItem) => {
      return previousPromise.then(() => {
        return new Promise((resolvePage, rejectPage) => {
          var u = {
            id: userItem.resolutionId,
            fileName: userItem.fileName,
            fileSize: userItem.fileSize,
            resolutionId: userItem.resolutionId,
            documentId: userItem.documentId,
            targetUserId: userItem.targetUserId,
            myIds: documentitem.myIds,
            key: userItem.key,
            binderId: documentitem.binderId,
            boardId: documentitem.boardId,
          }

          if (userItem.isAdmin) {
            u.kUser = documentitem.pUserGenSec

            binderService.downloadAdminDocument(u)
              .then(
                newDocumentId => {
                  u.documentId = newDocumentId
                  if (!populateOnly)
                    dispatch(fileActions.DownloadFile(u))
                  else
                    dispatch(fileActions.PopulateFile(u))
                  resolvePage()
                },
                error => {
                  rejectPage(error)
                }
              );
          } else {
            u.kUser = documentitem.kUser
            binderService.downloadUserDocument(u)
              .then(
                newData => {
                  u.kUser = newData.newKey
                  u.documentId = newData.newDocumentId
                  if (!populateOnly)
                    dispatch(fileActions.DownloadFile(u))
                  else
                    dispatch(fileActions.PopulateFile(u))
                  resolvePage()
                },
                error => {
                  rejectPage(error)
                }
              );
          }
        })
      });
    }, Promise.resolve())

    result.then(e => {
      //resolve()
    });
  }
}

function DownloadDocument(documentitem) {
  return (dispatch, getState) => {
    documentitem.processType = webConstants.DOWNLOAD_DOCUMENT;
    dispatch(queueActions.downloadDocument(documentitem));
    //PostWebWorker({type:webConstants.DOWNLOAD_DOCUMENT, data: documentitem, sessionToken: sessionToken});
  };
}

function populateDocument(documentitem) {
  return (dispatch, getState) => {
    documentitem.processType = webConstants.DOWNLOAD_POPULATE;
    dispatch(queueActions.downloadDocument(documentitem));
    //PostWebWorker({type:webConstants.DOWNLOAD_POPULATE, data: documentitem, sessionToken: sessionToken});
  };

  //  function downloadDocument(documentitem) { return { type: queueConstants.DOWNLOAD_QUEUE_DOCUMENT_REQUEST, documentitem } }
}

function CallbackDocumentDownload(documentitem, failed = false) {
  return (dispatch, getState) => {
    if (!documentitem || !documentitem.id) { return; }
    var callbackId = 'document-populate-callback#' + documentitem.id;
    var callback = getState().keyValuePair[callbackId];
    if (callback) {
      callback(documentitem, failed); //callback(item, failed = false)
      dispatch(kvpActions.delete_kvp(callbackId));
    }
  }
}

function CompletedDocumentDownload(documentitem) {
  return (dispatch, getState) => {
    dispatch(CallbackDocumentDownload(documentitem));
    dispatch(request(documentitem));
  };

  function request(documentitem) { return { type: binderConstants.COMPLETE_DOWNLOAD_DOCUMENT_REQUEST, documentitem } }
}

function CompletedBinderTemplateUpload(data) {
  return dispatch => {
    dispatch(request(data));
  };

  function request(data) { return { type: binderConstants.COMPLETE_UPLOAD_BINDER_REQUEST, data } }
}

/*function SetErrorBinder(item) {
  return dispatch => {
    dispatch(request(item));
    //dispatch(alertActions.error(item.error));
  };

  function request(item) { return { type: binderConstants.SET_BINDER_ERROR_REQUEST, item } }
}*/

function SetErrorDocument(item) {
  return dispatch => {
    dispatch(request(item));
    //dispatch(alertActions.error(item.error));
  };

  function request(item) { return { type: binderConstants.SET_DOCUMENT_ERROR_REQUEST, item } }
}

/*function UpdateProgressTemplate(data){
  return dispatch => {
    dispatch(request(data));
  };

  function request(data) { return { type: binderConstants.UPDATE_STATS_TEMPLATE_REQUEST, data } }
}*/

/*function UpdateProgressBinder(data){
  return dispatch => {
    dispatch(request(data));
  };

  function request(data) { return { type: binderConstants.UPDATE_STATS_BINDER_REQUEST, data } }
}*/

function getVote(id) {
  return dispatch => {
    binderService.getVote(id)
      .then(
        voteAnswer => {
          //  dispatch(success(item))
        },
        error => {
          //  dispatch(failure(error))
        }
      );
  };

  //function request(itemId) { return { type: binderConstants.DELETE_ITEM_REQUEST, itemId } }
  //function success(item) { return { type: binderConstants.DELETE_ITEM_SUCCESS, item } }
  //function failure(itemId, error) { return { type: binderConstants.DELETE_ITEM_FAILURE, itemId, error } }
}

function getAllVotes(items) {
  return dispatch => {
    dispatch(request(items.id))
    binderService.getAllVotes(items)
      .then(
        voteAnswer => {
          dispatch(success(items.id, voteAnswer))
        },
        error => {
          dispatch(failure(items.id, error))
        }
      );
  };

  function request(itemId) { return { type: binderConstants.ADD_VOTEANSWERS_REQUEST, itemId } }
  function success(itemId, answers) { return { type: binderConstants.ADD_VOTEANSWERS_SUCCESS, itemId, answers } }
  function failure(itemId, error) { return { type: binderConstants.ADD_VOTEANSWERS_FAILURE, itemId, error } }
}

function getBinderUsage(boardId, binderId) {
  return dispatch => {
    if (checkDemo('getBinderUsage' + boardId + binderId)) return
    binderService.getBinderUsage(boardId, binderId)
      .then(
        payload => {
          dispatch(success(payload, binderId))
        },
        error => {
          dispatch(failure(binderId, error))
        }
      );
  };

  function request() { return { type: binderConstants.GET_BINDERUSAGE_REQUEST } }
  function success(payload, id) { return { type: binderConstants.GET_BINDERUSAGE_SUCCESS, payload, id } }
  function failure(itemId, error) { return { type: binderConstants.GET_BINDERUSAGE_FAILURE, itemId, error } }
}

function workerAllBinderFileUpdate(userItem) {
  return (dispatch) => {
    dispatch(request());
    userService.populateListofUsers(userItem.customerId)
      .then(
        users => {
          dispatch(successUsers(users, userItem.customerId))

          new Promise((resolveSection, rejectSection) => {
            var promiseBinders = [];
            dispatch(taskStep({
              id: userItem.taskId[0].id,
              step: 0,
              stepTotal: users.boards.length,
            }));
            for (var x = 0; x < users.boards.length; x++) {
              var boardId = users.boards[x].id;
              promiseBinders.push(
                new Promise((resolve, reject) => {
                  var boardIdA = boardId;
                  boardService.getMembership(boardIdA)
                    .then(
                      membership => {
                        dispatch(successMembers(boardIdA, membership));
                        //Process Binders Information
                        //get all templates and binders for each boards
                        boardService.previewBoardBinders(boardIdA)
                          .then(
                            binders => {
                              dispatch(successBindersPreview(binders, boardIdA, userItem.customerId));
                              //get the templates list
                              boardService.getBoardTemplates(boardIdA)
                                .then(
                                  templist => {
                                    var bId = boardIdA;
                                    resolve({
                                      templates: templist,
                                      binders: binders,
                                    })
                                  },
                                  error => {
                                    dispatch(errorTask(userItem.taskId[0].id, error))
                                    dispatch(failure(error))
                                  }
                                );
                            },
                            error => {
                              dispatch(errorTask(userItem.taskId[0].id, error))
                              dispatch(failure(error))
                            }
                          )
                          .catch((error) => {
                            dispatch(errorTask(userItem.taskId[0].id, error))
                            dispatch(failure(error))
                          });
                      },
                      error => {
                        dispatch(failure(error))
                      }
                    );
                })
              );
            }
            Promise.all(promiseBinders)
              .then((data) => {
                resolveSection(data);
              })
              .catch((error) => {
                rejectSection(error);
              })
          })
            .then((bindersData) => {
              var binders = [], templates = [];
              bindersData.forEach((o) => {
                function addToList(obj) {
                  binders.push({
                    id: obj.id,
                    binderId: obj.id,
                  });
                }
                o.binders.Archive.forEach(addToList);
                o.binders.Current.forEach(addToList);
                o.binders.Previous.forEach(addToList);
                o.binders.Unpublished.forEach(addToList);

                o.templates.forEach(function (id) {
                  templates.push({
                    id: id,
                    binderId: id,
                  })
                });
              })

              dispatch(taskStep({
                id: userItem.taskId[0].id,
                stepTotalAppend: binders.length + templates.length,
              }))

              return ({ binders: binders, templates: templates });
            })
            .then((data) => {
              var listBinders = data.binders;
              return new Promise((resolve, reject) => {
                //Collect the data for binder that wish to Add
                var pos = 0;

                function Chunck() {
                  if (pos >= listBinders.length) {
                    //we all done - return results
                    resolve({ binders: listBinders, templates: data.templates });
                    return;
                  }

                  var promiseBinder = [];
                  function GetNextFive() {
                    var array = [];
                    var count = 0;
                    for (var x = pos; x < listBinders.length; x++) {
                      if (count > 4) {
                        break;
                      }

                      var binderIdp = listBinders[x].binderId;
                      array.push(
                        new Promise((resolveAPI, rejectAPI) => {
                          var binderId = binderIdp;
                          binderService.populateBinderContent(binderId)
                            .then(
                              binders => {
                                dispatch(successBinders(binders));
                                binders.type = "binder";
                                if (userItem.taskId.length > 0) {
                                  userItem.taskId.forEach(function (task) {
                                    dispatch(taskStep({
                                      id: task.id,
                                      stepIncrement: pos,
                                    }));
                                  });
                                }
                                resolveAPI(binders);
                              },
                              error => {
                                rejectAPI(binderId + ": " + error)
                              }
                            ).catch((error) => {
                              rejectAPI(binderId + ": " + error)
                            });
                        })
                      )
                      count++;
                    }
                    pos += count;

                    return array;
                  }

                  promiseBinder = GetNextFive();

                  Promise.all(promiseBinder)
                    .then((data) => {
                      for (var y = 0; y < data.length; y++) {
                        var binder = data[y];

                        var i = listBinders.findIndex(o => o.id === data[y].id);
                        if (i !== -1) {
                          listBinders[i] = data[y];
                        }
                      }
                      Chunck();
                    })
                    .catch((error) => {
                      reject(error);
                    })
                }

                Chunck();
              });
            })
            .then((data) => {
              var listTemplates = data.templates;
              var listBinders = data.binders;
              return new Promise((resolve, reject) => {
                //Collect the data for binder that wish to Add
                var pos = 0;

                function Chunck() {
                  if (pos >= listTemplates.length) {
                    //we all done - return results
                    var results = listTemplates.concat(listBinders);
                    resolve(results);
                    return;
                  }

                  var promiseBinder = [];
                  function GetNextFive() {
                    var array = [];
                    var count = 0;
                    for (var x = pos; x < listTemplates.length; x++) {
                      if (count > 4) {
                        break;
                      }

                      var binderIdp = listTemplates[x].binderId;
                      array.push(
                        new Promise((resolveAPI, rejectAPI) => {
                          var binderId = binderIdp;
                          binderService.populateTemplateContent(binderId)
                            .then(
                              binders => {
                                dispatch(successPopTemplate(binders));
                                binders.type = "template";
                                binders.binderStatus = 'template';
                                if (userItem.taskId.length > 0) {
                                  userItem.taskId.forEach((task) => {
                                    dispatch(taskStep({
                                      id: task.id,
                                      stepIncrement: pos,
                                    }));
                                  });
                                }
                                resolveAPI(binders);
                              },
                              error => {
                                rejectAPI(binderId + ": " + error)
                              }
                            );
                        })
                      )
                      count++;
                    }
                    pos += count;

                    return array;
                  }

                  promiseBinder = GetNextFive();

                  Promise.all(promiseBinder)
                    .then((data) => {
                      for (var y = 0; y < data.length; y++) {
                        var binder = data[y];

                        var i = listTemplates.findIndex(o => o.id === data[y].id);
                        if (i !== -1) {
                          listTemplates[i] = data[y];
                        }
                      }
                      Chunck();
                    })
                    .catch((error) => {
                      reject(error);
                    })
                }

                Chunck();
              });
            })
            .then((bindersData) => {
              //Collect File data
              return new Promise((resolveSection, rejectSection) => {
                var promiseFiles = [];
                dispatch(taskStep({
                  id: userItem.taskId[0].id,
                  step: 1,
                }));
                for (var x = 0; x < users.boards.length; x++) {
                  var boardId = users.boards[x].id;
                  promiseFiles.push(
                    new Promise((resolve, reject) => {
                      var boardIdA = boardId;
                      boardService.getMembership(boardIdA)
                        .then(
                          membership => {
                            dispatch(successMembers(boardIdA, membership));
                            //check the membership against task
                            var found = userItem.taskId.find((e) => {
                              var member = membership.find((b) => {
                                return e.dataId === b.id;
                              });
                              return ((e.type === UserAccountTask.BoardRegistration && member !== undefined) || e.type !== UserAccountTask.BoardRegistration);
                            });
                            if (!found) {
                              resolve();
                            } else {
                              boardService.getBoardFileList(boardIdA)
                                .then(
                                  list => {
                                    var bId = boardIdA;
                                    dispatch(successBoardFiles({ boardId: bId, fileIds: list }));
                                    dispatch(taskStep({
                                      id: userItem.taskId[0].id,
                                      stepTotalAppend: list.length,
                                    }));
                                    //get the file IDs
                                    var promisefiles = [];
                                    for (var y = 0; y < list.length; y++) {
                                      var fileId = list[y];
                                      promisefiles.push(
                                        new Promise((resolvefile, rejectfile) => {
                                          fileService.GetFileProperties(fileId)
                                            .then(
                                              filestat => {
                                                dispatch(successFileDetails(filestat));
                                                filestat.boardId = bId;
                                                dispatch(taskStep({
                                                  id: userItem.taskId[0].id,
                                                  stepIncrement: 0,
                                                }));
                                                resolvefile({ boardId: bId, membership: membership, filestat: filestat });
                                              },
                                              error => {
                                                log('error3', error);
                                                rejectfile(error)
                                              }
                                            )
                                        })
                                      );
                                    }
                                    Promise.all(promisefiles)
                                      .then((filesData) => {
                                        var boardId = "";
                                        var membership = [];
                                        var Data = [];
                                        for (var y = 0; y < filesData.length; y++) {
                                          boardId = filesData[y].boardId;
                                          membership = filesData[y].membership;
                                          Data.push(filesData[y].filestat);
                                        }
                                        resolve({ boardId: boardId, membership: membership, filesData: Data });
                                      })
                                      .catch((error) => {
                                        log('error2', error);
                                        dispatch(failureFile(error));
                                        dispatch(alertActions.error(error));
                                      });
                                  },
                                  error => {
                                    log('error1', error);
                                    reject(error)
                                  }
                                );
                            }
                          },
                          error => {
                            dispatch(failureFile(error))
                          }
                        );
                    })
                  );
                }

                Promise.all(promiseFiles)
                  .then((data) => {
                    resolveSection({ bindersData, filesData: data });
                  })
                  .catch((error) => {
                    rejectSection(error);
                  })
              });
            })
            .then((Data) => {
              var compressList = Data.bindersData;
              var Filedata = Data.filesData;
              dispatch(taskStep({
                id: userItem.taskId[0].id,
                step: 2,
              }));

              var newUserId = BLANK_GUID//userItem.userId;
              var userMap = users.users.map(e => e.id);
              var jobQueue = [];
              for (var y = 0; y < compressList.length; y++) {
                var binder = compressList[y];
                if (binder.items === undefined) break;
                if (binder.items.length === 0) continue

                var adminUsers = [];
                var KeyList = [];
                users.adminUsers.forEach((obj) => {
                  if (obj.hasOwnProperty('kUserPublic') && obj.hasRegistered === true)
                    if (obj.kUserPublic !== "") {
                      KeyList.push({ userId: obj.id, key: obj.kUserPublic });
                      adminUsers.push(obj.id);
                    }
                });

                KeyList.push({ userId: BLANK_GUID, key: userItem.kUserGenSec });

                var items = [];
                binder.items.forEach((obj) => {
                  var binderType = getBinderType(obj.type, obj.style);

                  var i = {
                    id: obj.id,
                    documentId: '',
                    voteId: '',
                    position: obj.position,
                    adminPositionString: '',
                    name: '',
                    filename: '',
                    timing: 0,
                    expiryDate: null,
                    key: '',
                    type: obj.type,
                    style: obj.style,
                    binderType: binderType,
                    size: 0,
                    date: '',
                    itemdata: null,
                    image: null,
                    userItems: [],
                    voteDes: "",
                    voteAns: [],
                    duration: "",
                    indentCount: 0,
                    positionString: '',
                    itemRequiresDecision: '',
                    itemPresentedBy: '',
                    showItemName: null,
                    updateDate: obj.updateDate,
                    genseckey: false,
                  };
                  if (obj.hasOwnProperty('documentId')) i.documentId = obj.documentId;
                  if (obj.hasOwnProperty('voteId')) i.voteId = obj.voteId;
                  if (obj.hasOwnProperty('name')) i.name = obj.name;
                  if (obj.hasOwnProperty('fileName')) i.filename = obj.fileName;
                  if (obj.hasOwnProperty('timing')) i.timing = obj.timing;
                  if (obj.hasOwnProperty('expiryDate')) i.expiryDate = obj.expiryDate;
                  if (obj.hasOwnProperty('key')) i.key = obj.key;
                  if (obj.hasOwnProperty('size')) i.size = obj.size;
                  if (obj.hasOwnProperty('date')) i.date = obj.date;
                  if (obj.hasOwnProperty('duration')) i.duration = obj.duration;
                  if (obj.hasOwnProperty('indentCount')) i.indentCount = obj.indentCount;
                  if (obj.hasOwnProperty('positionString')) i.positionString = obj.positionString;
                  if (obj.hasOwnProperty('adminPositionString')) i.adminPositionString = obj.adminPositionString;
                  if (obj.hasOwnProperty('showItemName')) i.showItemName = obj.showItemName;
                  if (obj.hasOwnProperty('itemPresentedBy')) i.itemPresentedBy = obj.itemPresentedBy;
                  if (obj.hasOwnProperty('itemRequiresDecision')) i.itemRequiresDecision = obj.itemRequiresDecision;

                  var userItems = [], sentUserItems = [];
                  if (obj.hasOwnProperty('userItems')) userItems = obj.userItems;

                  var f = obj.userItems.find(o => o.userId === BLANK_GUID);
                  if (f) {
                    if (f.documentId !== "" && f.key !== "" && f.size !== 0) {
                      i.documentId = f.documentId
                      i.size = f.size
                      i.key = f.key
                      i.genseckey = true
                    }
                  }

                  //Check to see if we already in the list
                  var f = userItems.find(o => o.userId === newUserId);
                  if (f) {
                    if (f.documentId !== "" && f.key !== "" && f.size !== 0) {
                      return;
                    }
                  } else {
                    var u = {
                      userId: newUserId,
                      blank: false,
                      enabled: true,
                      locked: false,
                      viewArchived: true,
                      change: true,
                    };
                    if (i.documentId !== "" && i.documentId !== undefined &&
                      i.key !== "" && i.key !== undefined &&
                      i.size !== 0 && i.size !== undefined) {
                      u.documentId = "";
                      u.key = "";
                      u.size = 0;
                    }
                    userItems.push(u);
                  }

                  //get users key list
                  userItems.forEach((obj) => {
                    //SAFETY CHECK to see if deleted user remove key and size if documentId
                    if (obj.documentId === undefined && (obj.key !== undefined || obj.size !== undefined)) {
                      if (userItem.userList[obj.userId] === undefined) {
                        if (obj.key !== undefined) delete obj.key;
                        if (obj.size !== undefined) delete obj.size;
                      } else if (userItem.userList[obj.userId].isDeleted) {
                        if (obj.key !== undefined) delete obj.key;
                        if (obj.size !== undefined) delete obj.size;
                      }
                    }

                    //SAFETY CHECK check to see if userId already exists and if so only pick good one
                    var filtered = sentUserItems.find(o => o.userId === obj.userId)
                    if (filtered) {
                      if (filtered.documentId === undefined && obj.documentId !== undefined && obj.key !== undefined && obj.size !== undefined &&
                        obj.documentId !== "" && obj.key !== "" && obj.size !== 0) {
                        Object.keys(obj).map(e => filtered[e] = obj[e]);
                      } else {
                        return;
                      }
                    }

                    var onBoard = KeyList.map(e => e.userId).indexOf(obj.userId);
                    if (onBoard === -1) {
                      var haveDetails = userMap.indexOf(obj.userId);
                      if (users.users[haveDetails] !== undefined) {
                        if (users.users[haveDetails].hasOwnProperty('kUserPublic')) {
                          if (users.users[haveDetails].kUserPublic !== '') {
                            KeyList.push({ userId: obj.userId, key: users.users[haveDetails].kUserPublic });
                          }
                        }
                      }
                    }

                    sentUserItems.push(obj);
                  });
                  i.userItems = sentUserItems;

                  items.push(i);
                });

                var binderDetails = {};

                //Is it new or previous
                binderDetails = {
                  processtype: "binder",
                  id: binder.id,
                  name: binder.name,
                  boardId: binder.boardId,
                  customerId: userItem.customerId,
                  binderStatus: binder.binderStatus,
                  attendees: [],
                  //attendeeIds: [],
                  recipients: [],
                  //recipientIds: [],
                  invitees: [],
                  items: items,
                  adminUsers: adminUsers,
                  keyList: KeyList,
                  kUser: userItem.kUser,
                  kUserGenSec: userItem.kUserGenSec,
                  pUserGenSec: userItem.pUserGenSec,
                  myId: userItem.meIds,
                  //              blankFile: null,
                  //              blankVideo: null,
                  imageId: '',
                  //              updateDate: binder.updateDate,
                  taskId: userItem.taskId[0].id,
                  worker: false,
                  binderChange: false,
                };

                // binder.attendees.forEach(function(obj){
                //   binderDetails.attendeeIds.push(obj);
                // })
                // binder.recipients.forEach(function(obj){
                //   binderDetails.recipientIds.push(obj);
                // })
                if (binder.hasOwnProperty('thumbnailImageDownloadId'))
                  binderDetails.imageId = binder.thumbnailImageDownloadId;
                if (binderDetails.items.length) {
                  jobQueue.push(binderDetails);
                }
              }


              devlog('users', users);
              devlog('Filedata', Filedata);
              var ProcessOthers = false;
              var files = [];
              var userMap = users.users.map(e => e.id);
              for (var y = 0; y < Filedata.length; y++) {
                if (Filedata[y] !== undefined) {
                  if (Filedata[y].boardId !== "" && Filedata[y].filesData.length > 0) {
                    for (var z = 0; z < Filedata[y].filesData.length; z++) {
                      var file = Filedata[y].filesData[z];

                      var documentId = file.documentId
                      var fileSize = file.fileSize
                      var key = file.key
                      var kUser = userItem.kUser

                      var f = file.userFiles.find(o => o.userId === BLANK_GUID);
                      if (f) {
                        if (f.documentId !== "" && f.key !== "" && f.size !== 0) {
                          documentId = f.documentId
                          fileSize = f.fileSize
                          key = f.key
                          kUser = userItem.pUserGenSec
                        }
                      }

                      var fileitem = {
                        processtype: "file",
                        id: file.id,
                        customerId: userItem.customerId,
                        kUser: kUser,
                        myId: userItem.meIds,
                        documentId: documentId,
                        fileSize: fileSize,
                        boardId: Filedata[y].boardId,
                        key: key,
                        userFiles: file.userFiles.slice(0),
                        taskId: userItem.taskId[0].id,
                      }

                      var f = file.userFiles.find(o => o.userId === newUserId);
                      if (f) {
                        continue
                      } else {
                        fileitem.userFiles.push({ userId: newUserId, documentId: "", fileSize: 0, key: "" });
                      }

                      if (ProcessOthers) {
                        //loop through members Ids and see if there any one missing
                        for (var x = 0; x < Filedata[y].membership.length; x++) {
                          var id = Filedata[y].membership[x].userId;
                          var index = userMap.indexOf(id);
                          if (index === -1) continue;
                          if (!users.users[haveDetails].hasRegistered) continue;
                          if (!users.users[haveDetails].hasOwnProperty('kUserPublic')) continue;
                          if (users.users[haveDetails].kUserPublic === '') continue;

                          var userindex = fileitem.userFiles.map(e => e.userId).indexOf(id);
                          if (index !== -1) continue;

                          fileitem.userFiles.push({ userId: id, documentId: "", fileSize: 0, key: "" });
                        }
                      }

                      var keys = [];
                      for (var x = 0; x < fileitem.userFiles.length; x++) {
                        var id = fileitem.userFiles[x].userId;
                        if (id === "") continue;

                        var index = userMap.indexOf(id);
                        if (index !== -1) {
                          if (!users.users[index].hasOwnProperty('kUserPublic')) continue;
                          if (users.users[index].kUserPublic === '') continue;

                          keys.push({ userId: id, key: users.users[index].kUserPublic });
                        }
                      }
                      keys.push({ userId: BLANK_GUID, key: userItem.kUserGenSec });

                      fileitem.keyList = keys;
                      jobQueue.push(fileitem);
                    }
                  }
                }
              }

              //add jobs to queue
              devlog("QUEUE", jobQueue);
              userItem.taskId.forEach((taskId) => {
                dispatch(taskStep({
                  id: taskId.id,
                  stepTotal: jobQueue.length,
                }));
              });
              if (jobQueue.length > 0) {
                var simultaneous = BINDERSIMUPLOAD;
                var errorLimit = 3;
                var pos = 0;
                var done = 0;
                var errors = [];
                var completed = false;
                function Completed() {
                  userItem.taskId.forEach(function (task) {
                    dispatch(taskStep({
                      id: task.id,
                      step: 3,
                    }));
                  });
                  if (!completed) dispatch(userActions.completedTask(userItem.taskId[0].id));
                  completed = true;
                  dispatch(success());
                }

                function RunTask(i, callback) {
                  var item = jobQueue[i];
                  if (item.processtype === "file") {
                    fileService.UpdateFileDetails(item)
                      .then(() => {
                        userItem.taskId.forEach(function (task) {
                          dispatch(taskStep({
                            id: task.id,
                            stepIncrement: pos,
                          }));
                        });
                        done++;
                        if (errors.length > 2) return;
                        setTimeout(function () {
                          callback();
                        }, 1000 + Math.floor((Math.random() * 3000) + 1));
                      })
                      .catch((error) => {
                        userItem.taskId.forEach(function (task) {
                          dispatch(taskStep({
                            id: task.id,
                            stepIncrement: pos,
                          }));
                        });
                        //done++;
                        errors.push({ id: item.id, type: item.processtype });
                        log("Admin Reg Problem");
                        dispatch(errorTask(userItem.taskId[0].id, error))
                        dispatch(failure(error))
                        if (errors < 2)
                          setTimeout(function () {
                            callback();
                          }, 5000 + Math.floor((Math.random() * 3000) + 1));
                      })
                  } else if (item.binderStatus === "template") {
                    binderService.updateTemplateContent(item)
                      .then(() => {
                        userItem.taskId.forEach(function (task) {
                          dispatch(taskStep({
                            id: task.id,
                            stepIncrement: pos,
                          }));
                        });
                        done++;
                        if (errors.length > 2) return;
                        setTimeout(function () {
                          callback();
                        }, 1000 + Math.floor((Math.random() * 3000) + 1));
                      })
                      .catch((error) => {
                        userItem.taskId.forEach(function (task) {
                          dispatch(taskStep({
                            id: task.id,
                            stepIncrement: pos,
                          }));
                        });
                        //done++;
                        errors.push({ id: item.id, type: item.processtype });
                        log("Admin Reg Problem");
                        dispatch(errorTask(userItem.taskId[0].id, error))
                        dispatch(failure(error))
                        if (errors < 2)
                          setTimeout(function () {
                            callback();
                          }, 5000 + Math.floor((Math.random() * 3000) + 1));
                      })
                  } else {
                    binderService.updateBinder(item)
                      .then(() => {
                        userItem.taskId.forEach(function (task) {
                          dispatch(taskStep({
                            id: task.id,
                            stepIncrement: pos,
                          }));
                        });
                        done++;
                        if (errors > 2) return;
                        setTimeout(function () {
                          callback();
                        }, 1000 + Math.floor((Math.random() * 3000) + 1));
                      })
                      .catch((error) => {
                        userItem.taskId.forEach(function (task) {
                          dispatch(taskStep({
                            id: task.id,
                            stepIncrement: pos,
                          }));
                        });
                        //done++;
                        errors.push({ id: item.id, type: item.processtype });
                        log("Admin Reg Problem");
                        dispatch(errorTask(userItem.taskId[0].id, error.message))
                        dispatch(failure(error.message))
                        dispatch(alertActions.errorDiagnosticData(error))
                        if (errors < 2)
                          setTimeout(function () {
                            callback();
                          }, 5000 + Math.floor((Math.random() * 3000) + 1));
                      })
                  }
                }

                function doNextTask() {
                  if (pos < jobQueue.length) {
                    RunTask(pos, doNextTask)
                    pos++;
                    return;
                  }
                  if (done >= jobQueue.length) {
                    Completed();
                  }
                }

                for (var x = 0; x < simultaneous; x++)
                  doNextTask();
              } else {
                dispatch(taskStep({
                  id: userItem.taskId[0].id,
                  step: 3,
                }));
                dispatch(userActions.completedTask(userItem.taskId[0].id));
              }

              /*
              //add Binders job to Queue
              if(jobQueue.length > 0){
                if(files.length > 0){
                  dispatch(fileActions.AddFiles(files));
                }
                dispatch(userActions.updateTask({id: userItem.taskId[0].id, jobTodo: jobQueue}));
              }else{
                dispatch(taskStep({
                  id: userItem.taskId[0].id,
                  step: 3,
                }));
                dispatch(userActions.completedTask(userItem.taskId[0].id));
              }*/
            })
            .catch((error) => {
              dispatch(errorTask(userItem.taskId[0].id, error))
              dispatch(failure(error));
              dispatch(alertActions.error(error));
            });
        },
        error => {
          dispatch(errorTask(userItem.taskId[0].id, error))
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );

    function successUsers(items, customerId) { return { type: userConstants.POPULATEALL_USERS_SUCCESS, items, customerId } }
    function successMembers(boardId, membership) { return { type: boardConstants.GETMEMBERSHIP_SUCCESS, boardId, membership } }
    function successBindersPreview(data, boardId, customerId) { return { type: boardConstants.POPULATE_BINDERS_SUCCESS, data, boardId, customerId } }
    function successBinders(item) { return { type: binderConstants.POPULATE_BINDERCONTENT_SUCCESS, item } }
    function successTemplateList(list) { return { type: boardConstants.GET_TEMPLATES_SUCCESS, list } }
    function successPopTemplate(item) { return { type: binderConstants.POPULATE_TEMPLATECONTENT_SUCCESS, item } }
    //function requestTemplate(templateItem) { return { type: binderConstants.ADD_STATS_TEMPLATE_REQUEST, templateItem } }
    //function requestBinder(binderItem) { return { type: binderConstants.ADD_STATS_BINDER_REQUEST, binderItem } }
    function successFileDetails(filestat) { return { type: fileConstants.GET_FILESTATS_SUCCESS, filestat } }
    function successBoardFiles(items) { return { type: boardConstants.BOARD_FILELIST_SUCCESS, items } }
    function taskStep(item) { return { type: userConstants.STEP_TASK_REQUEST, item } }

    function request() { return { type: binderConstants.WORKER_BINDER_UPDATE_REQUEST } }
    function success() { return { type: binderConstants.WORKER_BINDER_UPDATE_SUCCESS } }
    function failure(error) { return { type: binderConstants.WORKER_BINDER_UPDATE_FAILURE, error } }
    function errorTask(taskId, error) { return { type: userConstants.ERROR_TASK_FAILURE, taskId, error } }

    function requestFile() { return { type: fileConstants.WORKER_FILE_UPDATE_REQUEST } }
    function successFile() { return { type: fileConstants.WORKER_FILE_UPDATE_SUCCESS } }
    function failureFile(error) { return { type: fileConstants.WORKER_FILE_UPDATE_FAILURE, error } }
  };
}

function workerBinderFileUpdate(userItem) {
  return (dispatch) => {
    dispatch(request());
    userService.populateListofUsers(userItem.customerId)
      .then(
        users => {
          dispatch(successUsers(users, userItem.customerId))
          var listBinders = userItem.binders.filter(o => o.enable == true);
          if (userItem.taskIds.length > 0) {
            userItem.taskIds.forEach(function (taskId) {
              dispatch(taskStep({
                id: taskId.id,
                step: 0,
                stepTotal: listBinders.length
              }))
            })
          }

          new Promise(function (resolve, reject) {
            //Collect the data for binder that wish to Add
            var pos = 0;

            function Chunck() {
              if (pos >= listBinders.length) {
                //we all done - return results
                resolve(listBinders);
                return;
              }

              var promiseBinder = [];
              function GetNextFive() {
                var array = [];
                var count = 0;
                for (var x = pos; x < listBinders.length; x++) {
                  if (count > 4) {
                    break;
                  }

                  var binderIdp = listBinders[x].binderId;
                  var typep = listBinders[x].type;
                  array.push(
                    new Promise(function (resolveAPI, rejectAPI) {
                      var binderId = binderIdp;
                      var type = typep;
                      binderService.populateBinderContent(binderId)
                        .then(
                          binders => {
                            dispatch(successBinders(binders));
                            binders.type = type;
                            if (userItem.taskIds.length > 0) {
                              userItem.taskIds.forEach(function (taskId) {
                                dispatch(taskStep({
                                  id: taskId.id,
                                  stepIncrement: pos,
                                }));
                              });
                            }
                            resolveAPI(binders);
                          },
                          error => {
                            rejectAPI(binderId + ": " + error)
                          }
                        );
                    })
                  )
                  count++;
                }
                pos += count;

                return array;
              }

              promiseBinder = GetNextFive();

              Promise.all(promiseBinder)
                .then((data) => {
                  for (var y = 0; y < data.length; y++) {
                    var binder = data[y];

                    var i = listBinders.findIndex(o => o.id === data[y].binderId);
                    if (i !== -1) {
                      listBinders[i] = data[y];
                    }
                  }
                  Chunck();
                })
                .catch((error) => {
                  reject(error);
                })
            }

            Chunck();
          }).then(function (bindersData) {
            return new Promise(function (resolve, reject) {
              if (userItem.taskIds.length > 0) {
                userItem.taskIds.forEach(function (taskId) {
                  dispatch(taskStep({
                    id: taskId.id,
                    step: 1,
                  }));
                })
              }
              var promiseFiles = [];
              //Collect the data for all the files to add
              for (var x = 0; x < userItem.boardIds.length; x++) {
                var boardId = userItem.boardIds[x];
                promiseFiles.push(
                  new Promise(function (resolveInner, rejectInner) {
                    var boardIdA = boardId;
                    boardService.getMembership(boardIdA)
                      .then(
                        membership => {
                          dispatch(successMembers(boardIdA, membership));
                          //check the membership against task
                          var found = userItem.taskIds.find(function (e) {
                            var member = membership.find(function (b) {
                              return e.dataId === b.id;
                            });
                            return ((e.type === UserAccountTask.BoardRegistration && member !== undefined) || e.type !== UserAccountTask.BoardRegistration);
                          });
                          if (!found) {
                            resolveInner();
                          } else {
                            boardService.getBoardFileList(boardIdA)
                              .then(
                                list => {
                                  if (userItem.taskIds.length > 0) {
                                    userItem.taskIds.forEach(function (taskId) {
                                      dispatch(taskStep({
                                        id: taskId.id,
                                        stepTotalAppend: list.length,
                                      }));
                                    })
                                  }
                                  var bId = boardIdA;
                                  dispatch(successBoardFiles({ boardId: bId, fileIds: list }));
                                  //get the file IDs
                                  var promisefiles = [];
                                  for (var y = 0; y < list.length; y++) {
                                    var fileId = list[y];
                                    promisefiles.push(
                                      new Promise(function (resolvefile, rejectfile) {
                                        fileService.GetFileProperties(fileId)
                                          .then(
                                            filestat => {
                                              dispatch(successFileDetails(filestat));
                                              filestat.boardId = bId;
                                              if (userItem.taskIds.length > 0) {
                                                userItem.taskIds.forEach(function (taskId) {
                                                  dispatch(taskStep({
                                                    id: taskId.id,
                                                    stepIncrement: 0,
                                                  }));
                                                })
                                              }
                                              resolvefile({ boardId: bId, membership: membership, filestat: filestat });
                                            },
                                            error => {
                                              log('error3', error);
                                              rejectfile(error)
                                            }
                                          )
                                      })
                                    );
                                  }
                                  Promise.all(promisefiles)
                                    .then((filesData) => {
                                      var boardId = "";
                                      var membership = [];
                                      var Data = [];
                                      for (var y = 0; y < filesData.length; y++) {
                                        boardId = filesData[y].boardId;
                                        membership = filesData[y].membership;
                                        Data.push(filesData[y].filestat);
                                      }
                                      resolveInner({ boardId: boardId, membership: membership, filesData: Data });
                                    })
                                    .catch((error) => {
                                      log('error2', error);
                                      dispatch(failureFile(error));
                                      dispatch(alertActions.error(error));
                                    });
                                },
                                error => {
                                  log('error1', error);
                                  rejectInner(error)
                                }
                              );
                          }
                        },
                        error => {
                          dispatch(failureFile(error))
                        }
                      );
                  })
                );
              }

              Promise.all(promiseFiles)
                .then((filesData) => {
                  resolve({ bindersData: bindersData, filesData: filesData })
                }).catch((e) => {
                  rejectInner(e)
                })
            });
          }).then((data) => {
            devlog("s", data);
            var newUserId = userItem.userId;
            var userMap = users.users.map(e => e.id);
            var jobQueue = [];
            var curDate = moment(new Date());

            if (userItem.taskIds.length > 0) {
              userItem.taskIds.forEach(function (taskId) {
                dispatch(taskStep({
                  id: taskId.id,
                  step: 2,
                }));
              })
            }
            function GetTaskId(boardId) {
              var taskId = "";
              userItem.taskIds.forEach((e) => {
                if (e.type !== UserAccountTask.BoardRegistration) return;
                var found = userItem.board.find(function (m) {
                  if (e.dataId === m.membershipId);
                  taskId = m.boardId;
                });
              });

              return taskId;
            }
            for (var y = 0; y < data.bindersData.length; y++) {
              var binder = data.bindersData[y];

              var taskId = "";
              if (userItem.taskIds.length === 1)
                taskId = userItem.taskIds[0].id;
              else if (userItem.taskIds.length > 1) {
                taskId = GetTaskId(binder.boardId);
              }

              var adminUsers = [];
              var KeyList = [];

              //Just in case lets populate the admin users
              users.adminUsers.forEach(function (obj) {
                if (obj.hasOwnProperty('kUserPublic') && obj.hasRegistered === true)
                  if (obj.kUserPublic !== "") {
                    KeyList.push({ userId: obj.id, key: obj.kUserPublic });
                    adminUsers.push(obj.id);
                  }
              });

              var items = [];
              var dataComplete = true;
              binder.items.forEach(function (obj) {
                var binderType = getBinderType(obj.type, obj.style);

                var i = {
                  id: obj.id,
                  documentId: '',
                  voteId: '',
                  position: obj.position,
                  adminPositionString: '',
                  name: '',
                  filename: '',
                  timing: 0,
                  expiryDate: null,
                  key: '',
                  type: obj.type,
                  style: obj.style,
                  binderType: binderType,
                  size: 0,
                  date: '',
                  pageCount: 0,
                  itemdata: null,
                  image: null,
                  userItems: [],
                  voteDes: "",
                  voteAns: [],
                  duration: "",
                  indentCount: 0,
                  positionString: '',
                  itemRequiresDecision: '',
                  itemPresentedBy: '',
                  showItemName: null,
                  updateDate: obj.updateDate,
                  change: false,
                  genseckey: false,
                };
                if (obj.hasOwnProperty('documentId')) i.documentId = obj.documentId;
                if (obj.hasOwnProperty('voteId')) i.voteId = obj.voteId;
                if (obj.hasOwnProperty('name')) i.name = obj.name;
                if (obj.hasOwnProperty('fileName')) i.filename = obj.fileName;
                if (obj.hasOwnProperty('timing')) i.timing = obj.timing;
                if (obj.hasOwnProperty('expiryDate')) i.expiryDate = obj.expiryDate;
                if (obj.hasOwnProperty('key')) i.key = obj.key;
                if (obj.hasOwnProperty('size')) i.size = obj.size;
                if (obj.hasOwnProperty('date')) i.date = obj.date;
                if (obj.hasOwnProperty('userItems')) i.userItems = obj.userItems;
                if (obj.hasOwnProperty('pageCount')) i.pageCount = obj.pageCount;
                if (obj.hasOwnProperty('duration')) i.duration = obj.duration;
                if (obj.hasOwnProperty('indentCount')) i.indentCount = obj.indentCount;
                if (obj.hasOwnProperty('positionString')) i.positionString = obj.positionString;
                if (obj.hasOwnProperty('adminPositionString')) i.adminPositionString = obj.adminPositionString;
                if (obj.hasOwnProperty('showItemName')) i.showItemName = obj.showItemName;
                if (obj.hasOwnProperty('itemPresentedBy')) i.itemPresentedBy = obj.itemPresentedBy;
                if (obj.hasOwnProperty('itemRequiresDecision')) i.itemRequiresDecision = obj.itemRequiresDecision;

                var f = obj.userItems.find(o => o.userId === BLANK_GUID);
                if (f) {
                  if (f.documentId !== "" && f.key !== "" && f.size !== 0) {
                    i.documentId = f.documentId
                    i.size = f.size
                    i.key = f.key
                    i.genseckey = true
                  }
                }

                var u = i.userItems.map(e => e.userId).indexOf(newUserId);
                if (u === -1) {
                  var u = {
                    userId: newUserId,
                    blank: false,
                    locked: false,
                    viewArchived: true,
                    enabled: true,
                  };
                  if (i.documentId !== "" && i.documentId !== undefined &&
                    i.key !== "" && i.key !== undefined &&
                    i.size !== 0 && i.size !== undefined) {
                    u.documentId = "";
                    u.key = "";
                    u.size = 0;
                  }
                  i.userItems.push(u);
                  i.change = true;
                  dataComplete = false;
                } else {
                  if (binderType !== BinderItemType.header) {
                    if (i.userItems[u].hasOwnProperty('documentId')) {
                      if (i.userItems[u].documentId === "") {
                        dataComplete = false;
                        i.change = true;
                      }
                    } else {
                      dataComplete = false;
                      i.change = true;
                    }
                  }
                }

                //get users key list
                i.userItems.forEach(function (obj) {
                  var onBoard = KeyList.map(e => e.userId).indexOf(obj.userId);
                  if (onBoard === -1) {
                    var haveDetails = userMap.indexOf(obj.userId);
                    if (users.users[haveDetails] !== undefined) {
                      if (users.users[haveDetails].hasOwnProperty('kUserPublic')) {
                        if (users.users[haveDetails].kUserPublic !== '') {
                          KeyList.push({ userId: obj.userId, key: users.users[haveDetails].kUserPublic });
                        }
                      }
                    }
                  }
                });

                items.push(i);
              });

              var binderDetails = {};

              binderDetails = {
                processtype: "binder",
                id: binder.id,
                name: binder.name,
                boardId: binder.boardId,
                customerId: userItem.customerId,
                binderStatus: binder.binderStatus,
                attendees: [],
                attendeeIds: [],
                recipients: [],
                recipientIds: [],
                invitees: [],
                items: items,
                adminUsers: adminUsers,
                keyList: KeyList,
                kUser: userItem.kUser,
                myId: userItem.meIds,
                pUserGenSec: userItem.pUserGenSec,
                //blankFile: null,
                //blankVideo: null,
                imageId: '',
                //updateDate: curDate.utc().format(),
                taskId: taskId,
                notification: false,
                worker: false,
                binderChange: false,
              };
              if (binder.hasOwnProperty('thumbnailImageDownloadId'))
                binderDetails.imageId = binder.thumbnailImageDownloadId;

              if (binder.attendees !== undefined)
                binder.attendees.forEach(function (obj) {
                  binderDetails.attendeeIds.push(obj);
                })
              if (binder.recipients !== undefined)
                binder.recipients.forEach(function (obj) {
                  binderDetails.recipientIds.push(obj);
                })
              //First check to see if we already on the binder
              let obj = undefined;
              if (binder.attendees !== undefined)
                obj = binder.attendees.find(obj => obj.userId == userItem.userId);
              if (obj === undefined && binder.recipients !== undefined)
                obj = binder.recipients.find(obj => obj.userId == userItem.userId);
              if (obj === undefined) {
                dataComplete = false;
                //Add the attendee or recipient details
                if (binder.type === 'attendee') {
                  binderDetails.updateDate = curDate.utc().format()
                  binderDetails.attendees = [{
                    id: '',
                    userId: userItem.userId,
                    boardId: binder.boardId,
                    settings: null,
                  }];
                } else {
                  binderDetails.recipients = [{
                    id: '',
                    userId: userItem.userId,
                    boardId: binder.boardId,
                    settings: null,
                  }]
                }
              }

              if (!dataComplete) {
                if (binderDetails.items.length) {
                  //                dispatch(requestBinder(binderDetails));
                  devlog('binderDetails', binderDetails);
                  jobQueue.push(binderDetails);
                }
                //dispatch(publishBinder(binderDetails, sessionToken, false));
              }
            }

            //process all the file
            devlog('users', users);
            devlog('Filedata', data.filesData);
            var ProcessOthers = false;
            var userMap = users.users.map(e => e.id);
            for (var y = 0; y < data.filesData.length; y++) {
              if (data.filesData[y] !== undefined) {
                if (data.filesData[y].boardId !== "" && data.filesData[y].filesData.length > 0) {
                  var taskId = "";
                  if (userItem.taskIds.length === 1)
                    taskId = userItem.taskIds[0].id;
                  else if (userItem.taskIds.length > 1) {
                    taskId = GetTaskId(data.filesData[y].boardId);
                  }

                  for (var z = 0; z < data.filesData[y].filesData.length; z++) {
                    var file = data.filesData[y].filesData[z];

                    var documentId = file.documentId
                    var fileSize = file.fileSize
                    var key = file.key
                    var kUser = userItem.kUser

                    var f = file.userFiles.find(o => o.userId === BLANK_GUID);
                    if (f) {
                      if (f.documentId !== "" && f.key !== "" && f.size !== 0) {
                        documentId = f.documentId
                        fileSize = f.fileSize
                        key = f.key
                        kUser = userItem.pUserGenSec
                      }
                    }

                    var fileitem = {
                      processtype: "file",
                      id: file.id,
                      fileId: file.id,
                      customerId: userItem.customerId,
                      kUser: kUser,
                      myId: userItem.meIds,
                      documentId: documentId,
                      boardId: data.filesData[y].boardId,
                      //creationDate: file.creationDate,
                      //published: file.published===undefined?false:file.published,
                      //file: null,
                      key: key,
                      pUserGenSec: userItem.pUserGenSec,
                      userFiles: file.userFiles.slice(0),
                      taskId: taskId,
                    }

                    if (file.hasOwnProperty('tag'))
                      fileitem.tag = file.tag;
                    if (file.hasOwnProperty('displayName'))
                      fileitem.displayName = file.displayName;
                    if (file.hasOwnProperty('type'))
                      fileitem.type = file.type;
                    if (file.hasOwnProperty('status'))
                      fileitem.status = file.status;
                    if (file.hasOwnProperty('fileName'))
                      fileitem.fileName = file.fileName;
                    if (file.hasOwnProperty('fileSize'))
                      fileitem.fileSize = file.fileSize;

                    //var curDate = moment(new Date());
                    //fileitem.updateDate = curDate.utc().format();

                    var foundUser = false;
                    var haveDetails = userMap.indexOf(newUserId);
                    var onBoard = data.filesData[y].membership.map(e => e.userId).indexOf(newUserId);
                    var userindex = fileitem.userFiles.map(e => e.userId).indexOf(newUserId);

                    if (haveDetails !== -1 && onBoard !== -1) {
                      if (userindex === -1) {
                        fileitem.userFiles.push({ userId: newUserId, documentId: "", fileSize: 0, key: "" });
                        foundUser = true;
                      } else {
                        if (fileitem.userFiles[userindex].documentId === "") {
                          foundUser = true;
                        }
                      }
                    } else if (haveDetails !== -1) {
                      if (users.users[haveDetails].type === UserTypeEnum.Publish || users.users[haveDetails].type === UserTypeEnum.Create) {
                        if (users.users[haveDetails].hasOwnProperty('kUserPublic')) {
                          if (users.users[haveDetails].kUserPublic !== '') {
                            //we admin staff
                            if (userindex === -1) {
                              fileitem.userFiles.push({ userId: newUserId, documentId: "", fileSize: 0, key: "" });
                              foundUser = true;
                            } else {
                              if (fileitem.userFiles[userindex].documentId === "") {
                                foundUser = true;
                              }
                            }
                          }
                        }
                      }
                    }
                    if (ProcessOthers) {
                      //loop through members Ids and see if there any one missing
                      for (var x = 0; x < data.filesData[y].membership.length; x++) {
                        var id = data.filesData[y].membership[x].userId;
                        var index = userMap.indexOf(id);
                        if (index === -1) continue;
                        if (!users.users[haveDetails].hasRegistered) continue;
                        if (!users.users[haveDetails].hasOwnProperty('kUserPublic')) continue;
                        if (users.users[haveDetails].kUserPublic === '') continue;

                        var userindex = fileitem.userFiles.map(e => e.userId).indexOf(id);
                        if (userindex !== -1) continue;
                        foundUser = true;

                        fileitem.userFiles.push({ userId: id, documentId: "", fileSize: 0, key: "" });
                      }
                    }

                    var keys = [];
                    for (var x = 0; x < fileitem.userFiles.length; x++) {
                      var id = fileitem.userFiles[x].userId;
                      if (id === "") continue;

                      var index = userMap.indexOf(id);
                      if (index !== -1) {
                        if (!users.users[index].hasOwnProperty('kUserPublic')) continue;
                        if (users.users[index].kUserPublic === '') continue;

                        keys.push({ userId: id, key: users.users[index].kUserPublic });
                      }
                    }

                    fileitem.keyList = keys;
                    if (foundUser) {
                      //files.push(fileitem);
                      jobQueue.push(fileitem);
                    }
                  }
                }
              }
            }

            userItem.taskIds.forEach((taskId) => {
              dispatch(taskStep({
                id: taskId.id,
                stepTotal: jobQueue.length,
              }));
            });
            //add jobs to queue
            devlog(jobQueue);

            //add Binders job to Queue
            if (jobQueue.length > 0) {
              var simultaneous = BINDERSIMUPLOAD;
              var pos = 0;
              var done = 0;
              //var completed = false;
              function Completed() {
                userItem.taskIds.forEach(function (taskId) {
                  dispatch(taskStep({
                    id: taskId.id,
                    step: 3,
                  }));
                  dispatch(userActions.completedTask(taskId.id));
                  //completed = true;
                })

                dispatch(success());
              }

              function RunTask(i, callback) {
                var item = jobQueue[i];
                if (item.processtype === "file") {
                  fileService.UpdateFileDetails(item)
                    .then(() => {
                      userItem.taskIds.forEach(function (task) {
                        dispatch(taskStep({
                          id: task.id,
                          stepIncrement: pos,
                        }));
                      });
                      done++;
                      setTimeout(function () {
                        callback();
                      }, 1000 + + Math.floor((Math.random() * 3000) + 1));
                    })
                    .catch((error) => {
                      userItem.taskIds.forEach(function (task) {
                        dispatch(taskStep({
                          id: task.id,
                          stepIncrement: pos,
                        }));
                      });
                      dispatch(errorTask(userItem.taskId[0].id, error))
                      dispatch(failure(error))
                      done++;
                      log("User Reg Task Failure");
                      setTimeout(function () {
                        callback();
                      }, 5000 + + Math.floor((Math.random() * 3000) + 1));
                    })
                  //TODO MAYBE}else if(item.binderStatus === "template"){
                } else {
                  binderService.updateBinder(item)
                    .then(() => {
                      userItem.taskIds.forEach(function (task) {
                        dispatch(taskStep({
                          id: task.id,
                          stepIncrement: pos,
                        }));
                      });
                      done++;
                      setTimeout(function () {
                        callback();
                      }, 1000 + + Math.floor((Math.random() * 3000) + 1));
                    })
                    .catch((error) => {
                      userItem.taskIds.forEach(function (task) {
                        dispatch(taskStep({
                          id: task.id,
                          stepIncrement: pos,
                        }));
                        dispatch(errorTask(task.id, error.message))
                      });
                      dispatch(failure(error.message))
                      dispatch(alertActions.errorDiagnosticData(error))
                      done++;
                      log("User Reg Task Failure");
                      setTimeout(function () {
                        callback();
                      }, 5000 + + Math.floor((Math.random() * 3000) + 1));
                    })
                }
              }

              function doNextTask() {
                if (pos < jobQueue.length) {
                  RunTask(pos, doNextTask)
                  pos++;
                  return;
                }
                if (done >= jobQueue.length)
                  Completed();
              }

              for (var x = 0; x < simultaneous; x++)
                doNextTask();
            } else {
              userItem.taskIds.forEach(function (taskId) {
                dispatch(taskStep({
                  id: taskId.id,
                  step: 3,
                }));
                dispatch(userActions.completedTask(taskId.id));
              })
            }
          }).catch((error) => {
            //    debugger
            dispatch(failure(error));
            dispatch(alertActions.error(error));
          });
        },
        error => {
          //  debugger
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      )
  };

  function successFileDetails(filestat) { return { type: fileConstants.GET_FILESTATS_SUCCESS, filestat } }
  function successBoardFiles(items) { return { type: boardConstants.BOARD_FILELIST_SUCCESS, items } }
  function successMembers(boardId, membership) { return { type: boardConstants.GETMEMBERSHIP_SUCCESS, boardId, membership } }
  function successUsers(items, customerId) { return { type: userConstants.POPULATEALL_USERS_SUCCESS, items, customerId } }
  function successBinders(item) { return { type: binderConstants.POPULATE_BINDERCONTENT_SUCCESS, item } }
  //  function requestBinder(binderItem) { return { type: binderConstants.ADD_STATS_BINDER_REQUEST, binderItem } }
  function taskStep(item) { return { type: userConstants.STEP_TASK_REQUEST, item } }

  function request() { return { type: binderConstants.WORKER_BINDER_UPDATE_REQUEST } }
  function success() { return { type: binderConstants.WORKER_BINDER_UPDATE_SUCCESS } }
  function failure(error) { return { type: binderConstants.WORKER_BINDER_UPDATE_FAILURE, error } }
  function errorTask(taskId, error) { return { type: userConstants.ERROR_TASK_FAILURE, taskId, error } }

  function requestFile() { return { type: fileConstants.WORKER_FILE_UPDATE_REQUEST } }
  function successFile() { return { type: fileConstants.WORKER_FILE_UPDATE_SUCCESS } }
  function failureFile(error) { return { type: fileConstants.WORKER_FILE_UPDATE_FAILURE, error } }
}

function runAdminBgTask(items) {
  return (dispatch, getState) => {
    var sessionToken = getState().authentication.sessionToken
    var customerId = getState().authentication.customerId



    taskpool.onmessage = (event) => {
      //console.log("!",event.data)
      if (event.data.task !== undefined)
        dispatch(taskStep(event.data.task))
      else if (event.data.populate !== undefined) {
        if (event.data.populate.type === "successUser")
          dispatch(successUsers(event.data.populate.data1, event.data.populate.data2))
        else if (event.data.populate.type === "successMembers")
          dispatch(successMembers(event.data.populate.data1, event.data.populate.data2))
        else if (event.data.populate.type === "successBoardFiles")
          dispatch(successBoardFiles(event.data.populate.data))
        else if (event.data.populate.type === "successFileDetails")
          dispatch(successFileDetails(event.data.populate.data))
        else if (event.data.populate.type === "failureFile")
          dispatch(failureFile(event.data.populate.data))
        else if (event.data.populate.type === "completedTask")
          dispatch(userActions.completedTask(event.data.populate.data));
        else if (event.data.populate.type === "successBindersPreview")
          dispatch(successBindersPreview(event.data.populate.data, event.data.populate.data2, customerId));
        else if (event.data.populate.type === "successBinders")
          dispatch(successBinders(event.data.populate.data));
        else if (event.data.populate.type === "successPopTemplate")
          dispatch(successPopTemplate(event.data.populate.data));
        else if (event.data.populate.type === "failure")
          dispatch(failure(event.data.populate.data2, event.data.populate.data1));
      }
    }

    var promiseArray = []
    // offload a function to a worker
    items.forEach((item) => {
      dispatch(request());
      promiseArray.push(
        new Promise(function (resolve, reject) {
          dispatch(start({
            id: item.id,
            start: true
          }));
          item.url = GetURL()
          item.sessionToken = sessionToken
          taskpool.queueJob(bgAdminTask, item)
            .then((result) => {
              //              console.log("Results",result)
              if (result !== undefined) {
                result.forEach(task => {
                  dispatch(success(task.id));
                })
              }
              resolve()
            })
            .catch((id, error) => {
              log(id, error)
              dispatch(failure("Failed to approve password reset", item.id));

              /*var i = Object.assign({}, item)
              dispatch(userActions.sendFeedBack({
                customerId: item.customerId,
                feedbackTitle: "Error upload",
                feedBackType: 'Bug',
                feedback: JSON.stringify(i)+"\n"+id+"\n"+error,
              }))*/
              reject()
            });
        })
      )
    })

    Promise.all(promiseArray)
      .then((dataChunks) => {
        log("finished")
        //      dispatch(userActions.getAllTask());
      })
      .catch((error) => {
        log("error 3", error)
      })
  };

  function successUsers(items, customerId) { return { type: userConstants.POPULATEALL_USERS_SUCCESS, items, customerId } }
  function successFileDetails(filestat) { return { type: fileConstants.GET_FILESTATS_SUCCESS, filestat } }
  function successBoardFiles(items) { return { type: boardConstants.BOARD_FILELIST_SUCCESS, items } }
  function successMembers(boardId, membership) { return { type: boardConstants.GETMEMBERSHIP_SUCCESS, boardId, membership } }
  function successBinders(item) { return { type: binderConstants.POPULATE_BINDERCONTENT_SUCCESS, item } }
  function successBindersPreview(data, boardId, customerId) { return { type: boardConstants.POPULATE_BINDERS_SUCCESS, data, boardId, customerId } }
  function successPopTemplate(item) { return { type: binderConstants.POPULATE_TEMPLATECONTENT_SUCCESS, item } }

  function requestFile() { return { type: fileConstants.WORKER_FILE_UPDATE_REQUEST } }
  function successFile() { return { type: fileConstants.WORKER_FILE_UPDATE_SUCCESS } }
  function failureFile(error) { return { type: fileConstants.WORKER_FILE_UPDATE_FAILURE, error } }

  function taskStep(item) { return { type: userConstants.STEP_TASK_REQUEST, item } }

  function start(taskItem) { return { type: userConstants.UPDATE_TASK_REQUEST, taskItem } }
  function request(id) { return { type: userConstants.RESET_TASK_REQUEST, id } }
  function success(id) { return { type: userConstants.COMPLETE_TASK_SUCCESS, id } }
  function failure(error, taskId) { return { type: userConstants.ERROR_TASK_FAILURE, error, taskId } }
}

function CacheBinderTemplatePreview(customerId) {
  return (dispatch) => {
    dispatch(requestPreviewAll(customerId));
    boardService.getBoardsAllPreview(customerId)
    .then(
      data => {
        dispatch(successPreviewAll(data, customerId));
      },
      error => {
        if(error === 202 || error === 206){
          dispatch(CacheBinderTemplatePreview(customerId))
        }else{
          dispatch(failurePreviewAll(error, customerId))
        }
      }
    )
    
    function requestPreviewAll(customerId) { return { type: boardConstants.POPULATE_ALL_BINDERS_TEMPLATES_REQUEST, customerId } }
    function successPreviewAll(data, customerId) { return { type: boardConstants.POPULATE_ALL_BINDERS_TEMPLATES_SUCCESS, data, customerId } }
    function failurePreviewAll(error, customerId) { return { type: boardConstants.POPULATE_ALL_BINDERS_TEMPLATES_FAILURE, error, customerId } }
  };
}

function clearError() {
  return dispatch => {
    dispatch(success())
  }

  function success() { return { type: binderConstants.CLEAR_ERROR_SUCCESS } }
}

function setResolution(item) {
  return async (dispatch, getState) => {
    return binderService.setResolution(item).then((result) => {
      dispatch(kvpActions.set_kvp({
        key: item.itemId,
        value: {
          resolutionSuccessful: true
        }
      }));
    })
  }
}

function deleteBinderSettings(binderId) {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      binderService.deleteBinderSettings(binderId)
        .then((response) => {
          dispatch({
            type: binderConstants.BINDER_SETTINGS_DELETE,
            payload: { binderId }
          });
          resolve();
        })
        .catch(() => {
          reject();
        })
    });
  }
}

function updateBinderSettings(binderId, binderSettings) {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      binderService.updateBinderSettings(binderId, binderSettings)
        .then((response) => {
          dispatch({
            type: binderConstants.BINDER_SETTINGS_UPDATE,
            payload: { binderId, binderSettings }
          });
          resolve();
        })
        .catch(() => {
          reject();
        })
    });
  }
}