import { viewAs, authHeader, GetURL, LogoutAndRedirect, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch, handleCodeCatch, CodeError } from '@lib';
import * as CrytpoLib from '@lib/cryptojs';
import { FileStorage } from '@lib/indexeddb';

export const baseFileService = {
    Download,
    GetImage,
}

function Download(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(GetURL() + "Document/123456278-9e44-497e-a94b-042badbfda78", requestOptions)
        .then(handleJsonResponse)
        .then((data) => {
            data.data = new Blob([CrytpoLib.base64StringToArrayBuffer(data.data)], { type: "application/pdf" });
            return data
        })
        .catch(handleCatch);
    return fetch(GetURL() + 'Document/' + id, requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            return response.json();
        })
        .then(handleDataResponse).catch(handleCatch);
}

  
function GetImage(imageid) {
    return new Promise((resolve, reject) => {
      resolve();
    })
    .then(()=>{
      return new Promise((resolve, reject) => {
        FileStorage.Get(imageid)
       .then((data)=>{
         resolve({image: data, id:imageid});
       })
       .catch(()=>{
         const requestOptions = {
           method: 'GET',
           headers: authHeader({'Content-Type': 'application/base64'})
         };
 
         fetch(GetURL() + 'Document/'+imageid, requestOptions).then(response => {
           if (!response.ok) {
             return Promise.reject(response.statusText);
           }
           //return response.arrayBuffer();
           return response.json();
         })
         .then(json => {
           if(json !== undefined)
             if(json.hasOwnProperty('data'))
               return json.data;
 
           return Promise.reject('Json format incorrect');
         })
         .then(image => {
           FileStorage.Set(imageid, image);
           resolve({image: image, id:imageid});
         })
         .catch((e)=>{reject(e)});
       })
     })
   }).catch(handleCatch);
 }