import { alertConstants } from '../../constants';
import moment from 'moment';

export const alertActions = {
  error,
  clear,
  recordDiagnosticData,
};

function error(message) {
  return { type: alertConstants.ERROR, message };
}

function clear() {
  return { type: alertConstants.CLEAR };
}

function recordDiagnosticData(type, data, options = null, sendEmail = false){
  return (dispatch, getState) => {
    if(window.demo) return
    var userId = getState().authentication.userId
    var customerId = getState().authentication.customerId

    var obj = {
      searchName: type,
      customerId,
      date: moment().utc().format(),
      byteData: JSON.stringify(data),
      expiryDate: moment().add(30, 'days').utc().format(),
      sendEmail: sendEmail,
    };

    if (userId) {
      obj.userId = userId;
    }

    if(data.boardId !== undefined && data.boardId !=="")
      obj.boardId = data.boardId;
    if(type === 'binderUpload' || type === 'binderWorker' || type === 'templateNew' || type === 'templateUpdate'){
      if(data.id !== undefined && data.id !=="" && (type === 'binderUpload' || type === 'binderWorker'))
        obj.binderId = data.id;

      obj.byteData = stripBinder(data);
    }

    if(data.binderId !== undefined && data.binderId !=="")
      obj.binderId = data.binderId;
    if(data.userId !== undefined && data.userId !=="")
      obj.userId = data.userId;
    if(data.objectId !== undefined && data.objectId !=="")
      obj.objectId = data.objectId;

    (window.diagLayer = window.diagLayer || []).push(obj);
  }
}
