import React, { Component } from 'react';
import AlphabetItem from './AlphabetItem';

const mapArrToMap = (arr) => {
  const map = new Map();
  var Regx = /^[A-Za-z]$/;
  arr.forEach((row) => {
    const item = row.label
    let firstChar = item[0];
    if (!Regx.test(firstChar)) {
      firstChar = '#'
    } else {
      firstChar = firstChar.toUpperCase();
    }
    if (map.get(firstChar) == null) {
      map.set(firstChar, [row]);
    } else {
      const arr = map.get(firstChar);
      arr.push(row);
      map.set(firstChar, arr)
    }

  })
  return map;
}
class AlphabetList extends Component {
  constructor(props) {
    super(props);
//    this.mapPos = null;
    this.mapPos = new Map();
  }
  registerPos = (id, top) => {
    if (this.mapPos) {
      this.mapPos.set(id, top);
    }
  }
  handleAlphaClick = (char) => {
    const t = this.mapPos.get(char)
    this.scroller.scrollTop = t
  }
  render() {
    const { generateFn, style, data, className, renderSubIcon, subClick, listDisplay, sort } = this.props;
    const map = mapArrToMap(data);
    //this.mapPos = new Map();
    const keyArr = Array.from(map.keys())
    keyArr.sort();
    if(sort === false)
      keyArr.reverse();
    return (
      <div
        className={className}
        style={{
          position: 'relative',
          height: '100%',
          ...(style ? style : {})
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            overflow: 'auto',
            paddingRight: 12,
          }}
          ref={(ref) => { this.scroller = ref }}
        >
          {
            keyArr.map((char) => {
              if (map.get(char) != null) {
                return (
                  <AlphabetItem
                    id={`${char}`}
                    suffix={` (${map.get(char).length})`}
                    renderIcon={renderSubIcon}
                    subClick={()=>{
                      if(subClick){
                        subClick(map.get(char))
                      }
                    }}
                    key={char}
                    registerPos={this.registerPos}
                  >
                    <div className={listDisplay!==undefined?listDisplay:""}>
                      {
                        map.get(char).map((item, index) => {
                          return generateFn(item, index);
                        })
                      }
                    </div>
                  </AlphabetItem>
                )
              }
            })
          }

        </div>
        <div
          style={{
            position: 'absolute',
            top: 12,
            right: 12,
          //  color: '#AAA'
          }}
        >
          {
            keyArr.map(item => {
              return (
                <div
                  key={item}
                  style={{
                    fontSize: 12,
                    verticalAlign: 'top',
                    cursor: 'pointer',
                //    color: '#AAA'
                  }}
                  onClick={() => { this.handleAlphaClick(item) }}
                >
                  {item}
                </div>
              )
            })
          }
        </div>
      </div>

    );
  }
}
export default AlphabetList;
