import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Grid, CircularProgress, Popper, Fade, ClickAwayListener, Typography, Tooltip } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MuiButton } from '@common/MUI';
import ProgressBar from './progressBar';
import { UserTypeEnum, FreemiumType, RoutesConstants } from '@constants/common.constants';
import { fileActions, companyActions, userActions, alertActions, queueActions, customerActions, boardActions, adminPermissionsActions, popoverAction } from '@actions/admin';
import { cmpNum, getSupportURL, GetInitials, GetImageDom, ImageDataBase64, cmpWord, setDirectorCookie, ToggleClassOnElementById, TrackEvent } from '@lib/simpletools';
import { webConstants } from '@constants/admin';
import {SettingStorage} from '@lib/indexeddb';
import { TruncateText } from '@ansarada/ace-react';
import { UpgradeNow, Loader } from '@common/admin'
import { CreateNewBinderModal } from '@common/CreateNewBinderModal';
import { USAGELOGSTATUS, BOARD_LIMIT, BINDERLIMIT, TEMPLATE_LIMIT, BINDERCREATELIMIT, UNPUBLISH_LIMIT } from '@lib/limits';
import {
  FaTrash as DeleteIcon,
  FaBars as Bars,
  FaPause as PauseIcon,
  FaPlay as PlayIcon,
  FaSyncAlt as RefreshIcon,
  FaRedo as Retry,
  FaTimesCircle as FailedIcon,
  FaCaretDown as DownIcon,
  FaCaretRight as RightIcon,
  FaCaretUp as UpIcon,
  FaTimes as ExitIcon,
  FaAngleRight as RightArrow,
  FaInfoCircle as InfoCircle,
} from 'react-icons/fa';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
//import track from 'react-tracking';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Modal from 'react-modal';
//import SlidingPane from 'react-sliding-pane';
//import 'react-sliding-pane/dist/react-sliding-pane.css';
import { confirmAlert } from 'react-confirm-alert';
import '@styles/navBar.css';
import logoi from '@image/logo/Ansarada Board Product Logo Citrus White RGB.svg';
import logoOnly from '@image/logo/Ansarada Symbol Citrus RGB.svg';
import LockIcon from '@mui/icons-material/Lock';
import { useIntercom } from 'react-use-intercom';
import { baseMarketingActions } from '../../actions/marketing.base.actions';
import moment from 'moment';

var MAX_CUSTOMERLIST = 4;

const IntercomUpdate = (props) => {
  const { update } = useIntercom()
  useEffect(()=>{
    let object = {}
    if (props.firstName !== undefined && props.lastName !== undefined) {
      object.name = props.firstName + " " + props.lastName
    }
    if (props.users !== undefined && props.users[props.userId] !== undefined) {
      if(props.users[props.userId].mobile !== ""){
        object.phone = props.users[props.userId].mobile
      }
    }
    if(props.userId !== undefined && props.customers !== undefined){
      const customer = props.customers.find(o => o.userId === props.userId)
      if (customer !== undefined && customer.intercomHmac !== undefined) {
        object.userHash = customer.intercomHmac
        object.userId = customer.userId
        object.company = { companyId: customer.id, name: customer.companyName, plan: customer.accountType }
        let companies = []
        props.customers.forEach(item=>{
          companies.push({ companyId: item.id, name: item.companyName, plan: item.accountType })
        })
        object.companies = companies
        if (props.users !== undefined && props.users[props.userId] !== undefined) {
          if(props.users[props.userId].email !== ""){
            object.email = props.users[props.userId].email
          }
        }
      }
    }
    if (Object.keys(object).length > 0) {
      update(object)
    }
  }, [props.firstName, props.lastName, props.companyName, props.customerId, props.userId, props.users, props.customers])
  return "";
}

class MultiCompanyBoardSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: "",
      anchorEl: null,
      hideCompany: false,
    }
  }

  handleClose = () =>{
    this.setState({ open: "", anchorEl: null, hideCompany: false })
  }

  handleOpen = (event, customerId) => {
    this.setState({ open: customerId, anchorEl: event.currentTarget, hideCompany: true })
  }

  handleSwitch = (customerId) => {
    if(customerId !== this.props.customerId){
      this.props.props.dispatch(boardActions.setCurrentBoard("", "", ""))
      this.props.props.dispatch(customerActions.switchCustomer(customerId, true))
    }
  }

  handleCreate = (customerId) => {
    this.handleSwitch(customerId)
    this.handleClose()
    this.props.onCreate()
  }

  handleView = (customerId) => {
    this.handleSwitch(customerId)
    this.handleClose()
    this.props.onView()
  }

  render(){
    if(this.props.isMobile){
      return (
        <div>
          {this.props.company.map((item, index)=>{
            return (
              <div key={index}>
                {!this.state.hideCompany && (
                  <div className='link navddown-board-item centerVFlex spacebetween' onClick={(e) => this.handleOpen(e, item.customerId)}>
                    <div className={`navddown-board-title ${item.customerId === this.props.customerId ? "active" : ""}`}>{item.label}{item.isSuspended ? <b> (Suspended)</b> : null}</div>
                    <div>{" "}<RightIcon className="menuexpicon text14" /></div>
                  </div>
                )}
                {this.state.open === item.customerId && (
                  <div>
                    <BoardSelection
                      boards={item.boards}
                      company={item}
                      current={this.props.current}
                      isMobile={this.props.isMobile}
                      isMulti={true}
                      onSelect={(id, name, image, customerId) => {
                        this.props.onSelect(id, name, image, customerId)
                        this.handleClose()
                      }}
                      onCreate={()=>this.handleCreate(item.customerId)}
                      onView={()=>this.handleView(item.customerId)}
                    />
                    <div className="centerFlex centerpanel" style={{ height: 40 }} onClick={this.handleClose}>
                      <label className="centerVFlex text14s"><ArrowBackIcon sx={{ marginRight: '5px', fontSize: 14 }} />Back to company list</label>
                    </div>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )
    }else {
      return (
        <div className='navdown-board-popper navdown-board-popper-multi'>
          {this.props.company.map((item, index) => {
            return (
              <div key={index}>
                <div className='link navddown-board-item centerVFlex spacebetween' onClick={(e) => this.handleOpen(e, item.customerId)}>
                  <div className={`navddown-board-title ${item.customerId === this.props.customerId ? "active" : ""}`}>{item.label}{item.isSuspended ?  <b> (Suspended)</b> : null}</div>
                  <div>{" "}<RightIcon className="menuexpicon text14" /></div>
                </div>
                <Popper
                  open={this.state.open === item.customerId}
                  anchorEl={this.state.anchorEl}
                  placement='right-start'
                  transition
                  style={{
                    zIndex: '999999'
                  }}
                >
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={50}>
                      <div>
                        <BoardSelection
                          boards={item.boards}
                          company={item}
                          current={this.props.current}
                          isMobile={this.props.isMobile}
                          isMulti={true}
                          isSuspended={item.isSuspended || false}
                          onSelect={(id, name, image, customerId) => {
                            this.props.onSelect(id, name, image, customerId)
                            this.handleClose()
                          }}
                          onCreate={()=>this.handleCreate(item.customerId)}
                          onView={()=>this.handleView(item.customerId)}
                        />
                      </div>
                    </Fade>
                  )}
                </Popper>
              </div>
            )
          })}
        </div>
      )
    }
  }
}

class BoardSelection extends React.Component {
  constructor(props) {
    super(props);
  }

  renderIcon = (item) => {
    if (!item.canAccess) {
      return <LockIcon />;
    }

    if (item.image) {
      return <div style={{ height: "25px" }}><img className="boardlogoNav fs-exclude" src={item.image} /></div>;
    }

    return <div className="boardlogoNav LogoFont LogoImage" style={{ fontSize: '6px' }}>LOGO</div>;
  }

  renderList(list){
    return list.map((item, index) => {
      return (
        <Grid item xs={12} sm={this.props.isMobile ? 12 : 6} key={index}>
          <div className={`boardpanel centerpanel link navddown-board-item ${!item.canAccess ? 'navdown-board-item-disabled' : ''}`} 
            style={{ height: '100%' }}
            onClick={() => { if (!item.canAccess) { return; } this.props.onSelect(item.id, item.name, item.image, this.props.company.customerId) }}
          >
            <div>
              {this.renderIcon(item)}
            </div>
            <div className={`navddown-board-title ${item.id === this.props.current ? "active":""}`}>{item.name}{this.props.isSuspended ? <b> (Suspended)</b> : null}</div>
          </div>
        </Grid>
      )
    })
  }

  sortList(){
    let boards = this.props.boards
    if(!this.props.isMobile){
      const secondColumnStart = Math.round(boards.length / 2)
      let arr1 = boards.slice(0, secondColumnStart)
      let arr2 = boards.slice(secondColumnStart)
      let arrToConvert = arr1.map((e,index) => arr2[index] ? [e,arr2[index]] : [e]);
      if (arrToConvert && arrToConvert.length) {
        boards = arrToConvert.reduce((prev, next) => {
          return prev.concat(next);
        });
      }
    }
    return boards
  }

  render(){
    const boards = this.sortList()

    return(
      <div className={`navddown-board-container ${!this.props.isMobile && 'navdown-board-popper'} ${this.props.isMulti ? 'navdown-board-popper-multi-board' : 'navdown-board-popper-board'}`}>
        <Grid container alignItems="stretch" >
          {this.renderList(boards)}
          {!this.props.isSuspended
            ? <Grid item xs={12}>
              <div className='cText navddown-board-button'>
                <MuiButton variant='contained' onClick={this.props.onCreate}>Create new board</MuiButton>
                <MuiButton variant='contained' onClick={this.props.onView} style={{ marginLeft: 10 }}>Show all boards</MuiButton>
              </div>
            </Grid>
            : null
          }
        </Grid>
      </div>
    )
  }
}

class SlidingPane extends React.Component {
  constructor(props) {
    super(props);
  }

  render(){
    var {
      isOpen,
      children,
      onAfterOpen,
      onRequestClose,
      from,
      width,
      className,
      overlayClassName,
      title,
      subtitle
    } = this.props
    if(from === undefined) from = 'right'
    if(title === undefined) title = ''
    if(subtitle === undefined) subtitle = ''

    var s = width !== undefined? { width: width }:{}

    const directionClass = `slide-pane_from_${from}`;
    return(
      <Modal
        className={ `slide-pane ${directionClass} ${className || ''}` }
        style={{
            content: s
        }}
        overlayClassName={ `slide-pane__overlay ${overlayClassName || ''}`}
        closeTimeoutMS={ 500 }
        isOpen={ isOpen }
        ariaHideApp={false}
        onAfterOpen={ onAfterOpen }
        onRequestClose={ onRequestClose }
        contentLabel={ `Modal "${title || ''}"` }>
        <div className='slide-pane__header'>
            <label>MY ACCOUNT</label>
            <div className='slide-pane__close' onClick={ onRequestClose }>
              <ExitIcon />
            </div>
        </div>
        <div className='slide-pane__content'>
            { children }
        </div>
      </Modal>
    )
  }
}

class ItemPanel extends React.Component {
  constructor(props) {
    super(props);
  }

  sizeString(size){
    var i = size == 0 ? 0 : Math.floor( Math.log(size) / Math.log(1024) );
    return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  }

  onPause(){
    //this.props.tracking.trackEvent({ click: 'onPause', type: "ProgressPanel" })
    this.props.dispatch(queueActions.pauseItem(this.props.index));
  }

  onResume(){
    //this.props.tracking.trackEvent({ click: 'onResume', type: "ProgressPanel" })
    this.props.dispatch(queueActions.resumeItem(this.props.index));
  }

  onDelete(){
    //this.props.tracking.trackEvent({ click: 'onDelete', type: "ProgressPanel" })
    this.props.dispatch(queueActions.deleteItem(this.props.index));
  }

  onRetry(){
    //this.props.tracking.trackEvent({ click: 'onRetry', type: "ProgressPanel" })
    this.props.dispatch(queueActions.retryItem(this.props.index));
  }

  getFileName(currentFile){
    if(currentFile === "Loading item details...")
      return '';
    if(currentFile === "")
      return 'Item: Unknown';
    return 'Item: '+currentFile;
  }

  /*renderItems(){
    const { item } = this.props;
    if(item.currentFiles === null){
      return (
        <div>
          Loading
        </div>
      )
    }

    var array = [];
    for(var x=0; x<item.currentFiles.length; x++){
      if(item.currentFiles[x].percentage < 100){
        var currentFile = item.data.items[item.currentFiles[x].itemPos].name;
        if(currentFile === "Loading item details..." || currentFile === "" || currentFile === "Verifing...")
          array.push(
            <div className="maxWidth" key={x}>
              <div className="uploadItem">
                {currentFile}
              </div>
              <div/>
            </div>
          )
        else
          array.push(
            <div className="maxWidth" key={x}>
              <div className="uploadItem">
                {currentFile}
              </div>
              <ProgressBar
                success={item.currentFiles[x].percentage}
                height={2}
                className="progressBar2"
                classNameBar={`${!item.currentFiles[x].converting?'progressBarSuccess':'progressBarConvert'}`}
              />
            </div>
          )
      }
      if(array.length > 2) break;
    }

    return array;
  }*/

  renderItems(item){
    var currentFile = item.currentFile;
    if(currentFile === "Loading item details..." || currentFile === "" || currentFile === "Verifing...")
      return(
        <div className="maxWidth">
          <div className="uploadItem fs-exclude" id={"uploadId-"+item.id}>
            {currentFile}
          </div>
          <div/>
        </div>
      );
    return(
      <div style={{marginTop:-5}} className="maxWidth">
        <TruncateText truncatePosition="Tail" className="uploadItem fs-exclude" id={"uploadId-"+item.id}>
          Item: {currentFile}
        </TruncateText>
        <ProgressBar
          success={item.sectionPercent}
          height={2}
          className="progressBar2"
          classNameBar={`${!item.converting?'progressBarSuccess':'progressBarConvert'}`}
        />
      </div>
    )
  }

  render(){
    const { item } = this.props;
    return(
      <div className="boardpanel spacebetween uploadRow">
        <div className="boardpanel centerpanel maxWidth">
          <div>
            {(item.error !== "" && item.complete) ?
              <FailedIcon style={{marginRight: 5}} color="#f12314"/>
              :
              <Bars style={{marginRight: 5}}/>
            }
          </div>
          <div className="uploadTitle">
            <TruncateText truncatePosition="Tail" className="fs-exclude" id={"uploadId-"+item.id}>{item.binderName!==undefined?item.binderName:item.fileName}</TruncateText>
            {!item.loading &&
              <span>Queued - Estimate Upload Size: {this.sizeString(item.size)}</span>
            }
            {item.loading && item.contentType === "file" &&
              <span>Uploading data</span>
            }
            {item.loading && item.contentType === "binder" && item.paused &&
              <span>Paused</span>
            }
            {item.loading && item.contentType === "binder" && !item.paused && !item.running &&
              <span>Resume - Waiting for next slot</span>
            }
            {item.loading && item.contentType === "binder" && !item.paused && item.running &&
              this.renderItems(item)
            }
            {
              /*<div style={{marginRight: 20}}>
                <ProgressBar
                  success={item.overallPercent}
                  height={2}
                  className="progressBar2"
                  classNameBar="progressBarSuccess"
                />
              </div>*/
            }
          </div>
        </div>
        <div className="boardpanel centerpanel">
          {!item.paused && !item.complete &&
            <div className="toolp">
              <PauseIcon size={16} color='#999999' className="link" onClick={this.onPause.bind(this)}/>
              <span className="toolptextSmall">Pause</span>
            </div>
          }
          {item.paused && !item.complete &&
            <div className="toolp">
              <PlayIcon size={16} color='#999999' className="link toolp" onClick={this.onResume.bind(this)}/>
              <span className="toolptextSmall">Resume</span>
            </div>
          }
          {item.error !== "" && item.complete &&
            <div className="toolp">
              <Retry size={16} color='#999999' className="link toolp" onClick={this.onRetry.bind(this)}/>
              <span className="toolptextSmall">Retry</span>
            </div>
          }
          <div className="toolp">
            <DeleteIcon style={{marginLeft: 10}} size={16} color='#ff1818' className="link toolp" onClick={this.onDelete.bind(this)}/>
            <span className="toolptextSmall">Delete</span>
          </div>
        </div>
      </div>
    );
  }
}

class ProgressPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allPaused: false,
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    var allPaused = true;
    for(var key in nextProps.Queue){
      if(key === "master") continue;
      var item = nextProps.Queue[key];
      if(!item.complete &&
          item.error === "" &&
          !item.paused){
        allPaused = false;
      }
    }
    return {allPaused}
  }

  onDragStart(result){
  //  console.log("onDragStart",result);
  }

  onDragEnd(result){
    console.log("onDragEnd",result);
    var startIndex = result.source.index;
    var endIndex = result.destination.index;
    var draggableId = result.draggableId;

    if(startIndex !== endIndex)
      this.props.dispatch(queueActions.swapQueue({
        id: draggableId,
        startIndex: startIndex,
        endIndex: endIndex,
      }))
  }

  getListStyle(snapshot) {
    var grid = 2;
    var j = {
      background: snapshot.isDraggingOver ? "lightblue" : "#ffffff",
      padding: grid,
      overflowY: 'auto',
      overflowX: 'hidden',
    }
    if(this.props.showMobile !== true){
      j.height = 320
    }
    return j
  }

  getItemStyle(snapshot, isGhosting, draggableStyle){
    var grid = 2;
    var style = {
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      padding: grid * 2,
      margin: `0 0 ${grid}px 0`,

      // change background colour if dragging
      background: snapshot.isDragging?"#f9f9f9":"#ffffff",
      opacity: isGhosting ? "0.8" : "1",
      // styles we need to apply on draggables
      ...draggableStyle
    }

    return style;
  }

  onPauseAll(){
    //this.props.tracking.trackEvent({ click: 'onPauseAll', type: "ProgressPanel" })
    this.props.dispatch(queueActions.pauseAllItems());
  }

  onResumeAll(){
    //this.props.tracking.trackEvent({ click: 'onResumeAll', type: "ProgressPanel" })
    this.props.dispatch(queueActions.resumeAllItems());
  }

  onCancelAll(){
    //this.props.tracking.trackEvent({ click: 'onCancelAll', type: "ProgressPanel" })
    this.props.dispatch(queueActions.deleteAllItems());
  }

  onClose(){
    if(this.props.onClose)
      this.props.onClose()
  }

  render(){
    var proList = Object.keys(this.props.Queue).filter((key) => {
      if(key !== "master" &&
          this.props.Queue[key].direction === 'upload' &&
          (!this.props.Queue[key].complete || this.props.Queue[key].complete && this.props.Queue[key].error !== ""))
        return true;
      return false;
    }).map((key)=>{return this.props.Queue[key]});

    proList.sort(function(a, b) {
      return cmpNum(a.adminPosition,b.adminPosition);
    })

    return(
      <div className="page maxHeight">
        {this.props.showMobile ?
          <div className="boardpanel spacebetween centerpanel" style={{margin: 10}}>
            <ExitIcon size={20} onClick={this.onClose.bind(this)}/>
            <div className="boardpanel spacebetween">
              {!this.state.allPaused?
                <Button variant="Primary" onClick={this.onPauseAll.bind(this)}>Pause</Button>
                :
                <Button variant="Primary" onClick={this.onResumeAll.bind(this)}>Resume</Button>
              }
              <Button variant="Default" onClick={this.onCancelAll.bind(this)} style={{marginLeft: 10}}>Cancel All</Button>
            </div>
          </div>
          :
          <div className="boardpanel spacebetween" style={{marginBottom: 10}}>
            {!this.state.allPaused?
              <Button variant="Primary" onClick={this.onPauseAll.bind(this)}>Pause</Button>
              :
              <Button variant="Primary" onClick={this.onResumeAll.bind(this)}>Resume</Button>
            }
            <Button variant="Default" onClick={this.onCancelAll.bind(this)}>Cancel All</Button>
          </div>
        }
        <div className="uploadPanel">
          <DragDropContext
              onDragStart={this.onDragStart.bind(this)}
              onDragEnd={this.onDragEnd.bind(this)}
            >
            <Droppable
              droppableId="dropUpload"
              >
              {(provided, snapshot) => (
                <div
                  ref={(r) => {this.listDropBox = r; provided.innerRef(r)}}
                  style={this.getListStyle(snapshot)}
                >
                  {proList.map((item, index) => {
                    return (
                      <Draggable draggableId={item.id} index={index} key={item.id}>
                        {(provided, snapshot) => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            style={this.getItemStyle(
                              snapshot,
                              false,
                              provided.draggableProps.style
                            )}
                          >
                            <ItemPanel
                              index={item.id}
                              item={item}
                              {...this.props}
                              />
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    )
  }
}

class BaseUser extends React.Component {
  constructor(props) {
    super(props);
  }

  onSupport(){
    window.open(getSupportURL(), '_blank').focus();
  }

  getPageName(){
    switch(this.props.active){
      case 'home':
        return 'Home'
      case 'audit':
        return 'Audit logs'
      case 'binders':
        return 'Binders'
      case 'bindernewwizard':
        return 'New Binder'
      case 'allboards':
        return 'Boards'
      case 'boardsnew':
        return 'New Board'
      case 'boards':
        return 'Edit Board'
      case 'calendar':
        return 'Calendar'
      case 'companies':
        return 'Companies'
      case 'company':
        return 'Company'
      case 'feedback':
        return 'Feedback'
      case 'files':
        return 'Files'
      case 'minutes':
        return 'Minutes'
      case 'myaccount':
        return 'Profile settings'
      case 'master':
        return 'Master administrators'
      case 'admins':
        return 'Admin users'
      case 'settingscustomer':
        return 'Company settings'
      case 'users':
        return 'Users'
      case 'appusers':
        return 'App users'
      case 'contactsupport':
        return 'Contact support'
      case 'people':
        return 'People'
    }
  }

  onLogout = (event) => {
//TODO    this.props.tracking.trackEvent({ click: 'onLogout' })
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) =>
        <div className="confirm-alert-ui">
          <h1 className='bottom20'>Are you sure you want to logout?</h1>
          <div className="boardpanel flexend">
            <MuiButton variant="outlined" style={{marginRight:20}} onClick={onClose}>No</MuiButton>
            <MuiButton variant="contained" onClick={()=>{onClose();this.props.history.push(RoutesConstants.logout)}}>Yes</MuiButton>
          </div>
        </div>,
    })
  }

  onOpenApp(){
    setDirectorCookie()
    window.open('appuser.html?viewas=true', '_blank').focus();
  }

  onManual(type){
    switch(type){
      case 0:
        if(this.state.branding === "BoardRoom")
          return "/lib/BoardRoom iOS Guide.pdf"
        return "/lib/Ansarada Board iOS Guide.pdf"
      case 1:
        if(this.state.branding === "BoardRoom")
          return "/lib/BoardRoom macOS Guide.pdf"
        return "/lib/Ansarada Board macOS Guide.pdf"
      case 2:
        if(this.state.branding === "BoardRoom")
          return "/lib/BoardRoom Windows Guide.pdf"
        return "/lib/Ansarada Board Windows Guide.pdf"
      case 3:
        if(this.state.branding === "BoardRoom")
          return "/lib/BoardRoom iOS iPhone Guide.pdf"
        return "/lib/Ansarada Board iOS iPhone Guide.pdf"
      case 4:
        if(this.state.branding === "BoardRoom")
          return "/Ansarada Board Admin Guide.pdf"
        return "/Ansarada Board Admin Guide.pdf"
    }
  }

  serverType(){
    if (appConfig.envLabel !== undefined){
      return <li onClick={this.onOpenApp.bind(this)} className="navddown"><label className="menuItem" style={{color: 'yellow'}}>{appConfig.envLabel.toUpperCase()}</label></li>;
    }
  }
}

class NonActiveUser extends BaseUser {
  constructor(props) {
    super(props);
  }

  render(){
    return(
      <div className="bar">
        <div className="navtop">
          <div className="boardpanel">
            <ul className="actoffset" id="navbar">
              <li onClick={this.onLink} className="navddown" id="navhome" style={{marginRight: 10}}>
                <Link to="/"><img src={logoi} className="navlogo"/></Link>
              </li>
            </ul>
          </div>
          {this.props.loggingIn !== true && (
            <ul className="barright" style={{ marginRight: this.props.showMobile ? 0 : 160 }}>
              <li className="navddown"><a onClick={e => this.onLogout(e)} className="menuItem menuright menurightpad" id="navlogout">Logout</a></li>
            </ul>
          )}
        </div>
        {appConfig.intercom === true && <IntercomUpdate {...this.props} />}
      </div>
    )
  }
}

class ActiveUser extends BaseUser {
  constructor(props) {
    super(props);

    var initial = {
      image: "",
      imageId: "",
      companyImageId: "",
      companyImage: "",
      companyList: [],
      companyListTop: [],
      minutesEnabled: false,
      branding: "",

      current:0,
      total: 0,
      errorTotal:0,
      type:'',
      currentName:"",
      currentId:"",
      overallPercent:0,
      sectionPercent:0,
      canSwitch: true,

      usageLogsFeatureStatus: USAGELOGSTATUS,
      tabValue: 0,
      boardLimit: BOARD_LIMIT,
      boardCount: -1,
      showMaxBoard: false,
      binderLimit: BINDERLIMIT,
      unpublishedLimit: UNPUBLISH_LIMIT,
      templateLimit: TEMPLATE_LIMIT,
      annualBinderCount: -1,
      annualBinderLimit: BINDERCREATELIMIT,
      menuItemDetails: '',
    }

    if(this.props.imageId !== undefined && this.props.imageId !== ""){
      initial.imageId = this.props.imageId
      if(initial.imageId !== "" && initial.imageId !== undefined){
        if(this.props.files[initial.imageId] !== undefined){
          if(!this.props.files[initial.imageId].loading && this.props.files[initial.imageId].error === "")
          initial.image = ImageDataBase64(this.props.files[initial.imageId]);
        }else
          this.props.dispatch(fileActions.GetImage(initial.imageId))
      }
    }
    if(this.props.customerSettings !== undefined && this.props.customerSettings[this.props.customerId] !== undefined &&
        this.props.customerSettings[this.props.customerId].logoImageId !== undefined ){
      initial.companyImageId = this.props.customerSettings[this.props.customerId].logoImageId
      if(initial.companyImageId !== "" && initial.companyImageId !== undefined){
        if(this.props.files[initial.companyImageId] !== undefined){
          if(!this.props.files[initial.companyImageId].loading && this.props.files[initial.companyImageId].error === ""){
            initial.companyImage = ImageDataBase64(this.props.files[initial.companyImageId])
          }else if(this.props.files[initial.companyImageId].loading){
            initial.companyImage = "loading"
          }
        }else{
          initial.companyImage = "loading"
          this.props.dispatch(fileActions.GetImage(initial.companyImageId))
        }
      }
    }

    if(this.props.Queue !== undefined){
      if(this.props.Queue['master'] !== undefined){
        initial.current = this.props.Queue['master'].current;
        initial.total = this.props.Queue['master'].total;
        initial.errorTotal = this.props.Queue['master'].error;
        initial.type = this.props.Queue['master'].type;
        initial.currentName = this.props.Queue['master'].currentName;
        initial.currentId = this.props.Queue['master'].currentId;
        //initial.currentfile = this.props.Queue['master'].currentfile;
        var o = this.props.Queue['master'].overallPercent;
        if(o >=0 && o <= 100) initial.overallPercent = o;
        var p = this.props.Queue['master'].sectionPercent;
        if(p >=0 && p <= 100) initial.sectionPercent = p;
        if(initial.total > 0){
          initial.canSwitch = false
        }
      }
    }

    if(this.props.taskList !== undefined){
      for(var key in this.props.taskList){
        if(this.props.taskList[key].start){
          initial.canSwitch = false
          break
        }
      }
    }

    if(this.props.customerId !== undefined && this.props.customerId !== ""){
      initial.tabValue = this.props.customerId
      if(this.props.companies !== undefined){
        var company = this.props.companies[this.props.customerId]
        if (company !== undefined && company.boardLimit !== undefined) {
          initial.boardLimit = company.boardLimit
        }
      }
    }

    var membership = this.props.customer[this.props.customerId]
    if(membership !== undefined && membership.membership !== undefined){
      initial.boardCount = membership.membership.length
    }

    this.state = {
      image: initial.image,
      imageId: initial.imageId,
      companyImage: initial.companyImage,
      companyImageId: initial.companyImageId,
      companyList: initial.companyList,
      companyListTop: initial.companyListTop,
      showMyAccount: false,
      showListType: '',
      minutesEnabled: initial.minutesEnabled,
      branding: initial.branding,

      showDropDownMenu: false,
      showBoards: false,
      showUsers: false,
      showBinders: false,
      showSwitch: false,
      showHelp: false,
      showCreate: false,
      showBoard: false,

      showControlPanel: false,

      current: initial.current,
      total: initial.total,
      errorTotal: initial.errorTotal,
      type: initial.type,
      flag: "",
      currentName: initial.currentName,
      currentId: initial.currentId,
      overallPercent: initial.overallPercent,
      sectionPercent: initial.sectionPercent,

      canSwitch: initial.canSwitch,

      timerNav: null,
      isFreemium: true,
      isTrial: false,
      isSample: false,
      usageLogsFeatureStatus: initial.usageLogsFeatureStatus,
      tabValue: initial.tabValue,
      selectedBoard: "",
      selectedBoardId: "",
      selectedBoardLogo: "",
      showBoardMenu: false,
      boardRef: null,
      boardLimit: initial.boardLimit,
      boardCount: initial.boardCount,
      binderLimit: initial.binderLimit,
      unpublishedLimit: initial.unpublishedLimit,
      templateLimit: initial.templateLimit,
      annualBinderCount: initial.annualBinderCount,
      annualBinderLimit: initial.annualBinderLimit,
      showMaxBinders: false,
      showNewBinder: false,
      showMaxTemplates: false,
      showNewTemplate: false,
      loadingTemplates: false,
      templates: [],
      loadingAllBindersTemplates: true,
      haveTemplate: false,
      haveBinder: false,

      boardModalUrl: "",
      showDropDownName: false,
    }

    this.myAccountRef = null;
    this.progressRef = null;
    this.rankTimer = null;
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onLink = this.onLink.bind(this);
    this.getRanking = this.getRanking.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    // this.props.dispatch(boardActions.getCurrentBoard())
    // this.props.dispatch(boardActions.getBoardsPreview())
    if(this.props.companies[this.props.customerId] === undefined || this.props.companies[this.props.customerId].boardLimit === undefined){
      this.props.dispatch(companyActions.getCompanyUserLimits())
    }
    if(this.props.customer[this.props.customerId] === undefined || this.props.customer[this.props.customerId].membership === undefined){
      this.props.dispatch(userActions.populateListofUsers(this.props.customerId));
    }
    if(this.state.companyList.length < MAX_CUSTOMERLIST+2) return

    this.rankOnce = 0
    this.getRanking();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    clearTimeout(this.state.timerNav);
    clearTimeout(this.rankTimer);
  }

  static getDerivedStateFromProps(nextProps, state) {
    var newState = { selectedBoardId: state.selectedBoardId }

    if(nextProps.currentBoard.id !== undefined && nextProps.currentBoard.id !== state.selectedBoardId){
      newState.selectedBoardId = nextProps.currentBoard.id;
    }

    if(nextProps.currentBoard.name !== undefined && nextProps.currentBoard.name !== state.selectedBoard){
      newState.selectedBoard = nextProps.currentBoard.name;
    }

    if(nextProps.currentBoard && nextProps.currentBoard.id && nextProps.allBoards) {
      let currentBoard = nextProps.allBoards.find(b => b.id == nextProps.currentBoard.id);
      if(currentBoard) {
        newState.isSample = currentBoard.isSample;
      }
    }

    if(nextProps.customerId !== undefined && nextProps.currentBoard.id !== undefined && state.companyList.length > 0){
      const c = state.companyList.find(o => o.customerId === nextProps.customerId)
      if(c !== undefined && c.boards.length > 0){
        var b = c.boards.find(o => o.id === nextProps.currentBoard.id)
        if(b !== undefined){
          newState.selectedBoardLogo = b.image
          newState.selectedBoard = b.name
        }
      }
    }

    if(nextProps.imageId !== undefined && nextProps.imageId !== ""){
      newState.imageId = nextProps.imageId
      if(newState.imageId !== "" && newState.imageId !== undefined){
        if(nextProps.files[newState.imageId] !== undefined){
          if(!nextProps.files[newState.imageId].loading && nextProps.files[newState.imageId].error === "")
            newState.image = ImageDataBase64(nextProps.files[newState.imageId]);
        }
      }
    }

    if(nextProps.companies !== undefined && nextProps.companies[nextProps.customerId] !== undefined){
      const c = nextProps.companies[nextProps.customerId]
      newState.boardLimit = c.boardLimit
      newState.binderLimit = c.binderLimit
      newState.unpublishedLimit = c.unpublishedBinderLimit
      newState.templateLimit = c.templateLimit
      newState.annualBinderCount = c.annualBinderCount
      newState.annualBinderLimit = c.annualBinderLimit
      if(c.usageLogsFeatureStatus !== undefined)
        newState.usageLogsFeatureStatus = c.usageLogsFeatureStatus;
    }

    if(nextProps.boardtemplates !== undefined && nextProps.boardtemplates[newState.selectedBoardId] !== undefined){
      var templates = []
      nextProps.boardtemplates[newState.selectedBoardId].forEach(id => {
        var name = "", loading = true;
        if(id !== "" && nextProps.binders[id] !== undefined){
          name = nextProps.binders[id].name;
          loading = nextProps.binders[id].loading
        }
        templates.push({
          id: id,
          name: name,
        });
      })
      templates.sort(function(a, b) {
        return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
      })
      newState.templates = templates
    }

    if(nextProps.customer !== undefined && nextProps.customer[nextProps.customerId] !== undefined){
      newState.loadingTemplates = nextProps.customer[nextProps.customerId].loadingTemplates
      if(nextProps.customer[nextProps.customerId].loadingAllBindersTemplates !== undefined){
        newState.loadingAllBindersTemplates = nextProps.customer[nextProps.customerId].loadingAllBindersTemplates
      }
      if (nextProps.customer[nextProps.customerId].membership !== undefined) {
        newState.boardCount = nextProps.customer[nextProps.customerId].membership.length
      }
    }

    if(nextProps.customerSettings !== undefined && nextProps.customerSettings[nextProps.customerId] !== undefined &&
        nextProps.customerSettings[nextProps.customerId].logoImageId !== undefined ){
      newState.companyImageId = nextProps.customerSettings[nextProps.customerId].logoImageId
      if(newState.companyImageId !== "" && newState.companyImageId !== undefined){
        if(nextProps.files[newState.companyImageId] !== undefined){
          if(!nextProps.files[newState.companyImageId].loading && nextProps.files[newState.companyImageId].error === "")
            newState.companyImage = ImageDataBase64(nextProps.files[newState.companyImageId]);
        }else if(state.companyImage !== "loading"){
          newState.companyImage = "loading"
          nextProps.dispatch(fileActions.GetImage(newState.companyImageId))
        }
      }
    }else if(state.companyImage !== "") newState.companyImage = ""

    if(nextProps.customers !== undefined){
      const d = nextProps.customers.find(o => o.id === nextProps.customerId)
      if(d) {
        newState.isFreemium = d.accountType === FreemiumType.freemium || d.accountType === FreemiumType.freemium_internal ? true : false;
        newState.isTrial = d.accountType === FreemiumType.trial || d.accountType === FreemiumType.trial_internal ? true : false;
      }

      newState.companyList = state.companyList
      for(var key in nextProps.customers){
        if(nextProps.customers[key].id === nextProps.customerId){
          if(nextProps.customers[key].branding !== undefined)
            newState.branding = nextProps.customers[key].branding
          if(nextProps.customers[key].minutesEnabled !== undefined)
            newState.minutesEnabled = nextProps.customers[key].minutesEnabled
        }
        var f = nextProps.customerIds.find(o => o === nextProps.customers[key].id)
        if(f){
          var c = newState.companyList.find(o => o.customerId === nextProps.customers[key].id)
          if(c === undefined){
            newState.companyList.push(
              {
                label: nextProps.customers[key].companyName || nextProps.customers[key].name,
                customerId: nextProps.customers[key].id,
                rank: 0,
                enabled: true,
                detail: '',
                boards: []
              }
            )
          } else {
            c.label = nextProps.customers[key].companyName || nextProps.customers[key].name;
          }
        }
      }

      if(nextProps.suspendedCustomers !== undefined){
        nextProps.suspendedCustomers.forEach((item) => {
          var c = newState.companyList.find(o => o.customerId === item.customerId)
          if(c === undefined){
            newState.companyList.push(
              {
                label: item.companyName || item.name,
                customerId: item.customerId,
                rank: 1,
                enabled: false,
                detail: item.reason
              }
            )
          }else{
            c.enabled = false;
            c.isSuspended = true;
            c.detail = item.reason
          }
        });
      }

      newState.companyList.sort(function(a, b) {
        return cmpWord(a.label,b.label) || cmpWord(a.customerId,b.customerId);
      })

      newState.companyList.forEach(item=>{
        let list = nextProps.allBoards.filter(o => o.customerId === item.customerId)
        if(list !== undefined && list.length > 0){
          let newList = []
          list.forEach(item => {
            let image_data = ""
            if (item.imageId !== undefined && nextProps.files[item.imageId] !== undefined
              && !nextProps.files[item.imageId].loading && nextProps.files[item.imageId].error === "") {
              image_data = ImageDataBase64(nextProps.files[item.imageId])
            }else if(item.imageId !== undefined && nextProps.files[item.imageId] === undefined){
              nextProps.dispatch(fileActions.GetImage(item.imageId))
            }
            newList.push({
              id: item.id,
              name: item.name,
              image: image_data,
              canAccess: item.canAccess
            })
          })

          newList.sort(function (a, b) {
            return cmpWord(a.name, b.name) || cmpWord(a.id, b.id);
          });
          item.boards = newList
        }else{
          item.boards = []
        }
      })

      if(newState.selectedBoardId === "" && newState.companyList.length === 1){
        if (newState.companyList[0].boards.length === 1) {
          nextProps.dispatch(boardActions.setCurrentBoard(newState.companyList[0].boards[0].id, newState.companyList[0].boards[0].name, newState.companyList[0].customerId))
        }
      }

      newState.companyListTop = newState.companyList.slice(0);
      newState.companyListTop.sort(function(a, b) {
        return cmpNum(b.rank,a.rank) || cmpWord(a.label,b.label) || cmpWord(a.customerId,b.customerId);
      })
      newState.companyListTop = newState.companyListTop.filter(o => o.customerId !== nextProps.customerId).splice(0, MAX_CUSTOMERLIST);
    }

    newState.canSwitch = true
    if(nextProps.Queue !== undefined){
      if(nextProps.Queue['master'] !== undefined){
        newState.current = nextProps.Queue['master'].current;
        newState.total = nextProps.Queue['master'].total;
        newState.errorTotal = nextProps.Queue['master'].error;
        newState.type = nextProps.Queue['master'].type;
        newState.currentName = nextProps.Queue['master'].currentName;
        var o = nextProps.Queue['master'].overallPercent;
        if(o >=0 && o <= 100) newState.overallPercent = o;
        var p = nextProps.Queue['master'].sectionPercent;
        if(p >=0 && p <= 100) newState.sectionPercent = p;

        if(newState.total > 0){
          newState.canSwitch = false
        }

        if(newState.currentName !== undefined && o === 0){
          if(nextProps.Queue['master'].currentId !== undefined){
            var id = nextProps.Queue['master'].currentId;
            newState.currentId = id
            if(nextProps.Queue[id] !== undefined){
              if(nextProps.Queue[id].errorRetry > 0){
                newState.flag = "restart";
                clearTimeout(state.timerNav);
                newState.timerNav = setTimeout(() => {
                  newState.flag = ""
//                  this.setState({flag: ""})
                }, 5*1000)
              }
            }
          }
        }

        if(newState.total === 0)
          newState.showControlPanel = false;
      }
    }

    if(nextProps.taskList !== undefined){
      for(var key in nextProps.taskList){
        if(nextProps.taskList[key].start){
          newState.canSwitch = false
          break
        }
      }
    }

    if(Object.keys(newState).length === 0) return null
    return newState
  }

  getRanking(){
    SettingStorage.Get(this.props.username+'customers')
    .then((data)=>{
      var top = data.key.customer.slice(0);
      var list = this.state.companyList
      top.forEach(o => {
        var f = list.find(i => i.customerId === o.customerId)
        if(f){
          f.rank = o.rank
        }
      })
      this.setState({companyList: list})
    })
    .catch(e=>{
      if(e === 'pending' && this.rankOnce === 0)
        this.rankTimer = setTimeout(()=>{this.getRanking()}, 3000)
      this.rankOnce++
    })
  }

  handleClickOutside(event){
    if (this.progressRef && this.state.showControlPanel){
      if(!this.progressRef.contains(event.target)) {
        this.setState({showControlPanel: false});
      }
    }
  }

  isUserPage(){
    if(this.props.active === 'users' || this.props.active === 'settings')
      return true
    return false
  }

  onSlideMenu(){
    this.setState({showMyAccount: !this.state.showMyAccount})
  }

  onContractList(){
    this.setState({showListType: false})
  }

  onExpandList(){
    this.setState({showListType: true})
  }

  onBoards(){
    this.setState({showBoards: !this.state.showBoards})
  }

  onBinders = () => {
    this.setState({showBinders: !this.state.showBinders})
  }

  onCreates = () => {
    this.setState({showCreate: !this.state.showCreate})
  }

  onBoard = () => {
    this.setState({showBoard: !this.state.showBoard})
  }

  onUsers(){
    this.setState({showUsers: !this.state.showUsers})
  }

  onSwitch(){
    this.setState({showSwitch: !this.state.showSwitch})
  }

  onHelp(){
    this.setState({showHelp: !this.state.showHelp})
  }

  onDropBox(){
    this.setState({showDropDownMenu: !this.state.showDropDownMenu, showBoardMenu: false, showDropDownName: false})
  }

  onOpenPanel(){
    this.setState({showControlPanel: !this.state.showControlPanel})
  }

  onColourSelect(){
    if(this.state.errorTotal !== 0 && this.state.errorTotal === this.state.total) return "navError";
    if(this.state.flag === "restart") return "navRestart";
    return "";
  }

  onBlock(e){
    e.stopPropagation();
  }

  hasBoardSelected = () => {
    return (this.state.selectedBoard !== "" || this.state.selectedBoardId !== "");
  }

  onSwitchCustomer(customerId){
    if(!this.state.canSwitch) return
    //this.props.tracking.trackEvent({ click: 'onSwitchCustomer', type: customerId })
    this.props.dispatch(customerActions.switchCustomer(customerId))
    if(window.location.pathname === RoutesConstants.bindernew ||
        window.location.pathname === RoutesConstants.binderdash ||
        window.location.pathname === RoutesConstants.binderboard ||
        window.location.pathname === RoutesConstants.templatenew
        )
      this.props.history.push(RoutesConstants.binders)
    else if(window.location.pathname === RoutesConstants.boardedit)
      this.props.history.push(RoutesConstants.boards)
    else if(window.location.pathname === RoutesConstants.calendarboard)
      this.props.history.push(RoutesConstants.calendar)
    else if(window.location.pathname === RoutesConstants.fileboard)
      this.props.history.push(RoutesConstants.files)
    else if(window.location.pathname === RoutesConstants.minuteboard)
      this.props.history.push(RoutesConstants.minutes)
    else if(window.location.pathname === RoutesConstants.settingsnew)
      this.props.history.push(RoutesConstants.people)
    else if(window.location.pathname === RoutesConstants.useradd || window.location.pathname === RoutesConstants.useredit)
      this.props.history.push(RoutesConstants.people)
    else if(window.location.pathname === RoutesConstants.audit)
      this.props.history.push(RoutesConstants.boards)
    this.setState({showMyAccount: false})
  }

  onReason(item){
    switch(item.detail){
      case 'AdminPasswordResetInProgress':
        this.setState({showMyAccount: false})
        confirmAlert({
          customUI: ({ title, message, onClose }) =>
            <div className="confirm-alert-ui">
              <h1>Account Suspended</h1>
              <p>Access to {item.label} is currently disabled because an administrator password reset has not yet been completed.</p>
              <p>Please contact the board adminstrator for further information.</p>
              <Button variant="Primary" onClick={onClose}>OK</Button>
            </div>,
        })
        break
    }
  }

  onLink(){
    if(this.props.onLink !== undefined)
      this.props.onLink();
  }

  onOpenApp(){
    setDirectorCookie()
    window.open('appuser.html?viewas=true', '_blank').focus();
  }

  onUpgrade(){
    this.setState({ showUpgrade: true })
  }

  onDropName(){
    this.setState({showDropDownName: !this.state.showDropDownName, showDropDownMenu: false, showBoardMenu: false})
  }

  renderCompanyList(){
    if(this.state.companyList.length < 2) return null;

    var list = [];
    if(!this.state.showListType){
      list = this.state.companyListTop.slice(0);
    }else{
      list = this.state.companyList.slice(0);
      list = list.filter(o => o.customerId !== this.props.customerId);
    }

    var css = ""
    if(!this.state.canSwitch)
      css = "unlink"
    return (
      <div className="dropMenuItem sideMenuCompany">
        {!this.state.canSwitch &&
          <span className="centerFlex italic colorStand">Please wait for upload/tasks to be completed</span>
        }
        {list.map((item, index)=>(
          <div key={index} onClick={item.enabled?this.onSwitchCustomer.bind(this,item.customerId):this.onReason.bind(this, item)}>
            {item.enabled?
              <div className="boardpanel spacebetween maxWidth"><label className={css}>{item.label}</label>{this.state.canSwitch && <RightArrow />}</div>
              :
              <div className="boardpanel spacebetween maxWidth"><label className='error'>{item.label}</label><InfoCircle /></div>
            }
          </div>
        ))}
        {!this.state.showListType && this.state.companyList.length > MAX_CUSTOMERLIST &&
          <div onClick={this.onExpandList.bind(this)}><span>More</span><label/></div>
        }
        {this.state.showListType &&
          <div onClick={this.onContractList.bind(this)}><span>Less</span><label/></div>
        }
      </div>
    )
  }

  renderDropDown(){
    var hasBoardSelected = this.hasBoardSelected();
    var css = 'navdropdown', boardcss = "msubContent", bindercss = "msubContent"

    if(this.state.showDropDownMenu) css += ' navactive'
    if(this.state.showBoards) boardcss += " mactive"
    if(this.state.showBinders) bindercss += " mactive"

    return (
      <div className={css} onClick={this.onDropBox.bind(this)}>
        <div className="page navdropp" onClick={this.onBlock.bind(this)}>
          <ul className="moffset" id="navbar">
            <li onClick={this.onLink} className="mdropdown" id="navhome">
              <Link to="/" className="boardpanel mMenuItem">
                Home
                {this.props.taskListNew !== undefined && this.props.taskListNew > 0 &&
                  <span>{this.props.taskListNew}</span>
                }
              </Link>
            </li>
            <li onClick={this.onLink} className="mdropdown" id="navboards">
              <div className="page">
                <label onClick={this.onBoards.bind(this)} className="boardpanel mMenuItem">
                  Boards/Committees {this.state.showBoards?
                    <UpIcon className="menuexpicon" style={{marginTop: -1}}/>
                    :
                    <DownIcon className="menuexpicon" style={{marginTop: -1}}/>
                  }
                </label>
                <div className={boardcss}>
                  <a onClick={(e) => this.addMenuNewBoardPage(e)} id="navbindercreate">New</a>
                  {hasBoardSelected ? <a onClick={(e) => this.handleSelectBoard(e, RoutesConstants.boardedit, true)} id="navbinderedit">Board Settings</a> : <span className='disabled-item-dropdown'>Board Settings</span>}
                  {hasBoardSelected ? <a onClick={(e)=>this.handleSelectBoard(e, RoutesConstants.fileboard, true)} id="navfiles">Files</a> : <span className='disabled-item-dropdown'>Files</span>}
                  {hasBoardSelected ? <a onClick={(e)=>this.handleSelectBoard(e, RoutesConstants.calendarboard, true)} id="navcalendar">Calendar</a> : <span className='disabled-item-dropdown'>Calendar</span>}
                  {this.state.minutesEnabled === true &&
                    hasBoardSelected ? <a onClick={(e)=>this.handleSelectBoard(e, RoutesConstants.minuteboard, true)} id="navminutes">Minutes</a> : <span className='disabled-item-dropdown'>Minutes</span>
                  }
                  {this.state.usageLogsFeatureStatus !== 2 &&
                    this.hasBoards() ? <a onClick={this.toAudit} id="navaudit">Audit Logs</a> : <span className='disabled-item-dropdown'>Audit Logs</span>
                  }
                  <div className='account-divider-mobile'></div>
                  <Link to={RoutesConstants.boards} id="navboards">Show all boards</Link>
                </div>
              </div>
            </li>
            <li onClick={this.onLink} className="mdropdown" id="navboards">
              <div className="page">
                <label onClick={this.onBinders.bind(this)} className="boardpanel mMenuItem">
                  Binders {this.state.showBinders?
                    <UpIcon className="menuexpicon" style={{marginTop: -1}}/>
                    :
                    <DownIcon className="menuexpicon" style={{marginTop: -1}}/>
                  }
                </label>
                <div className={bindercss}>
                  {hasBoardSelected && this.props.active !== 'bindernewwizard' && this.GetBinderUnPublishedCount() !== false && !(this.state.isFreemium && this.state.isSample) ? <a onClick={(e) => this.onNewBinder(e, 'new', true)} id="navbindercreate">New</a> : <span className='disabled-item-dropdown'>New</span>}
                  {hasBoardSelected ? <a onClick={(e) => this.handleSelectBoard(e, RoutesConstants.binderboard, true)} id="navbinderedit">Manage</a> : <span className='disabled-item-dropdown'>Manage</span>}
                  {!this.state.loadingAllBindersTemplates && this.haveBinders() && hasBoardSelected && this.props.active !== 'bindernewwizard' && !(this.state.isFreemium && this.state.isSample) ? <a onClick={(e) => this.onNewBinder(e, 'copy', true)} id="navbindercopy">Copy</a> : <span className='disabled-item-dropdown'>Copy</span>}
                  {!this.state.loadingAllBindersTemplates && hasBoardSelected && this.props.active !== 'bindernewwizard' && !(this.state.isFreemium && this.state.isSample) ? <a onClick={(e) => this.onNewTemplate(e, true)} id="navbindertemplate">Template</a> : <span className='disabled-item-dropdown'>Template</span>}
                </div>
              </div>
            </li>
            <li onClick={this.onLink} className="mdropdown">
              <div className="page">
                <Link to={RoutesConstants.people} className="boardpanel mMenuItem" id="navuser">People</Link>
              </div>
            </li>
            {this.props.updateWebPage === true &&
              <li className="mdropUpdate">
                <RefreshIcon style={{marginRight: 5}} size={16} color="#f12314"/>Update Available
              </li>
            }
          </ul>
          <div className="centerFlex centerpanel" style={{height: 40}} onClick={this.onDropBox.bind(this)}>
            <label className="centerVFlex"><ExitIcon size={18} style={{marginRight:5}}/> Close</label>
          </div>
        </div>
      </div>
    )
  }

  renderNameDropdown(){
    var css = 'navdropdown'
    if(this.state.showDropDownName) css += ' navactive'

    return (
      <div className={css}>
        <div className="page navdropp" onClick={this.onBlock.bind(this)}>
          <ul className="moffset" id="navbar">
            <li className="mdropdown" id="navhome">
              <Link to={RoutesConstants.myaccount} className="mMenuItem" id="navprofileset">Profile settings</Link>
            </li>
            <li onClick={this.onLink} className="mdropdown">
              <Link to={RoutesConstants.settingscustomer} className="mMenuItem" id="navset">Company settings</Link>
            </li>
            <div className='account-divider-mobile'></div>
            <li onClick={this.onLink} className="mdropdown">
              <Link to={RoutesConstants.feedback} className="mMenuItem" id="navfeed">Send Feedback</Link>
            </li>
            <li onClick={this.onLink} className="mdropdown">
              <a onClick={this.onSupport.bind(this)} className="mMenuItem" id="navhelp">Help Centre</a>
            </li>
            <div className='account-divider-mobile'></div>
            <li onClick={this.onLink} className="mdropdown">
              <a onClick={e => this.onLogout(e)} className="mMenuItem" id="navlogout">Logout</a>
            </li>
          </ul>
          <div className="centerFlex centerpanel" style={{height: 40}} onClick={this.onDropName.bind(this)}>
            <label className="centerVFlex"><ExitIcon size={18} style={{marginRight:5}}/> Close</label>
          </div>
          {/* <div style={{ textAlign: 'right', paddingRight: '10px', color: '#bebebe' }}>Version: {adminPortalVersion()}</div> */}
        </div>
      </div>
    )
  }

  onNewBinder = (event, type, isMobile = false) => {
    if(this.state.selectedBoard === "" || this.state.selectedBoardId === ""){
      if(this.hasBoards()){
        if(isMobile){
          this.handleShowBoardMenu(event)
        }
      }
      return
    }

    if(this.state.annualBinderCount >= this.state.annualBinderLimit && this.state.annualBinderLimit !== -1){
      this.setState({showMaxBinders: true})
      return
    }

    var u = this.GetBinderUnPublishedCount(), c = this.GetBinderCurrentCount()
    if(c === false || u === false) return
    if(c >= this.state.binderLimit || u >= this.state.unpublishedLimit){
      this.setState({showMaxBinders: u >= this.state.unpublishedLimit ? 'unpublished' : true})
      return
    }

    TrackEvent("f_board_create-new-binder-button.clicked",{
      navigated_from: 'navbar',
      user_id: this.props.userId,
      person_id: this.props.personId,
      company_id: this.props.customerId,
      alias: this.props.alias,
      board_id: this.state.selectedBoardId
    })

    if(type === 'new'){
      TrackEvent("f_board_new-binder.started",{
        board_id: this.state.selectedBoardId,
        user_id: this.props.userId,
        person_id: this.props.personId,
        company_id: this.props.customerId,
        alias: this.props.alias,
        binder_is_created_from_blank: true,
        binder_is_created_from_binder: false,
        binder_is_created_from_template: false,
        binder_copy_id: "",
        template_copy_id: "",
      })

      this.props.history.push({
        pathname: RoutesConstants.bindernewwizard,
        query: { board: { boardId: this.state.selectedBoardId, boardname: this.state.selectedBoard } }
      })
    }else {
      this.setState({showNewBinder: true})
    }
  }

  haveBinders(){
    let haveBinder = false
    if(this.props.companies[this.props.customerId] !== undefined && this.props.companies[this.props.customerId].boardIds !== undefined){
      const list = this.props.companies[this.props.customerId].boardIds
      if(list !== null){
        list.forEach(id => {
          if((this.props.bindersCurrent[id] !== undefined && this.props.bindersCurrent[id].length > 0)
          || (this.props.bindersPrevious[id] !== undefined && this.props.bindersPrevious[id].length > 0)){
            haveBinder = true
            return
          }
        })
      }
    }
    return haveBinder
  }

  onNewTemplate = (event, isMobile = false) => {
    if(this.state.selectedBoard === "" || this.state.selectedBoardId === ""){
      if(this.hasBoards()){
        if(isMobile){
          this.handleShowBoardMenu(event)
        }
      }
      return
    }
    const templateCount = this.GetTemplateCount()
    if(templateCount === false || templateCount >= this.state.templateLimit){
      this.setState({ showMaxTemplates: true })
      return
    }
    let haveTemplate = false, haveBinder = false
    if(this.props.companies[this.props.customerId] !== undefined && this.props.companies[this.props.customerId].boardIds !== undefined){
      const list = this.props.companies[this.props.customerId].boardIds
      if (list) {
        list.forEach(id => {
          if (this.props.boardtemplates[id] !== undefined && this.props.boardtemplates[id].length > 0) {
            haveTemplate = true
            return
          }
        })
        list.forEach(id => {
          if ((this.props.bindersCurrent[id] !== undefined && this.props.bindersCurrent[id].length > 0) ||
            (this.props.bindersPrevious[id] !== undefined && this.props.bindersPrevious[id].length > 0)) {
            haveBinder = true
            return
          }
        })
      }
    }

    // TrackEvent("f_board_newtemplate-button.clicked",{
    //   navigated_from: 'navbar',
    //   user_id: this.props.userId,
    //   person_id: this.props.personId,
    //   company_id: this.props.customerId,
    //   alias: this.props.alias,
    //   board_id: this.state.selectedBoardId
    // })

    if(!haveTemplate && !haveBinder){
      // TrackEvent("f_board_newbinder-template.started",{
      //   board_id: this.state.selectedBoardId,
      //   user_id: this.props.userId,
      //   person_id: this.props.personId,
      //   company_id: this.props.customerId,
      //   alias: this.props.alias,
      //   template_is_created_from_blank: true,
      //   template_is_created_from_binder: false,
      //   template_is_created_from_template: false,
      //   binder_copy_id: "",
      //   template_copy_id: "",
      // })

      this.props.history.push({
        pathname: RoutesConstants.bindernewwizard,
        query: { board: { boardId: this.state.selectedBoardId, boardname: this.state.selectedBoard, isTemplate: true } }
      })
    }else{
      this.setState({showNewTemplate: true, haveTemplate, haveBinder})
    }
  }

  GetBinderUnPublishedCount(){
    var total = 0;
    var loading = true;
    if(this.props.bindersUnpublished !== undefined){
      if(this.props.bindersUnpublished[this.state.selectedBoardId] !== undefined && this.props.bindersUnpublished[this.state.selectedBoardId] !== null){
        loading = false;
        if(this.props.bindersUnpublished[this.state.selectedBoardId].length !== undefined)
          total += this.props.bindersUnpublished[this.state.selectedBoardId].length;
      }
    }

    if(loading) return false;
    return total;
  }

  GetBinderCurrentCount(){
    var total = 0;
    var loading = true;
    if(this.props.bindersCurrent !== undefined){
      if(this.props.bindersCurrent[this.state.selectedBoardId] !== undefined && this.props.bindersCurrent[this.state.selectedBoardId] !== null){
        loading = false;
        if(this.props.bindersCurrent[this.state.selectedBoardId].length !== undefined)
          total += this.props.bindersCurrent[this.state.selectedBoardId].length;
      }
    }

    if(loading) return false;
    return total;
  }

  GetTemplateCount(){
    if(this.props.boardtemplates === undefined || this.props.boardtemplates === null) return false;
    if(this.props.boardtemplates[this.state.selectedBoardId] === undefined || this.props.boardtemplates[this.state.selectedBoardId] === null) return false;

    var value = 0;
    if(this.props.templatesDraft !== undefined && this.props.templatesDraft !== null){
      if(this.props.templatesDraft[this.state.selectedBoardId] !== undefined && this.props.templatesDraft[this.state.selectedBoardId] !== null)
        value = this.props.templatesDraft[this.state.selectedBoardId].length;
    }

    return this.props.boardtemplates[this.state.selectedBoardId].length + value;
  }

  showHeaderMsg(){
    let msg = ""
    if(this.state.isFreemium){
      const current = this.GetBinderCurrentCount()
      if(current !== false){
        msg = 'Your annual binder limit has ' + (this.state.annualBinderLimit - current) + ' of ' + this.state.annualBinderLimit + ' binder' + ((this.state.annualBinderLimit - current) < 2 ? "" : "s") + ' remaining'
      }
    }
    return msg
  }

  exitNewBinder = () => {
    this.setState({showNewBinder: false, showNewTemplate: false})
  }

  hasBoards(){
    let hasBoards = false;
    for(let i=0; i<this.state.companyList.length; i++){
      if(this.state.companyList[i].boards.length > 0){
        hasBoards = true
        break
      }
    }
    return hasBoards
  }

  handleSelectBoard = (event, url, isMobile = false) => {
    if(this.state.selectedBoard !== "" && this.state.selectedBoardId !== ""){
      this.props.history.push(url)
    }else if(this.hasBoards()){
      if(isMobile){
        this.handleShowBoardMenu(event)
      }
    }
  }

  handleShowBoardMenu = (e) => {
    if(this.props.active === 'bindernewwizard' || this.props.active === 'boards' || this.props.active === 'boardsnew'){
      return
    }
    if(!this.hasBoards()){
      this.props.history.push(RoutesConstants.boards)
    }else {
      if(this.props.customerId !== undefined){
        this.setState({tabValue: this.props.customerId})
      }
      this.setState({ showBoardMenu: !this.state.showBoardMenu, boardRef: e.currentTarget, showDropDownMenu: false, showDropDownName: false })
    }
  }

  handleHideBoardMenu = () => {
    this.setState({ showBoardMenu: false })
  }

  onSelectBoard = (id, name, logo, customerId) => {
    this.handleHideBoardMenu();
    if(id === this.state.selectedBoardId) return
    this.setState({selectedBoardId: id, selectedBoard: name, selectedBoardLogo: logo});
    this.props.dispatch(boardActions.setCurrentBoard(id, name, customerId))
    this.props.dispatch(adminPermissionsActions.getPermissions(id));
    if(customerId !== this.props.customerId){
      this.props.dispatch(customerActions.switchCustomer(customerId, true))
    }
    if (window.location.pathname === RoutesConstants.bindernew ||
      window.location.pathname === RoutesConstants.binderdash ||
      window.location.pathname === RoutesConstants.bindernewwizard ||
      window.location.pathname === RoutesConstants.templatenew
    )
      this.props.history.push(RoutesConstants.binderboard)
    else if (window.location.pathname === RoutesConstants.boardedit)
      this.props.history.push(RoutesConstants.boards)
    else if (window.location.pathname === RoutesConstants.fileboard)
      this.props.history.push(RoutesConstants.fileboard)
    else if (window.location.pathname === RoutesConstants.settingsnew)
      this.props.history.push(RoutesConstants.people)
    else if (window.location.pathname === RoutesConstants.useradd || window.location.pathname === RoutesConstants.useredit)
      this.props.history.push(RoutesConstants.users)
  }

  addMenuNewBoardPage = (event) => {
    this.movetoNewBoardPage("navbar")
  }

  addNewBoardPage = (event) => {
    this.movetoNewBoardPage("companybar")
  }

  movetoNewBoardPage = (type) => {
    var nonSampleCount = this.state.boardCount;
    this.props.allBoards.forEach(b => {
      if(b && b.isSample && this.state.isFreemium) {
        nonSampleCount += -1;
      }
    });
    if(nonSampleCount===-1||nonSampleCount>=this.state.boardLimit){
      this.setState({ showMaxBoard: true })
      return
    }
    if(nonSampleCount >= this.state.boardLimit){
      confirmAlert({
        customUI: ({ onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Board limit has been reached.</h1>
            <p>Currently reached maximum limit allowed for boards. Please contact sales or support for more information.</p>
            <div className="boardpanel flexend"><MuiButton variant="contained" onClick={onClose}>OK</MuiButton></div>
          </div>,
      })
      return
    }

    TrackEvent("f_board_addnewboard-committeebutton.clicked",{
      user_id: this.props.myId,
      person_id: this.props.personId,
      company_id: this.props.customerId,
      alias: this.props.alias,
      navigated_from: type
    })

    this.props.history.push({
      pathname: RoutesConstants.boardnew
    })
  }

  viewAll = () => {
    this.props.history.push({
      pathname: RoutesConstants.boards
    })
  }

  renderBoardMenu(isSuspended = false){
    let companyList = this.state.companyList
    // if(this.state.isFreemium){
    //   // return (
    //   //   <div className={`navddown-board-container cText ${!this.props.showMobile && 'navdown-board-popper'}`} style={{padding: '15px 10px'}}>
    //   //     <div className='bottom10 text16s'>You can only have 1 board in Ansarada Board Free</div>
    //   //     <MuiButton variant="contained" onClick={()=>{
    //   //       this.handleHideBoardMenu()
    //   //       this.onUpgrade()
    //   //     }}>
    //   //       Upgrade
    //   //     </MuiButton>
    //   //   </div>
    //   // )
    // } 
    if(companyList.length === 1){
      return (
        <BoardSelection
          boards={companyList[0].boards}
          company={companyList[0]}
          current={this.state.selectedBoardId}
          isMobile={this.props.showMobile}
          isSuspended={isSuspended}
          isMulti={false}
          onSelect={this.onSelectBoard}
          onCreate={()=>this.addNewBoardPage()}
          onView={this.viewAll}
        />
      )
    }else if(companyList.length > 1){
      return (
        <MultiCompanyBoardSelection
          company={companyList}
          customerId={this.props.customerId}
          current={this.state.selectedBoardId}
          isMobile={this.props.showMobile}
          onSelect={this.onSelectBoard}
          onCreate={()=>this.addNewBoardPage()}
          onView={this.viewAll}
          props={this.props}
        />
      )
    }
  }

  renderContactSupport(){
    return (
      <div className='text12s' style={{padding: '0 20px'}}>
        <div className="colorAnsaradaDark">Contact support</div>
        <a href="mailto:support@ansarada.com" target="_blank" style={{padding: 0, margin: 0}}>
          <div className="colorAnsarada">support@ansarada.com</div>
        </a>
        <div className="colorAnsaradaDark">Australasia</div>
        <div className="colorAnsaradaDark">+61 2 8241 0888</div>
        <div className="colorAnsaradaDark">Americas</div>
        <div className="colorAnsaradaDark">+1 312 638 2200</div>
        <div className="colorAnsaradaDark">Europe</div>
        <div className="colorAnsaradaDark">+44 20 3058 1060</div>
        <div className="colorAnsaradaDark">South Africa</div>
        <div className="colorAnsaradaDark">+27 87550 2398</div>
      </div>
    )
  }

  renderLink(linkItem, type, menuItemDetails = ''){
    return (
      <span
        onMouseEnter={() => { if (menuItemDetails && this.props.showTooltip !== false) { this.setState({ menuItemDetails }) } }}
        onMouseLeave={() => { if (this.state.menuItemDetails) { this.setState({ menuItemDetails: '' }) } }}
      >
        {linkItem}
      </span>
    )
  }

  getMenuItemStyling = () => {
    return (this.state.menuItemDetails === 'Maximum binders exceeded' 
    || this.state.menuItemDetails.includes('A board must be added before') 
    || this.state.menuItemDetails.includes("Board selection cannot be changed") 
    || this.state.menuItemDetails.includes("No binders found")
    || this.state.menuItemDetails.includes("until you leave")
    ? ' navbar-menu-item-hover-details-error' : '')
  }

  handleHoverBoard = () => {
    if(this.props.showTooltip !== false){
      if (this.props.active === 'bindernewwizard' || this.props.active === 'boards' || this.props.active === 'boardsnew') {
        this.setState({ menuItemDetails: "Board selection cannot be changed while editing or creating boards, binders or templates" })
      } else {
        this.setState({ menuItemDetails: 'Select the current board' })
      }
    }
  }

  handleLeaveBoard = () => {
    if(this.state.menuItemDetails) { 
      this.setState({ menuItemDetails: "" }) 
    }
  }

  displayCompanyName(){
    if(this.props.customerId !== undefined && this.props.customerId !== "" && this.state.companyList.length > 1){
      const c = this.state.companyList.find(o => o.customerId === this.props.customerId)
      if(c !== undefined){
        return c.label
      }
    }
    return ""
  }

  toAudit = () => {
    if(this.state.isFreemium){
      this.onUpgrade()
    }else {
      this.props.history.push(RoutesConstants.audit)
    }
  }
  
  renderNewBinderLink = (hasBoardSelected, inCreatingBinder) => {
    if(this.state.isSample && this.state.isFreemium){
      return this.renderLink(<div className='disabled-item boardpanel'><span>New</span></div>, 'New', 'Binders cannot be created in demonstration boards');
    }
    if (!hasBoardSelected) {
      return this.renderLink(<div className='disabled-item boardpanel'><span>New</span></div>, 'New', 'A board must be added before binders can be created');
    }
    if (inCreatingBinder) {
      return this.renderLink(<div className='disabled-item boardpanel'><span>New</span></div>, 'New', "Binder can't be created until you leave the create or edit binder/template page");
    }
    if (this.GetBinderUnPublishedCount() === false) {
      return this.renderLink(<div className='disabled-item boardpanel'><span>New</span><Loader small={true} size={9} /></div>, 'New', '');
    }
    if (hasBoardSelected && !inCreatingBinder) {
      return this.renderLink(<a onClick={(e) => this.onNewBinder(e, 'new')} className="menuItemLink" id="navbindernew">New</a>, 'New', 'Create a new binder');
    }

    return null;
  }

  renderCopyBinderLink = (hasBoardSelected, inCreatingBinder) => {
    if (!hasBoardSelected || inCreatingBinder || (this.state.isSample && this.state.isFreemium)) {
      var tooltip = !hasBoardSelected ? 'A board must be added before binders can be created'
        : inCreatingBinder ? "Binder can't be copied until you leave the create or edit binder/template page" : '';

      if (this.state.isSample && this.state.isFreemium) {
        tooltip = 'Binders cannot be created in demonstration boards'
      }
      return this.renderLink(<div className='disabled-item boardpanel'><span>Copy</span></div>, 'Copy', tooltip);
    }
    
    if (this.state.loadingAllBindersTemplates) {
      return this.renderLink(<div className='disabled-item boardpanel'><span>Copy{" "}</span><Loader small={true} size={9} /></div>, '');
    }

    if (!this.haveBinders()) {
      return this.renderLink(<div className='disabled-item boardpanel'><span>Copy</span></div>, 'Copy', 'No binders found to copy from');
    } else {
      return this.renderLink(<a onClick={(e) => this.onNewBinder(e, 'copy')} className="menuItemLink" id="navbindercopy">Copy</a>, 'Copy', 'Copy an existing binder');
    }
  }

  renderTemplateBinderLink = (hasBoardSelected, inCreatingBinder) => {
    if(!hasBoardSelected || inCreatingBinder || (this.state.isSample && this.state.isFreemium)) {
      var tooltip = !hasBoardSelected ? 'A board must be added before templates can be created'
      : inCreatingBinder ? "Template can't be created until you leave the create or edit binder/template page" : '';
      if (this.state.isSample && this.state.isFreemium) {
        tooltip = 'Templates cannot be created in demonstration boards'
      }
      return this.renderLink(<div className='disabled-item boardpanel'><span>Template</span></div>, 'Template', tooltip);
    }
    if(this.state.loadingAllBindersTemplates) {
      return this.renderLink(<div className='disabled-item boardpanel'><span>Template{" "}</span><Loader small={true} size={9} /></div>, '');
    } else {
      return this.renderLink(<a onClick={(e) => this.onNewTemplate(e)} className="menuItemLink" id="navbindertemplate">Template</a>, 'Template', 'Create a new template');
    }
  }

  getFreemiumTitle = () => {
    return (
    <div className="freemiumTitle">
    <label>You're using Ansarada Board Free</label>
    <MuiButton variant="contained" onClick={this.onUpgrade.bind(this)}>Upgrade</MuiButton>
  </div>
  )
  }

  getTrialTitle = (isSuspended) => {
    var trialDaysRemaining = 0;
    var currentCustomer = this.props.customers ? this.props.customers.find(c => c.id == this.props.customerId) : null;
    if (currentCustomer.accountType === FreemiumType.trial || currentCustomer.accountType === FreemiumType.trial_internal) {
      try {
        trialDaysRemaining = Math.round(moment.duration(moment(currentCustomer.trialEndDate) - moment()).asDays());
      } catch { }
    }
    var text = `You're using Ansarada Board Trial: ${trialDaysRemaining} day${trialDaysRemaining == 1 ? '' : 's'} remaining`;
    if (isSuspended && isSuspended.reason == 'TrialEnded') {
      text = 'Your trial has ended for this customer';
    }
    return (
      <div className="freemiumTitle">
        <label>{text}</label>
        <MuiButton variant="contained" onClick={this.onUpgrade.bind(this)}>Upgrade</MuiButton>
      </div>
    )
  }

  renderTrialFreemiumSuspendedInfo = (isSuspended) => {
    if (this.state.isFreemium) { return this.getFreemiumTitle(); }
    if (this.state.isTrial) { return this.getTrialTitle(isSuspended); }

    if (isSuspended) {
      return (
        <div className="freemiumTitle">
          <label>This customer has been suspended. Please contact support.</label>
        </div>
      )
    }
  }

  renderDesktop(){
    var hasBoardSelected = this.hasBoardSelected();
    var inCreatingBinder = (this.props.active === 'bindernewwizard')
    var inCreatingBoard = (this.props.active === 'boardsnew')
    var isSuspended = this.props.suspendedCustomers ? this.props.suspendedCustomers.find(c => c.customerId == this.props.customerId) : false;

    const menuStyle={fontSize: '13px', textDecoration: 'none', userSelect: 'none', fontWeight: '700', fontFamily: "AktivGrotesk, sans-serif, arial"}

    return (
      <div className="bar" style={{ flex: 1 }}>
        <div className="navtop boardpanel" style={{marginBottom: 5}}>
          <div className="boardpanel AnsaradalogoN">
            <img src={logoi} className="navlogo" />
          </div>
          <div className='centerVFlex'>
            {this.renderTrialFreemiumSuspendedInfo(isSuspended)}
          </div>
          <div className="barright centerVFlex">
            <div className="navddown" onMouseEnter={this.handleHideBoardMenu}>
              <span className='menuItem' style={{marginRight: 0}}>{this.props.firstName} {this.props.lastName}<DownIcon className="menuexpicon" /></span>
              <div className="navddown-content account-navdown">
                {this.renderLink(<Link to={RoutesConstants.myaccount} className="menuItemLink" id="navprofileset">Profile settings</Link>, 'Profile settings', 'View profile settings')}
                {this.renderLink(<Link to={RoutesConstants.settingscustomer} className="menuItemLink" id="navset">Company settings</Link>, 'Company settings', 'View company settings')}
                <div className='account-divider'></div>
                {this.renderLink(<Link to={RoutesConstants.feedback} className="menuItemLink" id="navfeed">Send feedback</Link>,"Send feedback", 'Send your feedback directly to the Board team')}
                {this.renderLink(<a onClick={this.onSupport.bind(this)} id="navhelp" className="menuItemLink">Help centre</a>, 'Help centre', 'Ansarada Board help centre')}
                {this.renderLink(<a onClick={() => { this.props.dispatch(baseMarketingActions.showMarketing()); }} id="navhelp" className="menuItemLink">Show news</a>, 'Show news', 'Show news')}
                {/* {this.renderLink(<a style={{ display: 'flex' }} onClick={this.openAppcuesChecklist} id="navhelp" className="menuItemLink centerVFlex">
                  <CircularProgress
                    style={{ marginRight: '1px', marginLeft: '-12px' }}
                    size={11} color='success'
                    id='board-navbar-appcues-loading'
                  />
                  <CheckCircleOutlineIcon
                    id='board-navbar-appcues-checkmark'
                    className={window.Appcues_checklistShowing ? 'board-navbar-appcues-show-el' : ''}
                    style={{ fontSize: '14px', marginRight: '1px', marginLeft: '-15px' }}
                    color='success'
                  />
                  <Typography style={{ padding: '10px 0', fontSize: '12px', color: 'var(--dark-grey)', textDecoration: 'none', userSelect: 'none', fontWeight: '700' }}>Display assistance</Typography></a>
                  , 'Display assistance', 'Display onboarding assistance guide')
                } */}
                <div className='account-divider'></div>
                {/* <div>{this.renderContactSupport()}</div>
                <div className='account-divider'></div> */}
                {this.renderLink(<a onClick={e => this.onLogout(e)} className="menuItemLink" id="navlogout">Logout</a>, 'Logout', 'Logout from your Ansarada Board admin session')}
                {this.state.menuItemDetails &&
                  <div style={{ position: 'relative' }}>
                    <div className='navbar-menu-item-hover-details navbar-menu-item-hover-details-right'>{this.state.menuItemDetails}</div>
                  </div>
                }
                {/* <div style={{ textAlign: 'right', paddingRight: '10px', color: '#bebebe', fontSize: '12px', lineHeight: '1', paddingTop: '10px', userSelect: 'none' }}>Version: {adminPortalVersion()}</div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="navtop board-offset">
          <div className="boardpanel">
            <ul className="actoffset centerVFlex" id="navbar">
              <li className="navddown">
                <div onMouseEnter={this.handleHoverBoard} onMouseLeave={this.handleLeaveBoard}>
                  <MuiButton
                    variant="outlined"
                    disabled={this.props.active === 'bindernewwizard' || this.props.active === 'boards' || this.props.active === 'boardsnew'}
                    onClick={this.handleShowBoardMenu}
                    startIcon={!this.state.selectedBoard ? null : this.state.selectedBoardLogo
                      ? <div style={{ height: 20 }}><img className="boardlogoSmall fs-exclude" src={this.state.selectedBoardLogo} /></div>
                      : <div className="boardlogoSmall LogoFont LogoImage" style={{ fontSize: 5 }}>LOGO</div>}
                    endIcon={this.state.showBoardMenu ? <UpIcon className="menuexpicon-board" /> : <DownIcon className="menuexpicon-board" />}
                    style={{ marginRight: 25 }}
                  >
                    <div className='board-menu-text'>
                      <Typography noWrap style={menuStyle}>
                        {this.state.selectedBoard ? <span>{this.state.selectedBoard}{isSuspended ? <b> (Suspended)</b> : null}</span> : "Select board"}
                      </Typography>
                    </div>
                  </MuiButton>
                </div>
                <Popper
                  open={this.state.showBoardMenu}
                  anchorEl={this.state.boardRef}
                  onClose={this.handleHideBoardMenu}
                  placement='bottom-start'
                  style={{ zIndex: '999999' }}
                >
                  <ClickAwayListener onClickAway={this.handleHideBoardMenu}>
                    <div>{this.renderBoardMenu(isSuspended)}</div>
                  </ClickAwayListener>
                </Popper>
                <div className="navddown-content" style={{ padding: 0 }}>
                  {(this.state.menuItemDetails && !this.state.showBoardMenu) &&
                    <div style={{ position: 'relative' }}>
                      <div className={'navbar-menu-item-hover-details' + this.getMenuItemStyling()}>{this.state.menuItemDetails}</div>
                    </div>
                  }
                </div>
              </li>
              <li className="navddown">
                {this.renderLink(<Link to="/" className="menuItem" id="navhome">Home</Link>, 'Home', 'Ansarada Board dashboard')}
                <div className="navddown-content" style={{ padding: 0 }}>
                  {this.state.menuItemDetails && !this.state.showBoardMenu &&
                    <div style={{ position: 'relative' }}>
                      <div className='navbar-menu-item-hover-details'>{this.state.menuItemDetails}</div>
                    </div>
                  }
                </div>
              </li>
              <li className={`navddown ${isSuspended ? 'isSuspended' : ''}`} onMouseEnter={this.handleHideBoardMenu} id="navboards">
                {this.renderLink(<span className="dropbtn menuItem">Boards/Committees<DownIcon className="menuexpicon" /></span>, 'Main')}
                <div className="navddown-content">
                  {this.renderLink(!inCreatingBoard  ? <a onClick={(e) => this.addMenuNewBoardPage(e)} className="menuItemLink" id="navboard">New</a> : <div className='disabled-item boardpanel'><span>New</span></div>, 'New', !inCreatingBoard ? 'Create a new board' : "Board can't be created until you leave the create board page")}
                  {this.renderLink(hasBoardSelected ? <a onClick={(e) => this.handleSelectBoard(e, RoutesConstants.boardedit)} className="menuItemLink" id="navboard">Board settings</a> : <div className='disabled-item boardpanel'><span>Board settings</span></div>, 'Board settings', hasBoardSelected ? 'Manage your board settings' : 'A board must be added before board settings can be changed')}
                  {this.renderLink(hasBoardSelected ? <a onClick={(e) => this.handleSelectBoard(e, RoutesConstants.fileboard)} className="menuItemLink" id="navfiles">Files</a> : <div className='disabled-item boardpanel'><span>Files</span></div>,'Files',hasBoardSelected ?  'Manage your files' : 'A board must be added before files can be managed')}
                  {this.renderLink(hasBoardSelected ? <a onClick={(e)=>this.handleSelectBoard(e, RoutesConstants.calendarboard)} className="menuItemLink" id="navcalendar">Calendar</a> : <div className='disabled-item boardpanel'><span>Calendar</span></div>,'Calendar', hasBoardSelected ? 'Manage your calendar' : 'A board must be added before calendar can be managed')}
                  {this.state.minutesEnabled === true &&
                    this.renderLink(hasBoardSelected ? <a onClick={(e) => this.handleSelectBoard(e, RoutesConstants.minuteboard)} className="menuItemLink" id="navminutes">Minutes</a> : <div className='disabled-item boardpanel'><span>Minutes</span></div>, 'Minutes', hasBoardSelected ? 'Manage your minutes' : 'A board must be added before minutes can be managed')
                  }
                  {this.state.usageLogsFeatureStatus !== 2 &&
                    this.renderLink(this.hasBoards() ? <a onClick={this.toAudit} className="menuItemLink" id="navaudit">Audit logs</a> : <div className='disabled-item boardpanel'><span>Audit logs</span></div>,'Audit logs', this.hasBoards() ? 'View your audit logs' : 'A board must be added before audit logs can be viewed')
                  }
                  <div className='account-divider'></div>
                  {this.renderLink(<Link to={RoutesConstants.boards} className="menuItemLink" id="navboards">Show all boards</Link>,"Show all boards", 'Show all boards')}
                  {this.state.menuItemDetails &&
                    <div style={{ position: 'relative' }}>
                      <div className={'navbar-menu-item-hover-details' + this.getMenuItemStyling()}>{this.state.menuItemDetails}</div>
                    </div>
                  }
                </div>
              </li>
              <li className={`navddown ${isSuspended ? 'isSuspended' : ''}`} onMouseEnter={this.handleHideBoardMenu}>
                {this.renderLink(<span className="dropbtn menuItem" id="navbinder">Binders<DownIcon className='menuexpicon' /></span>, "Binders")}
                <div className="navddown-content">
                  {this.renderNewBinderLink(hasBoardSelected, inCreatingBinder)}
                  {this.renderLink(hasBoardSelected ? <a onClick={(e) => this.handleSelectBoard(e, RoutesConstants.binderboard)} className="menuItemLink" id="navbinderedit">Manage</a> : <div className='disabled-item boardpanel'><span>Manage</span></div>, 'Manage', hasBoardSelected ? 'Manage your binders' : 'A board must be added before binders can be managed')}
                  {this.renderCopyBinderLink(hasBoardSelected, inCreatingBinder)}
                  {this.renderTemplateBinderLink(hasBoardSelected, inCreatingBinder)}
                  {this.state.menuItemDetails &&
                    <div style={{ position: 'relative' }}>
                      <div className={'navbar-menu-item-hover-details' + this.getMenuItemStyling()}>{this.state.menuItemDetails}</div>
                    </div>
                  }
                </div>
              </li>
              <li className={`navddown ${isSuspended ? 'isSuspended' : ''}`}>
                {this.renderLink(<Link to={RoutesConstants.people} className="dropbtn menuItem" id="navusers">People</Link>, 'People', 'Manage users and administrators')}
                <div className="navddown-content" style={{ padding: 0 }}>
                  {this.state.menuItemDetails && !this.state.showBoardMenu &&
                    <div style={{ position: 'relative' }}>
                      <div className='navbar-menu-item-hover-details'>{this.state.menuItemDetails}</div>
                    </div>
                  }
                </div>
              </li>
              {this.props.updateWebPage === true &&
                <li className="updates toolp">
                  <RefreshIcon className="refreshIcon" size={16} color="#f12314"/>Update Available
                  <span className="toolptext toolp-bottom">
                    {this.state.total === 0?
                      "New version of this web portal is available, please click browser refresh button."
                      :
                      "New version of this web portal is available, please wait for all task to be completed."
                    }
                  </span>
                </li>
              }
            </ul>
          </div>
        </div>
        {this.state.companyList.length > 1 && <div className='board-company-text'>Company: {this.displayCompanyName()}</div>}
        <div>
          {this.state.total !== 0 &&
            <div className={`dProgBar navProPanel boardpanel ${this.onColourSelect()}`} onClick={this.onOpenPanel.bind(this)}>
              <div style={{width: '20%'}}>
                <div className="centerVFlex page uploadRemain" style={{width: 40, marginLeft: 15}}>
                  <span>{this.state.total.toString()}</span>
                  <label>Remaining</label>
                </div>
              </div>
              <div className="page" style={{marginLeft: 10, width: '80%'}} id="navprog">
                <TruncateText className="progressTitle fs-exclude" truncatePosition="Tail" id={"uploadId"+this.state.currentId}>
                  {this.state.currentName}
                </TruncateText>
                <div style={{marginRight: 20}}>
                  {this.state.errorTotal !== 0 && this.state.errorTotal === this.state.total?
                    <label className="navErrorText">Item failed to upload, either cancel upload or retry again</label>
                    :
                    <ProgressBar
                      success={this.state.overallPercent}
                      height={2}
                      className="progressBar2"
                      classNameBar="progressBarSuccess"
                    />
                  }
                </div>
                <div className="progressMsg">
                  Please do not close your browser until complete.
                </div>
              </div>
            </div>
          }
          {this.state.showControlPanel &&
            <div
              className="account-info"
              style={{right: 160, top: 125, width: 380, height: 400}}
              ref={(node) => this.progressRef = node}>
              <ProgressPanel
                {...this.props}
                />
            </div>
          }
        </div>
      </div>
    )
  }

  renderMobile(){
    const menuStyle={fontSize: '13px', textDecoration: 'none', userSelect: 'none', fontWeight: '700', fontFamily: "AktivGrotesk, sans-serif, arial"}

    return (
      <div className="boardpanel maxWidth">
        <div className="bar">
          <div className="spacebetween boardpanel centerpanel">
            <div className="spacebetween boardpanel centerpanel">
              <div style={{ marginRight: 15 }}>
                <MuiButton
                  variant="outlined"
                  disabled={this.props.active === 'bindernewwizard' || this.props.active === 'boards' || this.props.active === 'boardsnew'}
                  onClick={this.handleShowBoardMenu}
                  endIcon={this.state.showBoardMenu ? <UpIcon className="menuexpicon-board" /> : <DownIcon className="menuexpicon-board" />}
                >
                  <div className={this.state.isFreemium ? 'board-menu-text-freemium' : 'board-menu-text'}>
                    <Typography noWrap style={menuStyle}>
                      {this.state.selectedBoard ? this.state.selectedBoard : "Select board"}
                    </Typography>
                  </div>
                </MuiButton>
              </div>
              <div className="text14s Page-title-mobile" onClick={this.onDropBox.bind(this)}>
                Menu{this.state.showDropDownMenu ? <UpIcon className="menuexpicon" /> : <DownIcon className="menuexpicon" />}
              </div>
            </div>
            {this.state.isFreemium &&
              <div>
                <MuiButton variant="contained" onClick={this.onUpgrade.bind(this)}>Upgrade</MuiButton>
              </div>
            }
            <div className="text14s Page-title-mobile" style={{marginRight: 10}} onClick={this.onDropName.bind(this)}>
              {this.props.firstName} {this.props.lastName}{this.state.showDropDownName?<UpIcon className="menuexpicon" />:<DownIcon className="menuexpicon" />}
            </div>
          </div>
        </div>
        {this.renderDropDown()}
        {this.renderNameDropdown()}
        {this.state.showBoardMenu && (
          <div className="navdropdown-board">
            <div className='navdropdown-board-scroll'>{this.renderBoardMenu()}</div>
            <div className="centerFlex centerpanel" style={{ height: 40 }} onClick={this.handleShowBoardMenu}>
              <label className="centerVFlex text14s"><ExitIcon size={18} style={{ marginRight: 5 }} /> Close</label>
            </div>
          </div>
        )}
        {this.state.total !== 0 &&
          <div className="mProgBar">
            <div className={`navProPanel boardpanel ${this.onColourSelect()}`} onClick={this.onOpenPanel.bind(this)}>
              <div>
                <div className="centerVFlex page uploadRemain" style={{width: 40, marginLeft: 15, marginTop: 5}}>
                  <span>{this.state.total.toString()}</span>
                  <label>Remaining</label>
                </div>
              </div>
              <div className="page maxWidth" style={{marginLeft: 10}} id="navprog">
                <div className="progressTitle fs-exclude" id={"uploadId-"+this.state.currentId}>
                  {this.state.currentName}
                </div>
                <div style={{marginRight: 20}}>
                  {this.state.errorTotal !== 0 && this.state.errorTotal === this.state.total?
                    <label className="navErrorText">Item failed to upload, either cancel upload or retry again</label>
                    :
                    <ProgressBar
                      success={this.state.overallPercent}
                      height={2}
                      className="progressBar2"
                      classNameBar="progressBarSuccess"
                    />
                  }
                </div>
                <div className="progressMsg">
                  Please do not close your browser until complete.
                </div>
              </div>
            </div>
          </div>
        }
        {this.state.showControlPanel &&
          <div
            className="maccount-info"
            ref={(node) => this.progressRef = node}>
            <div className="maccount-Inner">
              <ProgressPanel
                onClose={this.onOpenPanel.bind(this)}
                {...this.props}
                />
            </div>
          </div>
        }
      </div>
    )
  }

  render(){
    const templateCount = this.GetTemplateCount()
    const headerMsg = this.showHeaderMsg()
    let loading = false
    if(this.props.customer[this.props.customerId] !== undefined){
      if (this.props.customer[this.props.customerId].loading !== undefined) {
        loading = this.props.customer[this.props.customerId].loading
      }
    }

    return(
      <div className='centerVFlex' style={{ flex: 1 }}>
        {this.props.showMobile ? this.renderMobile() : this.renderDesktop()}
        {appConfig.intercom === true && <IntercomUpdate {...this.props} />}
        {this.state.showMaxBoard &&
          <UpgradeNow
            open={this.state.showMaxBoard}
            freemium={this.state.isFreemium}
            content="You have reached the maximum number of boards."
            onExit={() => {
              this.setState({ showMaxBoard: false })
            }}
          />
        }
        {this.state.showMaxBinders &&
          <UpgradeNow
            open={this.state.showMaxBinders !== false}
            freemium={this.state.isFreemium}
            content={this.state.showMaxBinders === 'unpublished' ? "You have reached the maximum number of Unpublished Binders. Please either delete or publish one." : "You have reached the maximum number of binders."}
            onExit={() => {
              this.setState({ showMaxBinders: false })
            }}
          />
        }
        {this.state.showMaxTemplates &&
          <UpgradeNow
            open={this.state.showMaxTemplates}
            freemium={false}
            content="You have reached the maximum number of templates."
            onExit={() => {
              this.setState({ showMaxTemplates: false })
            }}
          />
        }
        {this.state.showUpgrade &&
          <UpgradeNow
            open={this.state.showUpgrade}
            isTrial={this.state.isTrial}
            freemium={this.state.isFreemium}
            onExit={() => { this.setState({ showUpgrade: false }) }}
            {...this.props}
          />
        }
        <CreateNewBinderModal
          boardLoading={loading}
          showBinder={this.state.showNewBinder}
          showTemplate={this.state.showNewTemplate}
          isNav={true}
          boardId={this.state.selectedBoardId}
          boardname={this.state.selectedBoard}
          templateCount={templateCount}
          templateLimit={this.state.templateLimit}
          loadingTemplates={this.state.loadingTemplates}
          templates={this.state.templates}
          haveTemplate={this.state.haveTemplate}
          haveBinder={this.state.haveBinder}
          onExit={this.exitNewBinder}
          onOpen={this.exitNewBinder}
          headerMsg={headerMsg}
          {...this.props}
        />
      </div>
    )
  }
}

class MasterUser extends BaseUser {
  constructor(props) {
    super(props);

    var initial = {
      image: "",
      imageId: "",
      companyImageId: "",
      companyImage: "",
      companyName: this.props.companyName,
    }

    if(this.props.imageId !== undefined && this.props.imageId !== ""){
      initial.imageId = this.props.imageId
      if(initial.imageId !== "" && initial.imageId !== undefined){
        if(this.props.files[initial.imageId] !== undefined){
          if(!this.props.files[initial.imageId].loading && this.props.files[initial.imageId].error === "")
          initial.image = ImageDataBase64(this.props.files[initial.imageId]);
        }else
          this.props.dispatch(fileActions.GetImage(initial.imageId))
      }
    }
    if(this.props.customerSettings !== undefined && this.props.customerSettings[this.props.customerId] !== undefined &&
        this.props.customerSettings[this.props.customerId].logoImageId !== undefined ){
      initial.companyImageId = this.props.customerSettings[this.props.customerId].logoImageId
      initial.companyName = this.props.customerSettings[this.props.customerId].companyName
      if(initial.companyImageId !== "" && initial.companyImageId !== undefined){
        if(this.props.files[initial.companyImageId] !== undefined){
          if(!this.props.files[initial.companyImageId].loading && this.props.files[initial.companyImageId].error === "")
          initial.companyImage = ImageDataBase64(this.props.files[initial.companyImageId]);
        }else
          this.props.dispatch(fileActions.GetImage(initial.companyImageId))
      }
    }

    this.state = {
      image: initial.image,
      imageId: initial.imageId,
      companyImage: initial.companyImage,
      companyImageId: initial.companyImageId,
      companyName: initial.companyName,

      showCompanies: false,
      showUsers: false,
      showDropDownMenu: false,
      showMyAccount: false,
    }

    this.onLink = this.onLink.bind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    var newState = {
    }

    if(nextProps.imageId !== undefined && nextProps.imageId !== ""){
      newState.imageId = nextProps.imageId
      if(newState.imageId !== "" && newState.imageId !== undefined){
        if(nextProps.files[newState.imageId] !== undefined){
          if(!nextProps.files[newState.imageId].loading && nextProps.files[newState.imageId].error === "")
            newState.image = ImageDataBase64(nextProps.files[newState.imageId]);
        }
      }
    }

    if(nextProps.customerSettings !== undefined && nextProps.customerSettings[nextProps.customerId] !== undefined &&
        nextProps.customerSettings[nextProps.customerId].logoImageId !== undefined ){
      newState.companyImageId = nextProps.customerSettings[nextProps.customerId].logoImageId
      if(newState.companyImageId !== "" && newState.companyImageId !== undefined){
        if(nextProps.files[newState.companyImageId] !== undefined){
          if(!nextProps.files[newState.companyImageId].loading && nextProps.files[newState.companyImageId].error === "")
          newState.companyImage = ImageDataBase64(nextProps.files[newState.companyImageId]);
        }
      }
    }else if(state.companyImage !== "") newState.companyImage = ""

    if(Object.keys(newState).length === 0) return null
    return newState
  }

  isCompanyPage(){
    if(this.props.active === 'companies' ||
        this.props.active === 'company' ||
        this.props.active === 'audit')
      return true
    return false
  }

  isUserPage(){
    if(this.props.active === 'users' ||
        this.props.active === 'master' ||
        this.props.active === 'people' ||
        this.props.active === 'appusers' ||
        this.props.active === 'admins')
      return true
    return false
  }

  onSlideMenu(){
    this.setState({showMyAccount: !this.state.showMyAccount})
  }

  onCompany(){
    this.setState({showCompanies: !this.state.showCompanies})
  }

  onUsers(){
    this.setState({showUsers: !this.state.showUsers})
  }

  onDropBox(){
    this.setState({showDropDownMenu: !this.state.showDropDownMenu})
  }

  onBlock(e){
    e.stopPropagation();
  }

  onLink(){
    if(this.props.onLink !== undefined)
      this.props.onLink();
  }

  renderDropDown(){
    var css = 'navdropdown', boardcss = "msubContent", usercss = "msubContent"
    if(this.state.showDropDownMenu) css += ' navactive'

    if(this.state.showCompanies) boardcss += " mactive"
    if(this.state.showUsers) usercss += " mactive"
    return (
      <div className={css} onClick={this.onDropBox.bind(this)}>
        <div className="page navdropp" onClick={this.onBlock.bind(this)}>
          <ul className="moffset" id="navbar">
            <li onClick={this.onLink} className="mdropdown" id="navhome">
              <Link to="/" className={`mMenuItem ${this.props.active === 'home' && 'active'}`}>
                Home
                {this.props.taskListNew !== undefined && this.props.taskListNew > 0 &&
                  <span>{this.props.taskListNew}</span>
                }
              </Link>
            </li>
            <li onClick={this.onLink} className="mdropdown" id="navboards">
              <div className="page">
                <label onClick={this.onCompany.bind(this)} className={`boardpanel mMenuItem ${this.isCompanyPage() && 'active'}`}>
                  COMPANIES {this.state.showCompanies?
                    <UpIcon className="menuexpicon" style={{marginTop: -1}}/>
                    :
                    <DownIcon className="menuexpicon" style={{marginTop: -1}}/>
                  }
                </label>
                <div className={boardcss}>
                  <Link to={RoutesConstants.companies} className={`${this.props.active === 'companies' && 'activeMenu'}`} id="navboard">COMPANIES </Link>
                  <Link to={RoutesConstants.audit} className={`${this.props.active === 'audit' && 'activeMenu'}`} id="navaudit">AUDIT LOGS</Link>
                </div>
              </div>
            </li>
            <li onClick={this.onLink} className="mdropdown">
              <label onClick={this.onUsers.bind(this)} className={`dropbtn mMenuItem ${this.isUserPage() && 'active'}`} id="navusers">
                USERS {this.state.showUsers?
                  <UpIcon className="menuexpicon" style={{marginTop: -1}}/>
                  :
                  <DownIcon className="menuexpicon" style={{marginTop: -1}}/>
                }
              </label>
              <div className={usercss}>
                <Link to={RoutesConstants.appusers} className={`dropTextItem ${this.props.active === 'appusers' && 'activeMenu'}`} id="navuserset">APP USERS</Link>
                <Link to={RoutesConstants.admins} className={`dropTextItem ${this.props.active === 'admins' && 'activeMenu'}`} id="navuserset">ADMIN USERS</Link>
                <Link to={RoutesConstants.settings} className={`dropTextItem ${this.props.active === 'master' && 'activeMenu'}`} id="navuserset">MASTER ADMIN</Link>
              </div>
            </li>
            {this.props.updateWebPage === true &&
              <li className="mdropUpdate">
                <RefreshIcon style={{marginRight: 5}} size={16} color="#f12314"/>Update Available
              </li>
            }
          </ul>
          <div className="centerFlex centerpanel" style={{height: 40}} onClick={this.onDropBox.bind(this)}>
            <label className="centerVFlex"><ExitIcon size={18} style={{marginRight:5}}/> Close</label>
          </div>
        </div>
      </div>
    )
  }

  renderSlideMenu(){
    return (
      <SlidingPane
        isOpen={ this.state.showMyAccount }
        width={this.props.showMobile?'':'375px'}
        onRequestClose={ () => {
            // triggered on "<" on left top click or on outside click
            this.setState({ showMyAccount: false });
        } }>
        <div>
          <Link to={RoutesConstants.myaccount}>
            <div className="boardpanel">
              <div style={{marginRight: 10}} className="fs-exclude" id={"imageId-"+this.state.imageId}>
                {GetImageDom(this.state.image, GetInitials(this.props.firstName + ' ' + this.props.lastName))}
              </div>
              <div className="page dropMenuTitle">
                <label className="text18 colorBlack link" id={"userId-"+this.props.userId}>
                  {(this.props.firstName === "" || this.props.lastName === "")?
                    'loading...'
                    :
                    this.props.firstName + ' ' + this.props.lastName
                  }
                </label>
                <label className="text14bold colorAnsarada link">
                  Profile Settings
                </label>
              </div>
            </div>
          </Link>
          <div className='dropMenuItem' id="navset">
            <Link to={RoutesConstants.settingscustomer} className={`dropTextItem ${this.props.active === 'settingscustomer' && 'activeMenu'}`}>
              <div className="boardpanel" style={{marginTop: 20}}>
                <div style={{marginRight: 10}}>
                  {GetImageDom(this.state.companyImage, GetInitials(this.props.companyName===undefined?"":this.props.companyName))}
                </div>
                <div className="page dropMenuTitle">
                  <label className="text18 colorBlack link">
                    {(this.props.companyName === "")?
                      'loading...'
                      :
                      this.props.companyName
                    }
                  </label>
                  <label className="text14bold colorAnsarada link">
                    Company Settings
                  </label>
                </div>
              </div>
            </Link>
          </div>
          <div className='dropMenuItemH' id="navfeed"><Link to={RoutesConstants.feedback} className={`dropTextItem text14bold colorAnsarada ${this.props.active === 'feedback' && 'activeMenu'}`}>Send Feedback</Link></div>
          <div className='dropMenuItemH' id="navhelp"><a href="#" className="colorAnsarada" target="_blank" onClick={this.onSupport.bind(this)}>Help Centre</a></div>
          <div className='dropSubMenuItemH page colorAnsaradaLight' id="callsupport">
            <div className="dropTextItem text14bold colorAnsaradaDark">Contact Support</div>
            <a href="mailto:support@ansarada.com" target="_blank"><div className="dropTextItem text14bold">support@ansarada.com</div></a>
            <div className="dropTextItem text14bold colorAnsaradaDark">Australasia</div>
            <div className="dropTextItem text14bold colorAnsaradaDark">+61 2 8241 0888</div>
            <div className="dropTextItem text14bold colorAnsaradaDark">Americas</div>
            <div className="dropTextItem text14bold colorAnsaradaDark">+1 312 638 2200</div>
            <div className="dropTextItem text14bold colorAnsaradaDark">Europe</div>
            <div className="dropTextItem text14bold colorAnsaradaDark">+44 20 3058 1060</div>
            <div className="dropTextItem text14bold colorAnsaradaDark">South Africa</div>
            <div className="dropTextItem text14bold colorAnsaradaDark">+27 87550 2398</div>
          </div>
          <div className='dropMenuItemH' id="navlogout"><a  onClick={e=>this.onLogout(e)} className="dropTextItem text14bold colorRed">LOG OUT</a></div>

        </div>
      </SlidingPane>
    )
  }

  renderDesktop(){
    return (
      <div className="bar">
        <div className="navtop">
          <div className="boardpanel">
            <ul className="actoffset" id="navbar">
              <li onClick={this.onLink} className="navddown" id="navhome" style={{ marginRight: 10 }}>
                <img src={logoi} className="navlogo" />
              </li>
              <li onClick={this.onLink} className="navddown" id="navhome">
                <Link to="/" className={`menuItem ${this.props.active === 'home' && 'active'}`}>
                  Home
                  {this.props.taskListNew !== undefined && this.props.taskListNew > 0 &&
                    <span>{this.props.taskListNew}</span>
                  }
                </Link>
              </li>
              <li onClick={this.onLink} className="navddown" id="navboards">
                <Link to={RoutesConstants.companies} className={`dropbtn menuItem ${this.isCompanyPage() && 'active'}`}>Companies <DownIcon className="menuexpicon"/>
                </Link>
                <div className="navddown-content">
                  <Link to={RoutesConstants.companies} className={`${this.props.active === 'companies' && 'activeMenu'}`} id="navboard">Companies </Link>
                  <Link to={RoutesConstants.audit} className={`${this.props.active === 'audit' && 'activeMenu'}`} id="navaudit">Audit Logs</Link>
                </div>
              </li>
              <li onClick={this.onLink} className="navddown">
                <Link to={RoutesConstants.users} className={`dropbtn menuItem ${this.isUserPage() && 'active'}`} id="navusers">Users <DownIcon className="menuexpicon"/></Link>
                <div className="navddown-content">
                  {/*Steve Requested App Users do not get seen in Master Account 18/10/2019
                    <Link to={RoutesConstants.users} className={`${this.props.active === 'users' && 'activeMenu'}`} id="navuser">APP USERS</Link>
                  */}
                  <Link to={RoutesConstants.appusers} className={`dropTextItem ${this.props.active === 'appusers' && 'activeMenu'}`} id="navuserset">App users</Link>
                  <Link to={RoutesConstants.admins} className={`dropTextItem ${this.props.active === 'admins' && 'activeMenu'}`} id="navuserset">Admin users</Link>
                  <Link to={RoutesConstants.settings} className={`dropTextItem ${this.props.active === 'master' && 'activeMenu'}`} id="navuserset">Master admin</Link>
                </div>
              </li>
              {this.serverType()}
              {this.props.updateWebPage === true &&
                <li className="updates toolp">
                  <RefreshIcon className="refreshIcon" size={16} color="#f12314"/>Update Available
                  <span className="toolptext toolp-bottom">
                    {this.state.total === 0?
                      "New version of this web portal is available, please click browser refresh button."
                      :
                      "New version of this web portal is available, please wait for all task to be completed."
                    }
                  </span>
                </li>
              }
            </ul>
          </div>
          <ul className="barright">
            <li onClick={this.onLink}>
              <div className='menuright link boardpanel centerpanel' onClick={this.onSlideMenu.bind(this)}>
                <div style={{marginRight: 10, opacity: 0.9 }}>
                  {GetImageDom(this.state.companyImage, GetInitials(this.props.companyName===undefined?"":this.props.companyName))}
                </div>
                <div className="page" style={{opacity: 0.6}}>
                  <label className="text14bold">My Account</label>
                  <span>{this.props.companyName}</span>
                  <span id={"userId-"+this.props.userId}>{this.props.firstName} {this.props.lastName}</span>
                </div>
              </div>
              {this.renderSlideMenu()}
            </li>
          </ul>
        </div>
        <div className="navBottom">
          <div className="Page-title" id="navtitle">
            <div>{this.getPageName()}</div>
            <label>MASTER</label>
          </div>
          {/*this.state.total === 0?
            <div></div>
            :
            <div className={`navProPanel boardpanel ${this.onColourSelect()}`} onClick={this.onOpenPanel.bind(this)}>
              <div style={{width: 75}}>
                <div className="centerVFlex page uploadRemain" style={{width: 40, marginLeft: 15, marginTop: 5}}>
                  <span>{this.state.total.toString()}</span>
                  <label>Remaining</label>
                </div>
              </div>
              <div className="page maxWidth" style={{marginLeft: 10}} id="navprog">
                <div className="progressTitle" id="navprogtitle">
                  {this.state.currentName}
                </div>
                <div style={{marginRight: 20}}>
                  {this.state.errorTotal !== 0 && this.state.errorTotal === this.state.total?
                    <label className="navErrorText">Item failed to upload, either cancel upload or retry again</label>
                    :
                    <ProgressBar
                      success={this.state.overallPercent}
                      height={2}
                      className="progressBar2"
                      classNameBar="progressBarSuccess"
                    />
                  }
                </div>
                <div className="progressMsg">
                  Please do not close your browser until complete.
                </div>
              </div>
            </div>
          }
          {this.state.showControlPanel &&
            <div
              className="account-info"
              style={{right: 160, top: 135, width: 380, height: 400}}
              ref={(node) => this.progressRef = node}>
              <ProgressPanel
                {...this.props}
                />
            </div>
          */}
        </div>
      </div>
    )
  }

  renderMobile(){
    var css = "arrow-up"
    if(this.state.showDropDownMenu) css = "arrow-down"
    return (
      <div className="boardpanel maxWidth">
        <div className="bar centerVFlex">
          <div className="spacebetween boardpanel">
            <div className="Page-title" id="navtitle" onClick={this.onDropBox.bind(this)}>
              <div className="centerVFlex">{this.getPageName()}<span className={css} style={{marginLeft: 8}}/> {this.serverType()}</div>
            </div>
          </div>
        </div>
        <div className="centerVFlex">
          <div style={{marginRight: 10, opacity: 0.9 }} onClick={this.onSlideMenu.bind(this)}>
            {GetImageDom(this.state.companyImage, GetInitials(this.props.companyName===undefined?"":this.props.companyName))}
          </div>
          {this.renderSlideMenu()}
        </div>
        {
          this.renderDropDown()
        }
      </div>
    )
  }

  render(){
    return (
      <div>
        {this.props.showMobile ? this.renderMobile() : this.renderDesktop()}
        {appConfig.intercom === true && <IntercomUpdate {...this.props} />}
      </div>
    )
  }
}

class NavBar extends React.Component {
    constructor(props) {
      super(props);

      var initial = {
        userType: '',
      }

      if(this.props.customerId !== undefined && this.props.customerIds !== undefined)
        if(this.props.userType !== undefined)
          if(this.props.userType[this.props.customerId] !== undefined)
            initial.userType = this.props.userType[this.props.customerId];

      this.state = {
        userType: initial.userType,
        showMobile: window.innerWidth < 810 ? true: false,
        nonActive: this.props.customerId === undefined || this.props.customerIds === undefined || this.props.customerIds.length === 0
      }

      this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
      window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.updateWindowDimensions)
    }

    componentWillReceiveProps(nextProps){
      if(nextProps.customerId !== undefined && nextProps.customerIds !== undefined)
        if(nextProps.userType !== undefined)
          if(nextProps.userType[nextProps.customerId] !== undefined)
            this.setState({userType: nextProps.userType[nextProps.customerId]});
      this.setState({nonActive: nextProps.customerId === undefined || nextProps.customerIds === undefined || nextProps.customerIds.length === 0});
    }

    updateWindowDimensions() {
      var mob = window.innerWidth < 810 ? true: false
      this.setState({ showMobile: mob });
    }

    /*onMyAccount(){
      this.setState({showAccount: !this.state.showAccount});
    }*/

    isMaster(){
      if(this.state.userType === UserTypeEnum.Master)
        return true;
      return false;
    }

    isActive(){
      if(this.state.userType === UserTypeEnum.Master || this.state.userType === '')
        return false;
      return true;
      //style={{paddingTop: this.isActive()?0:30}}
    }

    selectUser(){
      if(this.state.nonActive)
        return (
          <NonActiveUser
            showMobile={this.state.showMobile}
            {...this.props}
            />
        );
      if(this.isMaster())
        return (
          <MasterUser
            showMobile={this.state.showMobile}
            {...this.props}
            />
        );

      return (
        <ActiveUser
          showMobile={this.state.showMobile}
          canSwitch={this.state.canSwitch}
          showControlPanel={this.state.showControlPanel}
          {...this.props}
          />
      );
    }

    render() {
      return (
        <div className='Nav-bg'>
          {this.state.showMobile && !this.state.nonActive &&
            <div className='logo'>
              <img className="AnsardaSmall" src={logoOnly}/>
            </div>
          }
          {this.selectUser()}
        </div>
      );
    }
}

function mapStateToProps(state) {
  const  uploadQueue = state.uploadQueue;
  const  {
    username,
    firstName,
    lastName,
    companyName,
    customers,
    updateWebPage,
    customerOpt,
    userId,
    personId,
    alias,
    imageId,
    customerId,
    customerIds,
    customerSettings,
    userType,
    suspendedCustomers,
    keys,
    loggingIn,
    showTooltip
  } = state.authentication;

  const {
    customer,
    boards,
    templatesDraft,
    bindersCurrent,
    bindersPrevious,
    bindersUnpublished,
    templates,
    allBoards,
    currentBoard
  } = state.board;

  return {
    Queue: uploadQueue,
    username,
    firstName,
    lastName,
    companyName,
    customers,
    updateWebPage,
    customerOpt,
    taskListNew: state.users.taskListNew,
    taskList: state.users.taskList,
    userId,
    personId,
    alias,
    imageId,
    users: state.users.data,
    customerId,
    customerIds,
    customerSettings,
    userType,
    suspendedCustomers,
    keys,
    loggingIn,
    files: state.file,
    companies: state.company,
    board: boards,
    currentBoard,
    allBoards,
    customer,
    bindersCurrent,
    bindersUnpublished,
    bindersPrevious,
    boardtemplates: templates,
    templatesDraft: templatesDraft,
    binders: state.binder,
    showTooltip,
  };
}

const connectedNavBar = connect(mapStateToProps)(NavBar);
export { connectedNavBar as NavBar };
