import React from 'react';
import { binderActions, boardActions } from '@actions/admin';
import { Button, Loader } from '@common/admin';
import {
  FaTimes as ExitIcon,
  FaChevronLeft as Chevron,
  FaRegCircle as NoSelect,
} from 'react-icons/fa';
import Tick from '@image/icon/checkbox-on.svg';
import DocumentImage from '@common/documentImage';
import { confirmAlert } from 'react-confirm-alert';
import { SelectBoardBinder } from '@common/selectBinder';
import { BinderStatus, RoutesConstants } from '@constants/common.constants';
import { TrackEvent } from '@lib/simpletools';
import '@styles/ReactContexify.css';
import '../binderspage/binder.css';
import { MuiButton } from '@common/MUI';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';


export class CreateNewBinderModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showNewBinder: true,
      showCopyBinder: false,
      showCopySel: false,

      showOtherBoardsTemp: true,

      copyOptions: ['name','setting','attendees','recipients','invitees','location','items'],
      copyId: '',
      loadingAllBindersTemplates: true,
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.showBinder !== this.props.showBinder && this.props.showBinder === true){
      if(this.props.isNav === true){
        this.setState({showNewBinder: false, showCopyBinder: true})
      }
      if(this.props.customerId !== undefined && this.props.customerId !== ""){
        this.props.dispatch(binderActions.CacheBinderTemplatePreview(this.props.customerId))
      }
    }
    if(prevProps.showTemplate !== this.props.showTemplate && this.props.showTemplate === true){
      if(this.props.customerId !== undefined && this.props.customerId !== ""){
        this.props.dispatch(binderActions.CacheBinderTemplatePreview(this.props.customerId))
      }
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.customer !== undefined && nextProps.customer[nextProps.customerId] !== undefined){
      if(nextProps.customer[nextProps.customerId].loadingAllBindersTemplates !== undefined){
        state.loadingAllBindersTemplates = nextProps.customer[nextProps.customerId].loadingAllBindersTemplates
      }
    }
    return state
  }

  onClose(){
    this.setState({showCopySel: false, copyId:'', showNewBinder: true, showCopyBinder: false})
    this.props.onExit()
  }

  onBlank(){
    if(this.props.showTemplate === true){
      //this.props.tracking.trackEvent({ click: 'onNewTemplate', boardId: this.props.boardId })
      var c = this.props.templateCount
      if(c === false) return
      if(c >= this.props.templateLimit){
        confirmAlert({
          customUI: ({ onClose }) =>
            <div className="confirm-alert-ui">
              <h1>Template limit has been reached.</h1>
              <p>Currently reached maximum limit allowed for templates. Please contact sales or support for more information.</p>
              <Button onClick={onClose}>OK</Button>
            </div>,
        })
        this.props.onExit()
        return
      }

      // TrackEvent("f_board_newbinder-template.started",{
      //   board_id: this.props.boardId,
      //   user_id: this.props.myId !== undefined ? this.props.myId : this.props.userId,
      //   person_id: this.props.personId,
      //   company_id: this.props.customerId,
      //   alias: this.props.alias,
      //   template_is_created_from_blank: true,
      //   template_is_created_from_binder: false,
      //   template_is_created_from_template: false,
      //   binder_copy_id: "",
      //   template_copy_id: "",
      // })

      this.props.history.push({
        pathname: RoutesConstants.bindernewwizard,
        query: { board: { boardId: this.props.boardId, boardname: this.props.boardName, isTemplate: true } }
      });
    }else{
      TrackEvent("f_board_new-binder.started",{
        board_id: this.props.boardId,
        user_id: this.props.myId !== undefined ? this.props.myId : this.props.userId,
        person_id: this.props.personId,
        company_id: this.props.customerId,
        alias: this.props.alias,
        binder_is_created_from_blank: true,
        binder_is_created_from_binder: false,
        binder_is_created_from_template: false,
        binder_copy_id: "",
        template_copy_id: "",
      })
      //this.props.tracking.trackEvent({ click: 'onBlank', boardId: this.props.boardId })
      this.props.history.push({
        pathname: RoutesConstants.bindernewwizard,
        query: { board: {boardId: this.props.boardId, boardname: this.props.boardName}}
      });
    }

    this.props.onOpen()
  }

  onCopyTemplate(id){
    if(id === 'otherboard'){
      this.setState({showOtherBoardsTemp: true})
      return
    }
    this.setState({showNewBinder: false})

    if(this.props.showBinder){
      TrackEvent("f_board_new-binder.started",{
        board_id: this.props.boardId,
        user_id: this.props.myId !== undefined ? this.props.myId : this.props.userId,
        person_id: this.props.personId,
        company_id: this.props.customerId,
        alias: this.props.alias,
        binder_is_created_from_blank: false,
        binder_is_created_from_binder: false,
        binder_is_created_from_template: true,
        binder_copy_id: "",
        template_copy_id: id,
      })
    }else{
      // TrackEvent("f_board_newbinder-template.started",{
      //   board_id: this.props.boardId,
      //   user_id: this.props.myId !== undefined ? this.props.myId : this.props.userId,
      //   person_id: this.props.personId,
      //   company_id: this.props.customerId,
      //   alias: this.props.alias,
      //   template_is_created_from_blank: false,
      //   template_is_created_from_binder: false,
      //   template_is_created_from_template: true,
      //   binder_copy_id: "",
      //   template_copy_id: id,
      // })
    }

    this.props.dispatch(binderActions.copyTemplate(
      this.props.boardId,
      this.props.boardName,
      id,
      this.state.copyOptions,
      this.props.showBinder === true
    ));
    this.props.onOpen()
  }

  CopyBinder(id){
    this.setState({showCopyBinder: false, showCopySel: true, copyId: id})
  }

  onCopyBinder(){
    if(this.props.showBinder){
      TrackEvent("f_board_new-binder.started",{
        board_id: this.props.boardId,
        user_id: this.props.myId !== undefined ? this.props.myId : this.props.userId,
        person_id: this.props.personId,
        company_id: this.props.customerId,
        alias: this.props.alias,
        binder_is_created_from_blank: false,
        binder_is_created_from_binder: true,
        binder_is_created_from_template: false,
        binder_copy_id: this.state.copyId,
        template_copy_id: "",
      })
    }else{
      // TrackEvent("f_board_newbinder-template.started",{
      //   board_id: this.props.boardId,
      //   user_id: this.props.myId !== undefined ? this.props.myId : this.props.userId,
      //   person_id: this.props.personId,
      //   company_id: this.props.customerId,
      //   alias: this.props.alias,
      //   template_is_created_from_blank: false,
      //   template_is_created_from_binder: true,
      //   template_is_created_from_template: false,
      //   binder_copy_id: this.state.copyId,
      //   template_copy_id: "",
      // })
    }
    
    this.props.dispatch(binderActions.copyBinder(
      this.state.copyId,
      this.props.boardId,
      this.state.copyOptions,
      this.props.showBinder === true
    ));

    /*this.props.tracking.trackEvent({ click: 'onCopyBinder', boardId: this.props.boardId })
    this.props.dispatch(binderActions.openBinder(
      this.state.copyId,
      this.props.boardId,
      {copyOptions: this.state.copyOptions},
      true
    ));*/
    this.props.onOpen()
  }

  onDisableCopyOpt(type){
    var copyOptions = this.state.copyOptions;
    var i = copyOptions.indexOf(type);
    if(i !== -1)
      copyOptions.splice(i,1);
    this.setState({copyOptions});
  }

  onEnabledCopyOpt(type){
    var copyOptions = this.state.copyOptions;
    var i = copyOptions.indexOf(type);
    if(i === -1)
      copyOptions.push(type);
    this.setState({copyOptions});
  }

  getBinderItem(item){
    if(item.loading) //wait for binder data to be loaded
      return (
        <div key={item.id} className="bNewBinder-temprow noUnder fs-exclude" d={"binderId-"+item.id}>
          {item.name !== ""?item.name+" (loading...)":"Loading..."}
        </div>
      );
    return (
      <div
        key={item.id}
        onClick={this.onCopyTemplate.bind(this,item.id)}
        className="link bNewBinder-temprow noUnder fs-exclude"
        id={"binderId-"+item.id}
        >
        {item.name}
      </div>
    );
  }

  renderCopyTemplates(){
    if(((this.props.customer[this.props.customerId] === undefined || this.props.customer[this.props.customerId].loadingTemplates)
    && this.props.templateCount !== false) || this.state.loadingAllBindersTemplates){
      return (
        <div style={{marginTop: 50}}>
          <Loader text="Loading templates"/>
        </div>
      )
    }

    return (
      <div>
        {this.props.templates.map((item) => {
          return this.getBinderItem(item)
        })}
        <div
          onClick={this.onCopyTemplate.bind(this,'otherboard')}
          className="link bNewBinder-temprow noUnder italic">
          Other Boards Templates
        </div>
      </div>
    )
  }

  renderNewBinder(){
    var type = "binder"
    if(this.props.showTemplate === true) type = "template"
    //showBinder, showTemplate
    return (
      <div>
        <DialogTitle>
          <div className="boardpanel spacebetween binder-modal-align">
            <div className="page">
              <strong className='bold'>Create new {type}</strong>
              {this.props.headerMsg !== undefined && this.props.headerMsg !== "" && !this.props.showTemplate &&
                <label className="colorRed text16s">{this.props.headerMsg}</label>
              }
            </div>
            <IconButton onClick={this.onClose.bind(this)}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <div>
          <div className="boardpanel spacebetween bNewBinder-selection">
            <div onClick={this.onBlank.bind(this)} className="bNewBinder-Button noUnder">
              <DocumentImage
                size={64}
                plus={true}
              />
              <div style={{ marginLeft: 10 }}>Create a blank {type}</div>
            </div>
            {this.props.haveBinder && (
              <button id="btCopyBinder" className="bNewBinder-Button" onClick={() => { this.setState({ showCopyBinder: true, showNewBinder: false }) }}>
                <DocumentImage
                  size={64}
                  double={true}
                />
                {this.props.showTemplate ?
                  <div style={{ marginLeft: 10 }}>Create from binder</div>
                  :
                  <div style={{ marginLeft: 10 }}>Copy existing binder</div>
                }
              </button>
            )}
          </div>
          {this.props.haveTemplate && (
            <div className="bNewBinderModal-copy-grey">
              {this.props.showTemplate ?
                <h3>Or copy existing template</h3>
                :
                <h3>Or create a new binder from a template</h3>
              }
              <div className="bNewBinder-template">
                {this.state.showOtherBoardsTemp && !this.state.loadingAllBindersTemplates ?
                  <SelectBoardBinder
                    defaultBoardId={this.props.boardId}
                    onSelect={this.onCopyTemplate.bind(this)}
                    type={["Templates"]}
                  />
                  :
                  <div style={{ marginTop: 50 }}>
                    <Loader text="Loading templates" />
                  </div>
                }
                {!this.state.showOtherBoardsTemp &&
                  this.renderCopyTemplates()
                }
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  renderExistingBinder(){
    return (
      <div>
        <DialogTitle>
          <div className="boardpanel spacebetween binder-modal-align">
            <div>
              <div>
                {(this.props.isNav === false || this.props.showBinder === false) && (
                  <IconButton
                    onClick={() => {
                      this.setState({ showCopyBinder: false, showNewBinder: true, copyId: '', showCopySel: false })
                    }}
                  >
                    <ArrowBackIosNewIcon />
                  </IconButton>
                )}
                <strong className='bold'>{this.props.showTemplate ? "Create from binder" : "Copy existing binder"}</strong>
              </div>
              {this.props.headerMsg !== undefined && this.props.headerMsg !== "" && !this.props.showTemplate && this.props.isNav === true &&
                <label className="colorRed text16s">{this.props.headerMsg}</label>
              }
            </div>
            <IconButton onClick={this.onClose.bind(this)}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <div className="bNewBinderModal-copy" style={{ height: 589 }}>
          {this.props.boardLoading || this.state.loadingAllBindersTemplates ?
            <div className='centerFlex' style={{ marginTop: 50 }}>
              <Loader small={true} />
            </div>
            :
            <div className="bNewBinder-template">
              <label></label>
              <SelectBoardBinder
                defaultBoardId={this.props.boardId}
                onSelect={this.CopyBinder.bind(this)}
                type={[BinderStatus.Current, BinderStatus.Previous]}
                sort={["Name", "Date"]}
              />
            </div>
          }
        </div>
      </div>
    )
  }

  renderCopyBinder(){
    return (
      <div>
        <DialogTitle>
          <div className="boardpanel spacebetween binder-modal-align">
            <div>
              {!this.props.boardLoading && (
                <IconButton
                  onClick={() => {
                    this.setState({ showCopyBinder: false, showNewBinder: true, copyId: '', showCopySel: false })
                  }}
                >
                  <ArrowBackIosNewIcon />
                </IconButton>
              )}
              <strong className='bold'>{this.props.showTemplate ? "Create from binder" : "Copy existing binder"}</strong>
            </div>
            {!this.props.boardLoading && (
              <IconButton onClick={this.onClose.bind(this)}>
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </DialogTitle>
        <div className="bNewBinderModal-copy">
          <div>
            <div className="bNewBinder-template" style={{ marginTop: 20 }}>
              <div className="boardpanel maxWidth">
                <div className="boardpanel centerpanel" style={{ width: '50%' }}>
                  {this.state.copyOptions.includes("name") ?
                    <img className="copyenable" onClick={this.onDisableCopyOpt.bind(this, 'name')} src={Tick} />
                    :
                    <NoSelect className="copydisable" size={28} onClick={this.onEnabledCopyOpt.bind(this, 'name')} color='#d4d4d4' />
                  }
                  <div>Copy Binder Name</div>
                </div>
                <div className="boardpanel centerpanel" style={{ width: '50%' }}>
                  {this.state.copyOptions.includes("location") ?
                    <img className="copyenable" onClick={this.onDisableCopyOpt.bind(this, 'location')} src={Tick} />
                    :
                    <NoSelect className="copydisable" size={28} onClick={this.onEnabledCopyOpt.bind(this, 'location')} color='#d4d4d4' />
                  }
                  <div>Copy Meeting location</div>
                </div>
              </div>
              <div className="boardpanel maxWidth">
                <div className="boardpanel centerpanel" style={{ width: '50%' }}>
                  {this.state.copyOptions.includes("setting") ?
                    <img className="copyenable" onClick={this.onDisableCopyOpt.bind(this, 'setting')} src={Tick} />
                    :
                    <NoSelect className="copydisable" size={28} onClick={this.onEnabledCopyOpt.bind(this, 'setting')} color='#d4d4d4' />
                  }
                  <div>Copy Binder Settings</div>
                </div>
                <div className="boardpanel centerpanel" style={{ width: '50%' }}>
                  {this.state.copyOptions.includes("attendees") ?
                    <img className="copyenable" onClick={this.onDisableCopyOpt.bind(this, 'attendees')} src={Tick} />
                    :
                    <NoSelect className="copydisable" size={28} onClick={this.onEnabledCopyOpt.bind(this, 'attendees')} color='#d4d4d4' />
                  }
                  <div>Copy Attendees</div>
                </div>
              </div>
              <div className="boardpanel maxWidth">
                <div className="boardpanel centerpanel" style={{ width: '50%' }}>
                  {this.state.copyOptions.includes("recipients") ?
                    <img className="copyenable" onClick={this.onDisableCopyOpt.bind(this, 'recipients')} src={Tick} />
                    :
                    <NoSelect className="copydisable" size={28} onClick={this.onEnabledCopyOpt.bind(this, 'recipients')} color='#d4d4d4' />
                  }
                  <div>Copy Recipients</div>
                </div>
                <div className="boardpanel centerpanel" style={{ width: '50%' }}>
                  {this.state.copyOptions.includes("invitees") ?
                    <img className="copyenable" onClick={this.onDisableCopyOpt.bind(this, 'invitees')} src={Tick} />
                    :
                    <NoSelect className="copydisable" size={28} onClick={this.onEnabledCopyOpt.bind(this, 'invitees')} color='#d4d4d4' />
                  }
                  <div>Copy Invitees</div>
                </div>
              </div>
              <div className="boardpanel centerpanel">
                {this.state.copyOptions.includes("items") ?
                  <img className="copyenable" onClick={this.onDisableCopyOpt.bind(this, 'items')} src={Tick} />
                  :
                  <NoSelect className="copydisable" size={28} onClick={this.onEnabledCopyOpt.bind(this, 'items')} color='#d4d4d4' />
                }
                <div>Copy Items</div>
              </div>
            </div>
          </div>
        </div>
        <DialogActions>
          <MuiButton variant='contained' disabled={this.props.boardLoading} onClick={this.onCopyBinder.bind(this)}>
            Copy
          </MuiButton>
        </DialogActions>
      </div>
    )
  }

  render(){
    return (
      <Dialog open={this.props.showBinder === true || this.props.showTemplate === true}
        onClose={this.onClose.bind(this)} fullWidth maxWidth={!this.props.haveTemplate && this.state.showNewBinder ? "sm" : "lg"}>
        {this.state.showNewBinder && this.renderNewBinder()}
        {this.state.showCopyBinder && this.renderExistingBinder()}
        {this.state.showCopySel && this.renderCopyBinder()}
      </Dialog>
  )}
}
