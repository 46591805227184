import { alertActions as baseAction } from '../alert.actions';
import { alertConstants } from '@constants/admin';
import { userActions } from '@actions/admin';
import { alertService } from '@services/admin';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

const actions = {

  notificationSuccess,
  notificationError,
  notificationClear,

  notificationClearMultiple,

  sendPushNotifications,

  clearAll,
};

export const alertActions = Object.assign(baseAction, actions)

function notificationSuccess(message) {
  return { type: alertConstants.SUCCESS_NOTIFICATION, message };
}

function notificationError(message) {
  return { type: alertConstants.ERROR_NOTIFICATION, message };
}

function notificationClear(index) {
  return { type: alertConstants.CLEAR_NOTIFICATION, index };
}

function notificationClearMultiple(ids) {
  return { type: alertConstants.CLEAR_MULTIPLE_NOTIFICATION, ids };
}

function clearAll() {
  return { type: alertConstants.CLEARALL };
}

function clearPercentage() {
  return { type: alertConstants.LOADING_INDICATOR_SUCCESS };
}

function sendPushNotifications(items){
  return dispatch => {
    //dispatch(request());
    alertService.sendPushNotifications(items)
      .then(
        item => dispatch(success(items)),
        error => {
          //dispatch(failure(binderId, error))
        }
      );
  };

  function request() { return { type: alertConstants.PUSH_NOTIFICATION_REQUEST } }
  function success(items) { return { type: alertConstants.PUSH_NOTIFICATION_SUCCESS, items } }
  function failure(error) { return { type: alertConstants.PUSH_NOTIFICATION_FAILURE, error } }
}
