import React from 'react';
import { authHeader, GetURL, GetFrontendHelperURL, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch } from '@lib';
import { v4 as uuidv4 } from 'uuid';
import * as CrytpoLib from '@lib/cryptojs';
import fetchRetry from '@lib/fetch-retry';

export const queueService = {
    convertToPdf,
    checkConvertRequest,
};

function checkConvertRequest(requestId, abortController = new AbortController()) {
    return new Promise(function (resolve, reject) {
        if (!requestId) { reject(); }
        const requestOptions = {
            method: 'GET',
            headers: authHeader({ 'Content-Type': 'application/json' }, true),
            retries: 6,
            signal: abortController.signal,
        };

        fetchRetry(GetFrontendHelperURL() + 'Requests/' + requestId, requestOptions, true)
            .then((response) => {
                if(!response.ok) {
                    reject();
                    return;
                }
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

function convertToPdf(data, customerId, uploadToBoard = true, docId, requestId, abortController = new AbortController()) {
    return new Promise(function (resolve, reject) {
        const requestOptions = {
            method: 'GET',
            headers: authHeader({ 'Content-Type': 'application/json' }, true),
            signal: abortController.signal
        };

        fetch(GetFrontendHelperURL() + 'OneShotKey', requestOptions)
            .then(handleJsonResponse)
            .then((res) => resolve(res))
            .catch(error => {
                reject(error)
            })
    })
    .then((response) => {
        return new Promise(async (resolve, reject) => {
            let item = {
                uploadToBoard: uploadToBoard,
                returnResult: true,
                action: 1,
                oneShotKeyId: response.id,
            }

            if (item.uploadToBoard) {
                item.boardUploadDocumentId = docId || uuidv4();
                item.boardUploadCustomerId = customerId;
            }

            const blobToData = (blob) => {
                return new Promise((resolve) => {
                    const reader = new FileReader()
                    reader.onloadend = () => resolve(reader.result)
                    reader.readAsArrayBuffer(blob)
                })
            }

            let aeskey1 = CrytpoLib.GenerateRandom(32);
            let aeskey2 = CrytpoLib.GenerateRandom(32);
            try{
                const resData = await blobToData(data);
                var Uint8View = new Uint8Array(resData);
                const encryptedData = await CrytpoLib.AESEncrypt(aeskey1, Uint8View)
                //encrypt data with kData
                item.data = CrytpoLib.arrayBufferToBase64String(encryptedData)

                let publicKey = await CrytpoLib.importPublicKey(response.publicKey, CrytpoLib.defaultRSAAlgorithmMethod)
                const kData = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, publicKey, aeskey1)
                const kResult = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, publicKey, aeskey2)
                item.kData = CrytpoLib.arrayBufferToBase64String(kData)
                item.kResult = CrytpoLib.arrayBufferToBase64String(kResult)
            }catch(e){
                reject(e)
            }
            
            var reqId = requestId || uuidv4();
            const requestOptions = {
                method: 'POST',
                headers: authHeader({ 'Content-Type': 'application/json', 'RequestId': reqId }, true),
                body: JSON.stringify(item),
                signal: abortController.signal
            };
    
            fetch(GetFrontendHelperURL() + 'Document', requestOptions)
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 404) {
                            var url = response.url.replace(GetURL(), "");
                            return Promise.reject(response.statusText + ' URL:' + url);
                        }
                        if (response.status === 401) {
                            LogoutAndRedirect();
                            return;
                        }
                        if (response.status === 502 || response.status === 504) {
                            return Promise.reject(response);
                        }

                    } else if (response.status === 204) {
                        return {};
                    } else return response.json();
                })
                .then((response) => {
                    let res = {
                        documentId: item.boardUploadDocumentId,
                        data: response.data,
                        size: response.boardUploadSize || 0,
                        key: item.kResult,
                        docAES: aeskey2
                    };
                    resolve(res)
                })
                .catch((error) => {
                    checkConvertRequest(reqId, abortController)
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        let res = {
                            documentId: item.boardUploadDocumentId,
                            data: response.data,
                            size: response.boardUploadSize || 0,
                            key: item.kResult,
                            docAES: aeskey2
                        };
                        resolve(res);
                    })
                    .catch(() => {
                        reject();
                    });
                })
        })
    })
    .catch(handleCatch)
}