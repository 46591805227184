
function getARandomWordGreaterThan(greaterThan, lessThan) {
		var word = "";
		var ok = false;
    var crypto = window.crypto || window.msCrypto;
    var array = new Uint32Array(1);
    var l = wordlist.length;
		while (!ok) {
      crypto.getRandomValues(array);
			let pick = wordlist[array[0] % l];
			if (pick.length > greaterThan && pick.length < lessThan) {
				word = pick;
				ok = true;
			}
		}
		return word;
	}

export function GeneratePassword(){
  var password = "";

  let word1 = getARandomWordGreaterThan(3, 6);
  let word2 = getARandomWordGreaterThan(5, 7);
  let word3 = getARandomWordGreaterThan(3, 6);
  password = word1 + "-" + word2 + "-" + word3;

  return password;
}

export function GenerateTempUsername(){
  var password = "";

  let word1 = getARandomWordGreaterThan(3, 5);
  let word2 = getARandomWordGreaterThan(3, 5);
  password = word1 + word2;

  return password;
}
