export const boardConstants = {
  PREVIEW_BOARD_REQUEST: 'PREVIEW_BOARD_REQUEST',
  PREVIEW_BOARD_SUCCESS: 'PREVIEW_BOARD_SUCCESS',
  PREVIEW_BOARD_FAILURE: 'PREVIEW_BOARD_FAILURE',

  POPULATE_BOARD_REQUEST: 'POPULATE_BOARD_REQUEST',
  POPULATE_BOARD_SUCCESS: 'POPULATE_BOARD_SUCCESS',
  POPULATE_BOARD_FAILURE: 'POPULATE_BOARD_FAILURE',

  PREVIEW_BOARDBINDER_REQUEST: 'PREVIEW_BOARDBINDER_REQUEST',
  PREVIEW_BOARDBINDER_SUCCESS: 'PREVIEW_BOARDBINDER_SUCCESS',
  PREVIEW_BOARDBINDER_FAILURE: 'PREVIEW_BOARDBINDER_FAILURE',

  POPULATE_BOARDFILES_REQUEST: 'POPULATE_BOARDFILES_REQUEST',
  POPULATE_BOARDFILES_SUCCESS: 'POPULATE_BOARDFILES_SUCCESS',
  POPULATE_BOARDFILES_FAILURE: 'POPULATE_BOARDFILES_FAILURE',

  GET_CALENDAR_REQUEST: 'GET_CALENDAR_REQUEST',
  GET_CALENDAR_SUCCESS: 'GET_CALENDAR_SUCCESS',
  GET_CALENDAR_FAILURE: 'GET_CALENDAR_FAILURE',
}
