import React from 'react';

import IntlTelInputApp from './phone/components/IntlTelInputApp'
//import IntlTelInput from 'react-intl-tel-input';
import './phone/styles/main.css';

//import { PhoneInput as PhoneInputItem } from '@ansarada/ace-react';
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js'

import '@styles/phone.css';

export default class PhoneInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      country: '+61',
    };
  }

  onFlagMobile(val){
    this.setState({country: val});
  }

  onChangeMobile(valid, number, country){
    var nospace = number.replace(/ /g,'');
    if(nospace.indexOf('+') === -1){
      if(country !== undefined && country.dialCode !== undefined && country.dialCode !== "")
        nospace = "+" + country.dialCode + nospace;
      else
        nospace = this.state.country + nospace;
    }
    if(nospace.indexOf('+6104') === 0){
      nospace = nospace.replace("+6104", '+614');
    }else if(nospace.indexOf('+6402') === 0){
      nospace = nospace.replace("+6402", '+642');
    }else if(nospace.indexOf('+4407') === 0){
      nospace = nospace.replace("+4407", '+447');
    }
    var num = new AsYouType('International').input(nospace);

    if(num === this.props.value) return
    if(this.props.hasOwnProperty('onChange')){
      this.props.onChange(num);
    }
  }

  validMobile(valid, number){
    if(number === "") return;
    var valid = false;
    var phoneNumber = parsePhoneNumberFromString(number);
    if(phoneNumber){
      if(phoneNumber.isValid() === true)
        valid = true;
    }
    if(this.props.hasOwnProperty('onBlur'))
      this.props.onBlur(valid, number);
  }

  render() {
    var css = ['phone-control'];
    if(this.props.css !== undefined)
      css = this.props.css;
    return (
      <IntlTelInputApp
        style={{width: '100%', background: 'white'}}
        preferredCountries={['au','nz','us','gb']}
        css={ ['intl-tel-input','fs-exclude', ...css] }
        separateDialCode={true}
        nationalMode={false}
        defaultCountry={this.props.defaultCountry}
        value={this.props.value}
        onPhoneNumberChange={this.onChangeMobile.bind(this)}
        onPhoneNumberBlur={this.validMobile.bind(this)}
        onSelectFlag={this.onFlagMobile.bind(this)}
        placeholder={this.props.placeholder}
        size={this.props.size}
        error={this.props.error}
      />
    );
    /*
    return (
      <PhoneInputItem
        style={{width: '100%'}}
        //css={ ['intl-tel-input', ...css] }
        //separateDialCode={true}
        //nationalMode={false}

        value={this.props.value}
        onPhoneNumberChange={this.onChangeMobile.bind(this)}
        onPhoneNumberBlur={this.validMobile.bind(this)}
        onSelectFlag={this.onFlagMobile.bind(this)}
      />
    );*/
  }
}
