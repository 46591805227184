import React from "react";
import moment from "moment";
import { TimePicker, DatePicker, DateTimePicker, CalendarPicker } from "@mui/lab";
import { CustomTimePicker } from "./CustomTimePicker.js";
import { Popover, TextField } from "@mui/material";

export class CustomDateTimePicker extends React.Component {
    constructor(props) {
        super(props);

        this.customMeetingDateTimePickerRef = React.createRef();
    }

    render() {
        return (
            <React.Fragment>
                <DateTimePicker
                    label={this.props.label}
                    id='new-binder-wizard-binder-meeting-date'
                    value={this.props.meetingDate}
                    renderInput={(params) => <TextField {...params} fullWidth={true} error={this.props.error} />}
                    // minDate={this.props.minDate || null}
                    onChange={this.props.onMeetingDateChange}
                    onOpen={() => { this.props.onOpen() }}
                    inputFormat="DD/MM/YYYY hh:mm a"
                    open={false}
                    readOnly={this.props.readonly}
                />
                <span
                    ref={this.customMeetingDateTimePickerRef}
                    style={{ height: 0, width: 0, overflow: 'hidden', display: 'inline-block' }}
                >
                    <Popover
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                        }}
                        PaperProps={{
                            sx: {
                                '.Mui-selected': {
                                    background: 'var(--ansarada-green) !important'
                                }
                            }
                        }}
                        anchorEl={this.customMeetingDateTimePickerRef && this.customMeetingDateTimePickerRef.current}
                        open={this.props.meetingDatePickerOpen}
                        onClose={() => { this.props.onClose() }}
                    >
                        <CalendarPicker
                            readOnly={this.props.readonly}
                            date={this.props.tempMeetingDate}
                            onChange={this.props.onTempMeetingDateChange}
                            minDate={this.props.minDate || null}
                        />
                        <div style={{ padding: '5px 25px' }}>
                            <CustomTimePicker
                                onChange={(newTime, save) => {
                                    if (save) {
                                        this.props.onClose()
                                    }
                                    this.props.onCustomMeetingTimeChange(newTime, save);
                                }}
                                onClose={() => { this.props.onClose() }}
                                value={this.props.tempMeetingTime}
                            />
                        </div>
                    </Popover>
                </span>
            </React.Fragment>
        )
    }
}