import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import { MuiButton, DemoPopulateCountDown } from '@common/MUI';
import { popoverConstants } from "../../constants/admin";
import { v4 as uuidv4 } from 'uuid';

export const popoverAction = {
    add,
    remove,
    clearAll,
    showError,
    showDialog,
    showDemoDataPopulateDialog,
}

function showError(errorDetails) {
    return (dispatch, getState) => {
        var popoverId = uuidv4();
        var popover = {
            id: popoverId,
            el:
                <Dialog key={popoverId} open={true} maxWidth='sm' fullWidth={true}>
                    <DialogTitle sx={{ color: 'red', fontWeight: '700' }}>{errorDetails.title}</DialogTitle>
                    <DialogContent>{errorDetails.body}</DialogContent>
                    <DialogActions>
                        <MuiButton variant='contained' onClick={() => {
                            dispatch(remove(popoverId));
                            if (errorDetails.onClose) {
                                errorDetails.onClose()
                            }
                        }}>Close</MuiButton>
                    </DialogActions>
                </Dialog>
        }

        dispatch({ type: popoverConstants.ADD_POPOVER, payload: popover });
    }
}
// dialogDetails = {
// dialogId: string (unique),
// width: string 
// title: JSX 
// content : JSX 
// dialogActions : JSX 
// }
function showDialog(dialogDetails) {
    return (dispatch, getState) => {
        var popover = {
            id: dialogDetails.dialogId,
            el:
                <Dialog key={dialogDetails.dialogId} open={true} maxWidth={dialogDetails.width || 'sm'} fullWidth={true}>
                    <DialogTitle>{dialogDetails.title}</DialogTitle>
                    <DialogContent>{dialogDetails.content}</DialogContent>
                    <DialogActions>{dialogDetails.dialogActions}</DialogActions>
                </Dialog>
        }

        dispatch({ type: popoverConstants.ADD_POPOVER, payload: popover });
    }
}

function add(popover) {
    return (dispatch, getState) => {
        dispatch({ type: popoverConstants.ADD_POPOVER, payload: popover });
    }
}

function remove(id) {
    return (dispatch, getState) => {
        dispatch({ type: popoverConstants.REMOVE_POPOVER, payload: id});
    }
}

function clearAll() {
    return (dispatch, getState) =>{ 
        dispatch({ type: popoverConstants.CLEAR_ALL, payload: null })
    }
}

function showDemoDataPopulateDialog(seconds = 360, reset = false) {
    return dispatch => {
        dispatch(showDialog({
            dialogId: 'sample_demo_is_loading_popover',
            title: `Demonstration data is ${reset ? 'resetting' : 'populating'}`,
            content: <DemoPopulateCountDown seconds={seconds} isReset={reset}/>,
            dialogActions: <div></div>
        }));
    }
}