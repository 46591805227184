export const statusConstants = {
  GET_STATUS_REQUEST: 'GET_STATUS_REQUEST',
  GET_STATUS_SUCCESS: 'GET_STATUS_SUCCESS',
  GET_STATUS_FAILURE: 'GET_STATUS_FAILURE',

  SET_STATUS_REQUEST: 'SET_STATUS_REQUEST',
  SET_STATUS_SUCCESS: 'SET_STATUS_SUCCESS',
  SET_STATUS_FAILURE: 'SET_STATUS_FAILURE',

  SET_BOOKMARK_REQUEST: 'SET_BOOKMARK_REQUEST',
  SET_BOOKMARK_SUCCESS: 'SET_BOOKMARK_SUCCESS',
  SET_BOOKMARK_FAILURE: 'SET_BOOKMARK_FAILURE',

  SET_CURRENTPAGE_REQUEST: 'SET_CURRENTPAGE_REQUEST',
  SET_CURRENTPAGE_SUCCESS: 'SET_CURRENTPAGE_SUCCESS',
  SET_CURRENTPAGE_FAILURE: 'SET_CURRENTPAGE_FAILURE',

  UPLOAD_STATUS_SUCCESS: 'UPLOAD_STATUS_SUCCESS',

  SET_STATE_SUCCESS: 'SET_STATE_SUCCESS',
}
