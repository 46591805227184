import { companyConstants, userConstants, customerConstants } from '@constants/admin';
import { companyService, customerService, userService, fileService } from '@services/admin';
import { userActions, alertActions } from '@actions/admin';
import { history, CodeError } from '@lib';
import { UserTypeEnum, RoutesConstants } from '@constants/common.constants';
import moment from 'moment';
import {
  ADMINTIMEOUT,
} from '@lib/limits';

export const companyActions = {
  getCompany,
  getCompanyDetails,
  getCompanyList,
  getCompanyUserLimits,
  getCompanyLimits,
  getCompanyProperties,
  checkNameAvailable,
  clearNameCheck,
  populateCompany,
  newCompany,
  updateCompany,
  deleteCompany,
  clearDeleteCompanyRequest,

  disableCompany,
  enableCompany,

  newUser,
  updateUser,
  deleteUser,
  deletePerson,

  postUsage,
  postUsageArray,

  getCustomerAuditLogs,
};

function getCompany(customerId){
  return dispatch => {
    dispatch(request());
    companyService.getCompany(customerId)
      .then(
        payload => {
          dispatch(success(payload));
        },
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: companyConstants.GET_COMPANY_REQUEST,  } }
  function success(payload) { return { type: companyConstants.GET_COMPANY_SUCCESS, payload } }
  function failure(error) { return { type: companyConstants.GET_COMPANY_FAILURE, error } }
}

function getCompanyDetails(items){
  return dispatch => {
    dispatch(request(items.id));
    companyService.getCompanyDetails(items)
      .then(
        payload => {
          dispatch(success(payload));
        },
        error => dispatch(failure(items.id, error))
      );
  };

  function request(customerId) { return { type: companyConstants.GET_COMPANYDETAILS_REQUEST, customerId } }
  function success(payload) { return { type: companyConstants.GET_COMPANYDETAILS_SUCCESS, payload } }
  function failure(customerId, error) { return { type: companyConstants.GET_COMPANYDETAILS_FAILURE, customerId, error } }
}

function populateCompany(customerId){
  return dispatch => {
    dispatch(request());
    companyService.populateCompany(customerId)
      .then(
        payload => {
          dispatch(success(payload));
        },
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: companyConstants.POPULATE_COMPANY_REQUEST,  } }
  function success(payload) { return { type: companyConstants.POPULATE_COMPANY_SUCCESS, payload } }
  function failure(error) { return { type: companyConstants.POPULATE_COMPANY_FAILURE, error } }
}

function getCompanyProperties(customerId){
  return dispatch => {
    dispatch(request());
    companyService.getCompanyProperties(customerId)
      .then(
        payload => {
          dispatch(success(payload));
        },
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: companyConstants.GET_COMPANYPROPERTIES_REQUEST,  } }
  function success(payload) { return { type: companyConstants.GET_COMPANYPROPERTIES_SUCCESS, payload } }
  function failure(error) { return { type: companyConstants.GET_COMPANYPROPERTIES_FAILURE, error } }
}

function getCompanyList(){
  return dispatch => {
    dispatch(request());

    function RetriveItem(){
      return new Promise((resolve, reject) => {
        var retries = 3;
        var retryDelay = 10000;
        var opt = {'Content-Type': 'application/json'}

        var wrappedFetch = function(n) {
          companyService.getCompanyList()
            .then(
              list => {
                resolve(list)
              },
              error => {
                if(error.code !== undefined && error.code === "F206"){
                  opt['AsyncToken'] = error.asyncToken
/*                  error.response.json().then((data) => {
                    count[index+1] = data.length
                    dispatch(loading_prog(c(count)))
                  })*/
                  retry(retries)
                  return
                }
                reject(error)
              }
            );
        }

        function retry(n) {
          setTimeout(function() {
              wrappedFetch(--n);
            }, retryDelay);
        }

        wrappedFetch(retries);
      })
    }

    RetriveItem()
    .then((list) => {
      dispatch(success(list));
    })
    .catch((error) => {
      dispatch(failure(error))
    })
  };

  function request() { return { type: companyConstants.GET_ALL_COMPANY_REQUEST,  } }
  function success(list) { return { type: companyConstants.GET_ALL_COMPANY_SUCCESS, list } }
  function failure(error) { return { type: companyConstants.GET_ALL_COMPANY_FAILURE, error } }
}

function getCompanyUserLimits(){
  return dispatch => {
    dispatch(request());
    companyService.getCompanyUserLimits()
      .then(
        list => {
          dispatch(success(list));
        },
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: companyConstants.GET_COMPANY_LIMITS_REQUEST,  } }
  function success(list) { return { type: companyConstants.GET_COMPANY_LIMITS_SUCCESS, list } }
  function failure(error) { return { type: companyConstants.GET_COMPANY_LIMITS_FAILURE, error } }
}

function getCompanyLimits(customerId){
  return dispatch => {
    dispatch(request());
    companyService.getCompanyLimits(customerId)
      .then(
        list => {
          dispatch(success([list]));
        },
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: companyConstants.GET_COMPANY_LIMITS_REQUEST,  } }
  function success(list) { return { type: companyConstants.GET_COMPANY_LIMITS_SUCCESS, list } }
  function failure(error) { return { type: companyConstants.GET_COMPANY_LIMITS_FAILURE, error } }
}

function checkNameAvailable(name){
  return dispatch => {
    dispatch(request(name));
    companyService.checkNameAvailable(name)
      .then(
        result => {
          dispatch(success(result));
        },
        error => dispatch(failure(error))
      );
  };

  function request(name) { return { type: companyConstants.CHECK_COMPANY_NAME_REQUEST, name } }
  function success(result) { return { type: companyConstants.CHECK_COMPANY_NAME_SUCCESS, result } }
  function failure(error) { return { type: companyConstants.CHECK_COMPANY_NAME_FAILURE, error } }
}

function clearNameCheck(){
  return dispatch => {
    dispatch(success());
  }

  function success() { return { type: companyConstants.CHECK_COMPANY_NAME_CLEAR } }
}

function newCompany(payload){
  devlog("newCompany", payload)
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    let shareKeysWithAthenaAdmin = null
    const company = getState().company[customerId]
    if(company !== undefined && company.customerProperties !== null && company.customerProperties !== true && company.customerProperties !== false){
      shareKeysWithAthenaAdmin = false
      if(company.customerProperties.shareKeysWithAthenaAdmin !== undefined)
        shareKeysWithAthenaAdmin = company.customerProperties.shareKeysWithAthenaAdmin
    }

    dispatch(request());

    return new Promise(function(resolve, reject) {
      userService.hasDevice(payload.userItem.username)
      .then(
        DeviceInfo => {
          if(!DeviceInfo.hasDevice){
            return reject("no device", 0);
          }
          //We need to confirm the password is correct
          userService.login(payload.userItem)
            .then(
              logindetails => {
                dispatch(successLogin(logindetails));
                dispatch(userActions.connectWebSocket());
                dispatch(update(1));
                resolve();
              },
              error => {
                // //No has device, which should always work, so logout and refresh the page
                if(payload.userItem.failed !== undefined && payload.userItem.failed > 0){
                   dispatch(userActions.logout(payload.userItem.deviceId));
                }
                reject({error, code: 1});
              }
            );
        },
        error => {
          reject({error, code: 0})
        }
      );
    })
    .then(function() {
      return new Promise(function(resolve, reject) {
        if(shareKeysWithAthenaAdmin !== null){
          resolve({
            shareKeysWithAthenaAdmin
          })
        }

        companyService.getCompanyProperties(customerId)
          .then(
            payload => {
              dispatch(successProperties(payload));
              let shareKeysWithAthenaAdmin = false
              if(payload.shareKeysWithAthenaAdmin !== undefined)
                shareKeysWithAthenaAdmin = payload.shareKeysWithAthenaAdmin
              resolve({
                shareKeysWithAthenaAdmin
              })
            },
            error => {
              reject({error, code: 0})
            }
          );
      })
    })
    .then(function(data) {
      return new Promise(function(resolve, reject) {
        //Create the new company
        companyService.newCompany(payload)
        .then(
          newCustomerId => {
            payload.userItem.customerId = newCustomerId;
            payload.userItem.newCompany = true;

            dispatch(update(2));
            var newUser = Object.assign({}, payload.userItem);
            newUser.username = newUser.temporaryRegistrationName
            //create the user
            userService.newUser(newUser)
            .then(
              userData => {
                //patch the company with new user ID
                companyService.patchCompany({
                  id: newCustomerId,
                  MainUserId: userData.userId
                })
                .then(
                  p => {
                    dispatch(update(3));
                    data.customerId = newCustomerId
                    data.userId = userData.userId
                    data.registrationCode = userData.registrationCode
                    resolve(data);
                  },
                  (error, code) => {
                    reject({error, code})
                  }
                );
              },
              (error, code) => {
                reject({error, code})
              }
            );
          },
          error => {
            reject({error, code: 0})
          }
        );
      })
    })
    .then(function(data) {
      devlog("newCompany step3", data)
      return new Promise(function(resolve, reject) {
        userService.getMyRecoveryCards()
        .then(
          serialKeys => {
            payload.userItem.serialKeys = serialKeys

            var newUser = Object.assign({}, payload.userItem);
            newUser.username = payload.userItem.temporaryRegistrationName
            newUser.newpassword = payload.userItem.password
            newUser.password = payload.userItem.temporaryPassword
            if(data.registrationCode !== undefined && data.registrationCode !== "")
              newUser.password = data.registrationCode

            newUser.shareKeysWithAthenaAdmin = data.shareKeysWithAthenaAdmin

            newUser.unregisteredUserType = UserTypeEnum.Master
            //Now register the New user with same password
            setTimeout(() => {
              userService.registerNewUser(newUser, true)
              .then(
                done => {
                  dispatch(update(4));
                  resolve(data);
                },
                error => {
                  reject({error, code: 0})
                }
              );
            }, 2000)
          },
          error => {
            reject({error, code:0})
          }
        );

      })
    })
    .then(function(data) {
      return new Promise(function(resolve, reject) {
        //We need to login again
        userService.login(payload.userItem)
          .then(
            logindetails => {
              dispatch(update(5));
              dispatch(successLogin(logindetails));
              dispatch(userActions.connectWebSocket());
              resolve(data);
            },
            error => {
              reject({error, code: 0});
            }
          );
      })
    })
    .then(function(data) {
      return new Promise(function(resolve, reject) {
        var updateitem = {
        }
        if(payload.defaultSettings !== null)
          updateitem.settings = payload.defaultSettings
        if(payload.defaultCountryBlackList !== null)
          updateitem.defaultCountryBlackList = payload.defaultCountryBlackList
        if(payload.defaultEndOfBoardYear !== null)
          updateitem.defaultEndOfBoardYear = payload.defaultEndOfBoardYear
        if(payload.defaultEndOfFinancialYear !== null)
          updateitem.defaultEndOfFinancialYear = payload.defaultEndOfFinancialYear
        if(payload.defaultNumberOfDaysPrevious !== null)
          updateitem.defaultNumberOfDaysPrevious = payload.defaultNumberOfDaysPrevious
        if(payload.selectedPreviousMoveMechanism !== null)
          updateitem.selectedPreviousMoveMechanism = payload.selectedPreviousMoveMechanism
        if(payload.secondsForAdminTimeout !== null)
          updateitem.secondsForAdminTimeout = payload.secondsForAdminTimeout
        else updateitem.secondsForAdminTimeout = ADMINTIMEOUT

        if(Object.keys(updateitem).length === 0) return resolve(data)

        updateitem.customerId = data.customerId
        updateitem.id = data.customerId
        if(payload.notification !== null){
          updateitem.notification = payload.notification
          updateitem.notification.id = ""
        }else updateitem.notification = null

        customerService.setCustomerSettings(updateitem)
          .then(
              payload => {
                resolve(data);
              },
              error => {
                reject({error, code: 0});
              }
          );
      })
    })
    .then(function(data) {
      devlog("invite procees",data)
      //Invite any master admins to new company
      //first get list of master admins
      var cId = getState().authentication.customerId
      var keys = getState().authentication.keys
      var currentuserId = getState().authentication.userId
      var users = getState().users

      var updateUser = []
      if(users !== undefined && users.customer !== undefined && users.customer[cId] !== undefined && users.customer[cId].listofUser !== undefined)
        updateUser = users.customer[cId].listofUser

      var promiseInvites = []
      updateUser.forEach((userId)=>{
        if(userId === currentuserId) return
        promiseInvites.push(
          new Promise(function(resolveInvite, rejectInvite) {
            var personData = null
            if(users.data[userId] !== undefined)
              personData = users.data[userId]

            if(personData === null){
              return rejectInvite("Data not downloaded correctly")
            }

            if(!personData.hasRegistered || personData.key === null)
              return resolveInvite()

            var userItem = {
              firstName: personData.firstName,
              lastName: personData.lastName,
              mobile: personData.mobile,
              email: personData.email,
              username: personData.username,

              customerId: cId,
              customerIds: [data.customerId],
              type: personData.type,
              function: personData.function,
              userId: "",
              passwordPolicy: payload.passwordPolicy,
              passwordPolicyRegex: payload.passwordPolicyRegex,
              requiresRecoveryCard: payload.requiresRecoveryCard,
              companyName: payload.name,
              userpemkey: personData.key,

              userType: UserTypeEnum.Master,

              keys: keys,
            }

            if(personData.imageId !== ""){
              userItem.imageId = personData.imageId
            }

            if(personData.companyEmployeeId !== "")
              userItem.companyEmployeeId = personData.companyEmployeeId;

            userService.inviteAdminUser(userItem)
              .then(
                inviteData => {
                  resolveInvite(inviteData)
                },
                (error) => {
                  rejectInvite(error)
                }
              );
          })
        )
      })

      Promise.all(promiseInvites)
      .then(()=>{
        payload.id = data.customerId
        payload.userItem.userId = data.userId
        dispatch(success(payload));
        //sec delay for it to populate
        setTimeout(() => {
          dispatch(getCompanyList());
        }, 1000)
      })
      .catch((error)=>{
        dispatch(failure(error));
      })
    })
    .catch((failed)=>{
      if(failed.code !== undefined && failed.code === 1)
        dispatch(failureLogin(failed.error))
      else if(failed.error !== undefined)
        dispatch(failure(failed.error));
      else dispatch(failure(failed));
    })
  };

  function successLogin(payload) { return { type: companyConstants.ADD_NEW_COMPANY_LOGIN_SUCCESS, payload } }
  function failureLogin(error) { return { type: companyConstants.ADD_NEW_COMPANY_LOGIN_FAILURE, error } }
  function update(stage) { return { type: companyConstants.ADD_NEW_COMPANY_UPDATE, stage } }

  function successProperties(payload) { return { type: companyConstants.GET_COMPANYPROPERTIES_SUCCESS, payload } }

  function request() { return { type: companyConstants.ADD_NEW_COMPANY_REQUEST } }
  function success(items) { return { type: companyConstants.ADD_NEW_COMPANY_SUCCESS, items } }
  function failure(error) { return { type: companyConstants.ADD_NEW_COMPANY_FAILURE, error } }
}

function updateCompany(payload, redirect = null){
  return dispatch => {
    dispatch(request())
    companyService.updateCompany(payload)
      .then(
        results => {
          if(payload.customerSettings !== undefined && payload.customerSettings !== null){
            if(payload.customerSettings.id === ""){
              customerService.setCustomerSettings(payload.customerSettings)
                .then(
                    setPayload => {
                      if(payload.customerSettings.customerIds.length > 0 || payload.customerSettings.boards.length > 0){
                        customerService.applySetting(items)
                        .then(
                            d => {
                              dispatch(updateSetting(d.customers, d.boards))
                              dispatch(successSetting(payload.customerSettings))
                              dispatch(success(payload))
                              if(redirect !== null)
                                history.push(redirect)
                            },
                            error => {
                              dispatch(failure(error))
                            }
                        )
                      }else{
                        dispatch(successSetting(payload.customerSettings))
                        dispatch(success(payload))
                        if(redirect !== null)
                          history.push(redirect)
                      }
                    },
                    error => {
                      dispatch(failure(error))
                    }
                )
            }else{
              customerService.updateCustomerSettings(payload.customerSettings)
                .then(
                    setPayload => {
                      if(payload.customerSettings.customerIds.length > 0 || payload.customerSettings.boards.length > 0){
                        customerService.applySetting(payload.customerSettings)
                        .then(
                            d => {
                              dispatch(updateSetting(d.customers, d.boards))
                              dispatch(successSetting(payload.customerSettings))
                              dispatch(success(payload))
                              if(redirect !== null)
                                history.push(redirect)
                            },
                            error => {
                              dispatch(failure(error))
                            }
                        )
                      }else{
                        dispatch(successSetting(payload.customerSettings))
                        dispatch(success(payload))
                        if(redirect !== null)
                          history.push(redirect)
                      }
                    },
                    error => {
                      dispatch(failure(error))
                    }
                )
            }
          }else{
            dispatch(success(payload))
            if(redirect !== null)
              history.push(redirect)
          }
        },
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: companyConstants.UPDATE_COMPANY_REQUEST,  } }
  function success(payload) { return { type: companyConstants.UPDATE_COMPANY_SUCCESS, payload } }
  function updateSetting(customers, boards) { return { type: customerConstants.UPDATE_CUST_SETTINGS_SUCCESS, customers, boards } }
  function successSetting(payload) { return { type: customerConstants.SET_CUST_SETTINGS_SUCCESS, payload } }
  function failure(error) { return { type: companyConstants.UPDATE_COMPANY_FAILURE, error } }
}

function deleteCompany(id, authCode = ""){
  return (dispatch, getState) => {
    const userId = getState().authentication.userId;

    if(authCode !== "")
      dispatch(request(id, userId));
    else dispatch(request(id, ""));
    companyService.deleteCompany(id, authCode)
      .then(
        mfaUserId => {
          if(authCode !== ""){
            dispatch(getCompanyList())
            dispatch(success(id, userId, authCode))
            history.push(RoutesConstants.companies);
          }else{
            dispatch(success(id, mfaUserId, authCode))
          }
        },
        error => {
          dispatch(failure(id, error));
        }
      );
  };

  function request(id) { return { type: companyConstants.DELETE_COMPANY_REQUEST, id } }
  function success(id, done, authCode) { return { type: companyConstants.DELETE_COMPANY_SUCCESS, id, done, authCode } }
  function failure(id, error) { return { type: companyConstants.DELETE_COMPANY_FAILURE, id, error } }
}

function disableCompany(id){
  return (dispatch) => {
    dispatch(request(id));
    companyService.disableCompany(id)
      .then(
        payload => {
          dispatch(success(id))
        },
        error => {
          dispatch(failure(id, error));
        }
      );
  };

  function request(id) { return { type: companyConstants.COMPANY_SUSPEND_REQUEST, id } }
  function success(id, done, authCode) { return { type: companyConstants.COMPANY_SUSPEND_SUCCESS, id } }
  function failure(id, error) { return { type: companyConstants.COMPANY_SUSPEND_FAILURE, id } }
}

function enableCompany(company){
  return (dispatch) => {

    dispatch(request(company.id));
    companyService.enableCompany(company.id)
      .then(
        payload => {
          userService.login(company)
            .then(
              logindetails => {
                dispatch(successLogin(logindetails));
                dispatch(userActions.connectWebSocket());
                dispatch(success(company.id))
              },
              error => {
                //No has device, which should always work, so logout and refresh the page
                dispatch(userActions.logout(company.deviceId));
                if(RoutesConstants.login !== window.location.pathname)
                  window.location.reload(true);
              }
            );
        },
        error => {
          dispatch(failure(company.id, error));
        }
      );
  };

  function successLogin(payload) { return { type: companyConstants.ADD_NEW_COMPANY_LOGIN_SUCCESS, payload } }

  function request(id) { return { type: companyConstants.COMPANY_ACTIVE_REQUEST, id } }
  function success(id, done, authCode) { return { type: companyConstants.COMPANY_ACTIVE_SUCCESS, id } }
  function failure(id, error) { return { type: companyConstants.COMPANY_ACTIVE_FAILURE, id } }
}

function clearDeleteCompanyRequest(id){
  return dispatch => {
    dispatch(success(id));
  };

  function success(id) { return { type: companyConstants.CLEARDELETE_COMPANY_SUCCESS, id } }
}

function newUser(newUser, invites){
  return (dispatch, getState) => {
    dispatch(request());

    var cust = getState().authentication.customers.find(o => o.id === newUser.customerId)
    if(cust !== undefined && cust.branding !== undefined)
      newUser.branding = cust.branding
      userService.newUser(newUser)
      .then(
        payload => {
          dispatch(success());

          dispatch(userActions.populateListofUsers(newUser.customerId));

          let username = newUser.alias
          if(newUser.username !== undefined)
            username = newUser.username

          if(newUser.type === UserTypeEnum.Publish || newUser.type === UserTypeEnum.Create || newUser.type === UserTypeEnum.Master){
            var promiseArray = []
            invites.forEach(o => {
              promiseArray.push(
                new Promise(function(resolve, reject) {
                  userService.inviteUser(o)
                    .then(
                      inviteData => {
                        resolve(inviteData)
                      },
                      (error) => {
                        reject(error)
                      }
                    );
                })
              )
            })

            Promise.all(promiseArray)
            .then(()=>{
              dispatch(requestAlias(username, payload.registrationCode, newUser.sendEmail));
              newUser.userId = payload.userId;

              if(newUser.sendEmail === undefined || newUser.sendEmail === true)
                dispatch(userActions.sendWelcomeEmail(newUser));
            })
            .catch((error)=>{
              dispatch(failure(error, 0))
            })
          }else{
            dispatch(requestAlias(username, payload.registrationCode, newUser.sendEmail));
            newUser.userId = payload.userId;

            if(newUser.sendEmail === undefined || newUser.sendEmail === true)
              dispatch(userActions.sendWelcomeEmail(newUser));
          }
        },
        (error, code) => dispatch(failure(error, code))
      );
  };

  function request() { return { type: userConstants.NEW_USER_REQUEST } }
  function requestBoards(payload) { return { type: boardConstants.SETGROUPMEMBERSHIP_REQUEST, payload } }
  function requestAlias(alias, registrationCode, sendEmail) { return { type: userConstants.UPDATE_USER_INITALALIAS_REQUEST, alias, registrationCode, sendEmail } }
  function success() { return { type: userConstants.NEW_USER_SUCCESS } }
  function failure( error, code ) { return { type: userConstants.NEW_USER_FAILURE, error, code } }
}

function updateUser(roleChange, invites, redirect = RoutesConstants.admins){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    dispatch(request(roleChange[0].id, customerId))
    var promiseArray = []
    roleChange.forEach(o => {
      promiseArray.push(
        new Promise(function(resolve, reject) {
          userService.updateUser(o)
            .then(
              update => {
                resolve(o)
              },
              (error) => {
                reject(error)
              }
            );
        })
      )
    })

    Promise.all(promiseArray)
    .then((userData)=>{
      var promiseInvites = []
      invites.forEach(o => {
        promiseInvites.push(
          new Promise(function(resolve, reject) {
            if(o.type === "User"){
              userService.inviteUser(o)
                .then(
                  inviteData => {
                    resolve(inviteData)
                  },
                  (error) => {
                    reject(error)
                  }
                );
            }else{
              userService.inviteAdminUser(o)
                .then(
                  inviteData => {
                    resolve(inviteData)
                  },
                  (error) => {
                    reject(error)
                  }
                );
            }
          })
        )
      })

      Promise.all(promiseInvites)
      .then(()=>{
        userData.forEach((u)=>{
          dispatch(success(u, customerId))
        })

        //redirect to main page
        history.push(redirect);
      })
      .catch((error)=>{
        dispatch(failure(error, customerId))
      })
    })
    .catch((error)=>{
      dispatch(failure(error, customerId))
    })
  }

  function request(userId, customerId) { return { type: userConstants.UPDATE_REQUEST, userId, customerId } }
  function success(item, customerId) { return { type: userConstants.UPDATE_SUCCESS, item, customerId } }
  function failure(error, customerId) { return { type: userConstants.UPDATE_FAILURE, error, customerId } }
}

function deletePerson(userId){
  return (dispatch, getState) => {
    var parentCustomerId = getState().authentication.customerId
    var usersData = getState().users.data
    var user = getState().users.data[userId]
    var persons = getState().users.person[user.personId]

    var companies = getState().company
    var promiseArray = []

    var parentMainId = null
    if(companies[parentCustomerId] !== undefined){
      var m = companies[parentCustomerId].mainUserId
      var u = getState().users.data[m]
      parentMainId = u.personId
    }

    dispatch(request(parentCustomerId))

    function delUser(){
      companyService.deletePerson(user.personId)
        .then(
          payload => {
            persons.forEach(u => {
              dispatch(populateCompany(u.customerId));
            })
            dispatch(success(user.personId, parentCustomerId))
          },
          error => {
            dispatch(failure(error, parentCustomerId));
          }
        );
    }

    persons.forEach((u)=>{
      promiseArray.push(
        new Promise(function(resolveUser, rejectUser) {
          if(companies[u.customerId] !== undefined && companies[u.customerId].mainUserId === u.userId && parentMainId !== null){
            var pId = ""
            var list = companies[u.customerId].userIds.filter(o => {
              return usersData[o].type === UserTypeEnum.Master && usersData[o].personId === parentMainId
            })
            if(list.length > 0) pId = list[0]

            companyService.patchCompany({
              id: u.customerId,
              mainUserId: pId,
            })
            .then(
              payload => {
                resolveUser()
              },
              error => {
                resolveUser()
              }
            )
          }else{
            resolveUser()
          }
        })
      )
    })

    Promise.all(promiseArray)
    .then(()=>{
      delUser()
    })
    .catch((e)=>{
      delUser()
    })
  }

  function request(customerId) { return { type: companyConstants.DELETE_PERSON_REQUEST, customerId  } }
  function success(personId, customerId) { return { type: companyConstants.DELETE_PERSON_SUCCESS, personId, customerId } }
  function failure(error, customerId) { return { type: companyConstants.DELETE_PERSON_FAILURE, error, customerId } }
}

function deleteUser(personId, userType){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    var persons = getState().users.person[personId]
    var promiseArray = []

    if(persons !== undefined){
      persons.forEach((customer)=>{
        promiseArray.push(
          new Promise((resolveUser, rejectUser) => {
            if(userType.includes(customer.type))
              dispatch(userActions.deleteUser(customer.userId, customer.customerId))
            resolveUser()
          })
        )
      })
    }

    Promise.all(promiseArray)
    .then(()=>{
      dispatch(success(personId, customerId))
    })
    .catch((e)=>{
      dispatch(success(personId, customerId))
    })
  }

  function success(personId, customerId) { return { type: companyConstants.DELETE_PERSON_SUCCESS, personId, customerId } }
}

function postUsage(item){
  return (dispatch) => {
    companyService.postUsage(item)
      .then(
        payload => {

        },
        error => {

        }
      );
  }
}

function postUsageArray(items){
  return (dispatch) => {
    companyService.postUsageArray(items)
      .then(
        payload => {

        },
        error => {

        }
      );
  }
}


function getCustomerAuditLogs(dataItem){
  return async (dispatch, getState) => {
    const state = getState()

    const customerId = state.authentication.customerId
    var customerIds = [customerId]
    if(state.authentication.userType[customerId] !== undefined){
      if(state.authentication.userType[customerId] === UserTypeEnum.Master){
        customerIds = state.authentication.customerIds.slice(0)
      }
    }

    dispatch(request(customerId))
    dispatch(status("Retrieving User Information", customerId))
    try{
      var custItem = await companyService.getAuditLogsCustomer(customerIds, state)
      dispatch(status("Retrieving Company Usage Logs", customerId));
      var companyItem = await companyService.getAuditLogsCompany(customerIds, custItem, dataItem)
      /*
      dispatch(status("Retrieving Files Details"));
      var boardItem = await boardService.getAuditLogsFiles(custItem, state)
      dispatch(status("Retrieving Binders Details"));
      var binderItem = await boardService.getAuditLogsBinders(boardItem, state)
      dispatch(status("Retrieving Boards Usage Information"));
      var usageItem = await boardService.getAuditLogsBoardsUsage(binderItem, state)
      dispatch(status("Retrieving Deleted Information"));
      var missingItem = await boardService.getAuditLogsMissing(usageItem, state)
      */
      dispatch(status("Retrieving Usage Item Names", customerId));
      var allItem = await companyService.getAuditLogsItemNames(companyItem, state)
      dispatch(status("Populate Usage Item", customerId));
      var doneItem = await companyService.getAuditLogsPopulate(allItem, state)
      dispatch(success(doneItem, customerId))
    }catch(error){
      dispatch(alertActions.errorDiagnosticData(new CodeError("auditLogs",'901', error.msg !== undefined ? error.msg : "unknown", {
          error: error,
        }, error)))
      dispatch(failure(error))
    }
  };

  function request(customerId) { return { type: companyConstants.AUDIT_POPULATEFILELIST_REQUEST, customerId } }
  function success(allItem, customerId) { return { type: companyConstants.AUDIT_POPULATEFILELIST_SUCCESS, allItem, customerId } }
  function failure(error) { return { type: companyConstants.AUDIT_POPULATEFILELIST_FAILURE, error } }
  function status(message, customerId) { return { type: companyConstants.AUDIT_POPULATEFILELIST_STATUS, message, customerId }}
}
