import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { alert } from './alert.reducer';
import { intlReducer } from 'react-intl-redux'
import { popovers } from '../popover.reducer';

const rootReducer = combineReducers({
  authentication,
  alert,
  popovers,
  intl: intlReducer,
});

export default rootReducer;
