export default class WorkerPool {
  constructor(size){
    this.size = size || 1;
    this.currentCount = 0;
    this.jobs = [];
    this.resolves = {};
    this.rejects = {};
    this.globalMsgId = 0;
  }

  queueJob(c, data) {
    const newId = ++this.globalMsgId
    var job = {
      worker: c,
      id: newId,
      data
    };
    this.jobs.push(job);

		return new Promise((resolve, reject) => {
			this.resolves[newId] = resolve
			this.rejects[newId] = reject
      this.nextJob();
		})
  }

  nextJob() {
    if (this.jobs.length && this.currentCount < this.size) {
      this.currentCount++
      const job = this.jobs.shift();
      const {id, data} = job;
      const worker = new job.worker()

      const fnally = () => {
        delete this.resolves[id]
        delete this.rejects[id]
        this.currentCount--
        this.nextJob()
      }

      worker.postMessage(data);

      worker.onmessage = evt => {
//console.log('evt',evt)
        if(evt.data.completed === true){
          this.resolves[id](evt.data.msg);
          fnally();
          return
        }else if(evt.data.error !== undefined && evt.data.error !== ""){
console.log('err2',evt.data.error)
          this.rejects[id](evt.data.error)
          fnally()
          return
        }

        if(this.onmessage !== undefined)
          this.onmessage(evt)
      }

      worker.onerror = err => {
console.log('err',err)
        this.rejects[id](err)
        fnally();
      }

    }
  }
}
